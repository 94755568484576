import React, {Component} from 'react';
import {PANEL_TAG_WITHOUTPARAM} from "../../constants/routePaths";

class PanelTagItemComponent extends Component{
    render(){
        let {id,tag_name,created_date,selectTag} = this.props;
        return(
            <div className="table-item" onClick={()=>{
                this.props.history.push(`${PANEL_TAG_WITHOUTPARAM}?id=${id}`)
                selectTag({id,tag_name,created_date})
            }}>
                <div className="td-item t-1">
                    <i className={"icon-location"}></i>
                    <p>{tag_name}</p>
                </div>
                <div className="td-item t-2">
                    <p>{created_date}</p>
                </div>
            </div>

        )
    }
}

export default PanelTagItemComponent;