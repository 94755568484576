import React from 'react';
import {Link} from "react-router-dom";
import {EDITSMARTEDUCATIONDETAIL_WITHOUTPARAM} from "../../constants/routePaths";

const PanelParticipantItemComponent = ({name_surname,tc_no,id,toggleModal,department_name}) => (
    <div className="table-item" onClick={()=>{
        toggleModal({name_surname,id,tc_no,department_name})
    }}>
        <div className="td-item t-1">
            <i className="icon-make-survey"></i>
            <p>{name_surname}</p>
        </div>
        <div className="td-item t-2">
            <p>{tc_no}</p>
        </div>
        <div className="td-item t-3 resp-date">
            <p>{department_name}</p>
        </div>
    </div>
);

export default PanelParticipantItemComponent;