import React from 'react';
import {Link} from "react-router-dom";
import {DRAFT_DETAIL_WITHOUT_PARAM} from '../../constants/routePaths';
import Helper from '../../helper';

const PanelDraftItemComponent = ({name,document_type,created_user,created_date,id}) => (
    <Link to={`${DRAFT_DETAIL_WITHOUT_PARAM}${Helper.turkishToLower(name).split(" ").join("-").split("/").join("").split("?").join("")}-${id}`} className="table-item">
        <div className="td-item t-1">
            <i className={"icon-doc"}></i>
            <p>{name}</p>
        </div>
        <div className="td-item t-2 resp-type">
            {/*<p>{document_type}</p>*/}
            <p>Eğitim Dökümanı</p>
        </div>
        <div className="td-item t-3">
            <p>{created_user}</p>
        </div>
        <div className="td-item t-3 resp-date">
            <p>{created_date}</p>
        </div>
    </Link>
);

export default PanelDraftItemComponent;