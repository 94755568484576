import {RECEIVE_CHANGE_EDUCATION_STATUS, RECEIVE_EDUCATION_DETAIL} from '../constants/actionTypes';

const initialState = {};

const EducationDetailReducer = (state=initialState,action) => {
    switch (action.type){
        case RECEIVE_EDUCATION_DETAIL:
            return{
                ...state,
                [action.education]:action.payload
            };
        case RECEIVE_CHANGE_EDUCATION_STATUS:
            return{
                ...state,
                [action.education_id]:{
                    ...state[action.education_id],
                    info:{
                        ...state[action.education_id].info,
                        is_active:action.value
                    }
                }
            };
        default:
            return state;
    }
};

export default EducationDetailReducer;