import React, {Component, Fragment} from 'react'
import moment from 'moment';
import Helper from '../../helper';
import jsPDF from 'jspdf'
import html2canvas from "html2canvas";
import DocumentPrintModalComponent from "../../components/modals/documentPrintModalComponent";
import {createNewSessionQuizAction} from "../../actions/quizAction";
import {QUIZWEBTEACHER_WITHOUT_PARAMS} from "../../constants/routePaths";
import {connect} from "react-redux";
moment.locale("tr");

class EducationInfoComponent extends Component {
    isPrintModalOpened = false;
    type;
    printOfficialReport = () => {
        this.toggleModal(true,"print");
       /* let myWindow = window.open('', 'PRINT', 'height=400,width=600');
        let participants = [];
        this.props.participants.map((item) => {
            let participantItem = {
                name_surname: item.info ? item.info.name_surname : item.participant.name_surname,
                score: item.info ? item.correct_count + "/" + item.total_count : "-",
                tc_no: item.info ? item.info.tc_no : item.participant.tc_no
            };
            participants.push(participantItem);
        });
        myWindow.document.write(Helper.getOfficialReport(participants, this.props.sessionDetail.user_name_surname, "", this.props.educationDetail.name, moment(this.props.sessionDetail.created_date).format('DD MMMM')));
        myWindow.document.close(); // necessary for IE >= 10
        myWindow.focus(); // necessary for IE >= 10*!/

        myWindow.print();
        myWindow.close();
        return true;*/
    };
    goToQuizWeb = () => {
        this.props.dispatch(createNewSessionQuizAction({session_id: this.props.sessionDetail.id}, (result) => {
            if (result !== null) {
                this.props.history.push(`${QUIZWEBTEACHER_WITHOUT_PARAMS}${this.props.sessionDetail.id}/${result.quiz_id}/${result.question_id}`);
            }
        }))
    };
    downloadPdf = () => {
        this.toggleModal(true,"download");
    };
    toggleModal = (value,type) => {
        this.isPrintModalOpened = value;
        this.type = type;
        this.forceUpdate();
    };

    render() {
        let {educationDetail, sessionDetail, isQuizResult} = this.props;
        return (
            <Fragment>
                {
                    !isQuizResult &&
                    <div className="info-text-block">
                        <p><i className="icon-warn"></i> Eğitimi Tamamladınız!</p>
                    </div>
                }
                {
                    educationDetail &&
                    <div className="head-block">
                        <h2>{Helper.titleCase(educationDetail.name)}</h2>
                        <div className="info-item">
                            <span className="label-tag green">#{educationDetail.educationCategory.tag}</span>
                            <div className="info-text">
                                <span><i
                                    className="icon-calendar"></i>{moment(sessionDetail.session_start_date).format("D MMMM Y")}</span>
                            </div>
                            <div className="info-text">
                                <span><i className="icon-clock"></i>1sa 30dk</span>
                            </div>
                            <div className="info-text">
                                <span><i className="icon-user"></i>{sessionDetail.user_name_surname}</span>
                            </div>
                        </div>

                    </div>

                }
                <div className="doc-action-block">
                    <span className="btn-2" onClick={this.printOfficialReport}>BELGE YAZDIR</span>
                    <span className="btn-2" onClick={this.downloadPdf}>BELGEYİ İNDİR</span>
                    <span className="btn-2" onClick={this.goToQuizWeb}>QUİZİ ÇÖZ</span>
                </div>
                {
                    this.isPrintModalOpened === true &&
                    <DocumentPrintModalComponent participants={this.props.participants} sessionDetail={sessionDetail} type={this.type}
                                                 educationDetail={educationDetail} toggleModal={this.toggleModal}/>
                }
            </Fragment>
        )
    }
}
;

export default connect()(EducationInfoComponent)