import React from 'react';
import Helper from '../../helper';

const PanelQuizItemComponent = ({education,prepared,quiz_created,toggleEditedQuiz,id,education_id}) => (
    <div className="table-item" onClick={()=>{
        toggleEditedQuiz(education_id);
    }}>
        <div className="td-item t-1">
            <i className="icon-newquiz"></i>
            <p>{Helper.titleCase(education)}</p>
        </div>
        <div className="td-item t-2">
            <p>{prepared}</p>
        </div>
        <div className="td-item t-3 resp-date">
            <p>{quiz_created}</p>
        </div>
    </div>
);

export default PanelQuizItemComponent;