import React from 'react';

const QuizResultByEducationItemComponent = ({name_surname,correct_count,question_count}) => (
    <div className="table-item" >
        <div className="td-item t-1">
            <i className="icon-newquiz"></i>
            <p style={{color:parseInt(correct_count)/parseInt(question_count)>=0.5?"green":"red"}}>{name_surname}</p>
        </div>
        <div className="td-item t-2">
            <p style={{color:parseInt(correct_count)/parseInt(question_count)>=0.5?"green":"red"}}>{`${correct_count}/${question_count}`}</p>
        </div>
    </div>
);

export default QuizResultByEducationItemComponent;