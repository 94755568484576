import React from 'react';

const PanelMenuItemComponent = ({url,goToPage,title,notificationsItem,page,subPages,icon,pathName,role_id}) => (
    <li className={"main-head "}>
        <div className={"top-main "+(pathName === url ? "active":"")} onClick={() => {
            goToPage(url+(url==="/panel/raporlar"?"/egitimler":""))
        }}>
            <div className="main-head-text"><i className={icon}></i>
                <h3>{title}</h3>
            </div>
            {
                (notificationsItem !== null && notificationsItem>0) &&
                <div className="count-item">
                    <span>{notificationsItem}</span>
                </div>
            }

        </div>
        {
            (page === "dokumanlar" || page==="egitimler" || page==="raporlar") && <ul>
                {
                    subPages && subPages.map((subPageItem,key)=>
                        <li className={(pathName === url+subPageItem.link ? "active":"")} key={key} onClick={() => {
                            goToPage(url+subPageItem.link)
                        }}>{subPageItem.title}</li>
                    )
                }
            </ul>
        }
    </li>
);

export default PanelMenuItemComponent;