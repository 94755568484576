import {GET_EDUCATION_CATEGORIES} from '../constants/actionTypes'

const initialState = [];

const EducationCategoriesReducer = (state=initialState,action) => {
    switch (action.type){
        case GET_EDUCATION_CATEGORIES:
            return [
                ...JSON.parse(action.payload)
            ];
        default:
            return state;
    }
};

export default EducationCategoriesReducer;