import React from 'react';
import QuizResultListItemComponent from "../../components/quizResult/quizResultListItemComponent";

const sortResult = (a,b) => {
    return b.correct_count - a.correct_count;
};

const QuizResultListContainer = ({quizResults}) => {
    let bests = quizResults.sort(sortResult).filter(item=>item.correct_count/item.total_count >= 0.7 );
    let pasts = quizResults.sort(sortResult).filter(item=> item.correct_count/item.total_count < 0.7 && item.correct_count/item.total_count >= 0.5 );
    let fails = quizResults.sort(sortResult).filter(item=>item.correct_count/item.total_count < 0.5 );
    return(
        <div className="right-content-root">
            <div className="head-text-item">
                <h2>Quiz Sonuçları</h2>
                <p>Toplam Katılımcı : {quizResults.length}</p>
            </div>

            <div className="result-list-block">
                {
                    bests.length > 0 &&
                    <div className="result-list-item best-list">
                        <span className="degree">EN İYİLER</span>
                        <div className="name-list">
                            {
                                bests.map((quizResultItem,key)=>
                                    <QuizResultListItemComponent key={key} {...quizResultItem}/>
                                )
                            }
                        </div>
                    </div>
                }

                {
                    pasts.length > 0 &&
                    <div className="result-list-item middle-list">
                        <span className="degree">GEÇENLER</span>
                        <div className="name-list">
                            {
                                pasts.map((quizResultItem,key)=>
                                    <QuizResultListItemComponent key={key} {...quizResultItem}/>
                                )
                            }
                        </div>
                    </div>
                }

                {
                    fails.length > 0 &&
                    <div className="result-list-item last-list">
                        <span className="degree">EĞİTİMİ TEKRAR ETMESİ GEREKENLER</span>
                        <div className="name-list">
                            {
                                fails.map((quizResultItem,key)=>
                                    <QuizResultListItemComponent key={key} {...quizResultItem}/>
                                )
                            }
                        </div>
                    </div>
                }
            </div>

        </div>
    );
}

export default QuizResultListContainer;