import React, {Component} from 'react';
import {connect} from "react-redux";
import {endQuizAction, getQuizProcessAction} from "../actions/quizAction";
import QuizDurationComponent from "../components/quiz/quizDurationComponent";
import {QUIZRESULT, QUIZRESULT_WITHOUT_PARAM} from "../constants/routePaths";
import Helper from '../helper';

class QuizProcessPage extends Component {
    quizProcess;
    interval;
    componentDidMount() {
      this.getData();
      this.interval = setInterval(()=>{
          this.getData();
      },60000)
    }

    getData = () => {
        this.props.dispatch(getQuizProcessAction({quiz_id:this.props.match.params.quiz_id,education_session_id:this.props.match.params.education_session_id},(res)=>{
            if(res!==false){
                this.quizProcess = res;
                this.forceUpdate();
            }
        }))
    };

    componentWillUnmount() {
        clearInterval(this.interval);
        this.interval = null;
    }

    endQuiz = () => {
        let params = {quiz_id:this.props.match.params.quiz_id,education_session_id:this.props.match.params.education_session_id};
        this.props.dispatch(endQuizAction(params,(result)=>{
            if(result){
                this.props.history.push(`${QUIZRESULT_WITHOUT_PARAM}${this.props.match.params.education_session_id}`);
            }
        }))
    };

    render() {
        let rate = 0;
        if(this.quizProcess){
            rate = this.quizProcess.finishedCount === 0 ? 0 : (this.quizProcess.finishedCount*100) / this.quizProcess.totalCount
        }
        return (
            <div className="mainRoot">
                {
                    this.quizProcess &&
                    <div className="quiz-processing-root">
                        <div className="status-root">
                            <div className="text-block">
                                <div className="left-text-item">
                                    <h2>{Helper.titleCase(this.quizProcess.educationInfo.name)}</h2>
                                    <div className="tag-block">
                                        <span className="label-tag grey">#MESLEKİ EĞİTİM</span>
                                        <span className="label-tag grey">#QUIZ</span>
                                    </div>
                                </div>
                            </div>
                            <div className="status-block">
                                <QuizDurationComponent sessionQuiz={this.quizProcess.sessionQuiz} quizInfo={this.quizProcess.quizInfo}/>
                                <div className="status-item green-progress">
                                    <div className="status-text-item">
                                        <p>Tamamlanan</p>
                                        <p className="green">%{rate} Tamamlandı</p>
                                        <p>Beklenen</p>
                                    </div>
                                    <div className="status-bar">
                                        <div style={{width:rate+"%"}} className="progress-item"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="desc-text-block">
                            <div className="img-item">
                                <img src="/assets/img/common/qrCodeGorsel.png" alt="QR"/>
                            </div>
                            <p className="warn-text">Lütfen eğitmeniniz tarafından verilen QR kodları telefonunuzun
                                kamerasına okutunuz.</p>
                            <p>Katılımcıların tamamı telefonlarından quiz sorularını tamamladığında quiz tamamlanacaktır.
                                İsterseniz herhangi bir zamanda Quiz’i sonlandırıp sonuçları görebilirsiniz.</p>
                            <div className="btn-item" onClick={this.endQuiz}>
                                <span className="btn-green">QUIZ'İ SONLANDIR</span>
                            </div>
                        </div>


                    </div>
                }
            </div>
        )
    }
}


export default connect()(QuizProcessPage);
