import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import NotificationItemComponent from "../../components/header/notificationItemComponent";

class NotificationContainer extends Component {
    render() {
        let {notifications,history} = this.props;
        return (
            <Fragment>
                <div className="arr-up"></div>
                {
                    (notifications && notifications.length > 0) ? notifications.map((notify, i) =>
                        <NotificationItemComponent history={history} key={i} {...notify}/>
                    )
                        :
                        <div className="open-not-item new-item">
                            <p>Bildiriminiz bulunmuyor.</p>
                        </div>
                }
            </Fragment>
        )
    }
}
;

const mapStateToProps = (state) => ({
    notifications: state.homeReducer.notifications
});

export default connect(mapStateToProps)(NotificationContainer);