import React,{Component} from 'react';
import {connect} from "react-redux";
import PanelBreadCrumbComponent from "../../components/Panel/panelBreadCrumbComponent";
import PanelQuizItemComponent from "../../components/Panel/panelQuizItemComponent";
import {getQuizEducationAction} from "../../actions/panelAction";
import CreateQuizModalComponent from "../../components/modals/createQuizModalComponent";
import InfiniteScroll from 'react-infinite-scroller';


class PanelQuizListContainer extends Component{
    activePage = 0;
    order = "education";
    order_type = "asc";
    isMore = true;
    editedQuizId;
    loadFunc = () => {
        let params = {
            activePage:this.activePage,
            order:this.order,
            order_type:this.order_type
        };
        this.props.dispatch(getQuizEducationAction(params,(res,isMore)=>{
            if(res===true){
                this.isMore = isMore;
                this.activePage++;
                this.forceUpdate();
            }
        }));
    };
    sortFunc = (sort_name) => {
        // this.order_type = this.order === sort_name ? "asc":"desc";
        this.order_type = this.order_type === "asc" ? "desc":"asc";
        this.order = sort_name;
        this.activePage = 0;
        this.loadFunc();
    };
    toggleEditedQuiz = (quizId) => {
        this.editedQuizId = quizId;
        this.forceUpdate();
    };
    render() {
        let {items} = this.props;
        return (
            <div className="panel-content-block">
                <PanelBreadCrumbComponent page="Quizler"/>
                <div className="table-root">
                    <div className="table-container">
                        <div className="table-head">
                            <div className="t-1 td-item" onClick={()=>{this.sortFunc("education")}}>
                                <p>Bağlı Olduğu Eğitim</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-2 td-item" onClick={()=>{this.sortFunc("prepared")}}>
                                <p>Quiz'i Hazırlayan</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-3 td-item resp-date" onClick={()=>{this.sortFunc("quiz_created")}}>
                                <p>Oluşturulma Tarihi</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                        </div>
                        {
                            items &&
                            <InfiniteScroll
                                pageStart={0}
                                loadMore={this.loadFunc}
                                hasMore={this.isMore && items.length > 19}
                                threshold={250}
                                // loader={<div className="loader" key={0}>Loading ...</div>}
                            >
                                <div className="table-block">
                                    {
                                        items && items.map((item,key)=>
                                            <PanelQuizItemComponent toggleEditedQuiz={this.toggleEditedQuiz} key={key} {...item}/>
                                        )
                                    }
                                </div>
                            </InfiniteScroll>
                        }
                    </div>
                </div>
                {
                    this.editedQuizId && <CreateQuizModalComponent  editedQuizId={this.editedQuizId} toggleModal={this.toggleEditedQuiz}/>
                }
            </div>
        );
    }
}

const mapStateToProps = (state,ownProps) => ({
    items:state.PanelReducer.quiz
});

export default connect(mapStateToProps)(PanelQuizListContainer);