import React from 'react';
import {EDUCATIONDETAIL_WITHOUTPARAM} from "../../constants/routePaths";
import Helper from '../../helper';
import {Link} from "react-router-dom";

const SearchResultItemComponent = ({item}) => (
    item.education ?
        <li className="edu-li"><i
            className="icon-calendar"></i>
            <div className="edu-li-text">
                <p>{Helper.titleCase(item.education.name)}</p>
                <span>{item.planned_date}
                    - İstanbul Genel Merkez - {item.user.name_surname}</span>
            </div>
        </li>
        :

        item.educations ?
            <li>
                <Link
                    to={`${EDUCATIONDETAIL_WITHOUTPARAM}${Helper.turkishToLower(item.educations.name).split(" ").join("-").split("/").join("").split("?").join("")}-${item.educations.id}`}>
                    <i className="icon-doc"></i> {Helper.titleCase(item.educations.name)}
                </Link></li>

            :
            <li>
                <Link
                    to={`${EDUCATIONDETAIL_WITHOUTPARAM}${Helper.turkishToLower(item.name).split(" ").join("-").split("/").join("").split("?").join("")}-${item.id}`}>
                    <i className="icon-doc"></i> {Helper.titleCase(item.name)}
                </Link></li>


);

export default SearchResultItemComponent;