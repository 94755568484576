import React,{Component} from 'react';

class CloseEditModalComponent extends Component{
    contentBlock = React.createRef();
    render(){
        let {toggleCloseEdit} = this.props;
        return(
            <div className="lb-root" onClick={(e) => {
                if (this.contentBlock.current.contains(e.target) === false) {
                    toggleCloseEdit(false)
                }
            }}>
                <div className="lb-block">
                    <div ref={this.contentBlock} className="content-block">
                        <div className="content-item">
                            <i className="icon-close" onClick={() => {
                                toggleCloseEdit(false)
                            }}></i>
                            <div className="text-item">
                                <p className="head-text">Düzenleme Kapalı.</p>
                                <p>Bu döküman için bir düzenleme önerisi yapılmış. Bu nedenle şimdilik yeni bir düzenleme önerisi alamıyoruz. Teşekkürler..</p>
                            </div>
                            <div className="btn-block">
                                <span className="btn-2" onClick={() => {
                                    toggleCloseEdit(false)
                                }}>GERİ DÖN</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default CloseEditModalComponent;