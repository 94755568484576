import React,{Component} from 'react';

class RadioFieldComponent extends Component{
    render(){
        const {input, meta: {error, touched}, placeholder, type, label,linkIsShowing,example, options} = this.props;
        return(
            <div className="radio-container">
                <label>{label}
                    {
                        example && <span> {example}</span>
                    }
                </label>
                <div className="radio-block">
                    {
                        options.map((item,key)=>
                            <div className="radio-item" key={key}>
                                <input onChange={()=>{input.onChange(item.value)}} checked={input.value===item.value} id={`radio-${key}-${input.name}`} name={`radio-${key}-${input.name}`} type="radio"/>
                                <label htmlFor={`radio-${key}-${input.name}`} className="radio-label">{item.text}</label>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }
}

export default RadioFieldComponent;