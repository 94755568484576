import React, {Component, Fragment} from 'react';
import ReactQuill, {Mixin,Quill} from 'react-quill';
// import Quill from 'quill/core';
import Helper from '../../helper';
// import 'react-quill/dist/quill.bubble.css';
import dompurify from 'dompurify';

/*const Video = Quill.import('formats/video');
const Link = Quill.import('formats/link');

class CoustomVideo extends Video {
    static create(value) {
        console.log(value);
        if(value && value.indexOf("embed")===-1){
            const node = super.create(value);
            const video = document.createElement('video')
            video.setAttribute('controls', true);
            video.setAttribute('type', "video/mp4");
            video.setAttribute('style', "height: 400px; width: 100%");
            video.setAttribute('src', this.sanitize(value));
            node.appendChild(video);
            return node;
        }else{
            const node = super.create(value.replace("#IFRAME#",""));
            const video = document.createElement('iframe');
            video.setAttribute('controls', true);
            video.setAttribute('style', "height: 400px; width: 100%");
            video.setAttribute('src', this.sanitize(value.replace("#IFRAME#","")));
            node.appendChild(video);
            return node;
        }

    }

    static sanitize(url) {
        return Link.sanitize(url);
    }
};
CoustomVideo.blotName = 'video';
CoustomVideo.className = 'ql-video';
CoustomVideo.tagName = 'DIV';

Quill.register('formats/video', CoustomVideo);*/

class ReactQuillComponent extends Component {
    quillElement;
    addElement;
    subItemVideo = false;
    videoInput = React.createRef();
    isEntered = false;
    quilKey = Helper.generateString(10);
    componentDidMount() {
        /*console.log("this.quillElement",this.quillElement);
        let Block = Quill.import('blots/block');
        Block.tagName = 'div';
        Quill.register(Block);*/
        /*if(this.quillElement){
            this.quillElement.editor.on('text-change', (delta, oldDelta, source) => {
                if (source == 'api') {
                } else if (source == 'user') {
                   /!* if(delta.ops[1].insert && delta.ops[1].insert.charCodeAt(0) === 10){
                        // let range = this.quillElement.getEditor().getSelection();
                        // let position = range ? range.index : 0;
                        // this.quillElement.getEditor().insertText(position+1, 'Hello, World! ');
                        // range.length=1;
                        // range.index=range.index+1;
                        // this.quillElement.getEditor().getLines(range)[0].domNode.classList.add("plus-icon")
                        // console.log(Helper.getOffset(this.quillElement.getEditor().getLines(range)[0].domNode,document.body));
                        /!*let range = this.quillElement.editor.getSelection();
                        let text = this.quillElement.editor.getLines(range);
                        console.log("------",range);
                        console.log("------",text);
                        console.log(this.quillElement.editor.container.firstChild);*!/
                    }*!/
                }
            });
        }*/
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(nextProps.isReKey === true){
            this.quilKey = Helper.generateString(10);
        }
        return true;
    }

    module = {
        toolbar: {
            container: [
                // ['list-2', 'bold', 'italic', {'header': 2}],
                ['bold', 'italic', {'header': 2},'link',{'list': 'bullet'},{'indent': '-1'}, {'indent': '+1'},'clean'],
                // [{'list': 'bullet'}],
                // [{'indent-list': '-1'}, {'indent-list': '+1'}],
                // [{'indent': '-1'}, {'indent': '+1'}],
                // ['clean']
            ],
            handlers: {
                "list-2": () => {
                    this.addSubItem();
                },
                "indent": (value) => {
                    let range = this.quillElement.editor.getSelection();
                    if (range === null || range.length === 0) return;
                    // let text = this.quillElement.editor.getLines(range)[0].domNode.innerHTML;
                    let text = "";
                    this.quillElement.editor.getLines(range).map((element,index)=>{
                        text += `<p>${element.domNode.innerHTML}</p>`;
                    });
                    if(this.quillElement.editor.getLines(range)[0].domNode.tagName === "LI"){
                        this.quillElement.editor.format("indent",value);
                    }else{
                        //TODO: İÇERİ ELEMENT EKLEME
                        if (value === "+1") {
                            //TODO: KONTROL SEBEBİ BÜTÜN MADDEYİ Mİ ALMAK İSTİYOR YOKSA SADECE BİR KISMINI MI?
                            // if(this.props.subItem.content.replace(/(<([^>]+)>)/ig,"").indexOf(text) === 0){
                            if(this.quillElement.editor.getLines(range).prev === null){
                                this.addSubItem(this.props.subItem.content);
                            }else{
                                // text = text[0] === " " ? text.replace(" ", "") : text;
                                text = text.replace(" ", "");
                                let subItemArr = this.props.subItem.content.split(text);
                                let lastText = text + (subItemArr[1] ? subItemArr[1] : "");
                                let oldText = this.props.subItem.content.replace(lastText, "");
                                this.addSubItem(lastText,oldText);
                            }
                        }
                        //TODO: ÜST ELEMENT EKLEME
                        else if (value === "-1") {
                            //TODO: KONTROL SEBEBİ BÜTÜN MADDEYİ Mİ ALMAK İSTİYOR YOKSA SADECE BİR KISMINI MI?
                            let indexArr = this.props.index.toString().split(".");
                            // if (this.props.subItem.content.replace(/(<([^>]+)>)/ig,"").indexOf(text) === 0) {
                            if (this.quillElement.editor.getLines(range)[0].prev === null) {
                                // if (indexArr.length > 1) {
                                this.props.subItems.splice(this.props.indexKey, 1);
                                let newArr = [
                                    ...this.props.documentItems.slice(0, indexArr[indexArr.length - 1]),
                                    {...this.props.subItem, ref_id: this.props.documentItems[0].ref_id},
                                    ...this.props.documentItems.slice(indexArr[indexArr.length - 1])

                                ];
                                this.addUpperItem(newArr);
                                // }
                            } else {
                                String.prototype.replaceAt=function(index, replacement, toReplace) {
                                    return this.substr(0, index) + this.substr(index).replace(replacement,toReplace) ;
                                };
                                text = text[0] === " " ? text.replace(" ", "") : text;
                                let subItemArr = this.props.subItem.content.substring((range.index-range.length)-1).split(text);
                                let lastText = text + (subItemArr[1] ? subItemArr[1] : "");
                                // this.props.subItem.content = this.props.subItem.content.replace(lastText.replace(/(<([^>]+)>)/ig,""), "");
                                this.props.subItem.content = this.props.subItem.content.replaceAt((range.index-range.length)-1,lastText.replace(/(<([^>]+)>)/ig,""), "");
                                let newArr = [
                                    ...this.props.subItems.slice(0, this.props.indexKey+1),
                                    {
                                        ...this.props.subItem,
                                        id:null,
                                        content: "<p>" + lastText,
                                        ref_id: this.props.documentItems[0].ref_id
                                    },
                                    ...this.props.subItems.slice(this.props.indexKey+1)
                                ];
                                this.props.subItems.length = 0;
                                this.props.updateComponent();
                                newArr.map((item) => {
                                    this.props.subItems.push(item);
                                });
                                this.props.updateComponent();
                                // this.addUpperItem(newArr);
                            }
                        }
                    }
                }
                // indent:this.indentHandler
            }
        }
    };
    addUpperItem = (newArr) => {
        this.props.documentItems.length = 0;
        this.props.updateComponent();
        newArr.map((item) => {
            this.props.documentItems.push(item);
        });
        this.props.updateComponent();
    };
    addSubItem = (text, oldText) => {
        if (this.props.subItems.length-1 > 0) {
            // this.props.addSubItem(this.props.subItems[this.props.indexKey - 1], `${this.props.index}.${this.props.indexKey}`, 0, this.props.subItem.content, text?text: true);
            if (text) {
                this.props.subItems[this.props.indexKey].content = oldText;
                this.props.addSubItem(this.props.subItems[this.props.indexKey], `${this.props.index}.${this.props.indexKey+1}`, 0, text ? text : this.props.subItem.content, true);
            }else{
                // this.props.subItems.splice(this.props.indexKey, 1);
                this.props.subItems[this.props.indexKey].content = "";
                this.props.addSubItem(this.props.subItems[this.props.indexKey], `${this.props.index}.${this.props.indexKey+1}`, 0, text ? text : this.props.subItem.content, true);
            }
        } else {
            this.props.subItems.splice(this.props.indexKey, 1);
            this.props.subItems.push({
                education_id: this.props.subItem.education_id,
                content: oldText ? this.props.subItem.content.replace(text, "") : "",
                heading_id: this.props.subItem.heading_id,
                order_number: 1,
                ref_id: this.props.subItem.ref_id,
                id: this.props.subItem.id
            });
            delete this.props.subItem.id;
            // this.props.addSubItem(this.props.subItems[0], `${this.props.index}.1`, 0, this.props.subItem.content, text?text: true);
            this.props.addSubItem(this.props.subItems[0], `${this.props.index}.1`, 0, text, true);
        };
    };
    addItemToTopElement = () => {
        //TODO:DEĞİŞİKLİK YAPILDIĞINDA ÖNCEKİ MADDENİN ÜSTÜNE YAZIYOR!! BAKILACAK.
        let urlArr = this.props.urlLink.split("-");
        let newObj = {
            education_id: urlArr[urlArr.length - 1],
            content: "",
            heading_id: this.props.documentItems[0].heading_id,
            order_number: 1,
            ref_id: null
        };
        let indexArr = this.props.index.toString().split(".");
        let spliceIndex = parseInt(indexArr[indexArr.length - 1]);
        indexArr.splice(indexArr.length - 1, 1);
        this.props.documentItem.subItems.splice(this.props.indexKey, 1);
        this.props.documentItems.splice(spliceIndex, 0, newObj);
        // this.quilKey = Helper.generateString(10);
        this.props.updateComponent(true);
        // let focusIndex = indexArr.length > 0 ? `${indexArr.join(".")}.${spliceIndex + 1}` : `${spliceIndex + 1}`;
        // this.props.focusElement(focusIndex);
        /* let newArr = Object.assign([], this.props.documentItems);
         this.props.documentItems.length = 0;
         this.props.updateComponent();
         setTimeout(() => {
             this.props.documentItems.length = 0;
             newArr.map((item, key) => {
                 if (key === spliceIndex) {
                     this.props.documentItems.push(newObj);
                 }
                 this.props.documentItems.push(item);
             });
             if (newArr.length - 1 < spliceIndex) {
                 this.props.documentItems.push(newObj);
             }
             this.props.updateComponent();
             let focusIndex = indexArr.length > 0 ? `${indexArr.join(".")}.${spliceIndex + 1}` : `${spliceIndex + 1}`;
             this.props.focusElement(focusIndex);
         }, 1);*/
    };
    addVideoToggle = (value) => {
        this.subItemVideo = value;
        if (this.subItemVideo === true) {
            setTimeout(() => {
                this.videoInput.current.focus();
            }, 150)
        }
        this.forceUpdate();
    };
    openEdit = (value) => {
        this.addElement.classList.toggle("opened");
    };
    getCaretPosition = (element) => {
        var caretOffset = 0;
        var ie = (typeof document.selection != "undefined" && document.selection.type != "Control") && true;
        var w3 = (typeof window.getSelection != "undefined") && true;
        if (w3) {
            var range = window.getSelection().getRangeAt(0);
            var preCaretRange = range.cloneRange();
            preCaretRange.selectNodeContents(element);
            preCaretRange.setEnd(range.endContainer, range.endOffset);
            caretOffset = preCaretRange.toString().length;
        } else if (ie) {
            var textRange = document.selection.createRange();
            var preCaretTextRange = document.body.createTextRange();
            preCaretTextRange.moveToElementText(element);
            preCaretTextRange.setEndPoint("EndToEnd", textRange);
            caretOffset = preCaretTextRange.text.length;
        }
        return caretOffset;
    };

    render() {
        let {subItem, addSubItem, documentItem, index, indexKey, onKeyDown, subItems, setComponent, documentItems, updateComponent, setOnFocusElement, focusedIndex, subImgOnLoad, toggleSmartDocument, addTitle, addSubTitle, subVideoOnLoad, isSmartDocuments, isReadOnly, setAddBtnPosition, mainObject, documentKey} = this.props;
        const sanitizer = dompurify.sanitize;
        return (
            <div className="content-text-block">
                <div
                    className={"list-count" + (focusedIndex === `${index}.${indexKey + 1}` ? " head-count add-head " : "")}>
                    <div className="add-content-block" ref={(component) => {
                        setComponent("content-block" + `${index}.${indexKey + 1}`, component);
                        this.addElement = component;
                    }}>

                        <div style={{display: this.subItemVideo === false ? "block" : "none"}}>
                            <i className="icon-add-plus" onClick={() => {
                                this.openEdit("content-block" + `${index}.${indexKey + 1}`)
                            }}></i>
                            <div className="add-content-list">
                                <ul>
                                    <li>
                                        <input onChange={(e) => {
                                            subImgOnLoad(e, subItem, ("content-block" + `${index}.${indexKey + 1}`))
                                        }} accept="image/*" type="file"/>
                                        <i className="icon-add-img"></i>
                                    </li>
                                    <li onClick={() => {
                                        this.addVideoToggle(true)
                                    }}><i className="icon-add-video"></i></li>
                                    <li onClick={() => {
                                        subItem.content += '<div><img src=""/></div>';
                                        this.addElement.classList.toggle("opened");
                                        updateComponent();
                                    }}><i className="icon-add-delimeter"></i></li>
                                    <li onClick={addTitle}><i className="icon-h1"></i></li>
                                    <li onClick={addSubTitle}><i className="icon-h2"></i></li>
                                </ul>
                            </div>
                        </div>
                        {
                            this.subItemVideo === true &&
                            <Fragment>
                                <i className="icon-add-plus" onClick={() => {
                                    this.openEdit("content-block" + `${index}.${indexKey + 1}`)
                                    this.subItemVideo = false;
                                    this.forceUpdate();
                                }}></i>
                                <div className="add-input-block">
                                    <input ref={this.videoInput} type="text"
                                           placeholder="Video Linkiniz Buraya Yazın veya Yapıştırın."
                                           onKeyUp={(e) => {
                                               subVideoOnLoad(e, subItem, "content-block" + `${index}.${indexKey + 1}`,false,()=>{
                                                   this.subItemVideo = false;
                                                   this.forceUpdate();
                                               },null,this.quillElement.editor.getSelection());
                                           }}/>
                                </div>
                            </Fragment>

                        }
                    </div>
                    <span>{`${index}.${indexKey + 1}`}</span>
                </div>
                {
                    isReadOnly === true ?
                        <div className="text-item" dangerouslySetInnerHTML={{__html: sanitizer(subItem.content)}}></div>
                        :
                        <div className="text-item">
                            <ReactQuill value={subItem.content}
                                        ref={(component) => {
                                            this.quillElement = component;
                                            setComponent(`${index}.${indexKey + 1}`, component);
                                        }}
                                        onChangeSelection={()=>{
                                            if(this.quillElement){
                                                let range = this.quillElement.editor.getSelection();
                                                let regex = /(<([^>]+)>)/ig;
                                                let isLast =false;
                                                if(range && (!subItem.subItems || subItem.subItems.length === 0) && (!subItem.content || subItem.content.replace(regex,"").length <= range.index)){
                                                    isLast = Helper.checkArrayLastElement(mainObject.subItems,subItem);
                                                }
                                                setAddBtnPosition(this.quillElement,isLast,documentKey,subItem,"content-block" + `${index}.${indexKey + 1}`,null,false,this.quillElement.editor);
                                            }
                                        }}
                                        key={this.quilKey}
                                        // scrollingContainer={this.quillElement}
                                        readOnly={isReadOnly}
                                        theme="bubble"
                                        onFocus={() => {
                                            setOnFocusElement(`${index}.${indexKey + 1}`);
                                        }}
                                        scrollingContainer={document.documentElement}
                                        modules={this.module}
                                        onChange={(value, delta, source, editor) => {
                                            subItem.content = value;
                                            updateComponent();
                                        }}
                                        onKeyUp={(e) => {
                                            let regex = /(<([^>]+)>)/ig;
                                            if (this.isEntered === true && e.keyCode === 13 && this.getCaretPosition(e.target) >= subItem.content.replace(regex, "").length && (!subItem.subItems || subItem.subItems.length===0) && subItems.length===subItems.indexOf(subItem)+1) {
                                                this.isEntered = false;
                                                let regex = /(<([^>]+)>)/ig;
                                                if (subItem.content.indexOf("img")===-1 && subItem.content.replace(regex, "") === "") {
                                                    this.addItemToTopElement();
                                                } else {
                                                    addSubItem(documentItem, index, indexKey)
                                                }
                                            } else if(e.keyCode === 13){
                                                this.isEntered = true;
                                            } else {
                                                this.isEntered = false;
                                                let newSubItem = Object.assign({}, subItem);
                                                let objectKey = indexKey === 0 ? `${index}` : `${index}.${indexKey}`;
                                                onKeyDown(e, newSubItem, subItems, objectKey, indexKey, documentItem);
                                                let regex = /(<([^>]+)>)/ig;
                                                subItem.clear = !subItem.content || (subItem.content.indexOf("img")===-1 && subItem.content.replace(regex, "") === "");
                                            }
                                        }}
                            />
                        </div>
                }

            </div>
        )
    }
};

export default ReactQuillComponent;