import React, {Fragment, Component} from 'react';
import dompurify from 'dompurify';

const sanitizer = dompurify.sanitize;
const getContent = (subItems, index, focusText) => {
    return subItems.map((subItem, key) =>
        <Fragment key={key}>
            <div className="content-text-block" ref={"text-item"} onClick={(e) => {
                focusText(e.target, subItem)
            }}>
                <div className="list-count ">
                    <span>{`${index}.${key + 1}`}</span>
                </div>
                <div className="text-item" dangerouslySetInnerHTML={{__html: subItem.content}}></div>
            </div>
            {
                (subItem.subItems && subItem.subItems.length > 0) && getContent(subItem.subItems, `${index}.${key + 1}`, focusText)
            }
        </Fragment>
    )
};

class EducationDocumentItemComponent extends Component {
    getContent = (subItems, index, focusText, focusElementId, approvedSubItems, isVersion) => {
        return subItems && subItems.map((subItem, key) =>
            <Fragment key={key}>
                <div
                    className={`content-text-block ${subItem.id && focusElementId === subItem.id.toString() ? "highlights" : ""} ${isVersion && (!approvedSubItems || !approvedSubItems[key] || approvedSubItems[key].content !== subItem.content) ? "edited" : ""}`}
                    ref={(component) => {
                        this[`${index}.${key + 1}`] = component
                    }} onClick={(e) => {
                    focusText(this[`${index}.${key + 1}`], subItem)
                }}>
                    <div className="list-count ">
                        <span>{`${index}.${key + 1}`}</span>
                    </div>
                    <div className="text-item" dangerouslySetInnerHTML={{__html: subItem.content}}></div>
                </div>
                {
                    (subItem.subItems && subItem.subItems.length > 0) && this.getContent(subItem.subItems, `${index}.${key + 1}`, focusText, null, (approvedSubItems && approvedSubItems[key] ? approvedSubItems[key].subItems : null), isVersion)
                }
            </Fragment>
        )
    };

    render() {
        let {index, subItems, text, focusText, focusElementId, description, id, approved, isVersion} = this.props;
        return (
            <Fragment>
                <div
                    className={`content-text-block ${id && focusElementId === id.toString() ? "highlights" : ""} ${isVersion && (!approved || approved.text !== text || approved.description !== description) ? "edited" : ""}`}
                    ref={(component) => {
                        this[`${index}.desc`] = component
                    }}
                    onClick={(e) => {
                        focusText(this[`${index}.desc`], this.props)
                    }}>
                    <div className="list-count head-count">
                        <span>{index + 1}</span>
                    </div>
                    <div className="text-item">
                        <h2 dangerouslySetInnerHTML={{__html: text}}></h2>
                        <div className="text-item" dangerouslySetInnerHTML={{__html: description}}></div>
                    </div>
                </div>
                {this.getContent(subItems, index + 1, focusText, focusElementId, approved && approved.subItems ? approved.subItems : null, isVersion)}
            </Fragment>
        )
    }
}

/*const EducationDocumentItemComponent = ({index,subItems,text,focusText}) => (
    <Fragment>
        <div className="content-text-block">
            <div className="list-count head-count">
                <span>{index+1}</span>
            </div>
            <div className="text-item">
                <h2>{text}</h2>
            </div>
        </div>
        {getContent(subItems,index+1,focusText)}
    </Fragment>
);*/

export default EducationDocumentItemComponent;