import React from 'react';

const PanelBreadCrumbComponent = ({page,subPage}) => (
    <div className="breadcrumb-menu">
        <ul>
            <li>{page}</li>
            {
                subPage && <li>{subPage}</li>
            }
        </ul>
    </div>
);

export default PanelBreadCrumbComponent;