import React, {Component} from 'react';
import PageService from '../pageServices/pageService';
import {connect} from "react-redux";
import AllEducationContainer from '../containers/allEducation/allEducationContainer';
import queryString from 'query-string'
import * as ReactDOM from 'react-dom';

class AllEducationsPage extends Component {
    webService;
    allEducationContainer = React.createRef();
    constructor(props) {
        super(props);
        this.webService = new PageService(props.location.pathname);
        this.webService.getData(props.dispatch);

    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        if(values.education && this.props.allEducations){
            this.scrollItem();

        }
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        const values = queryString.parse(this.props.location.search);
        if(!prevProps.allEducations && this.props.allEducations && values.education){
            this.scrollItem();
        }
    }

    scrollItem = () => {
        setTimeout(()=>{
            const values = queryString.parse(this.props.location.search);
            let allEducationComponent = this.allEducationContainer.current.getWrappedInstance();
            if(ReactDOM.findDOMNode(allEducationComponent[values.education])){
                window.scrollTo({
                    top: ReactDOM.findDOMNode(allEducationComponent[values.education]).offsetTop,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        },250);

    };

    render() {
        return (
            <div className="mainRoot">
                <div className="edu-list-root">
                    <div className="list-container safe-area">
                        <div className="head-block">
                            <h2>TÜM EĞİTİMLER</h2>
                            <p>Ünlü Tekstil Sustainability portalındaki tüm eğitimleri görebilirsiniz</p>
                        </div>
                        <AllEducationContainer ref={this.allEducationContainer}/>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    allEducations: state.educationCategoriesReducer
});


export default connect(mapStateToProps)(AllEducationsPage);
