import React, {Component} from 'react';
import {connect} from "react-redux";
import PanelDocItemComponent from "../../components/Panel/panelDocItemComponent";
import PanelBreadCrumbComponent from "../../components/Panel/panelBreadCrumbComponent";
import Helper from '../../helper';
import InfiniteScroll from 'react-infinite-scroller';
import {getPanelDocumentsAction} from "../../actions/panelAction";
import PanelVersionDocItemComponent from "../../components/Panel/panelVersionDocItemComponent";
import {withRouter} from "react-router-dom";

class PanelListContainer extends Component {
    activePage = 0;
    order = "name";
    order_type = "asc";
    isMore = true;
    loadFunc = () => {
        let urlArr = this.props.match.params.subPage ? this.props.match.params.subPage.split("-") : ["0"];
        let params = {
            category_id: urlArr[urlArr.length - 1],
            orderName: this.order,
            orderType: this.order_type,
            index: this.activePage,
            subPage: this.props.match.params.subPage
        };
        this.props.dispatch(getPanelDocumentsAction(params, (res, isMore) => {
            if (res === true) {
                this.isMore = isMore;
                this.activePage++;
                this.forceUpdate();
            }
        }));
    };
    sortFunc = (sort_name) => {
        this.order_type = this.order_type === "asc" ? "desc" : "asc";
        // this.order_type = this.order === sort_name ? "asc" : "desc";
        this.order = sort_name;
        this.activePage = 0;
        this.loadFunc();
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.match.params.subPage !== prevProps.match.params.subPage)) {
            if (typeof window !== "undefined") {
                window.scrollTo({'top': 0});
            }
            this.activePage = 0;
            this.loadFunc();
        }
        // else if(this.props.location.search !== prevProps.location.search){
        //     this.isMore = true;
        //     this.forceUpdate();
        // }
    }


    render() {
        let {items, match, roleId} = this.props;
        let activeSubPage = Helper.getDocumentSubPage().find(subPage => subPage.link === `/${match.params.subPage}`);
        return (
            <div className="panel-content-block">
                <PanelBreadCrumbComponent page="Dökümanlar"
                                          subPage={activeSubPage ? activeSubPage.title : Helper.getDocumentSubPage()[0].title}/>
                {
                    match.params.subPage === "version" ?
                        <div className="table-root">
                            <div className="table-container">
                                <div className="table-head">
                                    <div className="t-1 td-item" onClick={() => {
                                        this.sortFunc("education_name")
                                    }}>
                                        <p>Dosya adı</p>
                                        <i className="icon-arr-bottom"></i>
                                    </div>
                                    <div className="t-2 td-item" onClick={() => {
                                        this.sortFunc("user_name_surname")
                                    }}>
                                        <p>Talep Eden</p>
                                        <i className="icon-arr-bottom"></i>
                                    </div>
                                    <div className="t-3 td-item resp-date" onClick={() => {
                                        this.sortFunc("created_date")
                                    }}>
                                        <p>Talep Tarihi</p>
                                        <i className="icon-arr-bottom"></i>
                                    </div>
                                </div>

                                <div className="table-block">
                                    {
                                        items &&
                                        <InfiniteScroll
                                            pageStart={0}
                                            loadMore={this.loadFunc}
                                            hasMore={this.isMore && items.length > 19}
                                            threshold={250}
                                        >
                                            {
                                                items.map((item, key) =>
                                                    <PanelVersionDocItemComponent role_id={roleId} key={key} {...item}/>
                                                )
                                            }
                                        </InfiniteScroll>
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div className="table-root">
                            <div className="table-container">
                                <div className="table-head">
                                    <div className="t-1 td-item" onClick={() => {
                                        this.sortFunc("name")
                                    }}>
                                        <p>Dosya adı</p>
                                        <i className="icon-arr-bottom"></i>
                                    </div>
                                    <div className="t-2 td-item" onClick={() => {
                                        this.sortFunc("is_active")
                                    }}>
                                        <p>Durum </p>
                                        <i className="icon-arr-bottom"></i>
                                    </div>
                                    <div className="t-3 td-item resp-date" onClick={() => {
                                        this.sortFunc("created_date")
                                    }}>
                                        <p>Son Düzenleme Tarihi</p>
                                        <i className="icon-arr-bottom"></i>
                                    </div>
                                    <div className="t-4 td-item resp-date">
                                        <span></span>
                                    </div>
                                </div>

                                <div className="table-block">
                                    {
                                        items &&
                                        <InfiniteScroll
                                            pageStart={0}
                                            loadMore={this.loadFunc}
                                            hasMore={this.isMore && items.length > 19}
                                            threshold={250}
                                        >
                                            {
                                                items.map((item, key) =>
                                                    <PanelDocItemComponent key={key} location={this.props.location}
                                                                           history={this.props.history} {...this.props} {...item}/>
                                                )
                                            }
                                        </InfiniteScroll>
                                    }
                                </div>
                            </div>
                        </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    items: state.PanelReducer.docs,
    roleId: state.sessionReducer.role_id
});

export default withRouter(connect(mapStateToProps)(PanelListContainer));