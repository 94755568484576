import {
    getDepartmentQuizResult, getEducationQuizResult,
    getEducationSessionByYear, getEducationSessionDepartmentByYear,
    getEducationSessionUsersByYear, getQuizResultByEducation, getQuizResultByEducationId, getWeeklyEducationReport
} from "../services/webService";
import * as actionTypes from "../constants/actionTypes"
export const getEducationSessionByYearAction = (params, cb) => dispatch => {
    getEducationSessionByYear(params).then((res) => {
        if (res.data.success) {
            cb(res.data.success);
        }
    })
};

export const getEducationSessionUsersByYearAction = (params,cb) => dispatch => {
    getEducationSessionUsersByYear(params).then((res)=>{
        if (res.data.success) {
            cb(res.data.success);
        }
    });
};

export const getEducationSessionDepartmentByYearAction = (params,cb) => dispatch => {
    getEducationSessionDepartmentByYear(params).then((res)=>{
        if (res.data.success) {
            cb(res.data.success);
        }
    });
};

export const getDepartmentQuizResultAction = (params,cb) => dispatch =>{
    getDepartmentQuizResult(params).then((res)=>{
        if (res.data.success) {
            cb(res.data.success);
        }
    });
};

export const getEducationQuizResultAction = (params,cb) => dispatch => {
    getEducationQuizResult(params).then((res)=>{
        if (res.data.success) {
            cb(res.data.success);
        }
    });
};

export const getQuizResultByEducationAction = (params,cb) => dispatch => {
    getQuizResultByEducation(params).then((res)=>{
        if (res.data.success) {
            cb(res.data.success);
        }
    });
};

export const getWeeklyEducationReportAction = (params,cb) => dispatch => {
    getWeeklyEducationReport(params).then((res)=>{
        if (res.data.success) {
            cb(res.data.success);
            dispatch(getWeeklyEducationResult(res.data.success,params.selectedWeek));
        }
    });
};

export const getWeeklyEducationResult = (data,week) => ({
    type: actionTypes.GET_EDUCATION_WEEKLY,
    payload: data,
    week:week
});

// export const getQuizResultByEducationIdAction = (params,cb) => dispatch => {
//     console.log("1111");
//     getQuizResultByEducationId(params).then((res)=>{
//         if (res.data.success) {
//             cb(res.data.success);
//             //dispatch(getEducationResult(res.data.success));
//         }
//     });
// };