import {
    RECEIVE_DOCUMENTS,
    RECEIVE_PANEL_EDUCATIONS,
    RECEIVE_USERS,
    RECEIVE_PANEL_FEEDBACK,
    RECEIVE_PANEL_QUIZ,
    RECEIVE_PANEL_DRAFT,
    RECEIVE_PANEL_LOCATION,
    RECEIVE_PANEL_SMART_DOCUMENTS,
    RECEIVE_PANEL_NOTIFICATIONS,
    RECEIVE_SURVEYS,
    RECEIVE_SURVEY_DETAIL, RECEIVE_EDUCATION_SESSION_DETAIL, RECEIVE_PARTICIPANTS, GET_EDUCATION_RESULT_BY_ID,
    GET_EDUCATION_WEEKLY, RECEIVE_PANEL_TAG, UPDATE_PANEL_TAG
} from '../constants/actionTypes';

const initialState = {
    "docs": [],
    "users": [],
    "educations": [],
    feedback: [],
    drafts: [],
    notifications: {},
    surveys: [],
    surveyDetail: {},
    quiz: [],
    tag: [],
    smartDocuments: [],
    locations: [],
    participants: [],
    sessionDetail: {}
};

const PanelReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_DOCUMENTS:
            if (action.isFirst === true) {
                return {
                    ...state,
                    "docs": [
                        ...action.payload.docs
                    ]
                };
            } else {
                return {
                    ...state,
                    "docs": [
                        ...state.docs,
                        ...action.payload.docs
                    ]
                };
            }
        case RECEIVE_USERS:
            if (action.isFirst === true) {
                return {
                    ...state,
                    "users": [
                        ...action.payload.users
                    ]
                };
            } else {
                return {
                    ...state,
                    "users": [
                        ...state.users,
                        ...action.payload.users
                    ]
                };
            }
        case RECEIVE_PANEL_EDUCATIONS:
            if (action.isFirst === true) {
                return {
                    ...state,
                    "educations": [
                        ...action.payload.educations
                    ]
                };
            } else {
                return {
                    ...state,
                    "educations": [
                        ...state.educations,
                        ...action.payload.educations
                    ]
                };
            }
        case RECEIVE_PANEL_FEEDBACK:
            if (action.isFirst === true) {
                return {
                    ...state,
                    "feedback": [
                        ...action.payload.feedback
                    ]
                };
            } else {
                return {
                    ...state,
                    "feedback": [
                        ...state.feedback,
                        ...action.payload.feedback
                    ]
                };
            }
        case RECEIVE_PANEL_QUIZ:
            if (action.isFirst === true) {
                return {
                    ...state,
                    "quiz": [
                        ...action.payload.quiz
                    ]
                };
            } else {
                return {
                    ...state,
                    "quiz": [
                        ...state.quiz,
                        ...action.payload.quiz
                    ]
                };
            }
        case RECEIVE_PANEL_TAG:
            if (action.isFirst === true) {
                return {
                    ...state,
                    "tag": [
                        ...action.payload.tags
                    ]
                };
            } else {
                return {
                    ...state,
                    "tag": [
                        ...state.tag,
                        ...action.payload.tags
                    ]
                };
            }

        case UPDATE_PANEL_TAG:

            if (action.payload.isNewTag === true) {

                return {
                    ...state,
                    "tag": [
                        action.payload, ...state.tag
                    ]
                };

            } else {
                if (action.payload.isDeleted === "1") {
                    let inx = state.tag.findIndex(x => x.id === action.payload.id);
                    return {
                        ...state,
                        "tag": [
                            ...state.tag.slice(0, inx), ...state.tag.slice(inx + 1, state.tag.length)
                        ]
                    };
                } else {
                    let findObj = state.tag.find(x => x.id === action.payload.id);
                    findObj.tag_name = action.payload.tag_name;
                    let inx = state.tag.findIndex(x => x.id === action.payload.id);
                    return {
                        ...state,
                        "tag": [
                            ...state.tag.slice(0, inx), findObj, ...state.tag.slice(inx + 1, state.tag.length)
                        ]
                    };
                }
            }

        case RECEIVE_PANEL_DRAFT:
            if (action.isFirst === true) {
                return {
                    ...state,
                    "drafts": [
                        ...action.payload.drafts
                    ]
                };
            } else {
                return {
                    ...state,
                    "drafts": [
                        ...state.drafts,
                        ...action.payload.drafts
                    ]
                };
            }
        case RECEIVE_PANEL_LOCATION:
            if (action.isFirst === true) {
                return {
                    ...state,
                    "locations": [
                        ...action.payload.locations
                    ]
                };
            } else if (action.payload.locations) {
                return {
                    ...state,
                    "locations": [
                        ...state.locations,
                        ...action.payload.locations
                    ]
                };
            }
        case RECEIVE_PANEL_SMART_DOCUMENTS:
            if (action.isFirst === true) {
                return {
                    ...state,
                    "smartDocuments": [
                        ...action.payload.smartDocuments
                    ]
                };
            } else {
                return {
                    ...state,
                    "smartDocuments": [
                        ...state.smartDocuments,
                        ...action.payload.smartDocuments
                    ]
                };
            }
        case RECEIVE_SURVEYS:
            if (action.isFirst === true) {
                return {
                    ...state,
                    "surveys": [
                        ...action.payload.surveys
                    ]
                };
            } else {
                return {
                    ...state,
                    "surveys": [
                        ...state.surveys,
                        ...action.payload.surveys
                    ]
                };
            }
        case RECEIVE_PARTICIPANTS:
            if (action.isFirst === true) {
                return {
                    ...state,
                    "participants": [
                        ...action.payload.participants
                    ]
                };
            } else {
                return {
                    ...state,
                    "participants": [
                        ...state.participants,
                        ...action.payload.participants
                    ]
                };
            }
        case RECEIVE_SURVEY_DETAIL:
            return {
                ...state,
                surveyDetail: {
                    ...state.surveyDetail,
                    [action.id]: action.payload
                }
            };
        case RECEIVE_PANEL_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload
            };
        case RECEIVE_EDUCATION_SESSION_DETAIL:
            return {
                ...state,
                sessionDetail: {
                    ...state.sessionDetail,
                    [action.education_session_id]: action.payload
                }
            };
        case GET_EDUCATION_RESULT_BY_ID:
            return {
                ...state,
                educationRanking: action.payload
            };
        case GET_EDUCATION_WEEKLY:
            return {
                ...state,
                educationWeekly: action.payload,
                selectWeek: action.week
            };
        default:
            return state;
    }
};

export default PanelReducer;