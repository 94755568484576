import React,{Component} from 'react';
import {connect} from "react-redux";
import PanelBreadCrumbComponent from "../../components/Panel/panelBreadCrumbComponent";
import {getSurveysAction} from "../../actions/panelAction";
import InfiniteScroll from 'react-infinite-scroller';
import PanelSurveyItemComponent from "../../components/Panel/panelSurveyItemComponent";
import CreateSurveyModalComponent from "../../components/modals/createSurveyModalComponent";
import SurveyDetailModalComponent from "../../components/modals/surveyDetailModalComponent";

class PanelSurveyListContainer extends Component{
    activePage = 0;
    order = "question";
    order_type = "asc";
    isMore = true;
    editedSurvey;
    surveyResult;
    loadFunc = () => {
        let params = {
            activePage: this.activePage,
            order: this.order,
            order_type: this.order_type
        };
        this.props.dispatch(getSurveysAction(params, (res, isMore) => {
            if (res === true) {
                this.isMore = isMore;
                this.activePage++;
                this.forceUpdate();
            }
        }));
    };
    sortFunc = (sort_name) => {
        // this.order_type = this.order === sort_name ? "asc" : "desc";
        this.order_type = this.order_type === "asc" ? "desc":"asc";
        this.order = sort_name;
        this.activePage = 0;
        this.loadFunc();
    };
    toggleModal = (item) => {
        // this.editedSurvey = item;
        this.surveyResult = item;
        this.forceUpdate();
    };
    render() {
        let {items} = this.props;
        return (
            <div className="panel-content-block">
                <PanelBreadCrumbComponent page="Anketler"/>
                <div className="table-root">
                    <div className="table-container">
                        <div className="table-head">
                            <div className="t-1 td-item" onClick={()=>{this.sortFunc("question")}}>
                                <p>Anket Adı</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-2 td-item" onClick={()=>{this.sortFunc("answered_count")}}>
                                <p>Katılımcı Sayısı</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-3 td-item resp-date" onClick={()=>{this.sortFunc("created_date")}}>
                                <p>Oluşturulma Tarihi</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                        </div>
                        <div className="table-block">
                            {
                                items &&
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={this.loadFunc}
                                    hasMore={this.isMore && items.length > 19}
                                    threshold={250}
                                >
                                    {
                                        items.map((item,key)=>
                                            <PanelSurveyItemComponent toggleModal={this.toggleModal} key={key} {...item}/>
                                        )
                                    }
                                </InfiniteScroll>
                            }
                        </div>
                    </div>
                </div>
                {
                    this.editedSurvey && <CreateSurveyModalComponent editedSurvey={this.editedSurvey} toggleModal={this.toggleModal}/>
                }
                {
                    this.surveyResult && <SurveyDetailModalComponent surveyResult={this.surveyResult} toggleModal={this.toggleModal}/>
                }
            </div>
        );
    }
}

const mapStateToProps = (state,ownProps) => ({
    items:state.PanelReducer.surveys
});

export default connect(mapStateToProps)(PanelSurveyListContainer);