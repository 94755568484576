import React from 'react';
import {connect} from "react-redux";
import {ASSETS} from '../../constants/Path';
import Helper from '../../helper';

const EducationEditContentHeaderComponent = ({isEducationStarted, startEducation, urlLink, detailInfo, toggleIsEditing}) => (
    detailInfo && detailInfo.image ?
            <div className={`banner-img-block ${isEducationStarted === true ? "" : "t-2"}`}>
                {
                    (detailInfo && detailInfo.image) &&
                    <div className="img-item">
                        <img src={ASSETS + detailInfo.image} alt=""/>
                    </div>
                }
                {
                    detailInfo &&
                    <div className="info-item">
                        {
                            detailInfo.tag &&
                            <span className="label-tag green">#{detailInfo.tag.toLocaleUpperCase('tr')}</span>
                        }
                        <h1>{Helper.titleCase(detailInfo.name)}</h1>
                        <div className="time-block">
                            <span><i className="icon-clock"></i>{detailInfo.duration}</span>
                        </div>
                    </div>
                }

            </div>
            :
            <div></div>
    )
;

const mapStateToProps = (state, ownProps) => {
    let urlArr = ownProps.urlLink.split("-");
    let key = ownProps.isSmartDocuments ? "smartDocumentDetailReducer" : "educationDetailReducer";
    return ({
        detailInfo: state[key][urlArr[urlArr.length - 1]] ? state[key][urlArr[urlArr.length - 1]].info : null
    });
};

export default connect(mapStateToProps)(EducationEditContentHeaderComponent);