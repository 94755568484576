import React,{Component} from 'react';
import {connect} from "react-redux";
import {LOGIN, PANEL_DOCUMENTS_WITOUTPARAM} from "../../constants/routePaths";
import {Link, withRouter} from "react-router-dom";
import {logOutAction} from "../../actions/userAction";

class HeaderUserComponent extends Component{
    isOpen = false;
    userRoot = React.createRef();
    componentDidMount() {
        window.addEventListener('click',this.clickedWindow)
    }

    toggleOpen = (value) => {
        this.isOpen = !this.isOpen;
        this.forceUpdate();
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(nextProps.location.pathname !== this.props.pathname){
            this.isOpen = false;
        }
        return true;
    }


    componentWillUnmount() {
        window.removeEventListener('click',this.clickedWindow)
    }

    clickedWindow = (e) => {
        if(this.userRoot.current.contains(e.target) === false && this.isOpen === true){
            this.isOpen = false;
            this.forceUpdate();
        }
    };


    logOut = () => {
        this.props.dispatch(logOutAction((res)=>{
            if(res === true){
                this.props.history.push(LOGIN);
            }
        }));
    };

    render(){
        let {session} = this.props;
        return(
            <div className="user-root" ref={this.userRoot}>
                <div className="user-btn" onClick={this.toggleOpen}>
                    <i className="icon-user"></i>
                </div>
                {
                    this.isOpen === true &&
                    <div className="open-user-root" >
                        <div className="arr-up"></div>
                        <p>{session.name_surname}</p>
                        <ul>
                            {
                                (session.role_id === "1" || session.role_id === "3") && <Link to={PANEL_DOCUMENTS_WITOUTPARAM}><li>Yönetim Paneli</li></Link>
                            }
                            <li onClick={this.logOut}>Çıkış yap</li>
                        </ul>
                    </div>
                }
            </div>
        )
    }
};

const mapStateToProps = (state) => ({
    session:state.sessionReducer
});

export default withRouter(connect(mapStateToProps)(HeaderUserComponent));