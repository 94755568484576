import React from 'react';

const PanelLocationItemComponent = ({id,name,created_date,selectLocation}) => (
    <div className="table-item" onClick={()=>{
        selectLocation({id,name,created_date})
    }}>
        <div className="td-item t-1">
            <i className={"icon-location"}></i>
            <p>{name}</p>
        </div>
        <div className="td-item t-2">
            <p>{created_date}</p>
        </div>
    </div>
);

export default PanelLocationItemComponent;