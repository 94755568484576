import React, {Component, Fragment} from 'react'
import {connect} from "react-redux";
import {reduxForm, Field, initialize} from "redux-form";
import inputFieldComponent from "../common/formFields/inputFieldComponent";
import {
    getParticipantDetailAction, receiveParticipants, receiveUsers,
    updateParticipantAction,
} from "../../actions/panelAction";
import LoaderModalComponent from "../common/loaderModalComponent";
import SuccessModalComponent from "../common/successModalComponent";
import autoCompleteFieldComponent from "../common/formFields/autoCompleteFieldComponent";
import {departmentAutoCompleteAction} from "../../actions/autoCompleteAction";

const validate = (values) => {
    let hasErrors = false;
    let errors = {};
    if (!values.name_surname || values.name_surname.trim() === "") {
        hasErrors = true;
        errors.name_surname = "Lütfen tüm alanları doldurun.";
    }
    if (!values.tc_no || values.tc_no.trim() === "") {
        hasErrors = true;
        errors.tc_no = "Lütfen tüm alanları doldurun.";
    }
    if (!values.department || values.department.trim() === "") {
        hasErrors = true;
        errors.department = "Lütfen tüm alanları doldurun.";
    }
    return hasErrors && errors;
};

class NewParticipantModalComponent extends Component {
    loaderIsShowing = false;
    successMessage = false;
    errMsg;
    contentBlock = React.createRef();
    selectedDepartment;
    constructor(props) {
        super(props);
        if (props.editedParticipant) {
            props.dispatch(getParticipantDetailAction({id: props.editedParticipant.id}, (res) => {
                if (res !== false) {
                    props.dispatch(initialize('createNewParticipant', {
                        name_surname: res.name_surname,
                        email: res.email,
                        phone: res.phone,
                        department: res.department_name,
                        tc_no: res.tc_no,
                        id:props.editedParticipant.id
                    }));
                    this.selectedDepartment = {id:res.department_id,department_name:res.department_name}
                }
            }))
        }
    }

    createNewParticipant = (values) => {
        this.loaderIsShowing = true;
        this.errMsg = null;
        this.forceUpdate();
        this.props.dispatch(updateParticipantAction({...values,department_id:this.selectedDepartment?this.selectedDepartment.id:null}, (res, message) => {
            this.loaderIsShowing = false;
            if (res === true) {
                if (!values.id) {
                    this.props.dispatch(receiveParticipants({participants: [message]}, false))
                }
                this.successMessage = true;
                this.forceUpdate();
                setTimeout(() => {
                    this.props.toggleModal(false, "isNewParticipant");
                }, 3000);
                this.props.getUsers();
            } else {
                this.errMsg = message;
                this.forceUpdate();
            }
        }))
    };

    departmentSelected = (item) => {
        this.selectedDepartment = item;
    };

    render() {
        let {toggleModal, handleSubmit, formErrors, submitFailed} = this.props;
        let errArr = formErrors ? Object.keys(formErrors) : {};
        return (
            <Fragment>
                {
                    this.successMessage === false &&
                    <div className="lb-root" onClick={(e) => {
                        if (this.contentBlock.current.contains(e.target) === false) {
                            toggleModal(false, "isNewParticipant")
                        }
                    }}>
                        <div className="lb-block" ref={this.contentBlock}>
                            <div className="content-block add-part-block add-new-block">

                                <form className="content-item" onSubmit={handleSubmit(this.createNewParticipant)}>

                                    <div className="head-block">
                                        <h2>{this.props.editedParticipant ? "Katılımcı Düzenle" : "Yeni Katılımcı"}</h2>
                                        <p>{this.props.editedParticipant ? "Katılımcıyı düzenleyin." : "Yeni bir katılımcı oluşturun."}</p>
                                    </div>

                                    <i className="icon-close" onClick={() => {
                                        toggleModal(false, "isNewParticipant")
                                    }}></i>


                                    <div className="form-block">

                                        <div className="input-container">
                                            <div className="input-block">
                                                <Field
                                                    name="name_surname"
                                                    label="ADI SOYADI"
                                                    type="text"
                                                    component={inputFieldComponent}
                                                />
                                            </div>
                                        </div>
                                        <div className="input-container">
                                            <div className="input-block">
                                                <Field
                                                    name="tc_no"
                                                    label="TC KİMLİK NUMARASI"
                                                    type="text"
                                                    component={inputFieldComponent}
                                                />
                                            </div>
                                        </div>
                                        <div className="input-container">
                                            <div className="input-block">
                                                <Field
                                                    name="email"
                                                    label="E-POSTA ADRESİ"
                                                    type="text"
                                                    component={inputFieldComponent}
                                                />
                                            </div>
                                        </div>
                                        <div className="input-container">
                                            <div className="input-block">
                                                <Field
                                                    name="phone"
                                                    label="TELEFON NUMARASI"
                                                    type="text"
                                                    component={inputFieldComponent}
                                                />
                                            </div>
                                        </div>
                                        <div className="input-container">
                                            <div className="input-block">
                                                <Field
                                                    name="department"
                                                    type="text"
                                                    label="DEPARTMAN SEÇİN"
                                                    selectedFunc={this.departmentSelected}
                                                    autoCompleteAction={departmentAutoCompleteAction}
                                                    example="Yazmaya başlayın..."
                                                    component={autoCompleteFieldComponent}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bottom-btn-item">
                                        <p>{submitFailed === true && errArr.length > 0 ? formErrors[errArr[0]] : this.errMsg ? this.errMsg : "" }</p>
                                        <button type="submit"
                                                className="btn-green">{this.props.editedParticipant ? "KATILIMCIYI DÜZENLE" : "KATILIMCI YARAT"}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.loaderIsShowing === true && <LoaderModalComponent/>
                }
                {
                    this.successMessage === true && <SuccessModalComponent
                        message={this.props.editedParticipant ? "Katılımcı Güncellendi." : "Katılımcı Eklendi."}/>
                }
            </Fragment>
        )
    }
}
;

const mapStateToProps = (state) => ({
    formErrors: state.form.createNewParticipant ? state.form.createNewParticipant.syncErrors : {}
});

NewParticipantModalComponent = reduxForm({
    form: "createNewParticipant",
    validate
})(NewParticipantModalComponent);

export default connect(mapStateToProps)(NewParticipantModalComponent);