import React,{Component} from 'react';
import {connect} from "react-redux";
import PanelBreadCrumbComponent from "../../components/Panel/panelBreadCrumbComponent";
import {getParticipantsAction} from "../../actions/panelAction";
import InfiniteScroll from 'react-infinite-scroller';
import NewParticipantModalComponent from "../../components/modals/newParticipantModalComponent";
import PanelParticipantItemComponent from "../../components/Panel/panelParticipantItemComponent";

class PanelParticipantListContainer extends Component{
    activePage = 0;
    order = "name_surname";
    order_type = "asc";
    isMore = true;
    editedParticipant;
    searchTerm = "";
    loadFunc = () => {
        let params = {
            activePage: this.activePage,
            order: this.order,
            order_type: this.order_type,
            searchTerm:this.searchTerm
        };
        this.props.dispatch(getParticipantsAction(params, (res, isMore) => {
            if (res === true) {
                this.isMore = isMore;
                this.activePage++;
                this.forceUpdate();
            }
        }));
    };
    sortFunc = (sort_name) => {
        this.order_type = this.order_type === "asc" ? "desc":"asc";
        this.order = sort_name;
        this.activePage = 0;
        this.loadFunc();
    };
    toggleModal = (item) => {
        this.editedParticipant = item;
        this.forceUpdate();
    };

    onChangeFunc = (value) => {
        this.searchTerm = value;
        this.activePage = 0;
        this.loadFunc();
    }

    render() {
        let {items} = this.props;
        return (
            <div className="panel-content-block">
                <PanelBreadCrumbComponent page="Katılımcılar"/>
                <div className="table-root">
                    <div className="table-container">
                    
                    <div className="table-head table-filter">
                    <div className="input-item">
                        <label>
                            <input autoComplete="off"
                                   onChange={(e) => {
                                       this.onChangeFunc(e.target.value)
                                   }}
                                   ref={this.inputItem}
                                   type="text"
                                   placeholder="Katılımcı Adı Yazınız."/>
                        </label>
                    </div>
                    </div>

                        <div className="table-head">
                            <div className="t-1 td-item" onClick={()=>{this.sortFunc("name_surname")}}>
                                <p>İsim Soyisim</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-2 td-item" onClick={()=>{this.sortFunc("tc_no")}}>
                                <p>TC</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-3 td-item resp-date" onClick={()=>{this.sortFunc("department_id")}}>
                                <p>Departman</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                        </div>
                        <div className="table-block">
                            {
                                items &&
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={this.loadFunc}
                                    hasMore={this.isMore && items.length > 19}
                                    threshold={250}
                                >
                                    {
                                        items.map((item,key)=>
                                            <PanelParticipantItemComponent toggleModal={this.toggleModal} key={key} {...item}/>
                                        )
                                    }
                                </InfiniteScroll>
                            }
                        </div>
                    </div>
                </div>
                {
                    this.editedParticipant && <NewParticipantModalComponent editedParticipant={this.editedParticipant} toggleModal={this.toggleModal}/>
                }
            </div>
        );
    }
}

const mapStateToProps = (state,ownProps) => ({
    items:state.PanelReducer.participants
});

export default connect(mapStateToProps)(PanelParticipantListContainer);