import async from "async";
import * as WebService from '../services/webService';
import {
    getEducationCategories,
    getOpenEducation,
    getUserNotifications,
    getFeatureCourses,
    getFundamental,
    getMainTiles,
    getNews,
    getSurvey
} from "../actions";

export const getHomeDataInit = (dispatch) => {
    return new Promise((resolve, failure) => {
        async.series([
            (cb) => {
                WebService.getEducationCategories().then((res) => {
                    if (res.data.success) {
                        cb(null, res.data.success);
                    }
                })
                    .catch((err) => {
                        console.log("educationErr", err);
                    })
            }
            , (cb) => {

                WebService.getOpenEducation().then((res) => {
                    if (res.data.success) {
                        cb(null, res.data.success);
                    }
                })
                    .catch((err) => {

                    })
            },
            (cb) => {

                WebService.getFeatureCourses().then((res) => {
                    if (res.data.success) {
                        cb(null, res.data.success);
                    }
                })
                    .catch((err) => {

                    })
            }, (cb) => {

                WebService.getFundamentalCourses().then((res) => {
                    if (res.data.success) {
                        cb(null, res.data.success);
                    }
                })
                    .catch((err) => {

                    })
            }, (cb) => {

                WebService.getMainTiles().then((res) => {
                    if (res.data.success) {
                        cb(null, res.data.success);
                    }
                })
                    .catch((err) => {

                    })
            }, (cb) => {

                WebService.getNews().then((res) => {
                    if (res.data.success) {
                        cb(null, res.data.success);
                    }
                })
                    .catch((err) => {

                    })
            }, (cb) => {

                WebService.getSurvey().then((res) => {
                    // if (res.data.success) {
                    //     cb(null, res.data.success);
                    // }
                    cb(null, res.data.success);
                })
                    .catch((err) => {

                    })
            }
        ], (err, result) => {
            dispatch(getEducationCategories(result[0]));
            dispatch(getOpenEducation(result[1]));
            dispatch(getFeatureCourses(result[2]));
            dispatch(getFundamental(result[3]));
            dispatch(getMainTiles(result[4]));
            dispatch(getNews(result[5]));
            dispatch(getSurvey(result[6]));
            resolve(result);
        });
    });


};
