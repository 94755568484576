import React, {Component} from 'react';
import {Chart} from 'react-google-charts';

class EducationForDepartmentComponent extends Component{
    yearArr=[];
    constructor(props){
        super(props);
        let startYear = 2019;
        while (startYear<new Date().getFullYear()+1){
            this.yearArr.push(startYear);
            startYear++;
        }
    }
    locationOnChange = (e) => {
        this.props.getData(this.props,e.target.value);
    };
    render(){
        let {chartData} = this.props;
        let infoArr = [['Kullancı', 'Eğitim']];
        chartData && chartData.map((item)=>{
            let itemArr =[item.department_name,parseInt(item.participant_count)];
            infoArr.push(itemArr);
        });
        return(
            <div>
                <div className="table-head">
                    <div></div>
                    <div className="select-container">
                        <div className="select-block">
                            {/*<label>Yıl Seçiniz</label>*/}
                            <select  onChange={this.locationOnChange} defaultValue={new Date().getFullYear()}>
                                {
                                    this.yearArr.map((yearItem, key) =>
                                        <option key={key} value={yearItem}>{yearItem}</option>
                                    )
                                }
                            </select>
                            <i className="icon-arr-bottom"></i>
                        </div>
                    </div>
                </div>

                <Chart
                    width={'100%'}
                    height={'750px'}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={infoArr}
                    options={{
                        title: 'Eğitim Alan Departmanlar',
                    }}
                />
            </div>
        )
    }
};

export default EducationForDepartmentComponent;