import React, {Component} from 'react';
import ModalEducationInfoComponent from "./modalEducationInfoComponent";
import {connect} from "react-redux";
import {breakSessionAction, endEducationSessionAction} from "../../actions/educationDetailAction";
import {
    HOME, QUIZPROCESS, QUIZPROCESS_WITHOUT_PARAMS, QUIZRESULT, QUIZWEB,
    QUIZWEBTEACHER_WITHOUT_PARAMS
} from '../../constants/routePaths'
import {withRouter} from "react-router-dom";
import {createNewSessionQuizAction, getParticipantsQrCodesAction} from "../../actions/quizAction";
import {ASSETS} from "../../constants/Path";

class EducationWarningModalComponent extends Component {
    contentBlock = React.createRef();
    isQuizExist;
    breakSession = () => {
        this.props.dispatch(breakSessionAction({
            education_session_id: this.props.sessionId,
            focusedId: this.props.focusedId
        }, (res) => {
            if (res === true) {
                this.props.toggleModal(false, null, null);
                this.props.endSession(false);
                this.props.history.push(HOME);
            }
        }))
    };
    goToHome = () => {
        this.props.history.push(HOME);
    };
    endEducation = () => {
        this.props.dispatch(endEducationSessionAction({id: this.props.sessionId}, (result,message) => {
            this.props.endSession(false);
            this.props.toggleModal(true, "Eğitimi Tamamladınız!", null, true, message.isQuizExist)
            // this.props.history.push(HOME);
        }));
    };
    goToQuizWeb = () => {
        this.props.dispatch(createNewSessionQuizAction({session_id:this.props.sessionId},(result)=>{
            if(result!==null){
                this.props.history.push(`${QUIZWEBTEACHER_WITHOUT_PARAMS}${this.props.sessionId}/${result.quiz_id}/${result.question_id}`);
            }
        }))
    };
    printQrCode = () => {
        this.props.dispatch(getParticipantsQrCodesAction({session_id:this.props.sessionId},(result)=>{
            if(result!==null){
                window.open(window.location.origin+"/back/"+result.filePath, "_blank");
                this.props.history.push(`${QUIZPROCESS_WITHOUT_PARAMS}${this.props.sessionId}/${result.quiz_id}`);
                // this.props.history.push(QUIZPROCESS)
            }
        }));
    };
    render() {
        let {message, desc, urlLink, toggleModal, quizIsShowing, focusedId, isQuizExist, changeKeyModal, openSessionDocs} = this.props;
        return (
            <div className="lb-root" onClick={(e) => {
                if (this.contentBlock.current.contains(e.target) === false) {
                    toggleModal(false, null, null)
                }
            }}>
                <div className="lb-block">
                    <div ref={this.contentBlock} className="content-block">
                        <ModalEducationInfoComponent urlLink={urlLink}/>
                        <div className="content-item">
                            <i className="icon-close" onClick={() => {
                                toggleModal(false, null, null)
                            }}></i>
                            <div className="text-item">
                                <p className="head-text">{message}</p>
                                {
                                    desc && <p>{desc}</p>
                                }
                            </div>

                            <div className="btn-block">
                                {
                                    quizIsShowing !== true && <span className="btn-green" onClick={() => {
                                        toggleModal(false, null, null)
                                    }}>EĞİTİME DEVAM ET</span>
                                }
                                {
                                    quizIsShowing !== true &&
                                    <span className="btn-orange" onClick={this.breakSession}>EĞİTİMİ ASKIYA AL</span>
                                }
                                {
                                    quizIsShowing !== true && <span className="btn-2" onClick={this.endEducation}>EĞTİMİ SONLANDIR</span>
                                }
                            </div>
                            {
                                (quizIsShowing === true && isQuizExist) &&
                                <div className="quiz-block">
                                    <div className="left-text">
                                        <p className="head-text">Quiz Başlat</p>
                                        <p>Kullancı cevaplarına yönelik performans değerlendirmeli quiz’i çözmek için
                                            her kullanıcıya özel QR kodu yazdırın.</p>
                                        <p>Performans değerlendirmesi olmadan sadece quiz sorularını görmek ve tüm
                                            katılımcılar ile birlikte çözmek için yazdırmadan başlayın.</p>
                                    </div>
                                    <div className="right-btn">
                                        <span className="btn-green" onClick={this.printQrCode}>QR KODU YAZDIR</span>
                                        <span className="btn-green" onClick={this.goToQuizWeb}>YAZDIRMADAN BAŞLAT</span>
                                    </div>
                                </div>
                            }
                            {
                                quizIsShowing === true &&
                                <div className="down-doc-block">
                                    <p className="head-text">Belge Yükle</p>
                                    <p>Bu eğitim seansı ile ilgili dökümanları sisteme yükleyin.</p>
                                    <div className="btn-item">
                                        <span className="btn-2" onClick={()=>{
                                            openSessionDocs(true,"computer")
                                        }}><i className="icon-qr"></i> BİLGİSAYARDAN YÜKLE</span>
                                        <span className="btn-2" onClick={()=>{
                                            openSessionDocs(true,"phone")
                                        }}><i className="icon-qr"></i> TELEFONDAN YÜKLE</span>
                                    </div>

                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
;

export default withRouter(connect()(EducationWarningModalComponent));