import React, {Component} from 'react';
import {connect} from "react-redux";
import {PANEL_DOCUMENTS_WITOUTPARAM, PANEL_SMARTDOCUMENTS_WITOUTPARAM, EDUCATIONDETAIL_WITHOUTPARAM} from '../../constants/routePaths';
import {createNewVersionOfDocumentAction, updateDocumentContentAction} from "../../actions/educationDetailAction";
import {updateSmartDocumentDetailAction} from "../../actions/smartDetailAction";
import {publishDraftAction, updateDraftContentAction} from "../../actions/panelAction";
import VersionSavedModalComponent from '../../components/modals/versionSavedModalComponent';

class EducationEditDetailHeaderContainer extends Component {
    menuBtn = React.createRef();
    isSuccess = false;
    openLeft = () => {
        this.props.detailContent.current.classList.toggle("open-menu");
        this.menuBtn.current.classList.toggle("active");
    };
    saveDocument = () => {
        let urlArr = this.props.urlLink.split("-");
        if(this.props.isSmartDocument === true){
            this.props.dispatch(updateSmartDocumentDetailAction({
                content: this.props.detailContentComponent.current.detailDocumentContainer.current.getWrappedInstance().props.detailDocument,
                smartDocumentId: urlArr[urlArr.length - 1]
            }, (res) => {
                if (res === true) {
                    this.props.history.push(PANEL_SMARTDOCUMENTS_WITOUTPARAM);
                }
            }));
        }else{
            if(this.props.isDraft===true){
                if(this.props.role_id === "3"){
                    this.props.dispatch(createNewVersionOfDocumentAction({
                        content: this.props.detailContentComponent.current.detailDocumentContainer.current.getWrappedInstance().props.detailDocument,
                        educationId: urlArr[urlArr.length - 1]
                    }, (res) => {
                        if (res === true) {
                            this.isSuccess = true;
                            this.forceUpdate();
                        }
                    }))
                }else{
                    this.props.dispatch(publishDraftAction({
                        content: this.props.detailContentComponent.current.detailDocumentContainer.current.getWrappedInstance().props.detailDocument,
                        educationId: urlArr[urlArr.length - 1]
                    }, (res) => {
                        if (res === true) {
                            this.props.history.push(EDUCATIONDETAIL_WITHOUTPARAM+this.props.urlLink.split("?").join(""));
                        }
                    }))
                }
            }else{
                if(this.props.role_id === "3"){
                    this.props.dispatch(createNewVersionOfDocumentAction({
                        content: this.props.detailContentComponent.current.detailDocumentContainer.current.getWrappedInstance().props.detailDocument,
                        educationId: urlArr[urlArr.length - 1]
                    }, (res) => {
                        if (res === true) {
                            this.isSuccess = true;
                            this.forceUpdate();
                            // this.props.history.push(EDUCATIONDETAIL_WITHOUTPARAM+this.props.urlLink);
                        }
                    }))
                }else{
                    this.props.dispatch(updateDocumentContentAction({
                        content: this.props.detailContentComponent.current.detailDocumentContainer.current.getWrappedInstance().props.detailDocument,
                        educationId: urlArr[urlArr.length - 1]
                    }, (res) => {
                        if (res === true) {
                            this.props.history.push(EDUCATIONDETAIL_WITHOUTPARAM+this.props.urlLink.split("?").join(""));
                        }
                    }));
                }

            }
        }
    };
    saveDrafts = () => {
        let urlArr = this.props.urlLink.split("-");
        this.props.dispatch(updateDraftContentAction({
            content: this.props.detailContentComponent.current.detailDocumentContainer.current.getWrappedInstance().props.detailDocument,
            educationId: urlArr[urlArr.length - 1]
        }, (res) => {
            if (res === true) {
                this.isDraftSuccess = true;
                this.forceUpdate();
                // this.props.history.push(EDUCATIONDETAIL_WITHOUTPARAM+this.props.urlLink);
            }
        }))
    };
    toggleModal = () => {
        this.props.history.push(EDUCATIONDETAIL_WITHOUTPARAM+this.props.urlLink.split("?").join(""));
    };
    render() {
        let {history, isSmartDocument, user_role, isEmpty} = this.props;
        return (
            <div className="detail-head-root">
                <div className="detail-head-block safe-area">
                    <div className="left-block">
                        {
                            isSmartDocument !== true &&
                            <div className="left-menu-btn" ref={this.menuBtn} onClick={this.openLeft}>
                                <i className="icon-menu"></i>
                            </div>
                        }
                        {/*{
                            isSmartDocument !== true &&
                            <div className="search-btn">
                                <i className="icon-search"></i>
                            </div>
                        }*/}
                    </div>
                    <div className="right-block">
                        <div className="btn-block">
                            <span className="btn-green" onClick={this.saveDocument}>{isEmpty===true?"YAYINLA":"DEĞİŞİKLİKLERİ KAYDET"}</span>
                            <span className="btn-2" onClick={() => {
                                history.push(isSmartDocument===true?PANEL_SMARTDOCUMENTS_WITOUTPARAM:PANEL_DOCUMENTS_WITOUTPARAM);
                            }}>KAYDETMEDEN ÇIK</span>
                            {
                                (this.props.isSmartDocument !== true && isEmpty===true) && <span className="btn-2" onClick={this.saveDrafts}>TASLAK OLARAK KAYDET</span>
                            }
                        </div>
                    </div>
                </div>
                {
                    this.isSuccess === true && <VersionSavedModalComponent toggleModal={this.toggleModal} history={this.props.history}/>
                }
                {
                    this.isDraftSuccess === true && <VersionSavedModalComponent isDraftSuccess={true} toggleModal={this.toggleModal} history={this.props.history}/>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    role_id:state.sessionReducer.role_id
});

export default connect(mapStateToProps)(EducationEditDetailHeaderContainer);