import React,{Component} from 'react';
import {connect} from "react-redux";
import CategoryItemComponent from "../../components/allEducations/categoryItemComponent";
import Helper from '../../helper';

class AllEducationContainer extends Component {
    render(){
        const {allEducations} = this.props;
        return (
            <div className="edu-list-block">
                {
                    allEducations && allEducations.map((educationItem, key) =>
                        <CategoryItemComponent ref={(component)=>{
                            this[Helper.turkishToLower(educationItem.name)] = component;
                        }} key={key} {...educationItem}/>
                    )
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    allEducations: state.educationCategoriesReducer
});

export default connect(mapStateToProps,null,null,{withRef:true})(AllEducationContainer);
