import React, {Component} from 'react';
import {Chart} from 'react-google-charts';

class EducationForUsersComponent extends Component {
    yearArr=[];
    constructor(props){
        super(props);
        let startYear = 2019;
        while (startYear<new Date().getFullYear()+1){
            this.yearArr.push(startYear);
            startYear++;
        }
    }
    locationOnChange = (e) => {
        this.props.getData(this.props,e.target.value);
    };
    render() {
        let {chartData} = this.props;
        let infoArr = [['Kullancı', 'Eğitim']];
        chartData && chartData.map((item) => {
            let itemArr = [item.user_name, parseInt(item.user_education_count)];
            infoArr.push(itemArr);
        });
        return (
            <div className="chart-block">
                <div className="table-head">
                    <div></div>
                    <div className="select-container">
                        <div className="select-block">
                            {/*<label>Yıl Seçiniz</label>*/}
                            <select  onChange={this.locationOnChange} defaultValue={new Date().getFullYear()}>
                                {
                                    this.yearArr.map((yearItem, key) =>
                                        <option key={key} value={yearItem}>{yearItem}</option>
                                    )
                                }
                            </select>
                            <i className="icon-arr-bottom"></i>
                        </div>
                    </div>
                </div>

                <Chart
                    width={'100%'}
                    height={'750px'}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={infoArr}
                    options={{
                        title: 'Eğitim Veren Kullanıcılar',
                    }}
                />
            </div>
        )
    }
}
;

export default EducationForUsersComponent;