import {getDepartmentAutoComplete} from "../services/webService";

export const departmentAutoCompleteAction = (params,cb) => dispatch => {
    getDepartmentAutoComplete(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch((err)=>{
        cb(false)
    })
};