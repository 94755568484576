export const BASE_URL = "";
export const WEB_SERVICE_BASE_URL =
  typeof window !== "undefined"
    ? process.env.NODE_ENV !== "production"
      ? "http://localhost:8080"
      : window.location.origin
    : "http://sustainability.unlutekstil.com.tr";
  // export const WEB_SERVICE_BASE_URL = typeof window !== "undefined" ? (process.env.NODE_ENV !== 'production' ? "http://testunluegitim.gulenayva.com/":window.location.origin):"http://testunluegitim.gulenayva.com";
export const API_URL = `${BASE_URL}/dummyData/`;
export const WEB_SERVICE_API_URL =`${WEB_SERVICE_BASE_URL}/back/api/`;
export const WEB_SERVICE_API_URL_EDUCATION = `${WEB_SERVICE_BASE_URL}/back/api/education/`;
