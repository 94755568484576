import React, {Component} from 'react';
import {connect} from "react-redux";

class FeedBackResultModalComponent extends Component {
    contentBlock = React.createRef();
    render() {
        let {toggleFeedBackResultModal} = this.props;
        return (
            <div className="lb-root" onClick={(e)=>{
                if(this.contentBlock.current.contains(e.target) === false){
                    toggleFeedBackResultModal(false)
                }
            }}>
                <div className="lb-block">
                    <div ref={this.contentBlock} className="content-block">
                        <div className="content-item">
                            <i className="icon-close" onClick={()=>{
                                toggleFeedBackResultModal(false)
                            }}></i>
                            <div className="text-item">
                                <p className="head-text">Teşekkürler!</p>
                                <p>Yaptığınız geri bildirim için teşekkür ederiz.
                                    Lütfen eğitimlerimizi geliştirmek adına geri bildirimde bulunmaya devam edin!</p>
                            </div>
                            <div className="btn-block" onClick={()=>{
                                toggleFeedBackResultModal(false)
                            }}>
                                <span className="btn-2">GERİ DÖN</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect()(FeedBackResultModalComponent);