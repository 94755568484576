import React, {Component} from 'react';
import ForgotPasswordRightContainer from '../containers/forgotPassword/forgotPasswordRightContainer';
import SignUpLeftContainer from "../containers/signUp/signUpLeftContainer";

class ForgotPassPage extends Component {

    render() {

        return (
            <div className="login-root">
                <div className="login-wrapper safe-area">

                    <div className="login-con">
                        <div className="login-block">
                            <SignUpLeftContainer/>
                            <ForgotPasswordRightContainer/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default ForgotPassPage;