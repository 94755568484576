import React, {Component, Fragment} from 'react';
import {Field, reduxForm} from "redux-form";
import InputFieldComponent from '../../components/common/formFields/inputFieldComponent';
import {forgotPasswordAction} from "../../actions/index";

const validate = (values) => {
    let hasErrors = false;
    let errors = {};
    if (!values.email) {
        hasErrors = true;
        errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        hasErrors = true;
        errors.email = 'Invalid email address'
    }
    return hasErrors && errors;
};

class ForgotPasswordRightContainer extends Component {
    isFailed = false;
    isSuccess = false;
    failMessage;
    onSubmit = (values) => {
        this.props.dispatch(forgotPasswordAction(values,(res, message)=>{
            if(res === true){
                this.isSuccess = true;
            }else{
                this.isFailed = true;
                this.failMessage = message;
            }
            this.forceUpdate();
        }));
    };

    render() {
        let {handleSubmit} = this.props;
        return (
            <Fragment>
                {
                    (this.isFailed === false && this.isSuccess === false) &&
                    <div className="right-block">
                        <div className="text-block">
                            <h2>Şifremi Unuttum</h2>
                            <p>Ünlü Tekstil Sustainability portalına giriş yapın.</p>
                        </div>

                        <form onSubmit={handleSubmit(this.onSubmit)}>
                            <div className="input-block">
                                <Field
                                    name="email"
                                    type="text"
                                    label="KAYITLI E-POSTA ADRESİ"
                                    placeholder="E-Posta Adresi giriniz.."
                                    component={InputFieldComponent}
                                />
                            </div>
                            <div className="input-bottom">
                                <input type="submit" value="SIFIRLAMA İSTEĞİ GÖNDER" className="btn-green"/>
                            </div>
                        </form>
                    </div>
                }

                {
                    this.isSuccess === true &&
                    <div className="right-block">
                        <div className="img-block">
                            <img src="/assets/img/common/talep-img.png" alt=""/>
                        </div>
                        <div className="text-item">
                            <h3>Sıfırlama Talebiniz Alındı.</h3>
                            <p>Şifrenizi sıfırlama talebinizi yöneticiye ilettik. Yöneticinin onayından sonra kayıtlı
                                e-posta adresinize bir sıfırlama bağlantısı göndereceğiz.</p>
                        </div>
                    </div>
                }
                {
                    this.isFailed === true &&
                    <div className="right-block">
                        <div className="img-block">
                            <img src="/assets/img/common/talep-img.png" alt=""/>
                        </div>
                        <div className="text-item">
                            <h3>Sıfırlama Talebiniz Alınamadı.</h3>
                            <p>{this.failMessage}</p>
                        </div>
                    </div>
                }
            </Fragment>
        )
    }
}
;

export default reduxForm({
    form: 'forgotPassword',
    validate
})(ForgotPasswordRightContainer);