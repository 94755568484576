import React, {Component} from 'react';
import QuizQuestionFormComponent from "./quizQuestionFormComponent";
import {connect} from "react-redux";
import {createQuizAction} from "../../actions/quizAction";

class CreateQuizQuestionsComponent extends Component {
    questions = [
        {
            name: "",
            picture: null,
            options: [
                {
                    "text": "",
                    "picture": null,
                    "is_answer": false
                },
                {
                    "text": "",
                    "picture": null,
                    "is_answer": false
                },
                {
                    "text": "",
                    "picture": null,
                    "is_answer": false
                },
                {
                    "text": "",
                    "picture": null,
                    "is_answer": false
                }
            ]
        }
    ];
    activeQuestionIndex = 0;
    errorArr = {};
    constructor(props){
        super(props);
        if(props.questions){
            this.questions = props.questions;
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.questions !== this.props.questions){
            this.questions = nextProps.questions;
        }
    }

    changeKey = (index) => {
        this.activeQuestionIndex = index;
        this.forceUpdate();
    };
    addNewQuestion = () => {
        let obj = {
            name: "",
            picture: null,
            options: [
                {
                    "text": "",
                    "picture": null,
                    "is_answer": false
                },
                {
                    "text": "",
                    "picture": null,
                    "is_answer": false
                },
                {
                    "text": "",
                    "picture": null,
                    "is_answer": false
                },
                {
                    "text": "",
                    "picture": null,
                    "is_answer": false
                }
            ]
        };
        this.questions.push(obj);
        this.forceUpdate();
    };
    deleteQuestion = (index) => {
        this.questions.splice(index, 1);
        this.forceUpdate();
    };
    checkFormIsValid = () => {
        this.errorArr = {};
        this.questions.map((questionItem, key) => {
            let errors = {};
            let hasErrors = false;
            if (!questionItem.name || questionItem.name.trim().length === 0) {
                hasErrors = true;
                errors.name = "Soru alanı boş olamaz.";
            }
            errors.options = [];
            if(questionItem.options.filter(optionItem=>(optionItem.text && optionItem.text.trim().length > 0) || optionItem.image || optionItem.picture).length<1){
                hasErrors = true;
            }
            /*questionItem.options.map((optionsItem, key) => {
                if ((!optionsItem.text || optionsItem.text.trim().length === 0) && !optionsItem.picture) {
                    hasErrors = true;
                    errors.options.push({index: key, error: "Seçeneklere Text veya Resim Ekleyiniz"});
                }
            });*/
            if (!questionItem.options.find(item => item.is_answer === true)) {
                hasErrors = true;
                errors.answer = "Lütfen doğru şık seçiniz.";
            }
            if (hasErrors === true) {
                this.errorArr[key] = errors;
            }
        });
        this.forceUpdate();
        return Object.keys(this.errorArr).length===0
    };
    saveQuiz = () => {
        this.props.saveQuiz({education_id:this.props.educationId,questions:this.questions});

    };
    render() {
        let {toggleModal} = this.props;
        return (
            <div className="content-item">
                <div className="head-block">
                    <h2>Soru Ekle</h2>
                    <p>Oluşturduğunuz quiz için soru ekleyin veya düzenleyin.</p>
                    <div className="step-block">
                        <span >1</span>
                        <span className="active">2</span>
                    </div>
                </div>
                <i className="icon-close" onClick={() => {
                    toggleModal(false, "isNewQuiz")
                }}></i>
                <QuizQuestionFormComponent activeQuestionIndex={this.activeQuestionIndex} questions={this.questions}
                                           changeKey={this.changeKey} deleteQuestion={this.deleteQuestion}
                                           activeQuestion={this.questions[this.activeQuestionIndex]}
                                           dispatch={this.props.dispatch} saveQuiz={this.saveQuiz}
                                           addNewQuestion={this.addNewQuestion} errorArr={this.errorArr}
                                           checkFormIsValid={this.checkFormIsValid}/>
            </div>
        )
    }
}
export default connect()(CreateQuizQuestionsComponent);