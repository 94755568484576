import React from 'react';
import {
    EDITEDUCATIONVERSIONDETAIL_WITHOUT_PARAM, PANEL_USERS_WITOUTPARAM
} from "../../constants/routePaths";
import Helper from '../../helper';

const NotificationItemComponent = ({notification_text,is_seen,passing_time,notification_user,history,notification_education_id,educationDetail}) => (
    <div className="open-not-item new-item" onClick={()=>{
        if(notification_user){
            history.push(PANEL_USERS_WITOUTPARAM+"?user_id="+notification_user)
        }else{
            history.push(EDITEDUCATIONVERSIONDETAIL_WITHOUT_PARAM+Helper.turkishToLower(educationDetail.name).split(" ").join("-").split("/").join("")+"-"+notification_education_id)
        }
    }}>
        <p>{notification_text}</p>
        <div className="date-item">
            {
                is_seen === "0" &&
                <span className="new-badge">YENİ</span>
            }
            <span><i className="icon-calendar"></i>{passing_time}</span>
        </div>
    </div>
);

export default NotificationItemComponent;