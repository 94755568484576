import React, {Component} from 'react'
import {connect} from "react-redux";
import {endSurveyAction, getSurveyResultsAction} from "../../actions/panelAction";

class SurveyDetailModalComponent extends Component {
    contentBlock = React.createRef();
    constructor(props){
        super(props);
        props.dispatch(getSurveyResultsAction({survey_id:props.surveyResult.id}));
    }
    endSurvey = () => {
      this.props.dispatch(endSurveyAction({survey_id:this.props.surveyResult.id},(res)=>{
          if(res === true){
              this.props.toggleModal(false)
          }
      }))
    };
    render() {
        let {toggleModal,surveyResult,surveyDetail} = this.props;
        return (
            <div className="lb-root" onClick={(e) => {
                if (this.contentBlock.current.contains(e.target) === false) {
                    toggleModal(false)
                }
            }}>
                <div className="lb-block">
                    <div className="content-block add-part-block" ref={this.contentBlock}>
                        <div className="content-item">
                            <div className="head-block">
                                <h2>Anket</h2>
                                <p>Anket sonuçlarını görüntüleyin ve anketi kapatın.</p>
                            </div>
                            <i className="icon-close" onClick={() => {
                                toggleModal(false)
                            }}></i>
                            <div className="survey-block">
                                <div className="text-item">
                                    <h3>ANKET İÇERİĞİ</h3>
                                    <p>{surveyResult.question}</p>
                                </div>

                                <div className="status-block">
                                    <h3>ANKET SONUÇLARI</h3>
                                    {
                                        surveyDetail && surveyDetail.options.map((optionItem,key)=>
                                            <div className="status-item" key={key}>
                                                <p>{optionItem.option_name}</p>
                                                <p>%{surveyDetail.totalCount === 0?0:(optionItem.answerCount*100)/surveyDetail.totalCount}</p>
                                                <div className="bar" style={{"width": `${(optionItem.answerCount*100)/surveyDetail.totalCount}%`}}></div>
                                            </div>
                                        )
                                    }
                                    {
                                        surveyDetail && <p className="count">Toplam {surveyDetail.totalCount} Oy</p>
                                    }
                                </div>

                            </div>
                            <div className="bottom-btn-item">
                                <span className="btn-end" onClick={this.endSurvey}>{surveyDetail && surveyDetail.surveyIsActive === "1"? "ANKETİ SONLANDIR":"ANKETİ BAŞLAT"}</span>
                                <span className="btn-back">GERİ DÖN</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

const mapStateToProps = (state,ownProps) => ({
    surveyDetail:state.PanelReducer.surveyDetail[ownProps.surveyResult.id]
});

export default connect(mapStateToProps)(SurveyDetailModalComponent);