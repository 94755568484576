import axios from 'axios';
import * as URLS from "./../constants/urls";
import {WEB_SERVICE_API_URL_EDUCATION} from "../constants/urls";

const configJson = {
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    }
};

export const createNewVersionOfDocument = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/education/createNewVersionOfDocument`,"params="+encodeURIComponent(JSON.stringify(params)));
    // return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/education/updateDocumentContent`,"params="+encodeURIComponent(JSON.stringify(params)));
};

export const educationAutoComplete = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/education/educationAutoComplete`,"params="+JSON.stringify(params))
};

export const checkQuizExist = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/education/checkEducationQuizExist`,"params="+JSON.stringify(params))
};

export const uploadQuizQuestionImage = (image,type) => {
    let formData = new FormData();
    formData.append(type, image);
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/quiz/uploadQuizQuestionImage`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const createQuiz = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/quiz/createQuiz`,"params="+encodeURIComponent(JSON.stringify(params)))
};

export const getQuiz = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/quiz/getQuiz`,"params="+JSON.stringify(params))
};


export const createPlanedEducation = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/createPlanedEducation`,"params="+JSON.stringify(params))
};

export const usersAutoComplete = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/usersAutoComplete`,"params="+JSON.stringify(params))
};

export const getSurveys = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/getSurveys`,"params="+JSON.stringify(params))
};

export const updateSurvey = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/updateSurvey`,"params="+encodeURIComponent(JSON.stringify(params)))
};

export const getSurveyResults = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/getSurveyResults`,"params="+JSON.stringify(params))
};

export const endSurvey = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/endSurvey`,"params="+JSON.stringify(params))
};

export const getFeedbackDetail = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/getFeedbackDetail`,"params="+JSON.stringify(params))
};

export const responseFeedback = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/responseFeedback`,"params="+encodeURIComponent(JSON.stringify(params)))
};

export const getDraftDetail = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/education/getDraftDetail`,"params="+JSON.stringify(params))
};

export const publishDraft = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/education/publishDraft`,"params="+JSON.stringify(params))
};

export const updateDraftContent = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/education/updateDraftContent`,"params="+JSON.stringify(params))
};

export const getDrafts = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/education/getDrafts`,"params="+JSON.stringify(params))
};

export const getSessionEducationDetail = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/education/getSessionEducationDetail`,"params="+JSON.stringify(params))
};

export const changeBannerItem = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/changeBannerItem`,"params="+JSON.stringify(params))
};

export const passiveEducation = (params) => {
  return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/education/passiveEducation`,"params="+JSON.stringify(params))
};

export const publishVersionOfDocument = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/education/publishVersionOfDocument`,"params="+JSON.stringify(params))
};

export const canceledDocumentVersion = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/education/canceledDocumentVersion`,"params="+JSON.stringify(params))
};

export const getVersionDocuments = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/education/getVersionDocuments`,"params="+JSON.stringify(params))
};

export const getParticipants = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/getParticipants`,"params="+JSON.stringify(params))
};

export const updateParticipant = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/updateParticipant`,"params="+JSON.stringify(params))
};

export const getParticipantDetail = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/getParticipantDetail`,"params="+JSON.stringify(params))
};