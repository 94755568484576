import React, {Component,Fragment} from 'react'
import {connect} from "react-redux";
import {reduxForm, Field} from "redux-form";
import radioFieldComponent from "../common/formFields/radioFieldComponent";
import Helper from '../../helper';
import {createPlanedEducationAction, educationAutoCompleteAction, usersAutoCompleteAction} from "../../actions/panelAction";
import autoCompleteFieldComponent from "../common/formFields/autoCompleteFieldComponent";
import datePickerInputComponent from "../common/formFields/datePickerInputComponent";
import LoaderModalComponent from "../common/loaderModalComponent";
import SuccessModalComponent from "../common/successModalComponent";

const validate = (values) => {
    let hasErrors = false;
    let errors = {};
    if (!values.education_name || values.education_name.trim() === "") {
        hasErrors = true;
        errors.education_name = "Eğitim Adını Doldurmanız Gerekmektedir.";
    }
    if (!values.user_name || values.user_name.trim() === "") {
        hasErrors = true;
        errors.user_name = "Lütfen Eğitim Kategorisi Seçiniz.";
    }
    if ((!values.planned_date || values.planned_date.trim() === "") || (!values.started_time || values.started_time.trim() === "")) {
        hasErrors = true;
        errors.planned_date = "Lütfen Eğitim Tarihi Seçiniz.";
    }
    return hasErrors && errors;
};

class NewPlanedEducationModalComponent extends Component {
    contentBlock = React.createRef();
    selectedUser;
    selectedEducation;
    errorMsg;
    loaderIsShowing = false;
    successMessage = false;
    createNewEducation = (values) => {
        this.loaderIsShowing = true;
        this.forceUpdate();
        this.errorMsg = null;
        let params = {...values};
        params.education_id = this.selectedEducation.id;
        params.user_id = this.selectedUser.id;
        params.planned_date = values.planned_date+" "+values.started_time;
        this.props.dispatch(createPlanedEducationAction(params,(res,massage)=>{
            if(res === true){
                this.loaderIsShowing = false;
                this.successMessage = true;
                this.forceUpdate();
                setTimeout(()=>{
                    this.props.toggleModal(false,"planedEducation");
                },3000);
            }else{
                this.errorMsg = massage;
                this.forceUpdate();
            }
        }))
    };
    userSelected = (item) => {
        this.selectedUser = item;
    };
    educationSelected = (item) => {
        this.selectedEducation = item;
    };
    render() {
        let {toggleModal, urlLink, locations, handleSubmit, educationCategories,formErrors,submitFailed} = this.props;
        let errArr = formErrors ? Object.keys(formErrors):{};
        return (
            <Fragment>
                {
                    this.successMessage === false &&
                    <div className="lb-root" onClick={(e)=>{
                        if(this.contentBlock.current.contains(e.target) === false){
                            toggleModal(false,"planedEducation")
                        }
                    }}>
                        <div className="lb-block">
                            <div ref={this.contentBlock} className="content-block add-part-block add-new-block">

                                <form className="content-item" onSubmit={handleSubmit(this.createNewEducation)}>

                                    <div className="head-block">
                                        <h2>Eğitim Planla</h2>
                                        <p>İleri bir tarih için eğitim planlayın.</p>
                                    </div>

                                    <i className="icon-close" onClick={() => {
                                        toggleModal(false,"planedEducation")
                                    }}></i>


                                    <div className="form-block">
                                        <Field
                                            name="category_id"
                                            label="EĞİTİM TİPİ"
                                            component={radioFieldComponent}
                                            options={Helper.dataStandardized(educationCategories,"name","id")}
                                            example="Döküman seçeceğiniz tipe göre menüde konumlandırılacaktır."
                                        />
                                        <div className="input-container">
                                            <div className="input-block">
                                                <Field
                                                    name="education_name"
                                                    label="EĞİTİM DÖKÜMANI SEÇİN "
                                                    selectedFunc={this.educationSelected}
                                                    autoCompleteAction={educationAutoCompleteAction}
                                                    example="Yazmaya başlayın..."
                                                    component={autoCompleteFieldComponent}
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="input-container">
                                            <div className="input-block">
                                                <Field
                                                    name="planned_date"
                                                    label="TARİH SEÇİN "
                                                    type="text"
                                                    component={datePickerInputComponent}
                                                />
                                            </div>
                                        </div>
                                        <div className="input-container">
                                            <div className="input-block">
                                                <Field
                                                    name="user_name"
                                                    type="text"
                                                    label="EĞİTMEN SEÇİN"
                                                    selectedFunc={this.userSelected}
                                                    autoCompleteAction={usersAutoCompleteAction}
                                                    example="Yazmaya başlayın..."
                                                    component={autoCompleteFieldComponent}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bottom-btn-item">
                                        <p>{submitFailed === true && errArr.length>0 ? formErrors[errArr[0]] : (this.errorMsg ? this.errorMsg :"") }</p>
                                        <button type="submit" className="btn-green">EĞİTİMİ YARAT</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.loaderIsShowing === true && <LoaderModalComponent/>
                }
                {
                    this.successMessage === true && <SuccessModalComponent message={"Eğitim Planlandı."}/>
                }
            </Fragment>
        )
    }
};

const mapStateToProps = (state) => ({
   formErrors: state.form.createNewEducation? state.form.createNewEducation.syncErrors:{}
});

NewPlanedEducationModalComponent = reduxForm({
    form: "createNewPlanedEducation",
    validate
})(NewPlanedEducationModalComponent);

export default connect(mapStateToProps)(NewPlanedEducationModalComponent);
