import React, {Component,Fragment} from 'react'
import {connect} from "react-redux";
import {reduxForm, Field, initialize} from "redux-form";
import inputFieldComponent from "../common/formFields/inputFieldComponent";
import radioFieldComponent from "../common/formFields/radioFieldComponent";
import {getUserDetailAction, receiveUsers, updateUserAction} from "../../actions/panelAction";
import LoaderModalComponent from "../common/loaderModalComponent";
import SuccessModalComponent from "../common/successModalComponent";

const validate = (values) => {
    let hasErrors = false;
    let errors = {};
    if (!values.name_surname || values.name_surname.trim() === "") {
        hasErrors = true;
        errors.name_surname = "Lütfen tüm alanları doldurun.";
    }
    if (!values.role || values.role.trim() === "") {
        hasErrors = true;
        errors.name_surname = "Lütfen tüm alanları doldurun.";
    }
    if (!values.email || values.email.trim() === "" || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        hasErrors = true;
        errors.email = "Lütfen tüm alanları doldurun.";
    }
    return hasErrors && errors;
};

class NewUserModalComponent extends Component {
    loaderIsShowing = false;
    successMessage = false;
    errMsg;
    contentBlock = React.createRef();
    constructor(props){
        super(props);
        if(props.selectedUserId){
            props.dispatch(getUserDetailAction({id:props.selectedUserId},(res)=>{
                if(res!==false){
                    props.dispatch(initialize('createNewUser',{name_surname:res.name_surname,email:res.email,role:res.role_id,id:props.selectedUserId,is_active:res.is_active}));
                }
            }))
        }
    }
    createNewUser = (values) => {
        this.loaderIsShowing = true;
        this.errMsg = null;
        this.forceUpdate();
        this.props.dispatch(updateUserAction(values,(res,message)=>{
            this.loaderIsShowing = false;
            if(res === true){
                if(!values.id){
                    this.props.dispatch(receiveUsers({users:[message]},false))
                }
                this.successMessage = true;
                this.forceUpdate();
                setTimeout(()=>{
                    this.props.toggleModal(false,"isNewUser");
                },3000);
                this.props.getUsers();
            }else{
                this.errMsg = message;
                this.forceUpdate();
            }
        }))
    };
    render() {
        let {toggleModal, handleSubmit, formErrors,submitFailed} = this.props;
        let errArr = formErrors ? Object.keys(formErrors):{};
        return (
            <Fragment>
                {
                    this.successMessage === false &&
                    <div className="lb-root" onClick={(e)=>{
                        if(this.contentBlock.current.contains(e.target) === false){
                            toggleModal(false,"isNewUser")
                        }
                    }}>
                        <div className="lb-block" ref={this.contentBlock}>
                            <div className="content-block add-part-block add-new-block">

                                <form className="content-item" onSubmit={handleSubmit(this.createNewUser)}>

                                    <div className="head-block">
                                        <h2>{this.props.selectedUserId ? "Kullanıcı Düzenle" : "Yeni Kullanıcı"}</h2>
                                        <p>{this.props.selectedUserId ? "Kullanıcıyı düzenleyin.":"Yeni bir kullanıcı oluşturun."}</p>
                                    </div>

                                    <i className="icon-close" onClick={() => {
                                        toggleModal(false,"isNewUser")
                                    }}></i>


                                    <div className="form-block">

                                        <div className="input-container">
                                            <div className="input-block">
                                                <Field
                                                    name="name_surname"
                                                    label="ADI SOYADI"
                                                    type="text"
                                                    component={inputFieldComponent}
                                                />
                                            </div>

                                        </div>
                                        <div className="input-container">
                                            <div className="input-block">
                                                <Field
                                                    name="email"
                                                    label="E-POSTA ADRESİ"
                                                    type="text"
                                                    component={inputFieldComponent}
                                                />
                                            </div>

                                        </div>
                                        <Field
                                            name="role"
                                            label="KULLANICI TİPİ"
                                            component={radioFieldComponent}
                                            options={[
                                                {"value":"1","text":"Admin"},
                                                {"value":"3","text":"Editör"},
                                                {"value":"4","text":"Eğitmen"},
                                                // {"value":"5","text":"Kullanıcı"}
                                            ]}
                                        />
                                        <Field
                                            name="is_active"
                                            label="DURUM"
                                            component={radioFieldComponent}
                                            options={[
                                                {"value":"1","text":"Aktif"},
                                                {"value":"0","text":"Pasif"},
                                            ]}
                                        />
                                    </div>
                                    <div className="bottom-btn-item">
                                        <p>{submitFailed === true && errArr.length>0 ? formErrors[errArr[0]] : this.errMsg ? this.errMsg : "" }</p>
                                        <button type="submit" className="btn-green">{this.props.selectedUserId ? "KULLANICIYI DÜZENLE":"KULLANICI YARAT"}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.loaderIsShowing === true && <LoaderModalComponent/>
                }
                {
                    this.successMessage === true && <SuccessModalComponent message={this.props.selectedUserId ? "Kullanıcı Güncellendi.":"Kullanıcı Eklendi."}/>
                }
            </Fragment>
        )
    }
};

const mapStateToProps = (state) => ({
   formErrors: state.form.createNewUser? state.form.createNewUser.syncErrors:{}
});

NewUserModalComponent = reduxForm({
    form: "createNewUser",
    validate
})(NewUserModalComponent);

export default connect(mapStateToProps)(NewUserModalComponent);