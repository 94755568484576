import React,{Component} from 'react';

class QuizParticipantsContainer extends Component{
    render(){
        let {participants} = this.props;
        return(
            <div className="table-root">
                <p className="head-text">Katılımcılar</p>
                <div className="table-container">
                    <div className="table-head">
                        <div className="t-1 td-item">
                            <p>Kullanıcı Adı</p>
                            <i className="icon-arr-bottom"></i>
                        </div>
                        <div className="t-2 td-item">
                            <p>T.C. Kimlik No</p>
                            <i className="icon-arr-bottom"></i>
                        </div>

                    </div>
                    <div className="table-block">
                        {
                            participants && participants.map((item,key)=>
                                <div className="table-item" key={key}>
                                    <div className="td-item t-1">
                                        <i className={"icon-user2"}></i>
                                        <p>{item.participant.name_surname}</p>
                                    </div>
                                    <div className="td-item t-2">
                                        <p>{item.participant.tc_no}</p>
                                    </div>

                                </div>
                            )
                        }

                    </div>

                </div>
            </div>
        )
    }
};

export default QuizParticipantsContainer;