import React,{Component} from 'react';

class ImageModalComponent extends Component{
    contentBlock = React.createRef();
    render(){
        let {imgPath,toggleImgModal} = this.props;
        return(
            <div className="lb-root" onClick={(e) => {
                if (this.contentBlock.current.contains(e.target) === false) {
                    toggleImgModal(false)
                }
            }}>
                <div className="lb-block">
                    <div ref={this.contentBlock} className="lb-img-block">
                        <div className="close-btn" onClick={()=> {
                            toggleImgModal(false)
                        }}>
                            <i className="icon-close"></i>
                        </div>
                        <img src={imgPath} alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ImageModalComponent;