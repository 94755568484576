import React, {Component,Fragment} from 'react'
import {connect} from "react-redux";
import {reduxForm, Field} from "redux-form";
import inputFieldComponent from "../common/formFields/inputFieldComponent";
import {createSmartDocumentAction} from "../../actions/panelAction";
import LoaderModalComponent from "../common/loaderModalComponent";
import {EDITSMARTEDUCATIONDETAIL_WITHOUTPARAM} from "../../constants/routePaths";

const validate = (values) => {
    let hasErrors = false;
    let errors = {};
    if (!values.smart_document_name || values.smart_document_name.trim() === "") {
        hasErrors = true;
        errors.smart_document_name = "Döküman Adını Doldurmanız Gerekmektedir.";
    }
    return hasErrors && errors;
};

class NewSmartDocumentsModalComponent extends Component {
    errorMessage;
    loaderIsShowing = false;
    contentBlock = React.createRef();
    createNewSmartDocument = (values) => {
        this.loaderIsShowing = true;
        this.forceUpdate();
            this.props.dispatch(createSmartDocumentAction({...values},(res,message)=>{
                this.loaderIsShowing = false;
                if(res === true){
                    this.props.history.push(EDITSMARTEDUCATIONDETAIL_WITHOUTPARAM+message);
                    this.props.toggleModal(false, "isNewSmartDocuments");
                    this.errorMessage = null;
                    this.forceUpdate();
                }else{
                    this.errorMessage = message;
                    this.forceUpdate();
                }
            }))
    };
    render() {
        let {toggleModal, handleSubmit, formErrors,submitFailed} = this.props;
        let errArr = formErrors ? Object.keys(formErrors):{};
        return (
            <Fragment>
                <div className="lb-root" onClick={(e)=>{
                    if(this.contentBlock.current.contains(e.target) === false){
                        toggleModal(false,"isNewSmartDocuments")
                    }
                }}>
                    <div className="lb-block">
                        <div ref={this.contentBlock} className="content-block add-part-block add-new-block">

                            <form className="content-item" onSubmit={handleSubmit(this.createNewSmartDocument)}>

                                <div className="head-block">
                                    <h2>Akıllı Döküman Oluştur</h2>
                                    <p>İstediğiniz eğitimde kullanabilceğiniz akıllı bir döküman oluşturun.</p>
                                </div>

                                <i className="icon-close" onClick={() => {
                                    toggleModal(false,"isNewSmartDocuments")
                                }}></i>


                                <div className="form-block">
                                    <div className="input-container">
                                        <div className="input-block">
                                            <Field
                                                name="smart_document_name"
                                                label="DÖKÜMAN ADI "
                                                type="text"
                                                component={inputFieldComponent}
                                                example="Örn: Kayıp İğne Prosedürü"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom-btn-item">
                                    <p>{(submitFailed === true && errArr.length>0) ? formErrors[errArr[0]] : (this.errorMessage ? this.errorMessage : "") }</p>
                                    <button type="submit" className="btn-green">AKILLI DÖKÜMAN YARAT</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {
                    this.loaderIsShowing === true && <LoaderModalComponent/>
                }
            </Fragment>
        )
    }
};

const mapStateToProps = (state) => ({
   formErrors: state.form.createNewSmartDocument? state.form.createNewSmartDocument.syncErrors:{}
});

NewSmartDocumentsModalComponent = reduxForm({
    form: "createNewSmartDocument",
    validate
})(NewSmartDocumentsModalComponent);

export default connect(mapStateToProps)(NewSmartDocumentsModalComponent);