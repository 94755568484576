import React, {Component, Fragment} from 'react';
import Helper from '../../helper';
import {connect} from "react-redux";
import {uploadQuizQuestionImageAction} from "../../actions/quizAction";
import {ASSETS} from "../../constants/Path";

class QuizQuestionFormComponent extends Component {
    options = ["A", "B", "C", "D", "E", "F", "G", "H"];
    deletedId;
    quesitionImage = React.createRef();
    checkIsValid = () => {
        if(this.props.errorArr[this.props.activeQuestionIndex]){
          this.props.checkFormIsValid();
      }
    };
    answerSelected = (activeQuestion,optionItem) => {
        if (activeQuestion.options.find((questionOption => questionOption.is_answer === true))) {
            activeQuestion.options.find((questionOption => questionOption.is_answer === true)).is_answer = false;
        }
        optionItem.is_answer = true;
        this.forceUpdate();
        this.checkIsValid();
    };
    questionFileChange = (e) => {
        if(e.target.files[0] && Helper.isImage(e.target.files[0].name)){
            this.props.dispatch(uploadQuizQuestionImageAction(e.target.files[0],(result)=>{
                if(result !==false){
                    this.props.activeQuestion.image = result;
                    this.forceUpdate();
                }
            }));
            /*const reader = new FileReader();
            reader.onload = (function(aImg) { return function(e) { aImg.src = e.target.result; }; })(this.quesitionImage.current);
            reader.readAsDataURL(e.target.files[0]);
            this.props.activeQuestion.image = e.target.files[0];*/
        }
    };
    optionFileChange = (e,optionItem,ref) => {
        if(e.target.files[0] && Helper.isImage(e.target.files[0].name)) {
            this.props.dispatch(uploadQuizQuestionImageAction(e.target.files[0],(result)=>{
                if(result !==false){
                    optionItem.image = result;
                    this.forceUpdate();
                }
            }));
           /* const reader = new FileReader();
            reader.onload = (function(aImg) { return function(e) { aImg.src = e.target.result; }; })(this[ref]);
            reader.readAsDataURL(e.target.files[0]);
            optionItem.image = e.target.files[0];*/
        }
    };
    render() {
        let {activeQuestion, questions, activeQuestionIndex, addNewQuestion, changeKey, deleteQuestion, errorArr, checkFormIsValid,saveQuiz} = this.props;
        if(activeQuestion && activeQuestion.options && activeQuestion.options.length<4){
            for(let i=0; i<5-activeQuestion.options.length; i++){
                activeQuestion.options.push({"text":"",image:null})
            }
        }
        return (
            <Fragment>
                <div className="form-block">
                    {
                        activeQuestion &&
                        <div className="add-quiz-container">
                            <div className="add-quiz-block">
                                <div className="question-text">
                                    <span>SORU {parseInt(activeQuestionIndex)+1}</span>
                                    <textarea  className={activeQuestion.name.length>0?"focus-text":""} value={activeQuestion.name} onChange={(e) => {
                                        activeQuestion.name = e.target.value;
                                        this.forceUpdate();
                                        this.checkIsValid();
                                    }}></textarea>
                                    {
                                        this.props.activeQuestion.image &&
                                        <div className="upload-img">
                                            <img ref={this.quesitionImage} src={ASSETS+this.props.activeQuestion.image} alt=""/>
                                        </div>
                                    }
                                    <div className="add-img">
                                        <input type="file" onChange={this.questionFileChange}/>
                                        <span>GÖRSEL EKLE</span>
                                    </div>
                                </div>
                                <div className="options-block">
                                    {
                                        activeQuestion.options.map((optionItem, key) =>
                                            <div className="option-item " key={key}>
                                                <span>{this.options[key]}</span>
                                                <div className="question-content">
                                                <textarea className={optionItem.text.length>0?"focus-text":""} type="text" value={optionItem.text} onChange={(e) => {
                                                    optionItem.text = e.target.value;
                                                    this.forceUpdate();
                                                    this.checkIsValid();
                                                }}></textarea>
                                                    {
                                                        optionItem.image &&
                                                        <div className="upload-img">
                                                            <img ref={(component)=>{
                                                                this[activeQuestionIndex+"-"+key] = component;
                                                            }} src={ASSETS+optionItem.image} alt=""/>
                                                        </div>
                                                    }
                                                    <div className="add-img">
                                                        <input onChange={(e)=>{this.optionFileChange(e,optionItem,activeQuestionIndex+"-"+key)}} type="file"/>
                                                        <span>GÖRSEL EKLE</span>
                                                    </div>
                                                </div>
                                                <div className="radio-item">
                                                    <input key={activeQuestionIndex+"-"+key} checked={optionItem.is_answer === true} type="radio" name="option"
                                                           onChange={() => {this.answerSelected(activeQuestion,optionItem)}}/>
                                                    <div className="radio-label"> </div>
                                                </div>

                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="new-question-root">
                                <div className="top-new-btn">
                                    <div className="new-button" onClick={() => {
                                        addNewQuestion();
                                    }}>
                                        <i className="icon-plus-circle"></i>
                                        <span>YENİ SORU</span>
                                    </div>
                                </div>

                                <div className="question-count-list">
                                    <ul>
                                        {
                                            questions.map((item, key) =>
                                                <li onClick={() => {
                                                    changeKey(key)
                                                }} className={`${key === activeQuestionIndex ? "active" : ""} ${errorArr[key] ? "error" : ""}`} key={key}>
                                                    {key + 1}. Soru
                                                    <i onClick={() => {
                                                        this.deletedId = key;
                                                        this.forceUpdate();
                                                        {/*deleteQuestion(key)*/}
                                                    }} className="icon-close-2"></i>
                                                    {
                                                        this.deletedId === key &&
                                                        <div className="confirm-item">
                                                            <span>Silinsin mi?</span>
                                                            <div className="btn-item">
                                                            <span onClick={() => {
                                                                this.deletedId = null;
                                                                deleteQuestion(key)
                                                            }}>Evet</span>
                                                                <span onClick={() => {
                                                                    this.deletedId = null;
                                                                    this.forceUpdate();
                                                                }}>Hayır</span>
                                                            </div>
                                                        </div>
                                                    }
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }

                </div>

                <div className="bottom-btn-item">
                    {/*<p className="error">Lütfen doğru şık seçiniz</p>*/}
                    <span className="btn-green" onClick={() => {
                        if(checkFormIsValid()){
                            saveQuiz()
                        }
                    }}>QUİZ OLUŞTUR</span>
                </div>
            </Fragment>
        )
    }
}

export default QuizQuestionFormComponent;