import React from 'react';
import Helper from '../../helper'

const PanelUserItemComponent = ({id,name_surname,role,is_active,last_edited_date,toggleNewUserModal}) => (
    <div className="table-item" onClick={()=>{
        toggleNewUserModal(true,id)
    }}>
        <div className="td-item t-1">
            <i className={"icon-user2"}></i>
            <p>{Helper.titleCase(name_surname)}</p>
        </div>
        <div className="td-item t-2">
            <p>{role}</p>
        </div>
        <div className="td-item t-2">
            <p>{is_active === "1" ? "Aktif" : "Pasif"}</p>
        </div>
        <div className="td-item t-3 resp-date">
            <p>{last_edited_date}</p>
        </div>
    </div>
);

export default PanelUserItemComponent;