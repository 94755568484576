import React,{Component} from 'react';
import {connect} from "react-redux";
import PanelBreadCrumbComponent from "../../components/Panel/panelBreadCrumbComponent";
import PanelSmartDocItemComponent from "../../components/Panel/panelSmartDocItemComponent";
import {getSmartDocumentsAction} from "../../actions/panelAction";
import InfiniteScroll from 'react-infinite-scroller';

class PanelSmartEducationsListContainer extends Component{
    activePage = 0;
    order = "smart_document_name";
    order_type = "asc";
    isMore = true;
    loadFunc = () => {
        let params = {
            activePage: this.activePage,
            order: this.order,
            order_type: this.order_type
        };
        this.props.dispatch(getSmartDocumentsAction(params, (res, isMore) => {
            if (res === true) {
                this.isMore = isMore;
                this.activePage++;
                this.forceUpdate();
            }
        }));
    };
    sortFunc = (sort_name) => {
        this.order_type = this.order_type === "asc" ? "desc":"asc";
        // this.order_type = this.order === sort_name ? "asc" : "desc";
        this.order = sort_name;
        this.activePage = 0;
        this.loadFunc();
    };
    render() {
        let {items} = this.props;
        return (
            <div className="panel-content-block">
                <PanelBreadCrumbComponent page="Akıllı Dökümanlar"/>
                <div className="table-root">
                    <div className="table-container">
                        <div className="table-head">
                            <div className="t-1 td-item" onClick={()=>{this.sortFunc("smart_document_name")}}>
                                <p>Dosya adı</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-2 td-item">
                                <p>Durum</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-3 td-item resp-date" onClick={()=>{this.sortFunc("created_date")}}>
                                <p>Son Düzenlenme Tarihi</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                        </div>

                        <div className="table-block">
                            {
                                items &&
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={this.loadFunc}
                                    hasMore={this.isMore && items.length > 19}
                                    threshold={250}
                                >
                                    {
                                        items.map((item,key)=>
                                            <PanelSmartDocItemComponent key={key} {...item}/>
                                        )
                                    }
                                </InfiniteScroll>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state,ownProps) => ({
    items:state.PanelReducer.smartDocuments
});

export default connect(mapStateToProps)(PanelSmartEducationsListContainer);