import React, {Component} from 'react';
import * as ROUTES from "../../constants/routePaths";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getUserNotificationsAction, searchAction} from "../../actions/index";
import HeaderUserComponent from "./headerUserComponent";
import {updateNotificationCount} from "../../actions/userAction";
import NotificationContainer from "../../containers/header/notificationContainer";
import SearchResultItemComponent from "../header/searchResultItemComponent";
import AllEducationsContainer from "../../containers/header/allEducationsContainer";

class HeaderComponent extends Component {

    menuRoot = React.createRef();
    notRoot = React.createRef();
    notificationContainer = React.createRef();
    educationMenu = React.createRef();
    openSearchMenu = React.createRef();
    bgOpen = React.createRef();
    bgOpenSearch = React.createRef();
    searchResult = [];
    searchRoot = React.createRef();
    elementShowingCount;
    lastWindowSize;

    constructor(props) {
        super(props);
        if (typeof window !== "undefined") {
            this.lastWindowSize = window.innerWidth;
            this.elementShowingCount = window.innerWidth > 850 ? 19 : 5;
            window.addEventListener('click', this.clickedWindow);
            window.addEventListener('resize', this.reSizingWindow);
        } else {
            this.elementShowingCount = 19;
        }
    }

    reSizingWindow = () => {
        if ((this.lastWindowSize > 850 && window.innerWidth < 851) || (this.lastWindowSize < 851 && window.innerWidth > 850)) {
            this.lastWindowSize = window.innerWidth;
            this.forceUpdate();
        }
    };

    componentDidUpdate(prevProps, prevState, prevContext) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.searchResult = [];
            this.forceUpdate();
            this.stopBodyScrolling(false);
            this.menuRoot.current.classList.remove("open-menu");
            this.bgOpen.current.classList.remove("opened");
            this.searchRoot.current && this.searchRoot.current.classList.remove("open");
            this.closeSearch();
        }
    }

    stopBodyScrolling = (bool) => {
        if (bool === true) {
            document.body.addEventListener("touchmove", this.freezeVp, {passive: false});
        } else {
            document.body.removeEventListener("touchmove", this.freezeVp, {passive: false});
        }
    };

    freezeVp = (e) => {
        e.preventDefault();
    };

    clickedWindow = (e) => {
        if (this.notRoot.current.contains(e.target) === false) {
            this.notRoot.current.classList.remove("open-root");

        }
        if (this.educationMenu.current.contains(e.target) === false && this.bgOpen.current.className.indexOf("opened")) {
            this.menuRoot.current.classList.remove("open-menu");
            this.bgOpen.current.classList.remove("opened");
            this.stopBodyScrolling(false);

        }
        if (this.openSearchMenu.current && this.openSearchMenu.current.contains(e.target) === false && this.searchResult.length > 0) {
            this.searchResult = [];
            this.forceUpdate();
            this.closeSearch();
        }
        if (this.searchRoot.current && this.searchRoot.current.contains(e.target) === false && this.notRoot.current.className.indexOf("open-root") > -1) {
            this.searchRoot.current.classList.remove("open");
            this.stopBodyScrolling(false);
        }
    };

    openMenu = () => {
        this.menuRoot.current.classList.toggle("open-menu");
        this.bgOpen.current.classList.toggle("opened");
        if (this.bgOpen.current.className.indexOf("opened") > -1) {
            this.stopBodyScrolling(true);
        } else {
            this.stopBodyScrolling(false);
        }
    };

    openNot = () => {
        this.notRoot.current.classList.toggle("open-root");
        if (this.notRoot.current.className.indexOf("open-root") > -1) {
            this.stopBodyScrolling(true);
            this.props.dispatch(getUserNotificationsAction());
            this.props.dispatch(updateNotificationCount(0));
        } else {
            this.stopBodyScrolling(false);
        }
    };

    componentWillUnmount() {
        if (typeof window !== "undefined") {
            window.removeEventListener('click', this.clickedWindow);
            window.removeEventListener('resize', this.reSizingWindow);
        }
    }

    searchFunc = (e) => {
        if (e.target.value.length > 2) {
            this.bgOpenSearch.current.classList.add("opened");
            this.props.dispatch(searchAction(e.target.value, (result) => {
                this.searchResult = result;
                this.forceUpdate();
            }));
        } else {
            this.searchResult = [];
            this.forceUpdate();
            this.closeSearch();
        }
    };

    closeSearch = () => {
        this.bgOpenSearch.current.classList.remove("opened");
        this.stopBodyScrolling(false);
    };

    render() {
        const {educationCategories, notification_count} = this.props;
        return (
            <div className="header-root">
                <div className="header-container safe-area">
                    <div className="logo-block">
                        <Link to={ROUTES.HOME}> <img src="/assets/img/unluTekstilLogo.png" alt="Ünlü Tekstil"/></Link>
                    </div>
                    <div className="menu-root" ref={this.educationMenu}>
                        <div className="menu-btn" onClick={this.openMenu}>
                            <i className="icon-ham-menu"></i>
                            <p>TÜM EĞİTİMLER</p>
                        </div>
                        <div className="bgOpen" onClick={this.openMenu} ref={this.bgOpen}></div>
                        <div className="open-menu-root" ref={this.menuRoot}>
                            <AllEducationsContainer educationCategories={educationCategories} history={this.props.history}/>
                        </div>
                    </div>
                    <div className="action-root">
                        <div className="bgOpen" onClick={this.closeSearch} ref={this.bgOpenSearch}></div>

                        <div className="search-root" ref={this.searchRoot}>
                            <div className="input-block">
                                <div className="input-item">
                                    <i className="icon-search" onClick={() => {
                                        this.searchRoot.current.classList.toggle("open");
                                    }}></i>
                                    <input type="text" onChange={this.searchFunc}
                                           placeholder="Meslek, konu ve eğitim arayın..."/>
                                    <i className="icon-close" onClick={() => {
                                        this.searchRoot.current.classList.remove("open");
                                    }}></i>
                                </div>
                            </div>
                            {
                                this.searchResult.length > 0 &&
                                <div className="open-search-root" ref={this.openSearchMenu}>
                                    <div className="open-search-block">
                                        <ul>
                                            {
                                                this.searchResult.map((item, key) =>
                                                    <SearchResultItemComponent key={key} item={item} />
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="not-root" ref={this.notRoot}>
                            <div className="ring-btn" onClick={this.openNot}>
                                <div className="ringing">
                                    <i className="icon-ring"></i>
                                    {
                                        notification_count > 0 && <span>{notification_count}</span>
                                    }
                                </div>

                            </div>
                            <div className="open-not-root" ref={this.notificationContainer}>
                               <NotificationContainer history={this.props.history}/>
                            </div>
                        </div>

                        <HeaderUserComponent/>
                    </div>

                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    educationCategories: state.educationCategoriesReducer ? state.educationCategoriesReducer : [],
    notification_count: state.sessionReducer.notification_count
});
export default connect(mapStateToProps)(HeaderComponent);

