import React, {Component} from 'react';

class FileFieldComponent extends Component {

    imgRef = React.createRef();

    render() {
        const {input, meta: {error, touched}, placeholder, type, label, linkIsShowing, example, fileOnChangeFunc, classTag} = this.props;
        return (
            <div className={`add-container ${classTag === "after-tag-input" ? "after-tag-input" : ""}`}>
                <label>{label}
                    {example && <span>{example}</span>}
                </label>
                <div className="add-block">
                    <p>{input.value}</p>
                    <div className="add-btn">
                        <input type="file" onChange={(e)=>{
                            if(e.target.files[0] && (e.target.files[0].name.split(".").pop() === "jpg" || e.target.files[0].name.split(".").pop() === "jpeg" || e.target.files[0].name.split(".").pop() === "png")){
                                let img = new Image();
                                img.src = window.URL.createObjectURL(e.target.files[0]);
                                let files = e.target.files;

                                {/*this.imgRef.src = window.URL.createObjectURL(e.target.files[0]);*/}

                                img.onload = (imgE)=> {
                                    let width = img.naturalWidth,
                                        height = img.naturalHeight;
                                    window.URL.revokeObjectURL( img.src );
                                    // if( width === 1000 && height === 1000 )
                                     {/*if( width === 1580 && height === 260 )*/}
                                    //if( width >= 1000 && height >=1000 )
                                    if( !this.props.width || (this.props.width >= 1000 && this.props.height >=1000) )
                                    {
                                        fileOnChangeFunc(files);
                                        input.onChange(files[0].name);
                                    }
                                    else {
                                    }
                                };
                            }
                        }} />
                        {/*<img style={{width:100}} ref={ref=>this.imgRef = ref} alt=""/>*/}


                        <div className="add-item">
                            <i className="icon-add-smartdoc"></i>
                            <span>GÖRSEL YÜKLE</span>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default FileFieldComponent;
