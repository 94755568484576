import React, {Component} from 'react';
import LoginLeftContainer from "../containers/login/loginLeftContainer";
import LoginRightComponent from "../containers/login/loginRightComponent";

class LoginPage extends Component{

    render(){
        return(
            <div className="login-root">

                <div className="login-wrapper safe-area">

                    <div className="login-con">
                        {/*<div className="back-btn">*/}
                            {/*<p>GERİ DÖN</p>*/}
                        {/*</div>*/}
                        <div className="login-block">
                            <LoginLeftContainer/>
                            <LoginRightComponent location={this.props.location} history={this.props.history}/>
                        </div>
                    </div>


                </div>
            </div>
        )
    }

}

export default LoginPage
