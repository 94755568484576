import React, {Component} from 'react';
import {Chart} from 'react-google-charts';

class QuizResultEducationComponent extends Component {
    yearArr = [];

    constructor(props) {
        super(props);
        let startYear = 2019;
        while (startYear < new Date().getFullYear() + 1) {
            this.yearArr.push(startYear);
            startYear++;
        }
    }

    locationOnChange = (e) => {
        this.props.getData(this.props, e.target.value);
    };
    render() {
        let {chartData} = this.props;
        let chartArr = [];
        Object.keys(chartData).map(chartKey => {
            if(!isNaN((chartData[chartKey].correctCount * 100) / chartData[chartKey].totalCount)){
                let charItem = [chartData[chartKey].name.substring(0, 5), (chartData[chartKey].correctCount * 10) / (chartData[chartKey].participantCount*chartData[chartKey].QuestionCount), chartData[chartKey].QuestionCount, chartData[chartKey].name, chartData[chartKey].participantCount];
                chartArr.push(charItem);
            }

        });
        return (
            <div className="chart-block">
                <div className="table-head">
                    <div></div>
                    <div className="select-container">
                        <div className="select-block">
                            {/*<label>Yıl Seçiniz</label>*/}
                            <select onChange={this.locationOnChange} defaultValue={new Date().getFullYear()}>
                                {
                                    this.yearArr.map((yearItem, key) =>
                                        <option key={key} value={yearItem}>{yearItem}</option>
                                    )
                                }
                            </select>
                            <i className="icon-arr-bottom"></i>
                        </div>
                    </div>
                </div>

                {
                    (chartArr && chartArr.length>0) &&
                    <Chart
                        width={'100%'}
                        height={'500px'}
                        chartType="BubbleChart"
                        loader={<div>Loading Chart</div>}
                        data={[
                            ['Eğitim', 'Doğru Cevap Sayısı (10 Soru Üzerinden)', 'Soru Sayısı', 'Eğitim Adı', 'Katılımcı Sayısı'],
                            ...chartArr
                        ]}
                        options={{
                            hAxis: {title: 'Doğru Cevap Sayısı'},
                            vAxis: {title: 'Soru Sayısı'},
                        }}
                    />
                }
            </div>
        )
    }
}
;

export default QuizResultEducationComponent;