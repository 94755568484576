import React, {Component,Fragment} from 'react';
import QuizResultByEducationItemComponent from "./QuizResultByEducationItemComponent";
import {educationAutoCompleteAction} from "../../actions/panelAction";

class QuizResultByEducationComponent extends Component {
    yearArr=[];
    monthArr=[
        {value:null,text:"Tümü"},
        {value:1,text:"Ocak"},
        {value:2,text:"Şubat"},
        {value:3,text:"Mart"},
        {value:4,text:"Nisan"},
        {value:5,text:"Mayıs"},
        {value:6,text:"Haziran"},
        {value:7,text:"Temmuz"},
        {value:8,text:"Ağustos"},
        {value:9,text:"Eylül"},
        {value:10,text:"Ekim"},
        {value:11,text:"Kasım"},
        {value:12,text:"Aralık"},
    ];
    activeYear=new Date().getFullYear();
    activeMonth;
    autoCompleteArr;
    autoIsShowing = false;
    selectedEducation;
    sortName="correct_count";
    sortType="desc";
    inputItem = React.createRef();
    constructor(props){
        super(props);
        let startYear = 2018;
        while (startYear<new Date().getFullYear()+1){
            this.yearArr.push(startYear);
            startYear++;
        }
    }
    onChangeFunc = (value) => {
        if (value.length > 2) {
            this.props.dispatch(educationAutoCompleteAction({searchTerm: value}, (res) => {
                if (res !== false) {
                    this.autoCompleteArr = res;
                    this.forceUpdate();
                }
            }));
        } else {
            this.autoCompleteArr = [];
            this.forceUpdate();
        }
    };
    selectedFunc = (item) => {
        this.selectedEducation = item;
        this.autoCompleteArr = [];
        this.inputItem.current.value = item.name;
        this.props.getData(this.props,this.activeYear,this.selectedEducation.id,this.activeMonth,this.sortName,this.sortType);
    };

    locationOnChange = (e) => {
        this.activeYear = parseInt(e.target.value);
        this.forceUpdate();
        if(this.selectedEducation){
            this.props.getData(this.props,e.target.value,this.selectedEducation.id,this.activeMonth,this.sortName,this.sortType);
        }
    };
    changeFocus = (value) => {
        this.autoIsShowing = value;
        this.forceUpdate();
    };
    monthOnChange = (e) => {
        this.activeMonth = parseInt(e.target.value);
        this.forceUpdate();
        if(this.selectedEducation){
            this.props.getData(this.props,this.activeYear,this.selectedEducation.id,this.activeMonth,this.sortName,this.sortType);
        }
    };
    sortFunc = (sortName) => {
        if(this.sortName === sortName){
            this.sortType = this.sortType === "desc" ? "asc":"desc";
        }
        this.sortName = sortName;
        this.props.getData(this.props,this.activeYear,this.selectedEducation.id,this.activeMonth,this.sortName,this.sortType);
    };
    render() {
        let {data} = this.props;
        return (
            <Fragment>
                <div className="table-head table-filter">
                    <div className="input-item">
                        <label>
                            <input autoComplete="off"
                                   onFocus={(e)=>{
                                       this.changeFocus(true)
                                   }}
                                   onBlur={(e)=>{
                                       setTimeout(()=>{
                                           if(this.autoCompleteArr && this.autoCompleteArr.length===1){
                                               this.selectedFunc(this.autoCompleteArr[0])
                                           }else{
                                               this.changeFocus(false)
                                           }
                                       },200)

                                   }}
                                   onChange={(e) => {
                                       this.onChangeFunc(e.target.value)
                                   }}
                                   ref={this.inputItem}
                                   type="text"
                                   placeholder="Eğitim Adı Yazınız."/>
                        </label>
                        {
                            (this.autoIsShowing === true && this.autoCompleteArr && this.autoCompleteArr.length > 0) &&
                            <div className="autocomplete-root">
                                <ul>
                                    {
                                        this.autoCompleteArr.map((item, key) =>
                                            <li onClick={() => {
                                                this.selectedFunc(item)
                                            }}
                                                key={key}>{item.name}</li>
                                        )
                                    }
                                </ul>
                            </div>

                        }
                    </div>
                    <div className="select-container">
                        <div className="select-block">
                            {/*<label>Yıl Seçiniz</label>*/}
                            <select onChange={this.locationOnChange} defaultValue={new Date().getFullYear()}>
                                {
                                    this.yearArr.map((yearItem, key) =>
                                        <option key={key} value={yearItem}>{yearItem}</option>
                                    )
                                }
                            </select>
                            <i className="icon-arr-bottom"></i>
                        </div>
                        <div className="select-block">
                            {/*<label>Ay Seçiniz</label>*/}
                            <select onChange={this.monthOnChange} defaultValue={null}>
                                {
                                    this.monthArr.map((monthItem, key) =>
                                        (this.activeYear !== new Date().getFullYear() || monthItem.value<=new Date().getMonth()+1) &&
                                        <option key={key} value={monthItem.value}>{monthItem.text}</option>
                                    )
                                }
                            </select>
                            <i className="icon-arr-bottom"></i>
                        </div>
                    </div>

                </div>

                <div className="table-head">
                    <div className="t-1 td-item" onClick={() => {
                        this.sortFunc("name")
                    }}>
                        <p>Kullanıcı Adı</p>
                        <i className="icon-arr-bottom"></i>
                    </div>
                    <div className="t-2 td-item" onClick={() => {
                        this.sortFunc("correct_count")
                    }}>
                        <p>Doğru Cevap Sayısı</p>
                        <i className="icon-arr-bottom"></i>
                    </div>
                </div>
                <div className="table-block">
                    {
                        data && data.map((item, key) =>
                            <QuizResultByEducationItemComponent key={key} {...item}/>
                        )
                    }
                </div>
            </Fragment>
        )
    }
}
;

export default QuizResultByEducationComponent;