import React from 'react';
import Helper from '../../helper';

const PanelFeedbackItemComponent = ({subject,user_name,state,feedback_date,id, toggleDetail, educationCategory, feedback, duration}) => (
    <div className="table-item" onClick={() => {toggleDetail({subject,user_name,state,feedback_date,id,educationCategory,duration,feedback},true)}} >
        <div className="td-item t-1">
            <i className={"icon-feedback"}></i>
            <p>{Helper.titleCase(subject)}</p>
        </div>
        <div className="td-item t-2">
            <p>{user_name}</p>
        </div>
        <div className="td-item t-3">
            <p>{state}</p>
        </div>
        <div className="td-item t-3 resp-date">
            <p>{feedback_date}</p>
        </div>
    </div>
);

export default PanelFeedbackItemComponent;