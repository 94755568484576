import React from 'react';
import * as ROUTES from "../../constants/routePaths";
import {Link} from "react-router-dom";

const SignUpLeftContainer = () => (
    <div className="left-block">
        <div className="logo-item">
            <img src="/assets/img/unluTekstilLogo2.png" alt="unluTekstilLogo"/>
        </div>
        <div>
            <p>Zaten bir hesabınız var mı?<br/><strong>Hemen giriş yap!</strong></p>
            <Link to={ROUTES.LOGIN} className="btn-1">GİRİŞ YAP</Link>
        </div>

    </div>
);

export default SignUpLeftContainer;