import React from 'react';
import {Link} from "react-router-dom";
import {EDITSMARTEDUCATIONDETAIL_WITHOUTPARAM} from "../../constants/routePaths";
import Helper from '../../helper';

const PanelSmartDocItemComponent = ({title,is_active,created_date,document_type,smart_document_name,id}) => (
    <Link to={EDITSMARTEDUCATIONDETAIL_WITHOUTPARAM+id} className="table-item">
        <div className="td-item t-1">
            <i className={document_type==="1"?"icon-doc":"icon-smartdoc"}></i>
            <p>{Helper.titleCase(smart_document_name)}</p>
        </div>
        <div className="td-item t-2">
            <p>{is_active==1?"Yayında":"Yayında Değil"}</p>
        </div>
        <div className="td-item t-3 resp-date">
            <p>{created_date}</p>
        </div>
    </Link>
);

export default PanelSmartDocItemComponent;