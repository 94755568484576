import React, {Component,Fragment} from 'react';
import inputFieldComponent from "../common/formFields/inputFieldComponent";
import {Field, reduxForm, change, initialize} from "redux-form";
import fileFieldComponent from "../common/formFields/fileFieldComponent";
import {receiveSurveys, saveEducationPictureAction, updateSurveyAction} from "../../actions/panelAction";
import InputOptionsFieldComponent from "../common/formFields/inputOptionsFieldComponent";
import {connect} from "react-redux";
import LoaderModalComponent from "../common/loaderModalComponent";
import SuccessModalComponent from "../common/successModalComponent";

const validate = (values) => {
    let hasErrors = false;
    let errors = {};
    if (!values.name || values.name.trim() === "") {
        hasErrors = true;
        errors.name = "Anket Adını Doldurmanız Gerekmektedir.";
    }
    if (!values.question || values.question.trim() === "") {
        hasErrors = true;
        errors.question = "Anket İçeriği Doldurmanız Gerekmektedir.";
    }
    if (!values.image || values.image.trim() === "") {
        hasErrors = true;
        errors.image = "Lütfen Kapak Görseli Ekleyiniz.";
    }
    if(!values.options || values.options.filter(optionItem=>optionItem.option_name.trim().length>0)>1){
        hasErrors = true;
        errors.options = "Lütfen En Az İki Seçenek Ekleyiniz.";
    }
    return hasErrors && errors;
};

class CreateSurveyModalComponent extends Component {
    contentBlock = React.createRef();
    image;
    errMsg;
    options=[];
    loaderIsShowing = false;
    successMessage = false;
    componentDidMount() {
        if(this.props.editedSurvey){
            this.image = this.props.editedSurvey.image;
            this.props.dispatch(initialize("createNewSurvey",this.props.editedSurvey));
        }
    }

    fileOnChangeFunc = (file) => {
        this.props.dispatch(saveEducationPictureAction(file[0], "survey", (res) => {
            if (res !== false) {
                // this.props.dispatch(change('createNewSurvey', 'image', res));
                this.image = res;
                this.forceUpdate();
            }
        }));
    };
    onSubmit = (values) => {
        this.loaderIsShowing = true;
        this.forceUpdate();
        values.image = this.image;
        this.props.dispatch(updateSurveyAction(values, (res, massage) => {
            this.loaderIsShowing = false;
            if (res === true) {
                if(!this.props.editedSurvey){
                    this.props.dispatch(receiveSurveys({surveys:massage},false))
                }
                this.successMessage = true;
                this.forceUpdate();
                setTimeout(()=>{
                    this.props.toggleModal(false, "isNewSurvey");
                },3000);
            } else {
                this.errMsg = massage;
                this.forceUpdate();
            }
        }));
    };

    render() {
        let {toggleModal, handleSubmit, errors} = this.props;
        return (
            <Fragment>
                {
                    this.successMessage !== true &&
                    <div className="lb-root" onClick={(e) => {
                        if (this.contentBlock.current.contains(e.target) === false) {
                            toggleModal(false, "isNewSurvey")
                        }
                    }}>
                        <div className="lb-block">
                            <div ref={this.contentBlock} className="content-block add-part-block add-new-block">
                                <form onSubmit={handleSubmit(this.onSubmit)} className="content-item">
                                    <div className="head-block">
                                        <h2>Anket Yarat</h2>
                                        <p>Yeni bir anket yaratın ve kullanıcıların hakkında ne düşündüğünü öğrenin.</p>
                                    </div>
                                    <i className="icon-close" onClick={() => {
                                        toggleModal(false, "isNewSurvey");
                                    }}></i>
                                    <div className="survey-block">
                                        <div className="form-block">
                                            <div className="input-container">
                                                <div className="input-block">
                                                    <Field
                                                        name="name"
                                                        label="ANKET ADI "
                                                        type="text"
                                                        component={inputFieldComponent}
                                                        example="Örn: Kullanıcı memnuniyeti"
                                                    />
                                                </div>
                                            </div>
                                            <div className="input-container">
                                                <div className="input-block">
                                                    <Field
                                                        name="question"
                                                        label="ANKET İÇERİĞİ "
                                                        type="text"
                                                        component={inputFieldComponent}
                                                        example="Maksimum 140 karakter ile açıklama yapabilirsiniz"
                                                    />
                                                </div>
                                            </div>
                                            <div className="input-container">
                                                <div className="input-block">
                                                    <InputOptionsFieldComponent
                                                        label="SEÇENEKLER "
                                                        example="Bir anket için max 3 seçenek girebilirsiniz. Boş bıraktığınız seçenekler görünmeyecektir."/>
                                                </div>
                                            </div>
                                            <Field
                                                name="image"
                                                label="ARKAPLAN GÖRSELİ "
                                                component={fileFieldComponent}
                                                example="Yükleyeceğiniz görsel boyutunun en düşük 1000x1000px olmalıdır."
                                                fileOnChangeFunc={this.fileOnChangeFunc}
                                            />
                                        </div>
                                    </div>
                                    <div className="bottom-btn-item">
                                        <p className="error">{this.props.submitFailed === true && errors && Object.keys(errors).length > 0 ? errors[Object.keys(errors)[0]] : ""}</p>
                                        <button type="submit" value="ANKETİ YARAT" className="btn-green">ANKETİ YARAT
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.loaderIsShowing === true && <LoaderModalComponent/>
                }
                {
                    this.successMessage === true && <SuccessModalComponent message={this.props.editedSurvey?"Anket güncellendi.":"Anket oluşturuldu."}/>
                }
            </Fragment>

        )
    }
};



CreateSurveyModalComponent = reduxForm({
    form: "createNewSurvey",
    validate
})(CreateSurveyModalComponent);

const mapStateToProps = (state) => ({
    errors: state.form.createNewSurvey?state.form.createNewSurvey.syncErrors:{}
});

export default connect(mapStateToProps)(CreateSurveyModalComponent);