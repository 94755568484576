import React from 'react';
import {Link} from "react-router-dom";
import * as ROUTES from "../../constants/routePaths";
import moment from "moment";

const EducationForWeekItemComponent = ({education_name,user_name,education_id,id,session_start_date,diff,location_name}) => (
    <Link to={ROUTES.PANEL_REPORTS_EDUCATION_RANKING_WITHOUT_PARAM+id} className="table-item" >
        <div className="td-item t-1">
            <i className="icon-newquiz"></i>
            <p >{education_name}</p>
        </div>
        <div className="td-item t-2">
            <p >{user_name}</p>
        </div>
        <div className="td-item t-2">
            <p >{moment(session_start_date).format('DD.MM.YYYY')}</p>
        </div>
        <div className="td-item t-2">
            <p >{diff}</p>
        </div>
        <div className="td-item t-2">
            <p >{location_name}</p>
        </div>
    </Link>
);

export default EducationForWeekItemComponent;