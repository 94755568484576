import React,{Component} from 'react';
import DatePicker, {setDefaultLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import {change} from 'redux-form'
import 'moment/locale/tr'
import {connect} from "react-redux";
moment.locale('tr-TR');
setDefaultLocale('tr');

class DatePickerInputComponent extends Component{
    handleChange = (date) => {
       this.startDate = date;
       if(!this.startTime){
           // this.startTime = new Date("00:00");
       }
       this.props.input.onChange(moment(this.startDate).format('YYYY-MM-DD'));
       this.forceUpdate();
    };
    startTimeOnChange = (date) => {
        this.startTime = date;
        this.props.dispatch(change('createNewPlanedEducation','started_time',moment(this.startTime).format('HH:mm')));
        this.forceUpdate();
    };
    render(){
        return(
            <div className="input-item">
                <label>{this.props.label}
                <div className="date-input-block">
                    <DatePicker
                        selected={this.startDate}
                        onChange={this.handleChange}
                        // locale={'tr-TR'}
                    />
                    <DatePicker
                        selected={this.startTime}
                        onChange={this.startTimeOnChange}
                        // locale={'tr-TR'}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        timeCaption="Time"
                    />
                </div>

                </label>
            </div>
        );
    }
};

export default connect()(DatePickerInputComponent);