import React, {Component} from 'react';
import PageService from '../pageServices/pageService';
import * as ROUTES from "../constants/routePaths";
import {connect} from "react-redux";
import {matchPath, Prompt} from "react-router-dom";
import EducationDetailHeaderContainer from "../containers/educationDetail/educationDetailHeaderContainer";
import EducationDetailContainer from "../containers/educationDetail/educationDetailContainer";
import EducationWarningModalComponent from "../components/modals/educationWarningModalComponent";
import FeedBackModalComponent from "../components/modals/feedBackModalComponent";
import ShareModalComponent from "../components/modals/shareModalComponent";
import AddParticipantsModalComponent from "../components/modals/addParticipantsModalComponent";
import FeedBackResultModalComponent from "../components/modals/feedBackResultModalComponent";
import SessionAddDocumentModalComponent from "../components/modals/sessionAddDocumentModalComponent";
import EducationRemoveModalComponent from "../components/modals/educationRemoveModalComponent";
import {getVersionEducationDetailAction} from "../actions/educationDetailAction";

class EducationDetailPage extends Component {
    detailContent = React.createRef();
    detailContentComponent = React.createRef();
    webService;
    sessionId;
    focusedId;
    headerComponent = React.createRef();
    versionData;
    activeTab = "version";
    constructor(props) {
        super(props);
        this.getPageData(props);
        this.state = {
            isBlocking: false
        };
        let urlArr = props.match.params.educationLink.split("-");
        this.educationId = urlArr[urlArr.length-1];
        this.props.dispatch(getVersionEducationDetailAction({link:urlArr[urlArr.length-1]},(res)=>{
            this.versionData = res;
            this.forceUpdate();
        }))
    }


    getPageData = (props) => {
        this.webService = new PageService(props.location.pathname);
        let urlParams = matchPath(props.location.pathname, {
            path: ROUTES.EDITEDUCATIONVERSIONDETAIL,
            exact: false,
            strict: false
        });
        this.webService.getData(props.dispatch, urlParams);
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.isSessionExist !== this.props.isSessionExist) {
            if (nextProps.activeBreak) {
                this.focusedId = nextProps.activeBreak.focused_id;
            }
            this.participants = nextProps.participants;
            this.sessionId = nextProps.isSessionExist ? nextProps.isSessionExist.id : null;
            this.setState({
                isEducationStarted: !!nextProps.isSessionExist
            })
        }
        if (nextProps.location.pathname !== this.props.location.pathname) {
            this.getPageData(nextProps);
            let urlArr = nextProps.match.params.educationLink.split("-");
            this.educationId = urlArr[urlArr.length-1];
            this.props.dispatch(getVersionEducationDetailAction({link:urlArr[urlArr.length-1]},(res)=>{
                this.versionData = res;
                this.forceUpdate();
            }))
        }
        return true;
    }

    changeFocused = (value) => {
        this.focusedId = value;
        this.forceUpdate();
    };

    componentWillUnmount() {
        window.onbeforeunload = () => {
        };
        window.onunload = () => {
        };
    }

    changeTab = (tab) => {
        this.activeTab = tab;
        this.forceUpdate();
    };

    render() {
        return (
            this.state.isEducationStarted !== null ?
                <div className="mainRoot">


                    {/*<Prompt
                     when={this.state.isBlocking}
                     message={location =>
                     `Are you sure you want to go to ${location.pathname}`
                     }
                     />*/}

                    <EducationDetailHeaderContainer toggleShareModal={this.toggleShareModal} roleId={this.props.roleId}
                                                    toggleIsEditing={this.toggleIsEditing} isVersion={true}
                                                    changeKeyModal={this.changeKeyModal} endSession={this.endSession}
                                                    toggleFeedBack={this.toggleFeedBack} isEditing={this.isEditing}
                                                    urlLink={this.props.match.params.educationLink} changeTab={this.changeTab}
                                                    ref={this.headerComponent} history={this.props.history} educationId={this.educationId}
                                                    detailInfo={this.props.detailInfo} activeTab={this.activeTab} versionData={this.versionData}
                                                    isStarted={this.state.isEducationStarted} sessionId={this.sessionId}
                                                    detailContent={this.detailContent} openModal={this.toggleModal}
                                                    detailContentComponent={this.detailContentComponent}
                                                    isEditExist={this.props.isEditExist}
                                                    toggleAddParticipants={this.toggleAddParticipants}/>
                    <div className="detail-content-root" ref={this.detailContent}>
                        <EducationDetailContainer urlLink={this.props.match.params.educationLink} versionData={this.activeTab === "version" ? this.versionData:null}
                                                  history={this.props.history} headerComponent={this.headerComponent}
                                                  toggleIsEditing={this.toggleIsEditing} focusedId={this.focusedId} isVersion={true}
                                                  ref={this.detailContentComponent} changeFocused={this.changeFocused}
                                                  startEducation={this.startEducation} sessionId={this.sessionId}
                                                  detailContent={this.detailContent} isEditing={this.isEditing}
                                                  isStarted={this.state.isEducationStarted}/>
                    </div>
                </div>
                :
                <div></div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let urlArr = ownProps.location.pathname.split('-');
    return ({
        isSessionExist: state.educationDetailReducer[urlArr[urlArr.length - 1]] ? state.educationDetailReducer[urlArr[urlArr.length - 1]].isSessionExist : null,
        participants: state.educationDetailReducer[urlArr[urlArr.length - 1]] ? state.educationDetailReducer[urlArr[urlArr.length - 1]].sessionParticipants : [],
        activeBreak: state.educationDetailReducer[urlArr[urlArr.length - 1]] ? state.educationDetailReducer[urlArr[urlArr.length - 1]].activeBreak : [],
        detailInfo: state.educationDetailReducer[urlArr[urlArr.length - 1]] ? state.educationDetailReducer[urlArr[urlArr.length - 1]].info : null,
        roleId: state.sessionReducer.role_id,
        isEditExist: state.educationDetailReducer[urlArr[urlArr.length - 1]] ? state.educationDetailReducer[urlArr[urlArr.length - 1]].isEditExist : null,
    });
};

export default connect(mapStateToProps)(EducationDetailPage);
