import React, {Component} from 'react'
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import autoCompleteFieldComponent from "../common/formFields/autoCompleteFieldComponent";
import {getSmartDocumentsAutoCompleteAction} from "../../actions/panelAction";

class AddSmartDocumentsModal extends Component {
    selectedSmartDocument;
    contentBlock = React.createRef();
    smartDocumentSelected = (item) => {
        this.selectedSmartDocument = item;
    };
    addSmartDocument = () => {
        this.props.addSmartDocument(this.selectedSmartDocument)
    };
    render() {
        let {toggleModal} = this.props;
        return (
            <div className="lb-root" onClick={(e) => {
                if (this.contentBlock.current.contains(e.target) === false) {
                    toggleModal(false)
                }
            }}>
                <div className="lb-block">
                    <form  ref={this.contentBlock} className="content-block add-part-block add-new-block">
                        <div className="content-item">
                            <div className="head-block">
                                <h2>Akıllı Döküman Ekle</h2>
                            </div>
                            <i className="icon-close" onClick={() => {
                                toggleModal(false)
                            }}></i>
                            <div className="form-block">
                                <div className="input-container">
                                    <div className="input-block">
                                        <Field
                                            name="smart_name"
                                            type="text"
                                            placeholder="AKILLI DÖKÜMAN ADI"
                                            label="AKILLI DÖKÜMAN ADI"
                                            selectedFunc={this.smartDocumentSelected}
                                            autoCompleteAction={getSmartDocumentsAutoCompleteAction}
                                            component={autoCompleteFieldComponent}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="bottom-btn-item">
                                <button onClick={this.addSmartDocument} type="submit" className="btn-green">EKLE</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
};

AddSmartDocumentsModal = reduxForm({
    form: 'smartDocument'
})(AddSmartDocumentsModal);

export default connect()(AddSmartDocumentsModal);