import React, {Component} from 'react';
import {connect} from "react-redux";
import PanelBreadCrumbComponent from "../../components/Panel/panelBreadCrumbComponent";
import PanelLocationItemComponent from "../../components/Panel/panelLocationItemComponent";
import {getPanelLocationAction} from "../../actions/panelAction";
import InfiniteScroll from 'react-infinite-scroller';
import NewLocationModalComponent from "../../components/modals/newLocationModalComponent";

class PanelLocationListContainer extends Component {
    activePage = 0;
    order = "name";
    order_type = "asc";
    isMore = true;
    isNewLocation = false;
    selectedLocation;
    loadFunc = () => {
        let params = {
            activePage: this.activePage,
            order: this.order,
            order_type: this.order_type
        };
        this.props.dispatch(getPanelLocationAction(params, (res, isMore) => {
            if (res === true) {
                this.isMore = isMore;
                this.activePage++;
                this.forceUpdate();
            }
        }));
    };
    sortFunc = (sort_name) => {
        this.order_type = this.order_type === "asc" ? "desc":"asc";
        // this.order_type = this.order === sort_name ? "asc" : "desc";
        this.order = sort_name;
        this.activePage = 0;
        this.loadFunc();
    };
    selectLocation = (item) => {
        this.selectedLocation = item;
        this.toggleNewLocation(true);
    };
    toggleNewLocation = (value) => {
          this.isNewLocation = value;
          this.forceUpdate();
    };
    render() {
        let {items} = this.props;
        return (
            <div className="panel-content-block">
                <PanelBreadCrumbComponent page="Lokasyonlar"/>
                <div className="table-root">
                    <div className="table-container">
                        <div className="table-head">
                            <div className="t-1 td-item" onClick={()=>{this.sortFunc("name")}}>
                                <p>Lokasyon</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-2 td-item" onClick={()=>{this.sortFunc("created_date")}}>
                                <p>Oluşturulma Tarihi</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                        </div>
                        {
                            items &&
                            <InfiniteScroll
                                pageStart={0}
                                loadMore={this.loadFunc}
                                hasMore={this.isMore && items.length > 19}
                                threshold={250}
                            >
                                <div className="table-block">
                                    {
                                        items && items.map((item, key) =>
                                            <PanelLocationItemComponent selectLocation={this.selectLocation} key={key} {...item}/>
                                        )
                                    }
                                </div>
                            </InfiniteScroll>
                        }
                        {
                            this.isNewLocation === true && <NewLocationModalComponent selectedLocation={this.selectedLocation} toggleModal={this.toggleNewLocation} />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    items: state.PanelReducer.locations
});

export default connect(mapStateToProps)(PanelLocationListContainer);