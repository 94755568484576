import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import EducationDocumentEditContainer from "../educationDetail/educationDocumentEditContainer";

class EducationEditDocumentContainer extends Component {
    render() {
        let {detailDocument, urlLink, detailContainer, isSmartDocuments, isSmartDocumentEdit, detailDocumentVersion} = this.props;
        // detailDocument = detailDocumentVersion ? detailDocumentVersion : detailDocument ? detailDocument : [];
        return (
            <div className="detail-content-block">
                <EducationDocumentEditContainer isSmartDocumentEdit={this.props.isSmartDocumentEdit}
                                                isSmartDocuments={isSmartDocuments} detailContainer={detailContainer}
                                                urlLink={urlLink}
                                                detailDocument={detailDocument}/>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let urlArr = ownProps.urlLink.split("-");
    let key = ownProps.isSmartDocuments ? "smartDocumentDetailReducer" : "educationDetailReducer";
    return ({
        detailDocument: ownProps.detailDocumentVersion ?  ownProps.detailDocumentVersion : (state[key][urlArr[urlArr.length - 1]] ? state[key][urlArr[urlArr.length - 1]].document : null)
    });
};

export default connect(mapStateToProps, null, null, {withRef: true})(EducationEditDocumentContainer);