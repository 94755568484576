import async from "async";
import * as WebService from '../services/webService';
import {receivePanelLocation, receivePanelNotifications} from "../actions/panelAction";

export const panelLocationServices = (dispatch,params) => {
    return new Promise((resolve, failure) => {
        async.series([
            (cb) => {
                WebService.getPanelLocations(params).then((res) => {
                    if (res.data.success) {
                        cb(null, res.data.success);
                    }
                })
                    .catch((err) => {

                    })
            },
            (cb) => {
                WebService.getPanelNotifications(params).then((res) => {
                    if (res.data.success) {
                        cb(null, res.data.success);
                    }
                })
                    .catch((err) => {
                        cb(null,null)
                    })
            }
        ], (err, result) => {
            dispatch(receivePanelLocation(result[0],true));
            dispatch(receivePanelNotifications(result[1]));
            resolve(result);
        });
    });
};
