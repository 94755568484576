import React, {Component} from 'react';
import PageService from '../pageServices/pageService';
import * as ROUTES from "../constants/routePaths";
import {connect} from "react-redux";
import {matchPath, Prompt} from "react-router-dom";
import EducationDetailHeaderContainer from "../containers/educationDetail/educationDetailHeaderContainer";
import EducationDetailContainer from "../containers/educationDetail/educationDetailContainer";
import EducationWarningModalComponent from "../components/modals/educationWarningModalComponent";
import FeedBackModalComponent from "../components/modals/feedBackModalComponent";
import ShareModalComponent from "../components/modals/shareModalComponent";
import AddParticipantsModalComponent from "../components/modals/addParticipantsModalComponent";
import FeedBackResultModalComponent from "../components/modals/feedBackResultModalComponent";
import SessionAddDocumentModalComponent from "../components/modals/sessionAddDocumentModalComponent";
import EducationRemoveModalComponent from "../components/modals/educationRemoveModalComponent";

class EducationDetailPage extends Component {
    detailContent = React.createRef();
    detailContentComponent = React.createRef();
    webService;
    educationWarningIsShowing = false;
    shareModalIsShowing = false;
    educationWarningMessage;
    educationWarningDesc;
    quizIsShowing;
    feedBackModalIsShowing;
    addParticipantsIsShowing = false;
    feedBackResultModal = false;
    isEditing = false;
    isQuizExist = false;
    docsType;
    isAddSessionDocument = false;
    isRemovedModal = false;
    sessionId;
    focusedId;
    participants = [];
    headerComponent = React.createRef();

    constructor(props) {
        super(props);
        this.getPageData(props);
        this.state = {
            isBlocking: false
        };

    }

    getPageData = (props) => {
        this.webService = new PageService(props.location.pathname);
        let urlParams = matchPath(props.location.pathname, {
            path: ROUTES.EDUCATIONDETAIL,
            exact: false,
            strict: false
        });
        this.webService.getData(props.dispatch, urlParams);
        if (props.isSessionExist !== null && props.isSessionExist !== false && typeof props.isSessionExist !== "undefined") {
            this.participants = props.participants;
            this.sessionId = props.isSessionExist.id;
            this.forceUpdate();
            this.state = {
                isEducationStarted: !!props.isSessionExist
            };
            if (props.activeBreak) {
                this.focusedId = props.activeBreak.focused_id;
            }
        } else {
            this.state = {
                isEducationStarted: null
            };
        }
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.isSessionExist !== this.props.isSessionExist) {
            if (nextProps.activeBreak) {
                this.focusedId = nextProps.activeBreak.focused_id;
            }
            this.participants = nextProps.participants;
            this.sessionId = nextProps.isSessionExist ? nextProps.isSessionExist.id : null;
            this.setState({
                isEducationStarted: !!nextProps.isSessionExist
            })
        }
        if (nextProps.location.pathname !== this.props.location.pathname) {
            this.getPageData(nextProps);
        }
        return true;
    }

    toggleModal = (value, message, desc, isQuiz, isQuizExist) => {
        this.educationWarningIsShowing = value;
        this.educationWarningMessage = message;
        this.educationWarningDesc = desc;
        this.quizIsShowing = isQuiz;
        this.isQuizExist = isQuizExist;
        this.forceUpdate();
    };
    toggleShareModal = (value) => {
        this.shareModalIsShowing = value;
        this.forceUpdate();
    };
    toggleAddParticipants = (value) => {
        this.addParticipantsIsShowing = value;
        this.forceUpdate();
    };
    toggleFeedBack = (value) => {
        this.feedBackModalIsShowing = value;
        this.forceUpdate();
    };
    toggleFeedBackResultModal = (value) => {
        this.feedBackResultModal = value;
        this.feedBackModalIsShowing = this.feedBackResultModal === false ? false : this.feedBackModalIsShowing;
        this.forceUpdate();
    };
    toggleIsEditing = (value) => {
        this.isEditing = value;
        this.forceUpdate();
    };
    changeKeyModal = (key, value) => {
        this[key] = value;
        this.forceUpdate();
    };
    startEducation = (participants, sessionId) => {
        if (participants) {
            this.participants = participants;
            this.sessionId = sessionId ? sessionId : this.sessionId;
            this.setState({
                isEducationStarted: true
            })
        } else {
            this.addParticipantsIsShowing = true;
            this.forceUpdate();
        }
    };
    endSession = () => {
        this.setState({
            isEducationStarted: false
        })
    };
    changeFocused = (value) => {
        this.focusedId = value;
        this.forceUpdate();
    };

    componentWillUnmount() {
        window.onbeforeunload = () => {
        };
        window.onunload = () => {
        };
    }

    openSessionDocs = (value, docsType) => {
        this.isAddSessionDocument = value;
        this.educationWarningIsShowing = !value;
        this.docsType = docsType;
        this.forceUpdate();
    };

    render() {
        return (
            this.state.isEducationStarted !== null ?
                <div className="mainRoot">


                    {/*<Prompt
                     when={this.state.isBlocking}
                     message={location =>
                     `Are you sure you want to go to ${location.pathname}`
                     }
                     />*/}

                    <EducationDetailHeaderContainer toggleShareModal={this.toggleShareModal} roleId={this.props.roleId}
                                                    toggleIsEditing={this.toggleIsEditing} isQuizExist={this.props.isQuizExist}
                                                    changeKeyModal={this.changeKeyModal} endSession={this.endSession}
                                                    toggleFeedBack={this.toggleFeedBack} isEditing={this.isEditing}
                                                    urlLink={this.props.match.params.educationLink}
                                                    ref={this.headerComponent} history={this.props.history}
                                                    detailInfo={this.props.detailInfo}
                                                    isStarted={this.state.isEducationStarted} sessionId={this.sessionId}
                                                    detailContent={this.detailContent} openModal={this.toggleModal}
                                                    detailContentComponent={this.detailContentComponent}
                                                    isEditExist={this.props.isEditExist}
                                                    toggleAddParticipants={this.toggleAddParticipants}/>
                    <div className="detail-content-root" ref={this.detailContent}>
                        <EducationDetailContainer urlLink={this.props.match.params.educationLink} isEditExist={this.props.isEditExist}
                                                  history={this.props.history} headerComponent={this.headerComponent}
                                                  toggleIsEditing={this.toggleIsEditing} focusedId={this.focusedId}
                                                  ref={this.detailContentComponent} changeFocused={this.changeFocused}
                                                  startEducation={this.startEducation} sessionId={this.sessionId}
                                                  detailContent={this.detailContent} isEditing={this.isEditing}
                                                  isStarted={this.state.isEducationStarted}/>
                    </div>
                    {
                        this.educationWarningIsShowing === true &&
                        <EducationWarningModalComponent urlLink={this.props.match.params.educationLink}
                                                        toggleModal={this.toggleModal} sessionId={this.sessionId}
                                                        message={this.educationWarningMessage}
                                                        isQuizExist={this.isQuizExist}
                                                        openSessionDocs={this.openSessionDocs}
                                                        endSession={this.endSession} focusedId={this.focusedId}
                                                        quizIsShowing={this.quizIsShowing}
                                                        desc={this.educationWarningDesc}/>
                    }
                    {
                        this.feedBackModalIsShowing === true &&
                        <FeedBackModalComponent toggleModal={this.toggleFeedBack}
                                                urlLink={this.props.match.params.educationLink}
                                                sessionId={this.sessionId}
                                                toggleFeedBackResultModal={this.toggleFeedBackResultModal}/>
                    }
                    {
                        this.shareModalIsShowing === true &&
                        <ShareModalComponent shareUrl={this.props.location.pathname}
                                             changeKeyModal={this.changeKeyModal}
                                             urlLink={this.props.match.params.educationLink}/>
                    }
                    {
                        this.addParticipantsIsShowing === true &&
                        <AddParticipantsModalComponent urlLink={this.props.match.params.educationLink}
                                                       participants={this.participants} sessionId={this.sessionId}
                                                       startEducation={this.startEducation}
                                                       toggleModal={this.toggleAddParticipants}
                                                       isStarted={this.state.isEducationStarted}/>
                    }
                    {
                        this.feedBackResultModal === true &&
                        <FeedBackResultModalComponent toggleFeedBackResultModal={this.toggleFeedBackResultModal}/>
                    }
                    {
                        this.isAddSessionDocument === true &&
                        <SessionAddDocumentModalComponent urlLink={this.props.match.params.educationLink}
                                                          openSessionDocs={this.openSessionDocs}
                                                          sessionId={this.sessionId} docsType={this.docsType}
                                                          detailInfo={this.props.detailInfo}
                                                          toggleModal={this.toggleModal}/>
                    }
                    {
                        this.isRemovedModal === true && <EducationRemoveModalComponent education_id={this.props.detailInfo.id} detailInfo={this.props.detailInfo}
                                                                                       changeKeyModal={this.changeKeyModal}/>
                    }
                </div>
                :
                <div></div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let urlArr = ownProps.location.pathname.split('-');
    return ({
        isSessionExist: state.educationDetailReducer[urlArr[urlArr.length - 1]] ? state.educationDetailReducer[urlArr[urlArr.length - 1]].isSessionExist : null,
        participants: state.educationDetailReducer[urlArr[urlArr.length - 1]] ? state.educationDetailReducer[urlArr[urlArr.length - 1]].sessionParticipants : [],
        activeBreak: state.educationDetailReducer[urlArr[urlArr.length - 1]] ? state.educationDetailReducer[urlArr[urlArr.length - 1]].activeBreak : [],
        detailInfo: state.educationDetailReducer[urlArr[urlArr.length - 1]] ? state.educationDetailReducer[urlArr[urlArr.length - 1]].info : null,
        roleId: state.sessionReducer.role_id,
        isEditExist: state.educationDetailReducer[urlArr[urlArr.length - 1]] ? state.educationDetailReducer[urlArr[urlArr.length - 1]].isEditExist : null,
        isQuizExist: state.educationDetailReducer[urlArr[urlArr.length - 1]] ? state.educationDetailReducer[urlArr[urlArr.length - 1]].isQuizExist : null,
    });
};

export default connect(mapStateToProps)(EducationDetailPage);
