import React, {Component} from 'react';
import PageService from '../pageServices/pageService';
import * as ROUTES from "../constants/routePaths";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {getQuizQuestionAction, getTeacherQuizQuestionAction} from "../actions/quizAction";
import QuizQuestionStatusContainer from "../containers/quizQuestion/quizQuestionStatusContainer";
import QuizQuestionContainer from "../containers/quizQuestion/quizQuestionContainer";
import _ from "lodash";
import {QUIZRESULT_WITHOUT_PARAM} from "../constants/routePaths";

class QuizWebTeacherPage extends Component {
    question;
    isResult;
    constructor(props) {
        super(props);
        this.getQuestion(props);
    }

    getQuestion(props) {
        props.dispatch(getTeacherQuizQuestionAction(props.match.params, (res) => {
            this.question = res;
            this.forceUpdate();
        }))
    }

    goToBeforeQuestion = () => {
        let params = this.props.match.params;
        this.props.history.push(`${ROUTES.QUIZWEBTEACHER_WITHOUT_PARAMS}${params.educationSessionId}/${params.quizId}/${this.question.beforeQuestionId}`)
    };

    goToNextQuestion = () => {
        if (this.question.nextQuestionId) {
            let params = this.props.match.params;
            this.props.history.push(`${ROUTES.QUIZWEBTEACHER_WITHOUT_PARAMS}${params.educationSessionId}/${params.quizId}/${this.question.nextQuestionId}`)
        } else {
            //TODO:RESULT SAYFASINA YÖNLEDİRELECEK.
            /*this.props.dispatch(getTeacherQuizQuestionAction(this.props.match.params, (res) => {
                this.question = res;
                this.forceUpdate();
            }));*/
            let params = this.props.match.params;
            this.props.history.push(`${QUIZRESULT_WITHOUT_PARAM}${params.educationSessionId}`);

            // this.isResult = true;
            // this.forceUpdate();
        }
    };

    componentDidUpdate(prevProps) {
        if (_.isEqual(this.props.match.params, prevProps.match.params) === false) {
            this.getQuestion(this.props);
        }
    }

    render() {
        return (
            <div className="mainRoot">
                {
                    this.question &&
                    <div className="quiz-web-root">

                        <div className="safe-area quiz-web-container">
                            {
                                (this.question && this.isResult !== true) && <QuizQuestionStatusContainer quizInfo={this.question.quizInfo} quizTotalQuestionCount={this.question.quizTotalQuestionCount}
                                                                                                          sessionQuiz={this.question.sessionQuiz}
                                                                                                          generalQuiz={this.question.generalQuiz} quizQuestionCount={this.question.quizQuestionCount}
                                                                              educationInfo={this.question.educationInfo} resultStatistic={this.question.resultStatistic}/>
                            }
                            {
                                this.isResult !== true ?
                                    <QuizQuestionContainer {...this.props} goToBeforeQuestion={this.goToBeforeQuestion}
                                                           goToNextQuestion={this.goToNextQuestion}
                                                           params={this.props.match.params} isTeacher={true}
                                                           question={this.question.question} options={this.question.options}
                                                           beforeQuestionId={this.question.beforeQuestionId}
                                                           nextQuestionId={this.question.nextQuestionId}/>
                                    :
                                    <div className=" quiz-processing-root result">
                                        <div className="desc-text-block">
                                            <div className="img-item">
                                                <img src="/assets/img/common/qrCodeGorsel.png" alt="QR"/>
                                            </div>
                                            <p className="warn-text">Quiz Sonlandı Teşekkür ederiz.</p>
                                        </div>
                                    </div>
                            }

                        </div>
                    </div>
                }
            </div>
        )
    }
}


export default connect()(QuizWebTeacherPage);
