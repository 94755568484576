import async from "async";
import * as WebService from '../services/webService';
import {receiveEducationDetail} from "../actions/educationDetailAction";
import {receivePanelLocation} from "../actions/panelAction";

export const edicationDetailServices = (dispatch,params,secondParams) => {
    return new Promise((resolve, failure) => {
        async.series([
            (cb) => {
                WebService.getEducationDetail(params).then((res) => {
                    if (res.data.success) {
                        cb(null, res.data.success);
                    }
                })
                    .catch((err) => {

                    })
            },
            (cb) => {
                WebService.getPanelLocations(secondParams).then((res) => {
                    if (res.data.success) {
                        cb(null, res.data.success);
                    }
                })
                    .catch((err) => {

                    })
            }
        ], (err, result) => {
            dispatch(receiveEducationDetail(params.link,result[0]));
            dispatch(receivePanelLocation(result[1],true));
            resolve(result);
        });
    });


};
