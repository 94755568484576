import React from 'react';

const ParticipantsContainer = ({participants}) => (
    <div className="table-root">
        <div className="table-container">
            <div className="table-head">
                <div className="t-1 td-item">
                    <p>Kullanıcı Adı</p>
                    <i className="icon-arr-bottom"></i>
                </div>
                <div className="t-2 td-item">
                    <p>T.C. Kimlik No</p>
                    <i className="icon-arr-bottom"></i>
                </div>

            </div>
            <div className="table-block">
                {
                    participants.map((participant,key)=>
                        <div className="table-item" key={key}>
                            <div className="td-item t-1">
                                <i className={"icon-user2"}></i>
                                <p>{participant.name_surname}</p>
                            </div>
                            <div className="td-item t-2">
                                <p>{participant.tc_no}</p>
                            </div>

                        </div>
                    )
                }
            </div>

        </div>
    </div>
);

export default ParticipantsContainer;