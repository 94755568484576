import React, {Component} from 'react';
import {connect} from "react-redux";
import {getSessionEducationDetailAction} from "../../actions/panelAction";
import ParticipantsContainer from "../../containers/educationSessionDetail/participantsContainer";
import SessionQuizResultContainer from "../../containers/educationSessionDetail/sessionQuizResultContainer";

class EducationSessionDetailModalComponent extends Component {
    activeTab = "participants";
    contentBlock = React.createRef();

    constructor(props) {
        super(props);
        props.dispatch(getSessionEducationDetailAction({education_session_id: props.detail.id}))
    }

    changeActiveTab = (activeTab) => {
        this.activeTab = activeTab;
        this.forceUpdate();
    };

    render() {
        let {detail, toggleDetail, sessionDetail} = this.props;
        return (
            <div className="lb-root" onClick={(e) => {
                if (this.contentBlock.current.contains(e.target) === false) {
                    toggleDetail(null)
                }
            }}>
                <div className="lb-block">
                    <div ref={this.contentBlock} className="content-block education-detail-lb">
                        <div className="head-block">
                            <h2>{detail.education_name}</h2>
                            <div className="info-item">
                                <span className="label-tag green">#MESLEKİ EĞİTİM</span>
                                <div className="time-block">
                                    <span><i className="icon-calendar"></i>{detail.education_start_date}</span>
                                </div>
                                <div className="time-block">
                                    <span><i className="icon-clock"></i>1sa 30 dk</span>
                                </div>
                                <div className="time-block">
                                    <span><i className="icon-user"></i>{detail.teacher}</span>
                                </div>
                            </div>
                        </div>
                        {
                            sessionDetail &&
                            <div className="content-items">
                                <i className="icon-close" onClick={() => {
                                    toggleDetail(null)
                                }}></i>
                                <div className="tab-block">
                                    <ul>
                                        <li onClick={() => {
                                            this.changeActiveTab("participants")
                                        }} className={this.activeTab === "participants" ? "active" : ""}>Katılımcılar
                                        </li>
                                        <li onClick={() => {
                                            this.changeActiveTab("quiz")
                                        }} className={this.activeTab === "quiz" ? "active" : ""}>Quiz
                                        </li>
                                        <li onClick={() => {
                                            this.changeActiveTab("docs")
                                        }} className={this.activeTab === "docs" ? "active" : ""}>Belgeler
                                        </li>
                                    </ul>
                                </div>
                                {
                                    this.activeTab === "participants" &&
                                    <ParticipantsContainer participants={sessionDetail.participants}/>
                                }
                                {
                                    this.activeTab === "quiz" &&
                                    <SessionQuizResultContainer quizSessionInfo={sessionDetail.quiz.quizSessionInfo}
                                                                quizResultArray={sessionDetail.quiz.quizResultArray}/>
                                }
                                {
                                    this.activeTab === "docs" &&
                                    <div className="table-root">
                                        <div className="new-button"><i
                                            className="icon-plus-circle"></i><span>BELGE EKLE</span></div>
                                        <div className="table-container">
                                            <div className="table-head">
                                                <div className="t-1 td-item">
                                                    <p>Dosya Adı</p>
                                                    <i className="icon-arr-bottom"></i>
                                                </div>
                                            </div>
                                            <div className="table-block">
                                                <div className="table-item">
                                                    <div className="td-item t-1">
                                                        <i className="icon-doc"></i>
                                                        <p>makineci_dikim_elemani_egitimi_18.08.19_16.30.42.png</p>
                                                    </div>

                                                    <div className="act-btn">
                                                        <i className="icon-search"></i>
                                                        <p>GÖRÜNTÜLE</p>
                                                    </div>
                                                    <div className="act-btn">
                                                        <i className="icon-arr-bottom"></i>
                                                        <p>İNDİR</p>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>

                </div>
            </div>
        )
    }
}
;

const mapStateToProps = (state, ownProps) => ({
    sessionDetail: state.PanelReducer.sessionDetail[ownProps.detail.id]
});

export default connect(mapStateToProps)(EducationSessionDetailModalComponent);