import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {
    PANEL_DOCUMENTS_WITOUTPARAM,
    PANEL_SMARTDOCUMENTS_WITOUTPARAM,
    PANEL_USERS_WITOUTPARAM,
    PANEL_EDUCATIONS_WITOUTPARAM,
    PANEL_FEEDBACK_WITOUTPARAM,
    PANEL_QUIZ_WITOUTPARAM,
    PANEL_DRAFTS_WITOUTPARAM,
    PANEL_LOCATIONS_WITOUTPARAM, PANEL_SURVEYS_WITOUTPARAM
} from "../../constants/routePaths";
import Helper from '../../helper';
import {connect} from "react-redux";
import PanelMenuItemComponent from "../../components/Panel/panelMenuItemComponent";
import NewItemComponent, {Fragment} from "../../components/Panel/newItemComponent";
import NewEducationModalComponent from "../../components/modals/newEducationModalComponent";
import NewUserModalComponent from "../../components/modals/newUserModalComponent";
import NewSmartDocumentsModalComponent from "../../components/modals/newSmartDocumentsModalComponent";
import NewLocationModalComponent from "../../components/modals/newLocationModalComponent";
import {receiveEducationCategories} from "../../actions/index";
import CreateQuizModalComponent from "../../components/modals/createQuizModalComponent";
import CreateSurveyModalComponent from "../../components/modals/createSurveyModalComponent";
import NewPlanedEducationModalComponent from "../../components/modals/newPlanedEducationModalComponent";
import NewParticipantModalComponent from '../../components/modals/newParticipantModalComponent';
import SuccessModalComponent from "../../components/common/successModalComponent";
import queryString from "query-string";
import NewTagModalComponent from "../../components/modals/newTagModalComponent";

class PanelMenuContainer extends Component {
    headBtn = React.createRef();
    addBlock = React.createRef();
    newBtnRef = React.createRef();
    isNewEducation = false;
    isNewUser = false;
    isNewSmartDocuments = false;
    isNewLocation = false;
    isNewQuiz = false;
    isNewSurvey = false;
    isNewParticipant = false;
    planedEducation = false;
    tagId;
    constructor(props) {
        super(props);
        if (!props.educationCategories) {
            props.dispatch(receiveEducationCategories())
        }
        const urlParams = queryString.parse(props.location.search)                        
        if(urlParams.education_id){
            this.isNewEducation = true;        
        }
        if (urlParams.id){
            this.isNewTag = true;
        }
    }

    componentDidMount() {
        if (typeof window !== "undefined") {
            window.addEventListener('click', this.clickedWindow)
        }
    }

    clickedWindow = (e) => {
        if (this.addBlock.current.contains(e.target) === false && this.newBtnRef.current.contains(e.target) === false) {
            this.headBtn.current.classList.remove("openMenu");
        }
    };
    newBtn = () => {
        this.headBtn.current.classList.toggle("openMenu");
    };
    goToPage = (url) => {
        this.props.history.push(url)
    };
    toggleModal = (value, key) => {
        this[key] = value;
        this.forceUpdate()
    };

    componentWillUnmount() {
        if (typeof window !== "undefined") {
            window.removeEventListener('click', this.clickedWindow)
        }
    }


    render() {
        let {match, notifications, educationCategories, roleAccess, routes, pathName, role_id} = this.props;
        let page = match.params.page;
        return (
            <aside className="panel-menu-root">
                <div className="panel-menu-block">
                    <div className="head-btn" ref={this.headBtn}>
                        <div className="new-button" ref={this.newBtnRef} onClick={this.newBtn}>
                            <i className="icon-plus-circle"></i>
                            <span>YENİ</span>
                        </div>
                        <div className="new-open-root" ref={this.addBlock}>
                            <div className="arr-up"></div>
                            {/*<i className="arr-up"></i>*/}
                            <NewItemComponent history={this.props.history} roleAccess={roleAccess} toggleModal={this.toggleModal}/>
                        </div>
                    </div>

                    <div className="panel-menu-list">
                        <ul>
                            {
                                roleAccess && roleAccess.map((roleItem, key) =>
                                routes[roleItem.page.menuItem] &&
                                    <PanelMenuItemComponent key={key} pathName={pathName} role_id={role_id}
                                                            url={"/panel" + roleItem.page.link} goToPage={this.goToPage}
                                                            icon={routes[roleItem.page.menuItem].icon}
                                                            title={roleItem.page.menuItem} notificationsItem={0}
                                                            page={page}
                                                            subPages={roleItem.page.menuItem === "Dökümanlar" && page==="dokumanlar" ? Helper.getDocumentSubPage() :roleItem.page.menuItem === "Eğitimler" && page==="egitimler"? Helper.getEducationSubPage(): (roleItem.page.menuItem === "Raporlar" && page==="raporlar"?Helper.getReportSubPage():[])}/>
                                )
                            }
                            {/*<PanelMenuItemComponent url={"/panel/dashboard"} goToPage={this.goToPage} icon={"icon-dashboard"}
                             title="Dashboard" notificationsItem={0} page={page}/>
                             <PanelMenuItemComponent url={PANEL_DOCUMENTS_WITOUTPARAM} goToPage={this.goToPage} icon={"icon-documents"}
                             title="Dökümanlar" notificationsItem={notifications.docs}
                             page={page} subPages={Helper.getDocumentSubPage()}/>
                             <PanelMenuItemComponent url={PANEL_SMARTDOCUMENTS_WITOUTPARAM} goToPage={this.goToPage} icon={"icon-smartdocuments"}
                             title="Akıllı Dökümanlar" notificationsItem={notifications.smartDocuments} page={page}/>
                             <PanelMenuItemComponent url={PANEL_USERS_WITOUTPARAM} goToPage={this.goToPage} icon={"icon-users"}
                             title="Kullanıcılar" notificationsItem={notifications.users} page={page}/>
                             <PanelMenuItemComponent url={PANEL_EDUCATIONS_WITOUTPARAM} goToPage={this.goToPage} icon={"icon-calendar"}
                             title="Eğitimler" notificationsItem={notifications.educations} page={page}/>
                             <PanelMenuItemComponent url={PANEL_FEEDBACK_WITOUTPARAM} goToPage={this.goToPage} icon={"icon-feedbacks"}
                             title="Geri Bildirimler" notificationsItem={notifications.feedback} page={page}/>
                             <PanelMenuItemComponent url={PANEL_QUIZ_WITOUTPARAM} goToPage={this.goToPage} icon={"icon-quizs"}
                             title="Quizler" notificationsItem={notifications.quiz} page={page}/>
                             <PanelMenuItemComponent url={PANEL_DRAFTS_WITOUTPARAM} goToPage={this.goToPage} icon={"icon-drafts"}
                             title="Taslaklar" notificationsItem={notifications.draft} page={page}/>
                             <PanelMenuItemComponent url={PANEL_LOCATIONS_WITOUTPARAM} goToPage={this.goToPage} icon={"icon-locations"}
                             title="Lokasyonlar" notificationsItem={notifications.location} page={page}/>
                             <PanelMenuItemComponent url={PANEL_SURVEYS_WITOUTPARAM} goToPage={this.goToPage} icon={"icon-surveys"}
                             title="Anketler " notificationsItem={notifications.location} page={page}/>*/}
                        </ul>
                    </div>
                </div>


                {
                    this.isNewEducation === true &&

                    <NewEducationModalComponent history={this.props.history} location={this.props.location} toggleModal={this.toggleModal}
                                                educationCategories={educationCategories}/>
                }
                {
                    this.isNewUser === true &&
                    <NewUserModalComponent toggleModal={this.toggleModal}/>
                }

                {
                    this.isNewSmartDocuments === true &&
                    <NewSmartDocumentsModalComponent history={this.props.history} toggleModal={this.toggleModal}/>
                }
                {
                    this.isNewLocation === true && <NewLocationModalComponent toggleModal={this.toggleModal}/>
                }
                {
                    this.isNewQuiz === true && <CreateQuizModalComponent toggleModal={this.toggleModal}/>
                }
                {
                    this.isNewSurvey === true && <CreateSurveyModalComponent toggleModal={this.toggleModal}/>
                }
                {
                    this.planedEducation === true &&
                    <NewPlanedEducationModalComponent educationCategories={educationCategories}
                                                      toggleModal={this.toggleModal}/>
                }
                {
                    this.isNewParticipant === true &&
                    <NewParticipantModalComponent toggleModal={this.toggleModal}/>
                }
                {
                    this.isNewTag === true &&
                    <NewTagModalComponent isNewTag={this.isNewTag} toggleModal={this.toggleModal} {...this.props}/>
                }
            </aside>
        )
    }
}

const mapStateToProps = (state) => ({
    notifications: state.PanelReducer.notifications,
    educationCategories: state.homeReducer.educationCategories,
    role_id:state.sessionReducer.role_id
});

export default withRouter(connect(mapStateToProps)(PanelMenuContainer));