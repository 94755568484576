import React,{Component} from 'react';
import SessionAddDocumentModalComponent from "../../components/modals/sessionAddDocumentModalComponent";

class UploadDocumentContainer extends Component{
    isAddSessionDocument = false;
    openSessionDocs = (value, docsType) => {
        if(value === false){
            this.props.getData();
        }
        this.isAddSessionDocument = value;
        this.docsType = docsType;
        this.forceUpdate();
    };
    render(){
        return(
            <div className="down-doc-block">
                <p className="head-text">Belge Yükle</p>
                <p>Bu eğitim seansı ile ilgili dökümanları sisteme yükleyin.</p>
                <div className="btn-item">
                                    <span className="btn-2" onClick={() => {
                                        this.openSessionDocs(true, "computer")
                                    }}><i className="icon-download"></i> BİLGİSAYARDAN YÜKLE</span>
                    <span className="btn-2" onClick={() => {
                        this.openSessionDocs(true, "phone")
                    }}><i className="icon-qr"></i> TELEFONDAN YÜKLE</span>
                </div>
                <div className="doc-list">
                    <ul>
                        {
                            this.props.sessionDocuments && this.props.sessionDocuments.map((document, key) =>
                                <li key={key}>
                                    <i className="icon-doc"></i>
                                    {document.file_name}
                                    {/*<i className="icon-close-2" onClick={()=>{this.deleteFromIndex(key)}}></i>*/}
                                </li>
                            )
                        }
                    </ul>
                </div>
                {
                    this.isAddSessionDocument === true &&
                    <SessionAddDocumentModalComponent openSessionDocs={this.openSessionDocs} sessionToken={this.props.sessionToken}
                                                      sessionId={this.props.sessionDetail.id} docsType={this.docsType}
                                                      detailInfo={this.props.educationDetail}/>
                }
            </div>
        )
    }
};

export default UploadDocumentContainer;