import React, {Component} from 'react';
import {EDUCATIONDETAIL_WITHOUTPARAM, PANEL_DOCUMENTS_WITOUTPARAM} from '../../constants/routePaths';
import moment from 'moment';
import trLocale from 'moment/locale/tr';
import {Link} from "react-router-dom";
import Helper from '../../helper';
import NewEducationModalComponent from "../modals/newEducationModalComponent";

moment.locale('tr', trLocale);

class PanelDocItemComponent extends Component {
    isClicked = false;
    toggleModal = (val) =>{
        this.isClicked = val;
        this.forceUpdate();
    };

    render() {
        let {title, state, publish_date, document_type, name, created_date, is_active, id, last_updated_date} = this.props;
        return (
            name ?
                <div>
                    <Link
                        to={`${EDUCATIONDETAIL_WITHOUTPARAM}${Helper.turkishToLower(name).split(" ").join("-").split("?").join("").split("/").join("").split("?").join("")}-${id}`}
                        // onClick={() => {
                        //     this.isClicked = true;
                        //     this.forceUpdate();
                        // }}
                        className="table-item">
                        <div className="td-item t-1">
                            <i className="icon-doc"></i>
                            <p>{name}</p>
                        </div>
                        <div className="td-item t-2">
                            <p>{is_active === "1" ? "Yayında" : "Yayında Değil"}</p>
                        </div>
                        <div className="td-item t-3 resp-date">
                            <p>{last_updated_date ? moment(last_updated_date).format('LL') : moment(created_date).format('LL')}</p>
                        </div>
                        <div className="td-item t-4 resp-date">
                            <span onClick={(e) => {
                                // e.stopPropagation();
                                e.preventDefault();
                                console.log("this.props.match",this.props.match)
                                this.props.history.push(`${this.props.match.params.subPage ? this.props.match.params.subPage : this.props.match.url}?education_id=${id}`)
                                this.toggleModal(true);
                            }}>Güncelle</span>
                        </div>
                    </Link>
                    {
                        this.isClicked &&
                        <NewEducationModalComponent fromUpdate={true} toggleModal={this.toggleModal} {...this.props}/>
                    }
                </div>
                :
                <div></div>
        )
    }
}

export default PanelDocItemComponent;