import React, {Component} from 'react';
import FundamentalCourseItemComponent from "./FundamentalCourseItemComponent";
import {Swipeable} from 'react-swipeable'

class FundamentalCoursesComponent extends Component {
    slickBlock = React.createRef();
    boxBlock = React.createRef();
    firstBoxBlock = React.createRef();
    sliderBlock = React.createRef();
    showingCount;

    constructor(props) {
        super(props);


        this.state = {
            fundamentalCoursesCurrentPage: 0,
            fundamentalCoursesTotalPage: 0,
            fundamentalCoursesPageSize: 5,
            currentFundamentalCoursesArr: []
        };
    }

    componentDidMount() {
        if (this.boxBlock.current) {
            let margin = parseFloat(window.getComputedStyle(this.boxBlock.current).getPropertyValue('margin-left'));
            if (this.slickBlock.current.offsetWidth > this.firstBoxBlock.current.offsetWidth) {
                this.showingCount = Math.floor((this.slickBlock.current.offsetWidth - this.firstBoxBlock.current.offsetWidth) / (this.boxBlock.current.offsetWidth + margin)) + 1;
            } else {
                this.showingCount = Math.floor((this.slickBlock.current.offsetWidth) / (this.boxBlock.current.offsetWidth + margin)) > 0 ? Math.floor((this.slickBlock.current.offsetWidth) / (this.boxBlock.current.offsetWidth + margin)) : 1;
            }
           this.addTouchListen();
        }
        this.setState({
            // currentFundamentalCoursesArr: this.props.fundamentalCourses.items,
            fundamentalCoursesTotalPage: Math.ceil((this.props.fundamentalCourses.items.length-1) / this.showingCount)
        });
        if (typeof window !== "undefined") {
            window.addEventListener('resize', this.resizingWindow)
        }
    }
    addTouchListen = () => {
        let tsY;
        let tsX;
        this.touchStartFunc = (e)=>{
            tsY = e.touches[0].clientY;
            tsX = e.touches[0].clientX;
        };
        this.touchMoveFunc = (e)=>{
            let tempY = e.changedTouches[0].clientY;
            let tempX = e.changedTouches[0].clientX;
            if(Math.abs(tempY-tsY)>Math.abs(tempX-tsX)){
            }else{
                e.preventDefault();
            }
        };
        this.sliderBlock.current.addEventListener('touchstart', this.touchStartFunc);
        this.sliderBlock.current.addEventListener('touchmove',this.touchMoveFunc);
    };
    resizingWindow = () => {
        this.goToPage(this.state.fundamentalCoursesCurrentPage);
        let margin = parseFloat(window.getComputedStyle(this.boxBlock.current).getPropertyValue('margin-left'));
        if (this.slickBlock.current.offsetWidth > this.firstBoxBlock.current.offsetWidth) {
            this.showingCount = Math.floor((this.slickBlock.current.offsetWidth - this.firstBoxBlock.current.offsetWidth) / (this.boxBlock.current.offsetWidth + margin)) + 1;
        } else {
            this.showingCount = Math.floor((this.slickBlock.current.offsetWidth) / (this.boxBlock.current.offsetWidth + margin)) > 0 ? Math.floor((this.slickBlock.current.offsetWidth) / (this.boxBlock.current.offsetWidth + margin)) : 1;
        }
        if (Math.ceil(this.props.fundamentalCourses.items.length / this.showingCount) !== this.state.fundamentalCoursesTotalPage) {
            this.setState({
                fundamentalCoursesTotalPage: Math.ceil((this.props.fundamentalCourses.items.length-1) / this.showingCount)
            });
        }
    };

    goToPage = (pageNo) => {
        if (pageNo >= 0 && pageNo < this.state.fundamentalCoursesTotalPage) {
            let margin = parseFloat(window.getComputedStyle(this.boxBlock.current).getPropertyValue('margin-left'));
            if (this.slickBlock.current.offsetWidth > this.firstBoxBlock.current.offsetWidth) {
                this.showingCount = Math.floor((this.slickBlock.current.offsetWidth - this.firstBoxBlock.current.offsetWidth) / (this.boxBlock.current.offsetWidth + margin)) + 1;
                this.slickBlock.current.style.transform = pageNo === 0 ? 'translateX(0)' : `translateX(${-(((this.showingCount) * (this.boxBlock.current.offsetWidth + margin) * pageNo))}px)`;
            } else {
                this.showingCount = Math.floor((this.slickBlock.current.offsetWidth) / (this.boxBlock.current.offsetWidth + 20)) > 0 ? Math.floor((this.slickBlock.current.offsetWidth) / (this.boxBlock.current.offsetWidth + margin)) : 1;
                this.slickBlock.current.style.transform = pageNo === 0 ? 'translateX(0)' : `translateX(${-(this.firstBoxBlock.current.offsetWidth + ((this.showingCount - 1) * (this.boxBlock.current.offsetWidth + margin) * pageNo))}px)`;
            }
            if (this.state.fundamentalCoursesCurrentPage !== pageNo) {
                this.setState({
                    fundamentalCoursesCurrentPage: pageNo,
                    // currentFundamentalCoursesArr: [...this.props.fundamentalCourses.items.slice(pageNo * this.state.fundamentalCoursesPageSize, (pageNo + 1) * this.state.fundamentalCoursesPageSize)],
                })
            }
        }

    };

    componentWillUnmount() {
        if (typeof window !== "undefined") {
            window.removeEventListener('resize', this.resizingWindow)
        }
    }

    previous = () => {
        if (this.state.fundamentalCoursesCurrentPage > 0) {
            this.goToPage(this.state.fundamentalCoursesCurrentPage - 1)
        }
    };

    next = () => {
        this.goToPage(this.state.fundamentalCoursesCurrentPage + 1)
    };


    render() {
        let {fundamentalCourses} = this.props;
        return (
            <div className="slick-root">
                <div className="slick-container safe-area">
                    <div className="head-block">
                        <h2>TEMEL EĞİTİMLER</h2>
                        <p>En çok kullanılan eğitimleri görüntüleyin ve bir eğitime başlayın.</p>
                    </div>
                    <div className="slider-slick-block" ref={this.sliderBlock}>
                        <Swipeable
                            nodeName="section"
                            onSwipedRight={(e) => {
                                this.previous();
                            }}
                            onSwipedLeft={(e) => {
                                this.next();
                            }}>
                            <div className="slick-block" ref={this.slickBlock}>
                                {/*First two item*/}
                                {
                                    (fundamentalCourses.items && fundamentalCourses.items.length > 0) &&

                                    <div className="box-block" ref={this.firstBoxBlock}>
                                        {
                                            fundamentalCourses.items.slice(0, 2).map((course, item) =>
                                                <FundamentalCourseItemComponent {...course} key={item}/>
                                            )
                                        }
                                    </div>

                                }


                                {/*Other items*/}

                                {
                                    (fundamentalCourses.items) && fundamentalCourses.items.slice(2).map((course, item) =>
                                        <div className="box-block" ref={this.boxBlock} key={item}>
                                            <FundamentalCourseItemComponent {...course} key={item}/>
                                        </div>)

                                }
                            </div>
                        </Swipeable>
                    </div>

                    <div className="paginate-block">
                        <i className="icon-left-arr" onClick={() => {
                            this.goToPage(this.state.fundamentalCoursesCurrentPage - 1);
                        }}></i>
                        <ul className="slick-dots">

                            {
                                this.state.fundamentalCoursesTotalPage > 0 && Array.apply([], {length: Math.ceil(this.state.fundamentalCoursesTotalPage)}).map((count, i) =>
                                    <li className={this.state.fundamentalCoursesCurrentPage === i ? "active" : ""}
                                        key={i} onClick={() => {
                                        this.goToPage(i)
                                    }}>{i + 1}</li>
                                )
                            }
                        </ul>
                        <i className="icon-right-arr-2" onClick={() => {
                            this.goToPage(this.state.fundamentalCoursesCurrentPage + 1);
                        }}></i>
                    </div>
                </div>
            </div>
        );
    }
}

FundamentalCoursesComponent.propTypes = {};
FundamentalCoursesComponent.defaultProps = {};

export default FundamentalCoursesComponent;
