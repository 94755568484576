import * as actionTypes from "../constants/actionTypes"
import {getDepartmentAutoComplete, signUp} from "../services/webService";
import {forgotPassword, answerToSurveyService, getSurvey as getSurveyService} from "../services/webService";
import * as webService from "../services/webService";
import {receivePanelTag} from "./panelAction";


const login = (data) => ({
    type: actionTypes.LOGIN_DATA,
    payload: data
});

export const getLoginData = (data) => dispatch => {
    dispatch(login(data))
};

export const signUpAction = (params, cb) => dispatch => {
    signUp(params).then((res) => {
        if (res.data.success) {
            cb(true);
        } else {
            cb(false);
        }
    }).catch((err) => {
        cb(false);
    })
};

export const forgotPasswordAction = (params, cb) => dispatch => {
    forgotPassword(params).then((res) => {
        if (res.data.success) {
            cb(true);
        } else {
            cb(false, res.data.state)
        }
    }).catch((err) => {
        cb(false)
    })
};

export const getEducationCategories = (data) => ({
    type: actionTypes.GET_EDUCATION_CATEGORIES,
    payload: data
});

export const getOpenEducation = (data) => ({
    type: actionTypes.GET_OPEN_EDUCATION,
    payload: data
});

export const getUserNotifications = (data) => ({
    type: actionTypes.GET_USER_NOTIFICATIONS,
    payload: data
});

export const getFeatureCourses = (data) => ({
    type: actionTypes.GET_FEATURE_COURSES,
    payload: data
});

export const getFundamental = (data) => ({
    type: actionTypes.GET_FUNDAMENTAL_COURSES,
    payload: data
});

export const getMainTiles = (data) => ({
    type: actionTypes.GET_MAIN_TILES,
    payload: data
});

export const getNews = (data) => ({
    type: actionTypes.GET_NEWS,
    payload: data
});

export const getSurvey = (data) => ({
    type: actionTypes.GET_SURVEY,
    payload: data
});

export const answerToSurveyAction = (answer) => dispatch => {

    answerToSurveyService(answer).then((res) => {
        if (res.data.success) {

            getSurveyService().then((res) => {
                if (res.data.success) {
                    getSurvey(res.data.success)
                }
            })
                .catch((err) => {

                })
        }
    })
};

export const getOpenEducationAction = () => dispatch => {
    webService.getOpenEducation().then((res) => {
        if (res.data.success) {
            dispatch(getOpenEducation(res.data.success));
        }
    }).catch((err) => {

    })
};

export const receiveEducationCategories = () => dispatch => {
    webService.getEducationCategories().then((res)=>{
        if(res.data.success){
            dispatch(getEducationCategories(res.data.success))
        }
    })
};

export const searchAction = (search,cb) => dispatch => {
    webService.search(search).then((res)=>{
        if(res.data.success){
            cb(res.data.success);
        }else{
            cb([]);
        }
    }).catch(()=>{
        cb([]);
    })
};

export const answerSurveyAction = (params,cb) => dispatch => {
    webService.answerSurvey(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success);
        }else{
            cb(false);
        }
    }).catch(()=>{
        cb(false);
    })
};

export const getMainTilesAction = () => dispatch => {
    webService.getMainTiles().then((res)=>{
        if(res.data.success){
            dispatch(getMainTiles(res.data.success))
        }
    })
};

export const getUserNotificationsAction = () => dispatch => {
    webService.getUserNotifications().then((res)=>{
        if(res.data.success){
            dispatch(getUserNotifications(res.data.success))
        }
    })
};

export const getTagsAction = (params,cb) => dispatch => {
    webService.getTags(params).then((res)=>{
        if(res.data.success){
            cb(true,res.data.success)
        }else{
            cb(false,[])
        }
    }).catch((err)=>{
        cb(false,[])
    })
};

export const getTagListAction = (params,cb) => dispatch => {
    webService.getPanelTagList(params).then((res)=>{
        if(res.data.success){
            dispatch(receivePanelTag(res.data.success, params.activePage === 0));
            cb(true, res.data.state);
        }else{
            cb(false,true)
        }
    }).catch((err)=>{
        cb(false,true)
    })
};

export const getTagsBySearchAction = (search,cb) => dispatch => {
    webService.getTagsBySearch(search).then((res)=>{
        if(res.data.success){
            cb(res.data.success);
        }else{
            cb([]);
        }
    }).catch(()=>{
        cb([]);
    })
};

export const getTagDetailAction = (id,cb) => dispatch => {
    webService.getTagDetail(id).then((res)=>{
        if(res.data.success){
            cb(true,res.data.success);
        }else{
            cb([]);
        }
    }).catch(()=>{
        cb([]);
    })
};

export const createNewTagAction = (id,cb) => dispatch => {
    webService.createNewTag(id).then((res)=>{
        if(res.data.success){
            cb(true,res.data.success);
        }else{
            cb([]);
        }
    }).catch(()=>{
        cb([]);
    })
};