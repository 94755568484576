import 'core-js/es6/map';
import 'core-js/es6/set';
import './assets/styles/main.css';

import React from 'react';
import ReactDOM from 'react-dom';
import {
    createStore,
    applyMiddleware
} from "redux";
import reducer from "./reducers";
import thunk from 'redux-thunk'
import {createLogger} from 'redux-logger'
import {Provider} from 'react-redux'
import {BrowserRouter} from "react-router-dom";
import App from "./App";


window.onload = () => {
    const middleware = [thunk];

    if (process.env.NODE_ENV !== 'production') {
         middleware.push(createLogger());
    }

    let store = createStore(reducer, applyMiddleware(...middleware));

    ReactDOM.hydrate(
        <Provider store={store}>
            <BrowserRouter >
                <App />
            </BrowserRouter>
        </Provider>,
        document.getElementById('root')
    );
};
