import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import EducationDocumentItemComponent from "../../components/educationDetail/educationDocumentItemComponent";
import EducationDocumentEditContainer from "./educationDocumentEditContainer";
import * as ReactDOM from 'react-dom';
import ImageModalComponent from "../../components/modals/imageModalComponent";

class EducationDocumentContainer extends Component {
    focusElementId;
    imgPath;
    modalIsOpened = false;

    constructor(props) {
        super(props);
        this.focusElementId = props.focusedId;
    }

    componentDidMount() {
        if (this.props.focusedId) {
            let focusedDocument = this.props.detailDocument.find(documentItem => documentItem.id === this.focusElementId || (documentItem.subItems && this.checkSubItemId(documentItem.subItems)));
            let focusedDocumentIndex = this.props.detailDocument.findIndex(documentItem => documentItem.id === this.focusElementId || (documentItem.subItems && this.checkSubItemId(documentItem.subItems)));
            if (focusedDocument && focusedDocument.id === this.focusElementId) {
                this.goToTitle(focusedDocument.id);
            } else if (focusedDocument) {
                let focusedElement = focusedDocument.subItems.findIndex(documentItem => documentItem.id === this.focusElementId || (documentItem.subItems && this.checkSubItemId(documentItem.subItems, this.focusElementId)));
                let index = `${focusedDocumentIndex + 1}.${focusedElement + 1}`;
                this.goToSubTitle(focusedDocument.id, index)
            }
        }
        if(typeof window !== "undefined"){
            window.addEventListener('click',this.clickedWindow);
        }
    }

    clickedWindow = (e) => {
        if(e.target.tagName==="IMG" && this.modalIsOpened === false){
            this.toggleImgModal(true,e.target.getAttribute('src'));
        }
    };

    goToTitle = (titleId) => {
        setTimeout(() => {
            if (this["title-" + titleId]) {
                window.scrollTo({
                    top: ReactDOM.findDOMNode(this["title-" + titleId]).offsetTop,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }, 200);

    };
    goToSubTitle = (titleId, subTitleId) => {
        setTimeout(() => {
            window.scrollTo({
                top: this["title-" + titleId][subTitleId].offsetTop,
                left: 0,
                behavior: 'smooth'
            });
        }, 200);
    };
    checkSubItemId = (subItems, focusedId) => {
        if (subItems) {
            return subItems.find(subItem => subItem.id === this.focusElementId || (subItem.subItems && this.checkSubItemId(subItem.subItems, this.focusElementId)))
        } else {
            return false;
        }
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.focusedId !== this.props.focusedId) {
            this.focusElementId = nextProps.focusedId;
        }
        return true;
    }

    focusText = (target, item) => {
        this.focusElementId = item.id;
        this.props.changeFocused(item.id);
        let highlightItem = document.getElementsByClassName("highlights")[0];
        if (highlightItem) {
            highlightItem.classList.remove("highlights");
        }
        target.classList.add("highlights")
    };

    toggleImgModal = (value,path) => {
        this.imgPath = path;
        this.modalIsOpened = value;
        this.forceUpdate();
    };

    componentWillUnmount() {
        if(typeof window !== "undefined"){
            window.removeEventListener('click',this.clickedWindow);
        }
    }


    render() {
        let {detailDocument, versionData} = this.props;
        return (
            <div className="detail-content-block">
                {
                    this.props.isEditing === true ?
                        <EducationDocumentEditContainer
                            detailDocument={versionData ? versionData.document : (detailDocument ? detailDocument : [])}/>
                        :
                        <Fragment>
                            {
                                versionData ?
                                    versionData.document.map((item, key) =>
                                        <EducationDocumentItemComponent
                                            ref={(component) => {
                                                this["title-" + item.id] = component;
                                            }}
                                            isVersion={true}
                                            toggleImgModal={this.toggleImgModal}
                                            approved={detailDocument ? detailDocument[key]:null}
                                            focusElementId={this.focusElementId}
                                            focusText={this.focusText}
                                            key={key} {...item} index={key}/>
                                    )
                                    :
                                    detailDocument && detailDocument.map((item, key) =>
                                        <EducationDocumentItemComponent
                                            ref={(component) => {
                                                this["title-" + item.id] = component;
                                            }}
                                            focusElementId={this.focusElementId}
                                            toggleImgModal={this.toggleImgModal}
                                            focusText={this.focusText}
                                            key={key} {...item} index={key}/>
                                    )
                            }
                        </Fragment>
                }
                {
                    this.modalIsOpened === true && <ImageModalComponent imgPath={this.imgPath} toggleImgModal={this.toggleImgModal}/>
                }
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let urlArr = ownProps.urlLink.split("-");
    return ({
        detailDocument: state.educationDetailReducer[urlArr[urlArr.length - 1]] ? state.educationDetailReducer[urlArr[urlArr.length - 1]].document : null
    });
};

export default connect(mapStateToProps, null, null, {withRef: true})(EducationDocumentContainer);