import React,{Component} from 'react';
import {connect} from "react-redux";
import {canceledDocumentVersionAction, publishVersionOfDocumentAction} from "../../actions/educationDetailAction";
import {withRouter} from "react-router-dom";
import {EDUCATIONDETAIL_WITHOUTPARAM} from "../../constants/routePaths";

class VersionModalComponent extends Component{
    contentBlock = React.createRef();
    publishVersion = () => {
        if(this.props.type === "confirm"){
            this.props.dispatch(publishVersionOfDocumentAction({educationId:this.props.educationId,versionId:this.props.versionData.versionIfo.id},(res)=>{
                this.props.history.push(EDUCATIONDETAIL_WITHOUTPARAM+this.props.urlLink.split("?").join(""));
            }))
        }else {
            this.props.dispatch(canceledDocumentVersionAction({version_id:this.props.versionData.versionIfo.id},(res)=>{
                this.props.history.push(EDUCATIONDETAIL_WITHOUTPARAM+this.props.urlLink.split("?").join(""));
            }))
        }
    };
    render(){
        let {toggleModal,type} = this.props;
        return(
            <div className="lb-root" onClick={(e) => {
                if (this.contentBlock.current.contains(e.target) === false) {
                    toggleModal(false, null)
                }
            }}>
                <div className="lb-block">
                    <div ref={this.contentBlock} className="content-block">
                        <div className="content-item">
                            <i className="icon-close" onClick={() => {
                                toggleModal(false, null)
                            }}></i>
                            {
                                type === "confirm" ?
                                    <div className="text-item">
                                        <p className="head-text">Düzenlenmiş dökümanı onaylıyorsunuz!</p>
                                        <p>Dökümanın düzenlenmiş halini onayladığınızda orjinal versiyon yerine geçecektir. Bu işlem geri alınamaz.</p>
                                    </div>
                                    :
                                    <div className="text-item">
                                        <p className="head-text">Düzenlenmiş dökümandaki değişiklikler iptal olacak!</p>
                                        <p>Dökümanın düzenlenmiş halini reddedildiğinde versiyon silecenektir. Bu işlem geri alınamaz.</p>
                                    </div>
                            }

                            <div className="btn-block">
                                <span className="btn-orange" onClick={this.publishVersion}>{type === "confirm" ? "ONAYLA":"REDDET"}</span>
                                <span className="btn-2" onClick={() => {
                                    toggleModal(false, null)
                                }}>İPTAL</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default withRouter(connect()(VersionModalComponent));