import React,{Component} from 'react'
import * as ROUTES from "../../constants/routePaths";
import {connect} from "react-redux"
import {Field,reduxForm} from "redux-form";
import InputFieldComponent from "../../components/common/formFields/inputFieldComponent";
import * as WebService from "../../services/webService";
import {getLoginData} from "../../actions";
import {Link} from "react-router-dom";

const validate = (values)=>{
    let hasError = false;
    let errors = {};

    if(!values.email || values.email.trim() === "" || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)){
        hasError = true;
        errors.email = true;
    }

    if(!values.password || (values.password && values.password.trim().length===0)){
        hasError = true;
        errors.password = true;
    }

    return hasError && errors;
};

class LoginRightComponent extends Component{
    errMsg;
    submitLogin = (values)=>{
        this.errMsg = null;
        WebService.login(values).then((res)=>{
            if(res.data && res.data.success){
                this.props.dispatch(getLoginData(res.data.success));
                if(this.props.location.search && this.props.location.search.split("redirect=")[1]){
                    this.props.history.push(this.props.location.search.split("redirect=")[1]);
                }else{
                    this.props.history.push(ROUTES.HOME);
                }
            }else{
                this.errMsg = res.data.state;
                this.forceUpdate();
            }
        }).catch((err)=>{
            console.log(err)
        })
    };
    render(){
        const {handleSubmit} = this.props;
        return(
            <div className="right-block">
                <div className="text-block">
                    <h2>Giriş Yap</h2>
                    <p>Ünlü Tekstil Sustainability portalına giriş yapın.</p>
                </div>

                <form onSubmit={handleSubmit(this.submitLogin)}>
                    <div className="input-block">
                        <Field
                            component={InputFieldComponent}
                            name={"email"}
                            type="text"
                            label="KULLANICI"
                            placeholder="E-Posta Adresi giriniz.."
                        />
                        <Field
                            component={InputFieldComponent}
                            name={"password"}
                            label="ŞİFRE"
                            linkIsShowing={true}
                            type="password" placeholder="*****"
                        />
                    </div>
                    {
                        this.errMsg && <div className="error-message">
                            <p>{this.errMsg}</p>
                        </div>
                    }
                    <div className="input-bottom">
                        <input type="submit" value="GİRİŞ YAP" className="btn-green"/>
                    </div>
                </form>
                <div className="resp-bottom-btn-block">
                    <div className="resp-bottom-btn">

                        <p>Sustainability portalına kayıtlı değil misin?<br/><strong>Hemen kayıt ol!</strong> </p>
                        <Link to={ROUTES.SIGNUP} className="btn-1">KAYIT OL</Link>
                    </div>
                </div>


            </div>
        )
    }
};

let InitializeFromStateForm = reduxForm({
    form: 'loginForm',
    validate
})(LoginRightComponent);

InitializeFromStateForm = connect(
    state => ({
        isLogin:state.loginReducer?state.loginReducer.isLogin:false
    }),
    { load: LoginRightComponent }
)(InitializeFromStateForm);

export default InitializeFromStateForm;