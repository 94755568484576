import React,{Component} from 'react';
import {Field, reduxForm} from "redux-form";
import {checkQuizExistAction, educationAutoCompleteAction} from "../../actions/panelAction";
import autoCompleteFieldComponent from "../common/formFields/autoCompleteFieldComponent";

class NewQuizFormComponent extends Component{
    selectedEducation;
    errorMsg;
    educationSelected = (item) => {
        this.selectedEducation = item;
    };
    createQuiz = () => {
        this.props.dispatch(checkQuizExistAction({education_id: this.selectedEducation.id}, (res) => {
            if(res !== false){
                this.errorMsg = "Seçtiğiniz döküman için daha önce bir quiz yaratılmış. Farklı bir döküman seçin.";
                this.forceUpdate();
            }else{
                this.props.goToStep("2",this.selectedEducation.id);
                this.errorMsg = null;
                this.forceUpdate();
            }
        }));
    };
    render(){
        let {toggleModal} = this.props;
        return(
            <div className="content-item">
                <div className="head-block">
                    <h2>Quiz Oluştur</h2>
                    <p>Bir eğitime bağlı quiz oluşturun</p>
                    <div className="step-block">
                        <span className="active">1</span>
                        <span>2</span>
                    </div>
                </div>
                <i className="icon-close" onClick={() => {
                    toggleModal(false, "isNewQuiz")
                }}></i>
                <div className="form-block">
                    <div className="input-container">
                        <div className="input-block">
                            <Field
                                name="smart_name"
                                type="text"
                                placeholder="EĞİTİM DÖKÜMAN ADI"
                                label="EĞİTİM DÖKÜMAN ADI"
                                selectedFunc={this.educationSelected}
                                autoCompleteAction={educationAutoCompleteAction}
                                component={autoCompleteFieldComponent}
                            />
                        </div>
                    </div>
                </div>
                <div className="bottom-btn-item">
                    <p className="error">{this.errorMsg?this.errorMsg:""}</p>
                    <span className="btn-green" onClick={this.createQuiz}>DEVAM ET</span>
                </div>
            </div>
        )
    }
};

NewQuizFormComponent = reduxForm({
    form: 'createQuiz'
})(NewQuizFormComponent);

export default NewQuizFormComponent;