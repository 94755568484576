import React from 'react';
import Helper from '../../helper';
import QuizResultListItemComponent from "../../components/quizResult/quizResultListItemComponent";

const sortResult = (a,b) => {
    return b.correct_count - a.correct_count;
};

const SessionQuizResultContainer = ({quizSessionInfo,quizResultArray}) => {
    let bests = quizResultArray.sort(sortResult).filter(item=>item.correct_count/item.total_count >= 0.7 );
    let pasts = quizResultArray.sort(sortResult).filter(item=> item.correct_count/item.total_count < 0.7 && item.correct_count/item.total_count >= 0.5 );
    let fails = quizResultArray.sort(sortResult).filter(item=>item.correct_count/item.total_count < 0.5 );
    let totalPasts = quizResultArray.filter(item=> item.correct_count/item.total_count >= 0.5 );
    return(
        <div>
            {
                quizSessionInfo !== false &&
                <div className="status-root">
                    <div className="status-block">
                        <div className="status-item">
                            <div className="status-text-item">
                                <p>Quiz Süresi</p>
                                <p>{Helper.diffTwoDate(quizSessionInfo.end_date,quizSessionInfo.start_date)}</p>
                            </div>
                            <div className="status-bar">
                                <div className="progress-item" style={{width: "100%"}}></div>
                            </div>
                        </div>
                        <div className="status-item green-progress">
                            <div className="status-text-item">
                                <p>Doğru Cevaplar</p>
                                <p className="green">%{totalPasts === 0? 0:(totalPasts.length*100) / quizResultArray.length} Başarı</p>
                                <p>Yanlış Cevaplar</p>
                            </div>
                            <div className="status-bar">
                                <div className="progress-item" style={{width: (totalPasts.length*100) / quizResultArray.length+"%"}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                quizSessionInfo !== false &&
                <div className="right-content-root">
                    <div className="head-text-item">
                        <h2>Quiz Sonuçları</h2>
                        <p>Toplam Katılımcı : {quizResultArray.length}</p>
                    </div>

                    <div className="result-list-block">
                        {
                            bests.length > 0 &&
                            <div className="result-list-item best-list">
                                <span className="degree">EN İYİLER</span>
                                <div className="name-list">
                                    {
                                        bests.map((quizResultItem, key) =>
                                            <QuizResultListItemComponent key={key} {...quizResultItem}/>
                                        )
                                    }
                                </div>
                            </div>
                        }

                        {
                            pasts.length > 0 &&
                            <div className="result-list-item middle-list">
                                <span className="degree">GEÇENLER</span>
                                <div className="name-list">
                                    {
                                        pasts.map((quizResultItem, key) =>
                                            <QuizResultListItemComponent key={key} {...quizResultItem}/>
                                        )
                                    }
                                </div>
                            </div>
                        }

                        {
                            fails.length > 0 &&
                            <div className="result-list-item last-list">
                                <span className="degree">EĞİTİMİ TEKRAR ETMESİ GEREKENLER</span>
                                <div className="name-list">
                                    {
                                        fails.map((quizResultItem, key) =>
                                            <QuizResultListItemComponent key={key} {...quizResultItem}/>
                                        )
                                    }
                                </div>
                            </div>
                        }
                    </div>

                </div>
            }
        </div>
    );
}

export default SessionQuizResultContainer;