import React, {Component, Fragment} from 'react'
import EducationMenuComponent from "./educationMenuComponent";
import EducationContentHeaderComponent from "../../components/educationDetail/educationContentHeaderComponent";
import EducationDocumentContainer from "./educationDocumentContainer";
import * as ReactDOM from 'react-dom';


class EducationDetailContainer extends Component {
    detailContainer = React.createRef();
    documentContainer = React.createRef();
    goToTitle = (titleId) => {
        this.props.detailContent.current.classList.remove("open-menu");
        this.props.headerComponent.current.getWrappedInstance().menuBtn.current.classList.remove("active");
        this.props.changeFocused(titleId);
        if (this.documentContainer.current) {
            window.scrollTo({
                top: ReactDOM.findDOMNode(this.documentContainer.current.getWrappedInstance()["title-" + titleId]).offsetTop,
                left: 0,
                behavior: 'smooth'
            });
        }
    };
    goToSubTitle = (titleId, subTitleId, focusId) => {
        this.props.detailContent.current.classList.remove("open-menu");
        this.props.headerComponent.current.getWrappedInstance().menuBtn.current.classList.remove("active");
        this.props.changeFocused(focusId);
        window.scrollTo({
            top: this.documentContainer.current.getWrappedInstance()["title-" + titleId][subTitleId].offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    };

    render() {
        return (
            <div className="safe-area">
                <div className="detail-content-wrapper">
                    <EducationMenuComponent focusedId={this.props.focusedId} goToSubTitle={this.goToSubTitle}
                                            goToTitle={this.goToTitle} urlLink={this.props.urlLink}/>
                    <div className="detail-content-container" ref={this.detailContainer}>
                        <EducationContentHeaderComponent urlLink={this.props.urlLink} isVersion={this.props.isVersion}
                                                         history={this.props.history} isEditExist={this.props.isEditExist}
                                                         toggleIsEditing={this.toggleIsEditing} isToken={this.props.isToken}
                                                         startEducation={this.props.startEducation}
                                                         isEducationStarted={this.props.isStarted}/>

                        <EducationDocumentContainer urlLink={this.props.urlLink} isEditing={this.props.isEditing}
                                                    versionData={this.props.versionData}
                                                    changeFocused={this.props.changeFocused}
                                                    focusedId={this.props.focusedId}
                                                    isEducationStarted={this.props.isStarted}
                                                    ref={this.documentContainer} goToTitle={this.goToTitle}
                                                    goToSubTitle={this.goToSubTitle}/>

                    </div>
                </div>
            </div>
        )
    }
}
;

export default EducationDetailContainer;