import React,{Component,Fragment} from 'react';
import {getSmartDocumentsAutoCompleteAction} from "../../actions/panelAction";
import {connect} from "react-redux";

class EmptyAddItemBlockComponent extends Component{
    smartDocumentIsShowing = false;
    videoLinkIsShowing = false;
    addNewBtn = React.createRef();
    videoInput = React.createRef();
    toggleSmartDocuments = (value) => {
        this.smartDocumentIsShowing = value;
        this.forceUpdate();
    };
    toggleOpenBlock = () => {
        this.addNewBtn.current.classList.toggle("opened");
    };
    onChangeFunc = (e) => {
        if (e.target.value.length === 0) {
            this.autoCompleteArr = [];
            this.forceUpdate();
        } else {
            this.props.dispatch(getSmartDocumentsAutoCompleteAction({searchTerm: e.target.value}, (res) => {
                if (res !== false) {
                    this.autoCompleteArr = res;
                    this.forceUpdate();
                } else {
                    this.autoCompleteArr = [];
                    this.forceUpdate();
                }
            }));
        }
    };
    addSmartDocument = (item) => {
        this.toggleSmartDocuments(false);
        this.props.addSmartDocument(item,this.props.titleIndex)
    };
    onKeyUp = (e) => {
        if (e.keyCode === 27) {
            this.toggleVideo(false);
        } else {
            this.props.textOnKeyUp(e, () => {
                this.addNewBtn.current.classList.remove("opened");
                // this.addContentBlock.current.classList.remove("opened");
                this.toggleVideo(false);
            })
        }
    };
    toggleVideo = (value) => {
        this.videoLinkIsShowing = value;
        this.forceUpdate();
        if (this.videoLinkIsShowing === true) {
            setTimeout(() => {
                this.videoInput.current.focus();
            }, 150);
        }
    };
    imageOnLoad = (e) => {
        this.props.imageOnLoad(e, () => {
            this.addNewBtn.current.classList.remove("opened");
            // this.addContentBlock.current.classList.remove("opened");
        })
    };
    videoOnLoad = (e) => {
        this.props.uploadVideo(e, () => {
            this.addNewBtn.current.classList.remove("opened");
        })
    };
    render(){
        let {isLast,addTitle,titleIndex} = this.props;
        return(
            <div className="add-content-block middle-add-area" style={{display:"none"}} ref={this.addNewBtn}>
                {
                    this.videoLinkIsShowing === false &&
                        <Fragment>
                            <div className="add-btn-item" onClick={this.toggleOpenBlock}><i className="icon-add-plus"></i></div>
                            <div className="add-content-list">
                                <ul>
                                    <li><input onChange={this.imageOnLoad} accept="image/*" type="file" /><i className="icon-add-img"></i></li>
                                    <li onClick={() => {
                                        this.toggleVideo(true)
                                    }}><i className="icon-add-video"></i></li>
                                    <li onClick={() => {}}>
                                        <input onChange={this.videoOnLoad} accept="video/*" type="file"/>
                                        <i className="icon-uploadvideo"></i>
                                    </li>
                                    {
                                        isLast === true && <li onClick={()=>{this.toggleSmartDocuments(true)}}><i className="icon-add-smartdoc"></i></li>
                                    }
                                    <li><i className="icon-add-delimeter"></i></li>
                                    {
                                        isLast === true && <li onClick={()=>{addTitle(titleIndex)}}><i className="icon-h1"></i></li>
                                    }
                                </ul>
                            </div>
                        </Fragment>
                }
                {
                    this.smartDocumentIsShowing === true &&
                    <Fragment>
                        <div className="add-btn-item">
                            <i className="icon-add-plus" onClick={() => {
                                {/*this.openEdit()*/}
                                this.toggleSmartDocument(false);
                            }}></i>
                        </div>

                        <div className="add-input-block">
                            <input ref={this.smartDocumentInput}
                                   placeholder="Eklemek istediğiniz akıllı dökümanı arayın ve listeden seçin."
                                   type="text" onChange={this.onChangeFunc}/>
                            <div className="autocomplete-list">
                                <ul>
                                    {
                                        this.autoCompleteArr && this.autoCompleteArr.map((item, key) =>
                                            <li key={key}><p><span>{item.smart_document_name}</span></p>
                                                <span onClick={() => {
                                                    this.addSmartDocument(item)
                                                }} className="btn-add">Ekle</span></li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </Fragment>
                }
                {
                    this.videoLinkIsShowing === true &&
                    <Fragment>
                        <div className="add-btn-item">
                            <i className="icon-add-plus" onClick={() => {
                                {/*this.openEdit()*/}
                                this.videoLinkIsShowing = false;
                                this.forceUpdate();
                            }}></i>
                        </div>

                        <div className="add-input-block">
                            <input ref={this.videoInput}
                                   placeholder="Video Linkiniz Buraya Yazın veya Yapıştırın." type="text"
                                   onKeyUp={this.onKeyUp}/>
                        </div>
                    </Fragment>
                }
            </div>
        )
    }
};

export default connect(null,null,null,{withRef:true})(EmptyAddItemBlockComponent);