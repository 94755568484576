import * as ROUTES from '../constants/routePaths';
import {
    getHomeDataInit
} from './homeServices';
import {getAllEducationsDataInit} from "./allEdicationsServices";
import {edicationDetailServices} from "./edicationsDetailServices";
import {panelDocumentServices} from "./panelDocumentServices";
import {panelUserServices} from "./panelUserServices";
import {panelEducationServices} from "./panelEducationServices";
import {panelFeedbackServices} from "./panelFeedbackServices";
import {panelQuizServices} from "./panelQuizServices";
import {panelDraftServices} from "./panelDraftServices";
import {panelLocationServices} from "./panelLocationServices";
import {getUserNotifications} from "../actions";
import * as WebService from "../services/webService";
import {getEducationCategories} from "../actions/index";
import {panelSmartDocumentsServices} from "./panelSmartDocumentsServices";
import {PanelSurveyPageServices} from "./panelSurveyPageServices";
import {draftDetailServices} from "./draftDetailServices";
import {educationsTokenDetailServices} from "./educationsTokenDetailServices";
import {panelParticipantPageServices} from "./panelParticipantPageServices";
import {getQuizResultByEducationIdAction} from "../actions/reportAction";
import {getEducationRankingInit} from "./educationRankingServices";


export default class PageService {
    currentPage = ROUTES.HOME;

    constructor(url) {
        this.currentPage = url;
    }

    getData = (dispatch, urlParams) => {
        let params = {};

        /*WebService.getUserNotifications().then((res) => {
            if (res.data.success) {
                dispatch(getUserNotifications(res.data.success));
            }
        }).catch((err) => {
        });*/
        WebService.getEducationCategories().then((res) => {
            if (res.data.success) {
                dispatch(getEducationCategories(res.data.success))
            }
        })
            .catch((err) => {
            });
        return new Promise((resolve, reject) => {
            switch (this.currentPage) {

                case ROUTES.HOME:
                    getHomeDataInit(dispatch)
                        .then((res) => {
                            resolve(res);
                        });
                    break;
                case ROUTES.ALLEDUCATIONS:
                    getAllEducationsDataInit(dispatch)
                        .then((res) => {
                            resolve(res);
                        });
                    break;
                case ROUTES.PANEL_REPORTS_EDUCATION_RANKING_WITHOUT_PARAM + urlParams.params.educationId :
                    // console.log(urlParams);
                    // console.log(urlParams.sort);
                   // console.log(urlParams.params.sort.sortName);
                    params = {
                        id:urlParams.params.educationId,
                        sortName:urlParams.sort?urlParams.sort.sortName:"correct_count",
                        sortType:urlParams.sort?urlParams.sort.sortType:"desc"
                    };
                    // getQuizResultByEducationIdAction(params).then((res) => {
                    //     resolve(res);
                    // });
                    getEducationRankingInit(dispatch, params)
                        .then((res) => {
                            resolve(res);
                        });
                    break;
                case ROUTES.EDUCATIONDETAIL_WITHOUTPARAM + urlParams.params.educationLink:
                case ROUTES.EDITEDUCATIONDETAIL_WITHOUTPARAM + urlParams.params.educationLink:
                case ROUTES.EDITEDUCATIONVERSIONDETAIL_WITHOUT_PARAM + urlParams.params.educationLink:
                    let linkArr = urlParams.params.educationLink.split("-");
                    params = {
                        link: linkArr[linkArr.length - 1]
                    };
                    let secondParams = {
                        activePage: 0,
                        order: "created_date",
                        order_type: "desc"
                    };
                    edicationDetailServices(dispatch, params, secondParams)
                        .then((res) => {
                            resolve(res);
                        });
                    break;
                case ROUTES.EDUCATION_TOKEN_WITHOUT_PARAM + urlParams.params.educationLink + "/" + urlParams.params.token:
                    let tokenLinkArr = urlParams.params.educationLink.split("-");
                    params = {
                        link: tokenLinkArr[tokenLinkArr.length - 1],
                        token:urlParams.params.token
                    };
                    let secondParam = {
                        activePage: 0,
                        order: "created_date",
                        order_type: "desc"
                    };
                    educationsTokenDetailServices(dispatch, params, secondParam)
                        .then((res) => {
                            resolve(res);
                        });
                    break;
                case (urlParams.params.subPage ? ROUTES.PANEL_DOCUMENTS_WITOUTPARAM + "/" + urlParams.params.subPage : ROUTES.PANEL_DOCUMENTS_WITOUTPARAM):
                    let urlArr = urlParams.params.subPage ? urlParams.params.subPage.split("-") : ["0"];
                    params = {
                        category_id: urlArr[urlArr.length - 1],
                        orderName: "name",
                        orderType: "asc",
                        index: 0,
                        subPage: urlParams.params.subPage
                    };
                    panelDocumentServices(dispatch, params).then((res) => {
                        resolve(res);
                    });
                    break;
                case (urlParams.params.subPage ? ROUTES.PANEL_USERS_WITOUTPARAM + "/" + urlParams.params.subPage : ROUTES.PANEL_USERS_WITOUTPARAM ):
                    params = {
                        activePage: 0,
                        order: "name_surname",
                        order_type: "asc"
                    };
                    panelUserServices(dispatch, params).then((res) => {
                        resolve(res);
                    });
                    break;
                case (urlParams.params.subPage ? ROUTES.PANEL_EDUCATIONS_WITOUTPARAM + "/" + urlParams.params.subPage : ROUTES.PANEL_EDUCATIONS_WITOUTPARAM):
                    params = {
                        activePage: 0,
                        order: "education.name",
                        order_type: "asc",
                        is_started: urlParams.params.subPage === "tamamlanmis"
                    };
                    panelEducationServices(dispatch, params).then((res) => {
                        resolve(res);
                    });
                    break;
                case (urlParams.params.subPage ? ROUTES.PANEL_DRAFTS_WITOUTPARAM + "/" + urlParams.params.subPage : ROUTES.PANEL_DRAFTS_WITOUTPARAM):
                    params = {
                        activePage: 0,
                        order: "name",
                        order_type: "asc"
                    };

                    panelDraftServices(dispatch, params).then((res) => {
                        resolve(res);
                    });
                    break;
                case (urlParams.params.subPage ? ROUTES.PANEL_FEEDBACK_WITOUTPARAM + "/" + urlParams.params.subPage : ROUTES.PANEL_FEEDBACK_WITOUTPARAM):
                    params = {
                        activePage: 0,
                        order: "subject",
                        order_type: "asc"
                    };
                    panelFeedbackServices(dispatch, params).then((res) => {
                        resolve(res);
                    });
                    break;
                case (urlParams.params.subPage ? ROUTES.PANEL_QUIZ_WITOUTPARAM + "/" + urlParams.params.subPage : ROUTES.PANEL_QUIZ_WITOUTPARAM ):
                    params = {
                        activePage: 0,
                        order: "education",
                        order_type: "asc"
                    };
                    panelQuizServices(dispatch, params).then((res) => {
                        resolve(res);
                    });
                    break;
                case ROUTES.DRAFT_DETAIL_WITHOUT_PARAM + urlParams.params.educationLink:
                    let draftLinkArr = urlParams.params.educationLink.split("-");
                    params = {
                        link: draftLinkArr[draftLinkArr.length - 1]
                    };
                    let draftSecondParams = {
                        activePage: 0,
                        order: "created_date",
                        order_type: "desc"
                    };
                    draftDetailServices(dispatch, params, draftSecondParams).then((res) => {
                        resolve(res);
                    });
                    break;
                case (urlParams.params.subPage ? ROUTES.PANEL_LOCATIONS_WITOUTPARAM + "/" + urlParams.params.subPage : ROUTES.PANEL_LOCATIONS_WITOUTPARAM):
                    params = {
                        activePage: 0,
                        order: "name",
                        order_type: "asc"
                    };
                    panelLocationServices(dispatch, params).then((res) => {
                        resolve(res);
                    });
                    break;
                case (urlParams.params.subPage ? ROUTES.PANEL_SMARTDOCUMENTS_WITOUTPARAM + "/" + urlParams.params.subPage : ROUTES.PANEL_SMARTDOCUMENTS_WITOUTPARAM):
                    params = {
                        activePage: 0,
                        order: "smart_document_name",
                        order_type: "asc"
                    };
                    panelSmartDocumentsServices(dispatch, params).then((res) => {
                        resolve(res);
                    });
                    break;
                case (urlParams.params.subPage ? ROUTES.PANEL_SURVEYS_WITOUTPARAM + "/" + urlParams.params.subPage : ROUTES.PANEL_SURVEYS_WITOUTPARAM):
                    params = {
                        activePage: 0,
                        order: "question",
                        order_type: "asc"
                    };
                    PanelSurveyPageServices(dispatch, params).then((res) => {
                        resolve(res);
                    });
                    break;
                case (urlParams.params.subPage ? ROUTES.PANEL_PARTICIANTS + "/" + urlParams.params.subPage : ROUTES.PANEL_PARTICIANTS):
                    params = {
                        activePage: 0,
                        order: "name_surname",
                        order_type: "asc",
                        searchTerm:""
                    };
                    panelParticipantPageServices(dispatch, params).then((res) => {
                        resolve(res);
                    });
                    break;


                // case (ROUTES.PANEL_TAG_WITHOUTPARAM):
                //     panelEducationServices(dispatch, {}).then((res) => {
                //         resolve(res);
                //     });
                //     break;


                default:
                    resolve({});
            }
        });
    }
}
