import React, {Component} from 'react';
import {
    getSessionDocumentsAction, saveSessionDocumentsAction,
    uploadSessionDocumentAction
} from "../actions/educationDetailAction";
import Helper from '../helper';
import {connect} from "react-redux";
import moment from 'moment';
import LoaderModalComponent from "../components/common/loaderModalComponent";

class AddDocumentToSessionPage extends Component {
    documentArr = [];
    educationName;
    loaderIsShowing = false;
    extensionErr;
    constructor(props) {
        super(props);
        this.getDocuments()
    }

    uploadDocument = (e) => {
        this.loaderIsShowing = true;
        this.extensionErr = false;
        this.forceUpdate();
        if (e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "application/pdf") {

            this.props.dispatch(uploadSessionDocumentAction(e.target.files[0], (res) => {
                this.loaderIsShowing = false;
                if (res) {
                    let file = {
                        file_name: Helper.turkishToLower(this.educationName).split(" ").join("_") + "_" + moment().format('DD.MM.YYYY_HH.mm.ss') + ".png",
                        path: res,
                        session_id: this.props.match.params.sessionId
                    };
                    this.documentArr.push(file);
                    this.forceUpdate()
                } else {
                    this.forceUpdate()
                }
            }));
        }else{
            this.extensionErr = true;
            this.loaderIsShowing = false;
            this.forceUpdate();
        }
    };
    getDocuments = () => {
        this.props.dispatch(getSessionDocumentsAction({session_id: this.props.match.params.sessionId, token:this.props.match.params.token}, (res, data, educationName) => {
            if (res === true) {
                this.educationName = educationName;
                this.documentArr = data;
                this.forceUpdate();
            }
        }))
    };
    deleteFromIndex = (key) => {
        this.documentArr.splice(key, 1);
        this.forceUpdate();
    };
    saveDocuments = () => {
        this.props.dispatch(saveSessionDocumentsAction({documents:this.documentArr,token:this.props.match.params.token}, (res) => {
            if (res === true) {
                this.props.openSessionDocs(false, null);
            }
        }))
    };

    render() {
        return (
            <div className="mainRoot">
                <div className="mobile-content-root">
                    <div className="content-item safe-area">
                        <div className="download-doc-root">
                            <div className="down-doc-btn">
                                <p>Belgeler</p>
                                <div className="new-button">
                                    <i className="icon-plus-circle"></i>
                                    <input type="file" onChange={this.uploadDocument}/>
                                    <span>BELGE EKLE</span>
                                </div>
                            </div>
                            <div className="doc-list">
                                <ul>
                                    {
                                        this.documentArr.map((document, key) =>
                                            <li key={key}>
                                                <i className="icon-doc"></i>
                                                {document.file_name}
                                                <i className="icon-close-2" onClick={() => {
                                                    this.deleteFromIndex(key)
                                                }}></i>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                            {
                                this.documentArr.length> 0 &&
                                <div className="message-item">
                                    <p>Belgeleri güncellemek için <span>"BELGELERİ YÜKLE"</span>  butonuna basınız.</p>
                                </div>
                            }
                            {
                                this.extensionErr === true &&
                                <div className="bottom-btn-item">
                                    <p className="error">Lütfen resim dosyası veya PDF dosyası ekleyiniz.</p>
                                </div>
                            }
                            <div className="btn-item">
                                <span className="btn-green" onClick={this.saveDocuments}>BELGELERİ YÜKLE</span>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.loaderIsShowing === true && <LoaderModalComponent/>
                }
            </div>
        )
    }
}
;

export default connect()(AddDocumentToSessionPage);