import React, {Component, Fragment} from 'react'
import EducationEditMenuComponent from "./educationEditMenuComponent";
import EducationEditContentHeaderComponent from "../../components/editEducationDetail/educationEditContentHeaderComponent";
import EducationEditDocumentContainer from "./educationEditDocumentContainer";

class EducationEditDetailContainer extends Component {
    detailContainer = React.createRef();
    detailDocumentContainer = React.createRef();

    render() {
        let {detailDocument} = this.props;
        return (
            <div className="safe-area">
                <div className="detail-content-wrapper">
                    <EducationEditMenuComponent isSmartDocuments={this.props.isSmartDocuments} detailContent={this.props.detailContent} isVersion={this.props.isVersion}
                                                urlLink={this.props.urlLink} detailDocumentVersion={detailDocument}/>
                    <div className="detail-content-container" ref={this.detailContainer}>
                        <EducationEditContentHeaderComponent urlLink={this.props.urlLink} detailDocumentVersion={detailDocument}
                                                             toggleIsEditing={this.toggleIsEditing}
                                                             isSmartDocuments={this.props.isSmartDocuments}/>
                        <EducationEditDocumentContainer detailContainer={this.detailContainer} detailDocumentVersion={detailDocument}
                                                        ref={this.detailDocumentContainer} urlLink={this.props.urlLink}
                                                        isEditing={this.props.isEditing} isSmartDocumentEdit={this.props.isSmartDocumentEdit}
                                                        isSmartDocuments={this.props.isSmartDocuments}/>
                    </div>
                </div>
            </div>
        )
    }
}
;

export default EducationEditDetailContainer;