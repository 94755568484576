import React, {Component} from 'react';
import * as ROUTES from "../../../constants/routePaths";
import {Link} from "react-router-dom";
import {Field} from "redux-form";

const inputField = ({input, meta: {error, touched}, placeholder, type}) => (
    <input {...input} name="option.1" className={`${error && touched === true ? "error" : ""}`} type={type}
           placeholder={placeholder}/>
);

class InputOptionsFieldComponent extends Component {
    render() {
        const {label, example} = this.props;
        return (
            <div className="input-item">
                <label>{label}
                    {
                        example && <span>{example}</span>
                    }
                    <div className="survey-option-block">
                        <Field
                            name="options.0.option_name"
                            type="text"
                            component={inputField}
                        />
                        <Field
                            name="options.1.option_name"
                            type="text"
                            component={inputField}
                        />
                        <Field
                            name="options.2.option_name"
                            type="text"
                            component={inputField}
                        />
                    </div>

                </label>
            </div>
        );
    }
}

export default InputOptionsFieldComponent;
