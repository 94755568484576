import React, {Component} from 'react';
import {changeEducationStatus, passiveEducationAction} from "../../actions/educationDetailAction";
import {connect} from "react-redux";

class EducationRemoveModalComponent extends Component {
    contentBlock = React.createRef();
    removeEducation = () => {
        this.props.dispatch(passiveEducationAction({education_id:this.props.education_id},(res)=>{
            if(res === true){
                this.props.dispatch(changeEducationStatus(this.props.education_id,this.props.detailInfo.is_active==="1"?"0":"1"));
                // this.props.detailInfo.is_active = this.props.detailInfo.is_active==="1"?"0":"1";
                this.props.changeKeyModal("isRemovedModal", false);
            }
        }))
    };
    render() {
        let {changeKeyModal} = this.props;
        return (
            <div className="lb-root" onClick={(e) => {
                if (this.contentBlock.current.contains(e.target) === false) {
                    changeKeyModal("isRemovedModal", false)
                }
            }}>
                <div className="lb-block">
                    <div ref={this.contentBlock} className="content-block">
                        <div className="content-item">
                            <i className="icon-close" onClick={() => {
                                changeKeyModal("isRemovedModal", false)
                            }}></i>
                            <div className="text-item">
                                <p className="head-text">Dökümanı yayından kaldırmak üzeresiniz!</p>
                                <p>Bu dökümanı yayından kaldırıyorsunuz. Daha sonra yönetici panelinden tekrar açabilirsiniz.</p>
                            </div>
                            <div className="btn-block">
                                {
                                    this.props.detailInfo && <span className="btn-orange" onClick={this.removeEducation}>{this.props.detailInfo.is_active === "0" ? "YAYINA AL":"YAYINDAN KALDIR"}</span>
                                }
                                <span className="btn-2" onClick={() => {
                                    changeKeyModal("isRemovedModal", false)
                                }}>İPTAL</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
;

export default connect()(EducationRemoveModalComponent);