import moment from 'moment';
import {WEB_SERVICE_BASE_URL} from "../constants/urls";

export default class Helper {
    static getDocumentSubPage = () => {
        return [
            {
                "title": "Tümü",
                "link": "/tumu-0"
            },
            {
                "title": "Çevre",
                "link": "/cevre-1"
            },
            {
                "title": "Sosyal Uygunluk",
                "link": "/sosyal-uygunluk-2"
            },
            {
                "title": "Production",
                "link": "/production-3"
            },
            {
                "title": "Self Reliance",
                "link": "/self-reliance-4"
            },
            {
                "title": "Supply Chain",
                "link": "/supply-chain-5"
            },
            {
                "title": "Değişiklik Talepleri",
                "link": "/version"
            }
        ]
    };
    static getEducationSubPage = () => {
        return [
            {
                "title": "Planlanmış",
                "link": "/acik-egitimler"
            },
            {
                "title": "Tamamlanmış",
                "link": "/tamamlanmis"
            }
        ]
    };
    static getReportSubPage = () => {
        return [
            {
                "title": "Eğitimler",
                "link": "/egitimler"
            },
            {
                "title": "Departmana Göre Eğitimler",
                "link": "/departman-egitim"
            },
            {
                "title": "Eğitmenler Göre Eğitimler",
                "link": "/egitimen-egitim"
            },
            {
                "title": "Departmanlara Göre Test Başarıları",
                "link": "/departman-test"
            },
            {
                "title": "Eğitimlere Göre Test Başarıları",
                "link": "/egitim-test"
            },
            {
                "title": "Katılımcıların Başarı Sıralaması",
                "link": "/katilimci-basari-siralamasi"
            },
            {
                "title": "Haftalık Eğitim Raporu",
                "link": "/haftalik-egitim-raporu"
            }
        ]
    };
    static dataStandardized = (unStandardData, firstParam, secParam) => {
        return [...unStandardData.map((x) => {
            return {text: x[firstParam], value: x[secParam].toString().trim(),}
        })];
    };
    static getVideoId = (url) => {
        let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        let match = url.match(regExp);

        if (match && match[2].length == 11) {
            return match[2];
        } else {
            return 'error';
        }
    };
    static focusLast = (element) => {
        let range, selection;
        if (document.createRange)
        {
            range = document.createRange();//Create a range (a range is a like the selection but invisible)
            range.selectNodeContents(element.editingArea.getElementsByClassName('ql-editor')[0]);//Select the entire contents of the element with the range
            range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
            selection = window.getSelection();//get the selection object (allows you to change selection)
            selection.removeAllRanges();//remove any selections already made
            selection.addRange(range);//make the range you have just created the visible selection
        }
        else if (document.selection)
        {
            range = document.body.createTextRange();//Create a range (a range is a like the selection but invisible)
            range.moveToElementText(element.editingArea.getElementsByClassName('ql-editor')[0]);//Select the entire contents of the element with the range
            range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
            range.select();//Select the range (make it the visible selection
        }
    };
    static getOffset = (el, topElement) => {
        let rect = el.getBoundingClientRect(),
            scrollLeft = topElement.offsetWidth || document.documentElement.scrollLeft,
            scrollTop = document.documentElement.scrollTop;
        return {top: rect.top + scrollTop - 182, left: rect.left + scrollLeft}
    };
    static getExtension = (filename) => {
        let parts = filename.split('.');
        return parts[parts.length - 1];
    };
    static isImage = (filename) => {
        let ext = Helper.getExtension(filename);
        switch (ext.toLowerCase()) {
            case 'jpg':
            case 'gif':
            case 'bmp':
            case 'png':
                return true;
        }
        return false;
    };
    static turkishToLower = (text) => {
        let letters = {
            "İ": "i",
            "ı": "i",
            "I": "i",
            "ş": "s",
            "Ş": "s",
            "ğ": "g",
            "Ğ": "g",
            "ü": "u",
            "Ü": "u",
            "ö": "o",
            "Ö": "o",
            "Ç": "c",
            "ç": "c"
        };
        text = text.replace(/(([İIŞĞÜÇÖışğüçö]))+/g, function (letter) {
            let text = "";
            letter.split("").map(item => {
                text += letters[item]
            });
            return text;
        });
        return text.toLowerCase();
    };
    static generateString = (length) => {
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < length; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    };
    static checkArrayLastElement = (arr, obj) => {
        if (arr[arr.length - 1] === obj && !arr[arr.length - 1].subItems) {
            return true;
        } else {
            if (arr[arr.length - 1] && arr[arr.length - 1].subItems) {
                return Helper.checkArrayLastElement(arr[arr.length - 1].subItems, obj);
            } else {
                return false;
            }
        }
    };
    static diffTwoDate = (firstDate, secondDate) => {
        let seconds = moment(secondDate).diff(moment(firstDate), "seconds") % 60;
        let minutes = moment(secondDate).diff(moment(firstDate), "minutes") % 24;
        let hours = moment(secondDate).diff(moment(firstDate), "hours");
        return `${hours.toString().length < 2 ? "0" + hours : hours}.${minutes.toString().length < 2 ? "0" + minutes : minutes}.${seconds.toString().length < 2 ? "0" + seconds : seconds}`
    };
    static getOfficialReport = (participants, teacher, desc, education_name, date) => {
        return `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport"
          content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
    <title>Eğitim Dökümanı</title>
    <style>
        /*NOTES*/

        /*Border and Padding*/

        /*td tag fit for padding but only padding-left,padding-right,padding-top,padding-bottom*/

        /*td also fit for border but only border-left,border-right,border-top,border-bottom*/

        body {
            background-color: #ffffff;
            font-family: Arial, Helvetica, sans-serif;
            -webkit-font-smoothing: antialiased;
            font-size: 14px;
            line-height: 1.4;
            margin: 0;
            padding: 0;
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
        }

        table {
            border-collapse: collapse;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
            padding: 0;
        }

        table td {
            border-collapse: collapse;
            margin: 0;
            padding: 0;
            vertical-align: top;
        }

        p {
            font-size: 14px;
            margin: 0;
            padding: 0;
            font-family: Arial, Helvetica, sans-serif;
        }

        ul {
            -webkit-margin-before: 0em;
            -webkit-margin-after: 0em;
        }

        img {
            display: block;
            margin: 0;
            padding: 0;
        }

        a {
            text-decoration: none !important;
        }
    </style>
</head>

<body bgcolor="#FFFFFF" leftmargin="0" topmargin="0" marginwidth="0" marginheight="0">
<table id="Table_01" width="600" border="0" cellpadding="0" cellspacing="0" align="center"
       style="background-color: #ffffff;">
    <tr>
        <td style="border-left: 1px solid #cfcfcf; border-right: 1px solid #cfcfcf; border-top: 1px solid #cfcfcf; border-bottom: 1px solid #cfcfcf;">
            <table border="0" cellpadding="0" cellspacing="0" width="100%">

                <tr>
                    <td style="">
                        <table border="0" cellpadding="0" cellspacing="0" width="100%">

                            <tr style="height: 20px;background-color: #FAF8F7;">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>

                                <td width="20px"style="background-color: #FAF8F7; "></td>
                                <td align="left" style="background-color: #FAF8F7; ">
                                    <img src="${WEB_SERVICE_BASE_URL}/assets/img/unluTekstilLogo.png" alt="">
                                </td>

                            </tr>
                            <tr style="height: 20px;background-color: #FAF8F7;">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>

                        </table>

                        <table border="0" cellpadding="0" cellspacing="0" width="100%">
                            <tr>
                                <td width="20px"></td>
                                <td align="center" style="">
                                    <br/><br/>
                                    <p style="font-size: 17px;font-weight:bold; color: #23323B ">${education_name}</p>

                                    <br/>
                                    <p style="color: #23323B ">
                                        Eğitimci: ${teacher}
                                    <p/>
                                    <br/>
                                    <p style="color: #23323B ">
                                       ${date} TARİHİNDE ${teacher} TARAFINDAN  VERİLEN  EĞİTİM BAŞARIYLA TAMAMLANMIŞTIR.
                                    <p/>

                                </td>
                                <td width="20px"></td>
                            </tr>
                        </table>
                    </td>
                </tr>


                <tr>
                    <td style="">
                        <table border="0" cellpadding="0" cellspacing="0" width="100%">

                            <tr style="height: 30px; ">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                           
                            <tr style="height: 50px; border-bottom: 1px solid #E8E8E8; ">
                                <td width="20"></td>
                                <td style="width:200px;font-weight: bold; color: #333333; font-size:16px; vertical-align: middle">
                                    Ad Soyad
                                </td>
                                <td style="width:150px;font-weight: bold; color: #333333; font-size: 14px;  vertical-align: middle">
                                  T.C No
                                </td>
                                <td style="font-weight: bold; width:180px; color: #333333; font-size: 14px;  vertical-align: middle">
                                    Skor
                                </td>
                                <td style="font-weight: bold; text-align: right; color: #333333; font-size: 14px;  vertical-align: middle">
                                    İmza
                                </td>
                                <td width="50"></td>
                            </tr>
                            ${Helper.getParticipants(participants)}
                            
                            <tr style="height: 30px; ">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>

                        </table>
                    </td>
                </tr>

                <tr style="height: 20px; ">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>




                <tr>
                    <td>
                        <table border="0" cellpadding="0" cellspacing="0" width="100%">
                            <tr style="height: 20px; ">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr style="height: 75px;">

                                <td width="50"></td>
                                <td style="vertical-align: middle;">
                                    <img src="${WEB_SERVICE_BASE_URL}/assets/img/unluTekstilLogo.png" alt="lookinn logo">

                                </td>
                                <td width="180"></td>
                                <td width="260" style=" font-size: 11px; vertical-align: middle; color: #BBBBBB ">
                                    Ünlü Tekstil Sanayi ve Ticaret A.Ş. </br> Emek Mahallesi Sıvat Yolu Caddesi No: 17 Sancaktepe / İstanbul - TURKEY
                                    </br>Tel: <a style="color: #BBBBBB" href="tel:02166456900">+90 216 645 69 00</a>  /<a style="color: #BBBBBB" href="tel:02166456950">+90 216 645 69 50</a>
                                    </br>Fax:+90 216 415 54 67
                                </td>
                                <td width="50"></td>

                            </tr>
                            <tr style="height: 20px; ">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>

                        </table>
                    </td>
                </tr>




            </table>
        </td>
    </tr>
</table>


</body>

</html>`
    };
    static getParticipants = (participants) => {
        let stringText = "";
        participants.map((item) => {
            stringText += `<tr style="height: 50px; border-bottom: 1px solid #E8E8E8; ">
                                <td width="50"></td>
                                <td style="width:200px;  color: #333333; font-size:16px; vertical-align: middle">
                                    ${item.name_surname}
                                </td>
                                <td style="width:150px;color: #333333; font-size: 14px;  vertical-align: middle">
                                    ${item.tc_no}
                                </td>
                                <td style="color: #333333; font-size: 14px;  vertical-align: middle">
                                    ${item.score?item.score:"-"}
                                </td>
                                <td style="text-align: right; color: #333333; font-size: 14px;  vertical-align: middle">

                                </td>
                                <td width="50"></td>
                            </tr>`})
        return stringText;
    };
    static titleCase = (str) => {
        if(str){
            let splitStr = str.toString().toLocaleLowerCase('tr').split(' ');
            for (let i = 0; i < splitStr.length; i++) {
                if (i === 0 && splitStr[i] === "sup."){
                    splitStr[i] = splitStr[i].toUpperCase();
                }else{
                    splitStr[i] = splitStr[i].charAt(0).toLocaleUpperCase('tr') + splitStr[i].substring(1);
                }
            }
            return splitStr.join(' ');
        }else{
            return str;
        }

    }


    static convertTimestampToMonthYearFormat(timestamp){

        let convertedDate=new Date(timestamp);
        return convertedDate.getDate() + "." + (parseInt(convertedDate.getMonth()) + 1) + "." + convertedDate.getFullYear()

    }
};