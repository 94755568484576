import React, {Component} from 'react';
import CreatePasswordRightContainer from '../containers/createPassword/createPasswordRightContainer';
import {Link} from "react-router-dom";
import {LOGIN} from "../constants/routePaths";

class CreatePassPage extends Component {
    isPasswordCreated = false;
    passwordCreated = () => {
     this.isPasswordCreated = true;
     this.forceUpdate();
    };
    render() {

        return (
            <div className="login-root">
                <div className="login-wrapper safe-area">

                    <div className="login-con">
                        <div className="login-block">
                            <div className="left-block">
                                <div className="logo-item">
                                    <img src="/assets/img/unluTekstilLogo2.png" alt="unluTekstilLogo"/>
                                </div>
                                {
                                    this.isPasswordCreated === true &&
                                    <div>
                                        <p>Zaten bir hesabınız var mı?<br/><strong>Hemen giriş yap!</strong></p>
                                        <Link to={LOGIN} className="btn-1">GİRİŞ YAP</Link>
                                    </div>
                                }

                            </div>
                            <CreatePasswordRightContainer passwordCreated={this.passwordCreated} match={this.props.match}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default CreatePassPage;