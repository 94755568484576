import React, {Component} from 'react';
import EducationDetailContainer from "../containers/educationDetail/educationDetailContainer";
import PageService from "../pageServices/pageService";
import * as ROUTES from "../constants/routePaths";
import {matchPath} from "react-router-dom";
import {connect} from "react-redux";

class EducationTokenPage extends Component {
    detailContentComponent = React.createRef();

    constructor(props) {
        super(props);
        this.getPageData(props);
    }

    getPageData = (props) => {
        this.webService = new PageService(props.location.pathname);
        let urlParams = matchPath(props.location.pathname, {
            path: ROUTES.EDUCATION_TOKEN,
            exact: false,
            strict: false
        });
        this.webService.getData(props.dispatch, urlParams);
        this.forceUpdate();
    };
    changeFocused = () => {

    };
    startEducation = () => {

    };

    render() {
        return (
            <div className="mainRoot">
                <div className="detail-content-root" ref={this.detailContent}>
                    <EducationDetailContainer urlLink={this.props.match.params.educationLink}
                                              isEditExist={false} isToken={true}
                                              history={this.props.history}
                                              focusedId={this.focusedId}
                                              ref={this.detailContentComponent} changeFocused={this.changeFocused}
                                              startEducation={this.startEducation} sessionId={null}
                                              detailContent={this.detailContent} isEditing={false}
                                              isStarted={false}/>
                </div>
            </div>
        )
    }
}
;

export default connect()(EducationTokenPage);