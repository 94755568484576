import React from 'react';
import {connect} from "react-redux";

const ModalEducationInfoComponent = ({detailInfo}) => (
    <div className="head-block">
        <h2>{detailInfo.name}</h2>
        <div className="info-item">
            <span className="label-tag green">#{detailInfo.tag.toLocaleUpperCase('tr')}</span>
            <div className="time-block">
                <span><i className="icon-clock"></i>{detailInfo.duration}</span>
            </div>
        </div>
    </div>
);

const mapStateToProps = (state, ownProps) => {
    let urlArr = ownProps.urlLink.split("-");
    return({
    detailInfo: state.educationDetailReducer[urlArr[urlArr.length-1]] ? state.educationDetailReducer[urlArr[urlArr.length-1]].info : null
});};

export default connect(mapStateToProps)(ModalEducationInfoComponent);