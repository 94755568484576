import React from 'react';
import {Link} from "react-router-dom";
import {EDITSMARTEDUCATIONDETAIL_WITHOUTPARAM} from "../../constants/routePaths";

const PanelSurveyItemComponent = ({name,created_date,id,document_type,state,toggleModal,options,question,image,answered_count}) => (
    <div className="table-item" onClick={()=>{
        toggleModal({name,id,created_date,options,question,image})
    }}>
        <div className="td-item t-1">
            <i className="icon-make-survey"></i>
            <p>{name}</p>
        </div>
        <div className="td-item t-2">
            <p>{answered_count}</p>
        </div>
        <div className="td-item t-3 resp-date">
            <p>{created_date}</p>
        </div>
    </div>
);

export default PanelSurveyItemComponent;