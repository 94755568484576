import React, {Component, Fragment} from 'react';
import {Field, reduxForm} from "redux-form";
import InputFieldComponent from '../../components/common/formFields/inputFieldComponent';
import {forgotPasswordAction} from "../../actions/index";
import {createPasswordWithTokenAction} from "../../actions/userAction";
import {connect} from "react-redux";

const validate = (values) => {
    let hasErrors = false;
    let errors = {};
    if (!values.password) {
        hasErrors = true;
        errors.password = 'Zorunlu Alan'
    }else if(values.password.length<6){
        hasErrors = true;
        errors.password = 'En az 6 haneli bir şifre girmeniz gerekmektedir.';
    }
    return hasErrors && errors;
};

class CreatePasswordRightContainer extends Component {
    isFailed = false;
    isSuccess = false;
    failMessage;
    onSubmit = (values) => {
        this.props.dispatch(createPasswordWithTokenAction({token:this.props.match.params.token,user_id:this.props.match.params.user_id,password:values.password},(res,message)=>{
            if(res === true){
                this.props.passwordCreated();
                this.isSuccess = true;
            }else{
                this.isFailed = true;
                this.failMessage = message;
            }
            this.forceUpdate();
        }))
    };

    render() {
        let {handleSubmit,errors,submitFailed} = this.props;
        return (
            <Fragment>
                {
                    (this.isFailed === false && this.isSuccess === false) &&
                    <div className="right-block">
                        <div className="text-block">
                            <h2>Şifremi Oluşturun</h2>
                            <p>Ünlü Tekstil Sustainability portalına giriş yapın.</p>
                        </div>

                        <form onSubmit={handleSubmit(this.onSubmit)}>
                            <div className="input-block">
                                <Field
                                    name="password"
                                    type="password"
                                    label="Şifre"
                                    placeholder="Şifre giriniz.."
                                    component={InputFieldComponent}
                                />
                            </div>
                            <div className="input-bottom">
                                <input type="submit" value="ŞİFREYİ OLUŞTUR" className="btn-green"/>
                            </div>
                        </form>
                        {
                            (errors && errors.password && submitFailed === true) &&
                            <div className="text-item">
                                <p>{errors.password}</p>
                            </div>
                        }
                    </div>
                }

                {
                    this.isSuccess === true &&
                    <div className="right-block">
                        <div className="img-block">
                            <img src="/assets/img/common/talep-img.png" alt=""/>
                        </div>
                        <div className="text-item">
                            <h3>Şifreniz Güncellendi</h3>
                            <p>Şifreniz başarıyla güncellendi. Giriş sayfasından sisteme giriş yapabilirsiniz.</p>
                        </div>
                    </div>
                }
                {
                    this.isFailed === true &&
                    <div className="right-block">
                        <div className="img-block">
                            <img src="/assets/img/common/talep-img.png" alt=""/>
                        </div>
                        <div className="text-item">
                            <h3>Şifreniz Oluşturulamadı.</h3>
                            <p>{this.failMessage}</p>
                        </div>
                    </div>
                }
            </Fragment>
        )
    }
};

const mapStateToProps = (state) => ({
    errors:state.form.createPassword?state.form.createPassword.syncErrors:null
});

CreatePasswordRightContainer = connect(mapStateToProps)(CreatePasswordRightContainer);

export default reduxForm({
    form: 'createPassword',
    validate
})(CreatePasswordRightContainer);