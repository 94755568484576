import React from 'react';
import dompurify from 'dompurify';

const sanitizer = dompurify.sanitize;
const getSubTitle = (content) => {
     return content ? content.replace(/(<([^>]+)>)/ig,"#HTMLTAG#").split("#HTMLTAG#").filter(item=>item.length>0)[0]:null
};

const EducationMenuItemComponent = ({index,text,subItems,goToTitle, id,goToSubTitle,focusedId}) => (
    <li className="head-1">
        <div className={"text-li "+(focusedId===id?"active":"")} onClick={()=>{goToTitle(id)}}>
            <span>{index+1}</span>
            <span className="head-1-text" dangerouslySetInnerHTML={{__html:sanitizer(text.replace(/(<([^>]+)>)/ig, ""))}}></span>
        </div>
        <ul>
            {
                (subItems && subItems.length>0 ) && subItems.map((subItem,key)=>
                    <li className="head-2" key={key} onClick={()=>{goToSubTitle(id,`${index+1}.${key+1}`,subItem.id)}}>
                        <div className={"text-li "+(focusedId===subItem.id?"active":"")}>
                            <span>{`${index+1}.${key+1}`}</span>
                            <span className="head-2-text" dangerouslySetInnerHTML={{__html:sanitizer(getSubTitle(subItem.content))}}></span>
                        </div>
                    </li>
                )
            }
        </ul>
    </li>
);

export default EducationMenuItemComponent;