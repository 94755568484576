import React, {Component} from 'react';
import {connect} from "react-redux";
import PanelBreadCrumbComponent from "../../components/Panel/panelBreadCrumbComponent";
import InfiniteScroll from 'react-infinite-scroller';
import NewLocationModalComponent from "../../components/modals/newLocationModalComponent";
import {getTagListAction} from "../../actions";
import PanelTagItemComponent from "../../components/Panel/PanelTagItemComponent";
import NewTagModalComponent from "../../components/modals/newTagModalComponent";

class PanelTagListContainer extends Component {
    activePage = 0;
    order = "tag_name";
    order_type = "asc";
    isMore = true;
    isNewTag = false;
    selectedTag;
    constructor(props) {
        super(props);
        this.loadFunc();

    }
    loadFunc = () => {
        let params = {
            activePage: this.activePage,
            order: this.order,
            order_type: this.order_type
        };
        this.props.dispatch(getTagListAction(params, (res, isMore) => {
            if (res === true) {
                this.isMore = isMore;
                this.activePage++;
                this.forceUpdate();
            }
        }));
    };
    sortFunc = (sort_name) => {
        this.order_type = this.order_type === "asc" ? "desc":"asc";
        this.order = sort_name;
        this.activePage = 0;
        this.loadFunc();
    };

    selectTag = (item) => {
        this.selectedTag = item;
        this.toggleNewTag(true);
    };

    toggleNewTag = (value) => {
        this.isNewTag = value;
        this.forceUpdate();
    };

    render() {
        let {tag} = this.props;
        return (
            <div className="panel-content-block">
                <PanelBreadCrumbComponent page="Etiketler"/>
                <div className="table-root">
                    <div className="table-container">
                        <div className="table-head">
                            <div className="t-1 td-item" onClick={()=>{this.sortFunc("tag_name")}}>
                                <p>Etiket Adı</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-2 td-item" onClick={()=>{this.sortFunc("created_date")}}>
                                <p>Oluşturulma Tarihi</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                        </div>
                        {
                            tag &&
                            <InfiniteScroll
                                pageStart={0}
                                loadMore={this.loadFunc}
                                hasMore={this.isMore && tag.length > 19}
                                threshold={250}
                            >
                                <div className="table-block">
                                    {
                                        tag && tag.map((item, key) =>
                                            <PanelTagItemComponent history={this.props.history} selectTag={this.selectTag} key={key} {...item}/>
                                        )
                                    }
                                </div>
                            </InfiniteScroll>
                        }
                        {
                            this.isNewTag === true && <NewTagModalComponent selectedTag={this.selectedTag} toggleModal={this.toggleNewTag} {...this.props}/>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    tag : state.PanelReducer.tag
});

export default connect(mapStateToProps)(PanelTagListContainer);