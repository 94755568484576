import React,{Component} from 'react';
import NewsItemComponent from "../../components/home/newsItemComponent";

class NewsContainer extends Component{
    constructor(props){
        super(props);


        this.state = {
            newsCurrentPage: 0,
            newsTotalPage: 0,
            newsPageSize: 5,
            currentNewsArr: []
        };
    }

    componentDidMount(){
        this.setState({
            currentNewsArr: this.props.news.items.slice(0, this.state.newsPageSize),
            newsTotalPage: Math.ceil(this.props.news.items.length / this.state.newsPageSize)
        })
    }

    goToPage = (pageNo) => {

        if (pageNo >= 0 && pageNo < this.state.newsTotalPage) {
            this.setState({
                newsCurrentPage: pageNo,
                currentNewsArr: [...this.props.news.items.slice(pageNo * this.state.newsPageSize, (pageNo + 1) * this.state.newsPageSize)],

            })
        }

    };
    render(){
        return(
            <div className="news-container">
                <div className="head-block">
                    <h2>GÜNCELLEME & HABERLER</h2>
                    <p>Eğitim sistemindeki yeni güncellemeler ve haberleri takip edin.</p>
                </div>
                <div className="news-block">
                    <div className="slick-list-con">
                        <h3>HABERLER</h3>
                        <div className="slick-list-block">
                            {
                                (this.state.currentNewsArr && this.state.currentNewsArr.length>0) && this.state.currentNewsArr.map((item, i) =>
                                    <NewsItemComponent key={i} {...item}/>
                                )
                            }
                        </div>
                        <div className="paginate-block">
                            <i className="icon-left-arr" onClick={() => {
                                this.goToPage(this.state.newsCurrentPage - 1);
                            }}></i>
                            <ul className="slick-dots">
                                {
                                    this.state.newsTotalPage > 0 && Array.apply([], {length: Math.ceil(this.state.newsTotalPage)}).map((count, i) =>
                                        <li className={this.state.newsCurrentPage === i ? "active" : ""}
                                            key={i} onClick={() => {
                                            this.goToPage(i)
                                        }}>{i + 1}</li>
                                    )
                                }
                            </ul>
                            <i className="icon-right-arr-2" onClick={() => {
                                this.goToPage(this.state.newsCurrentPage + 1);
                            }}></i>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default NewsContainer;