import {RECEIVE_SESSION_USER, UPDATE_NOTIFICATION_COUNT} from '../constants/actionTypes';
import {createPasswordWithToken, getActiveUser, logOut} from "../services/webService";

const receiveSessionUser = (payload) => ({
    type:RECEIVE_SESSION_USER,
    payload
});

export const getActiveUserAction = (cb) => dispatch => {
    getActiveUser().then((res)=>{
        if(res.data.success){
            dispatch(receiveSessionUser(res.data.success))
            cb(true);
        }else{
            cb(false);
        }
    }).catch((err)=>{
        cb(false);
    });
};

export const createPasswordWithTokenAction = (params,cb) => dispatch => {
    createPasswordWithToken(params).then((res)=>{
        if(res.data.success){
            cb(true);
        }else{
            cb(false);
        }
    }).catch((err)=>{
        cb(false);
    });
};

export const logOutAction = (cb) => dispatch => {
    logOut().then((res)=>{
        if(res.data.success){
            cb(true);
            dispatch(receiveSessionUser(null))
        }else{
            cb(false);
        }
    }).catch((err)=>{
        cb(false);
    })
};

const notificationCount = (payload) => ({
    type:UPDATE_NOTIFICATION_COUNT,
    payload
});

export const updateNotificationCount = (count) => dispatch => {
    dispatch(notificationCount(count));
};