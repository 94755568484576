import React, {Component} from 'react';
import {connect} from "react-redux";
import {canceledDocumentVersionAction, publishVersionOfDocumentAction} from "../../actions/educationDetailAction";
import {withRouter} from "react-router-dom";
import {EDUCATIONDETAIL_WITHOUTPARAM, HOME} from "../../constants/routePaths";

class VersionSavedModalComponent extends Component {
    contentBlock = React.createRef();
    render() {
        let {toggleModal,isDraftSuccess} = this.props;
        return (
            <div className="lb-root" onClick={(e) => {
                if (this.contentBlock.current.contains(e.target) === false) {
                    toggleModal(false, null)
                }
            }}>
                <div className="lb-block">
                    <div ref={this.contentBlock} className="content-block">
                        <div className="content-item">
                            <i className="icon-close" onClick={() => {
                                toggleModal(false, null)
                            }}></i>
                            {
                                isDraftSuccess === true ?
                                    <div className="text-item">
                                        <p className="head-text">Taslak olarak kayıt edilmiştir.</p>
                                    </div>
                                    :
                                    <div className="text-item">
                                        <p className="head-text">Düzenleme talebiniz yetkiliye gönderilmiştir.</p>
                                        <p>Onaylandığı takdirde yayına alınacaktır.</p>
                                    </div>
                            }
                            <div className="btn-block">
                                <span className="btn-orange" onClick={()=>{this.props.history.push(HOME)}}>ANASAYFAYA GİT</span>
                                <span className="btn-2" onClick={() => {
                                    toggleModal(false, null)
                                }}>KAPAT</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
;

export default withRouter(connect()(VersionSavedModalComponent));