import React, {Component} from 'react';
import {connect} from "react-redux";
import PanelBreadCrumbComponent from "../../components/Panel/panelBreadCrumbComponent";
import PanelFeedbackItemComponent from "../../components/Panel/panelFeedbackItemComponent";
import {getFeedbackEducationAction} from "../../actions/panelAction";
import InfiniteScroll from 'react-infinite-scroller';
import FeedbackDetailModalComponent from "../../components/modals/feedbackDetailModalComponent";

class PanelFeedbackListContainer extends Component {
    activePage = 0;
    order = "subject";
    order_type = "asc";
    isMore = true;
    isDetailOpen = false;
    detailItem;
    toggleDetail = (item, value) => {
        this.detailItem = item;
        this.isDetailOpen = value;
        this.forceUpdate();
    };

    loadFunc = () => {
        let params = {
            activePage: this.activePage,
            order: this.order,
            order_type: this.order_type
        };
        this.props.dispatch(getFeedbackEducationAction(params, (res, isMore) => {
            if (res === true) {
                this.isMore = isMore;
                this.activePage++;
                this.forceUpdate();
            }
        }));
    };
    sortFunc = (sort_name) => {
        // this.order_type = this.order === sort_name ? "asc" : "desc";
        this.order_type = this.order_type === "asc" ? "desc":"asc";
        this.order = sort_name;
        this.activePage = 0;
        this.loadFunc();
    };

    render() {
        let {items} = this.props;
        return (
            <div className="panel-content-block">
                <PanelBreadCrumbComponent page="Geri Bildirimler"/>
                <div className="table-root">
                    <div className="table-container">
                        <div className="table-head">
                            <div className="t-1 td-item" onClick={()=>{this.sortFunc("subject")}}>
                                <p>Konu</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-2 td-item" onClick={()=>{this.sortFunc("user_name")}}>
                                <p>Geri Bildirimi Yapan</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-3 td-item" onClick={()=>{this.sortFunc("state")}}>
                                <p>Durum</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-3 td-item resp-date" onClick={()=>{this.sortFunc("feedback_date")}}>
                                <p>Tarihi</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                        </div>
                        {
                            items &&
                            <InfiniteScroll
                                pageStart={0}
                                loadMore={this.loadFunc}
                                hasMore={this.isMore && items.length > 19}
                                threshold={250}
                                // loader={<div className="loader" key={0}>Loading ...</div>}
                            >
                                <div className="table-block">
                                    {
                                        items && items.map((item, key) =>
                                            <PanelFeedbackItemComponent toggleDetail={this.toggleDetail} key={key} {...item}/>
                                        )
                                    }
                                </div>
                            </InfiniteScroll>
                        }
                        {
                            this.isDetailOpen === true &&

                            <FeedbackDetailModalComponent detailItem={this.detailItem} toggleDetail={this.toggleDetail} />

                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    items: state.PanelReducer.feedback
});

export default connect(mapStateToProps)(PanelFeedbackListContainer);