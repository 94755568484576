import React,{Component,Fragment} from 'react';
import ModalEducationInfoComponent from '../../components/modals/modalEducationInfoComponent';
import {SITE_URL} from "../../constants/Path";
import {sendMailEducationToUserAction} from "../../actions/educationDetailAction";
import {connect} from "react-redux";
import LoaderModalComponent from "../common/loaderModalComponent";

class ShareModalComponent extends Component{
    contentBlock = React.createRef();
    shareInput = React.createRef();
    emailInput = React.createRef();
    emailInputError;
    isLoaderIsShowing = false;
    resultMessage;
    copyClipboard = () => {
      this.shareInput.current.select();
      document.execCommand("copy");
    };
    sendMail = () => {
        if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.emailInput.current.value) === false){
            this.isLoaderIsShowing = true;
            this.forceUpdate();
            let urlArr = this.props.urlLink.split("-");
            this.props.dispatch(sendMailEducationToUserAction({email:this.emailInput.current.value,education_id:urlArr[urlArr.length-1]},(res)=>{
                this.isLoaderIsShowing = false;
                if(res === true){
                    this.resultMessage = "Başarıyla Paylaşıldı.";
                    this.forceUpdate()
                    this.emailInput.current.value="";
                    //TODO: BAŞARILI SONUÇ.
                }else{
                    this.resultMessage = "Bir hata oluştu lütfen daha sonra tekrar deneyin.";
                    this.forceUpdate()
                    //TODO: HATA OLUŞTU.
                }
                setTimeout(()=>{
                    this.resultMessage = null;
                    this.forceUpdate();
                },1000)
                // this.props.changeKeyModal("shareModalIsShowing",true);
            }))
        }else{
            this.emailInputError = "E-mail adresi valid değil!";
            this.forceUpdate();
        }
    };
    render(){
        let {urlLink,shareUrl} = this.props;
        return(
            <Fragment>
                <div className="lb-root" onClick={(e)=>{
                    if(this.contentBlock.current.contains(e.target) === false){
                        this.props.changeKeyModal("shareModalIsShowing",false);
                    }
                }}>
                    <div className="lb-block">
                        <div ref={this.contentBlock} className="content-block">
                            <ModalEducationInfoComponent urlLink={urlLink}/>
                            <div className="content-item">
                                <i className="icon-close" onClick={()=>{this.props.changeKeyModal("shareModalIsShowing",false);}}></i>
                                <div className="text-item">
                                    <p className="head-text">Eğitimi Paylaş</p>
                                    <p>Bu eğitimi sadece sisteme yetkisi olan kişiler görüntüleyebilir.</p>
                                </div>

                                <div className="form-block">

                                    <div className="input-item">
                                        <label>LİNK PAYLAŞ <span>(Linki kopyalayın ya da kopyala butonuna basın. Linki kolayca paylaşabilirsiniz.)</span>

                                            <input ref={this.shareInput} onChange={()=>{}} value={(typeof window !== "undefined" ? window.location.origin:SITE_URL)+shareUrl} type="text"/>

                                        </label>
                                        <div className="right-btn" onClick={this.copyClipboard}>
                                            <span><i className="icon-copy"></i>LİNKİ KOPYALA</span>
                                        </div>
                                        <div className="right-btn resp" onClick={this.copyClipboard}>
                                            <span><i className="icon-copy"></i></span>
                                        </div>
                                    </div>


                                    <div className="input-item">
                                        <label>MAİL
                                            GÖNDER <span> (Paylaşmak istediğiniz kişinin mail adresini ekleyin.)</span>

                                            <input ref={this.emailInput} type="text" placeholder="E-Posta Adresi giriniz.."/>

                                        </label>
                                        <div className="right-btn" onClick={this.sendMail}>
                                            <input type="submit" value=""/>
                                            <span><i className="icon-right-arr"></i>GÖNDER</span>
                                        </div>
                                        <div className="right-btn resp" onClick={this.sendMail}>
                                            <input type="submit" value=""/>
                                            <span><i className="icon-right-arr"></i></span>
                                        </div>

                                    </div>
                                    {
                                        this.resultMessage && <div className="massage-item">
                                            <p>{this.resultMessage}</p>
                                        </div>
                                    }
                                    <span className="btn-orange" onClick={()=>{this.props.changeKeyModal("shareModalIsShowing",false);}}>GERİ DÖN</span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.isLoaderIsShowing === true && <LoaderModalComponent/>
                }
            </Fragment>
        )
    }
}

export default connect()(ShareModalComponent);