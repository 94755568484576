import {RECEIVE_CHANGE_EDUCATION_STATUS, RECEIVE_EDUCATION_DETAIL} from '../constants/actionTypes';
import {
    addFeedBackToEducation,
    breakSession,
    educationAddParticipant,
    endBreakSession,
    endEducationSession,
    getDepartmentParticipants,
    getEducationInfo,
    getEducationPdf,
    getSessionDocuments,
    getVersionEducationDetail,
    participantAutoComplete,
    saveEducationVideo,
    saveFeedBack,
    saveSessionDocuments,
    sendMailEducationToUser,
    startEducationSession,
    updateDocumentContent,
    uploadSessionDocument
} from "../services/webService";
import {
    canceledDocumentVersion, createNewVersionOfDocument, passiveEducation,
    publishVersionOfDocument
} from "../services/adminService";

export const receiveEducationDetail = (education, payload) => ({
    type: RECEIVE_EDUCATION_DETAIL,
    education,
    payload
});

export const getEducationInfoAction = (params,cb) => dispatch => {
    getEducationInfo(params).then((res)=>{
        if (res.data.success){
            cb(true,res.data.success);
        }else{
            cb(false);
        }
    }).catch((err)=>{
        cb(false);
    })
}

export const saveFeedBackAction = (params, cb) => dispatch => {
    saveFeedBack(params).then((res) => {
        if (res.data.success) {
            cb(true);
        } else {
            cb(false);
        }
    }).catch((err) => {
        cb(false);
    })
};

export const sendMailEducationToUserAction = (params, cb) => dispatch => {
    sendMailEducationToUser(params).then((res) => {
        if (res.data.success) {
            cb(true);
        } else {
            cb(false);
        }
    }).catch((err) => {
        cb(false);
    })
};

export const getEducationPdfAction = (params, cb) => dispatch => {
    getEducationPdf(params).then((res) => {
        if (res.data.success) {
            cb(true);
        } else {
            cb(false);
        }
    }).catch((err) => {
        cb(false);
    })
};

export const educationAddParticipantAction = (params, cb) => dispatch => {
    educationAddParticipant(params).then((res) => {
        if (res.data.success) {
            cb(res.data.success);
        }
    })
};

export const startEducationSessionAction = (params, cb) => dispatch => {
    startEducationSession(params).then((res) => {
        if (res.data.success) {
            cb(true, res.data.success);
        }
    })
};

export const endEducationSessionAction = (params, cb) => dispatch => {
    endEducationSession(params).then((res) => {
        if (res.data.success) {
            cb(true, res.data.success);
        }
    })
};

export const breakSessionAction = (params, cb) => dispatch => {
    breakSession(params).then((res) => {
        if (res.data.success) {
            cb(true);
        }
    })
};


export const endBreakSessionAction = (params, cb) => dispatch => {
    endBreakSession(params).then((res) => {
        if (res.data.success) {
            cb(true);
        }
    })
};

export const updateDocumentContentAction = (params, cb) => dispatch => {
    updateDocumentContent(params).then((res) => {
        if (res.data.success) {
            cb(true);
        } else {
            cb(false);
        }
    }).catch((err) => {
        cb(false);
    })
};

export const createNewVersionOfDocumentAction = (params, cb) => dispatch => {
    createNewVersionOfDocument(params).then((res) => {
        if (res.data.success) {
            cb(true);
        } else {
            cb(false);
        }
    }).catch((err) => {
        cb(false);
    })
};

export const getSessionDocumentsAction = (params, cb) => dispatch => {
    getSessionDocuments(params).then((res) => {
        if (res.data.success) {
            cb(true, res.data.success, res.data.state);
        } else {
            cb(false);
        }
    }).catch((err) => {
        cb(false);
    })
};

export const saveSessionDocumentsAction = (params, cb) => dispatch => {
    saveSessionDocuments(params).then((res) => {
        if (res.data.success) {
            cb(true);
        } else {
            cb(false);
        }
    }).catch((err) => {
        cb(false);
    })
};

export const uploadSessionDocumentAction = (image, cb) => dispatch => {
    uploadSessionDocument(image, "document").then((res) => {
        if (res.data.success) {
            cb(res.data.success);
        } else {
            cb(false);
        }
    }).catch((err) => {
        cb(false);
    })
};

export const passiveEducationAction = (params, cb) => dispatch => {
    passiveEducation(params).then((res) => {
        if (res.data.success) {
            cb(true);
        } else {
            cb(false);
        }
    }).catch((err) => {
        cb(false);
    })
};

export const changeEducationStatus = (education_id, value) => ({
    type: RECEIVE_CHANGE_EDUCATION_STATUS,
    education_id,
    value
});

export const getVersionEducationDetailAction = (params, cb) => dispatch => {
    getVersionEducationDetail(params).then((res) => {
        if (res.data.success) {
            cb(res.data.success)
        }
    })
};

export const publishVersionOfDocumentAction = (params, cb) => dispatch => {
    publishVersionOfDocument(params).then((res) => {
        if (res.data.success) {
            cb(true);
        } else {
            cb(false);
        }
    })
};

export const canceledDocumentVersionAction = (params, cb) => dispatch => {
    canceledDocumentVersion(params).then((res) => {
        if (res.data.success) {
            cb(true);
        } else {
            cb(false);
        }
    })
};

export const saveEducationVideoAction = (params, cb) => dispatch => {
    saveEducationVideo(params,"education").then((res) => {
        if (res.data.success) {
            cb(res.data.success);
        } else {
            cb(false);
        }
    });
};

export const getDepartmentParticipantsAction = (params, cb) => dispatch => {
    getDepartmentParticipants(params).then((res) => {
        if (res.data.success) {
            cb(res.data.success)
        } else {
            cb([]);
        }
    }).catch(() => {
        cb([]);
    })
};

export const participantAutoCompleteAction = (params, cb) => dispatch => {
    participantAutoComplete(params).then((res) => {
        if (res.data.success) {
            cb(res.data.success)
        }else{
            cb(false);
        }
    }).catch(()=>{
        cb(false);
    })
};