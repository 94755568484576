import React,{Component} from 'react';
import {connect} from "react-redux";
import EducationMenuItemComponent from "../../components/educationDetail/educationMenuItemComponent";

class EducationMenuComponent extends Component{
    render(){
        let {detailDocument, goToTitle, goToSubTitle, focusedId} = this.props;
        return(
            <div className="menu-block">
                <ul className="sticky">
                    {
                        detailDocument && detailDocument.map((item,key)=>
                            <EducationMenuItemComponent focusedId={focusedId} goToSubTitle={goToSubTitle} goToTitle={goToTitle} index={key} key={key} {...item}/>
                        )
                    }
                </ul>
            </div>
        )
    }
};

const mapStateToProps = (state, ownProps) => {
    let urlArr = ownProps.urlLink.split("-");
    return({
        detailDocument: state.educationDetailReducer[urlArr[urlArr.length-1]] ? state.educationDetailReducer[urlArr[urlArr.length-1]].document : null
    });
};

export default connect(mapStateToProps)(EducationMenuComponent);