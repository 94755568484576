import async from "async";
import * as WebService from '../services/webService';
import * as actionTypes from "../constants/actionTypes";
export const getEducationResult = (data) => ({
    type: actionTypes.GET_EDUCATION_RESULT_BY_ID,
    payload: data
});

export const getEducationRankingInit = (dispatch,params) => {
    return new Promise((resolve, failure) => {
        async.series([
            (cb) => {
                WebService.getQuizResultByEducationId(params).then((res) => {
                    if (res.data.success) {
                        cb(null, res.data.success);
                    }
                })
                    .catch((err) => {

                    })
            }
        ], (err, result) => {
            dispatch(getEducationResult(result[0]));
            resolve(result);
        });
    });


};
