import React, {Component} from 'react';
import ModalEducationInfoComponent from '../../components/modals/modalEducationInfoComponent';
import {saveFeedBackAction} from "../../actions/educationDetailAction";
import {connect} from "react-redux";

class FeedBackModalComponent extends Component {
    contentBlock = React.createRef();
    textArea = React.createRef();
    submitFeedBack = () => {
        if(this.textArea.current.value && this.textArea.current.value.trim().length>0){
            let urlArr = this.props.urlLink.split("-");
            this.props.dispatch(saveFeedBackAction({feedBack:this.textArea.current.value,educationLink:urlArr[urlArr.length-1],session_id:this.props.sessionId},(res)=>{
                if(res === true){
                    this.props.toggleFeedBackResultModal(true);
                }else{
                    this.props.toggleFeedBackResultModal(true);
                }
            }));
        }
    };
    render() {
        let {toggleModal,urlLink} = this.props;
        return (
            <div className="lb-root" onClick={(e)=>{
                if(this.contentBlock.current.contains(e.target) === false){
                    toggleModal(false,null,null)
                }
            }}>
                <div className="lb-block">
                    <div ref={this.contentBlock} className="content-block">
                        <ModalEducationInfoComponent urlLink={urlLink}/>
                        <div className="content-item">
                            <i className="icon-close" onClick={()=>{toggleModal(false)}}></i>
                            <div className="text-item">
                                <p className="head-text">Geri Bildirim Yap</p>
                                <p>Eğitim ile ilgili geri bildirim yapabilir, bu dökümanın gelişimi için katkıda
                                    bulunabilirsiniz.</p>
                            </div>

                            <div className="form-block">

                                <label>GERİ BİLDİRİMİNİZ <span> (Lütfen açıklayıcı olmaya gayret ediniz.)</span>
                                    <textarea ref={this.textArea}></textarea>
                                </label>

                                <div className="btn-item" onClick={this.submitFeedBack}>
                                    <span className="btn-green">GERİ BİLDİRİMİ PAYLAŞ</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect()(FeedBackModalComponent);