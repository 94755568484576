import React, {Component} from 'react';
import {EDUCATIONDETAIL_WITHOUTPARAM} from "../../constants/routePaths";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {changeBannerItemAction, educationAutoCompleteAction} from "../../actions/panelAction";
import Helper from '../../helper';

class BannerItemComponent extends Component {
    editContent = React.createRef();
    searchInput = React.createRef();
    resultArr = [];
    componentDidMount() {
        if(typeof window !== "undefined"){
            window.addEventListener('click',this.windowClicked);
        }
    }

    toggleEdit = () => {
        this.editContent.current.classList.toggle("open");
    };

    windowClicked = (e) => {
        if(this.editContent.current && this.editContent.current.contains(e.target)===false){
            this.editContent.current.classList.remove("open");
        }
    };

    componentWillUnmount() {
        if(typeof window !== "undefined"){
            window.addEventListener('click',this.windowClicked);
        }
    }

    searchEducation = () => {
        if(this.searchInput.current.value.length>0){
            this.props.dispatch(educationAutoCompleteAction({searchTerm:this.searchInput.current.value},(res)=>{
                if(res){
                    this.resultArr = res;
                    this.forceUpdate();
                }else{
                    this.resultArr = [];
                    this.forceUpdate();
                }
            }))
        }
    };

    changeItem = (resultItem) => {
        let params = {education_id:resultItem.id,order_number:this.props.index};
        this.props.dispatch(changeBannerItemAction(params,(res)=>{
            if(res){
                this.resultArr = [];
                this.editContent.current.classList.remove("open");
                this.props.refreshData();
            }
        }));
    };

    render() {
        let {obj,roleType} = this.props;
        return (
            <div className="box-item">
                {
                    roleType === "1" &&
                    <div className="edit-content-root" ref={this.editContent}>
                        <div className="edit-btn">
                            <i className="icon-pen" onClick={this.toggleEdit}></i>
                            <i className="icon-close" onClick={this.toggleEdit}></i>
                        </div>

                        <div className="open-search-content">
                            <div className="search-item">
                                <i className="icon-search"></i>
                                <input type="text" ref={this.searchInput} onChange={this.searchEducation} placeholder=""/>
                            </div>
                            <div className="autocomplete-root">
                                <ul>
                                    {
                                        this.resultArr && this.resultArr.map((resultItem,key)=>
                                            <li key={key} onClick={()=>{this.changeItem(resultItem)}}><i className="icon-doc"></i> {resultItem.name}</li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                }
                <div className="info-block">
                    <span
                        className={`label-tag ${obj.educationCategory.tag?"green":"blue"}`}>{obj.educationCategory.tag ? obj.educationCategory.tag : "DUYURU"}</span>
                    <Link
                        to={obj.education.name ? `${EDUCATIONDETAIL_WITHOUTPARAM}${Helper.turkishToLower(obj.education.name).split(" ").join("-").split("/").join("").split("?").join("")}-${obj.education.id}` : obj.link}>
                        <h3>{obj.education.name ? Helper.titleCase(obj.education.name) : obj.announcemen_text}</h3>
                    </Link>
                    {
                        obj.education.name && <div className="btn-item">
                            <Link
                                to={obj.education.name ? `${EDUCATIONDETAIL_WITHOUTPARAM}${Helper.turkishToLower(obj.education.name).split(" ").join("-").split("/").join("").split("?").join("")}-${obj.education.id}` : obj.link}
                                className="link-btn bl">
                                KONUYU İNCELE
                            </Link>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    roleType:state.sessionReducer.role_id
});

export default connect(mapStateToProps)(BannerItemComponent);