import {
    GET_EDUCATION_CATEGORIES,
    GET_FEATURE_COURSES,
    GET_FUNDAMENTAL_COURSES,
    GET_MAIN_TILES,
    GET_NEWS,
    GET_OPEN_EDUCATION, GET_SURVEY,
    GET_USER_NOTIFICATIONS
} from '../constants/actionTypes'

const initialState = {};

const homeReducer = (state = initialState,action) => {
    switch (action.type){

        case GET_EDUCATION_CATEGORIES:
            return {
                ...state,
                educationCategories:[...JSON.parse(action.payload)]
            };

            case GET_FEATURE_COURSES:
            return {
                ...state,
                featureCourses:[...action.payload]
            };

            case GET_FUNDAMENTAL_COURSES:
            return {
                ...state,
                fundamentalCourses:{...state.success,...action.payload}
            };

            case GET_MAIN_TILES:
            return {
                ...state,
                mainTiles:{...state.success,...action.payload}
            };

            case GET_NEWS:
            return {
                ...state,
                news:{...action.payload}
            };

            case GET_OPEN_EDUCATION:
            return {
                ...state,
                openEducation:{...state.success,...action.payload}
            };

            case GET_USER_NOTIFICATIONS:
            return {
                ...state,
                notifications:[...action.payload]
            };

            case GET_SURVEY:
            return {
                ...state,
                survey:{...state.success,...action.payload}
            };
        default:
            return state;
    }
};

export default homeReducer;
