import React, {Component} from 'react';
import {connect} from "react-redux";
import {answerQuestionAction, teacherAnswerQuestionAction} from "../../actions/quizAction";
import * as ROUTES from "./../../constants/routePaths";
import _ from "lodash";
import {ASSETS} from "../../constants/Path";
class QuizQuestionContainer extends Component {
    options = ["A", "B", "C", "D", "E", "F", "G", "H"];
    errorMessage;
    selectedItem;
    is_correct;
    correct_answer;
    selectOption = (item) => {
        this.selectedItem = item;
        this.forceUpdate();
    };
    answerQuestion = () => {
        let {params,isTeacher, questionId} = this.props;

        if(this.selectedItem && this.selectedItem.id){
            if(isTeacher===true){
                this.props.dispatch(teacherAnswerQuestionAction({
                    session_id: params.educationSessionId,
                    token: params.token,
                    question_id: params.questionId,
                    answer: this.selectedItem.id,
                    quiz_id: params.quizId
                },(res,msg)=>{
                    if(res!==false){
                        this.is_correct = res.is_correct;
                        this.correct_answer = res.correct_answer;
                        this.forceUpdate();
                        setTimeout(()=>{
                            this.is_correct = null;
                            this.correct_answer = null;
                            this.selectedItem = null;
                            this.goToNextQuestion()
                        },2000);
                    }else{
                        this.errorMessage = JSON.stringify(msg);
                        this.forceUpdate();
                        /*setTimeout(()=>{
                            this.goToNextQuestion()
                        },2000);*/
                    }
                }))
            }else{
                this.props.dispatch(answerQuestionAction({
                    session_id: params.educationSessionId,
                    token: params.token,
                    question_id: questionId ? this.props.questionId : params.questionId,
                    answer: this.selectedItem.id,
                    quiz_id: params.quizId
                },(res,msg)=>{
                    if(res !== false){
                        this.is_correct = res.is_correct;
                        this.correct_answer = res.correct_answer;
                        this.forceUpdate();
                        setTimeout(()=>{
                            this.is_correct = null;
                            this.selectedItem = null;
                            this.correct_answer = null;
                            this.goToNextQuestion()
                        },2000)
                    }else{
                        this.errorMessage = JSON.stringify(msg);
                        this.forceUpdate();
                    }
                }))
            }
        }

    };

    goToBeforeQuestion = ()=>{
        //let params = this.props.match.params;
        //this.props.history.push(`${ROUTES.QUIZWEBTEACHER_WITHOUT_PARAMS}${params.educationSessionId}/${params.quizId}/${this.props.beforeQuestionId}`)
        this.props.goToBeforeQuestion();
    };


    goToNextQuestion = ()=>{
        //let params = this.props.match.params;
        //this.props.history.push(`${ROUTES.QUIZWEBTEACHER_WITHOUT_PARAMS}${params.educationSessionId}/${params.quizId}/${this.props.beforeQuestionId}`)
        this.props.goToNextQuestion();
    };

    render() {
        let {question, options,beforeQuestionId,nextQuestionId,isTeacher} = this.props;
        return (
            <div className="right-content-root">
                <div className="question-text">
                    <span>SORU {parseInt(question.order_number)+1}</span>
                    <p>{question.question}</p>
                    {
                        question.image &&
                        <div className="img-block">
                            <img src={ASSETS+question.image} alt=""/>
                        </div>
                    }
                </div>

                <div className="options-block">
                    {
                        options.filter(optionItem=> optionItem.option_name || optionItem.image ).map((option, key) =>
                            <div
                                className={`option-item ${this.selectedItem && option.id === this.selectedItem.id ? "select" : ""} ${this.correct_answer && option.id.toString() === this.correct_answer .toString() ? "correct" : ""} ${this.correct_answer && this.is_correct === false && option.id === this.selectedItem.id ? "wrong" : ""}`}
                                key={key} onClick={() => {
                                    if(!this.correct_answer){
                                        this.selectOption(option)
                                    }
                            }}>
                                <span>{this.options[key]}</span>
                                <div>
                                    <p>{option.option_name}</p>
                                    {
                                        option.image &&
                                        <div className="img-block">
                                            <img src={ASSETS+option.image} alt={option.option_name}/>
                                        </div>
                                    }
                                </div>

                            </div>
                        )
                    }
                </div>
                {
                    this.errorMessage && <div>{this.errorMessage}</div>
                }
                <div className="btn-block">

                    {
                        (beforeQuestionId!==null && isTeacher === true) &&
                        <span className="btn-2" onClick={()=>{
                            this.goToBeforeQuestion()
                        }}>ÖNCEKİ SORU</span>
                    }

                    { !this.correct_answer && <span className="btn-2" onClick={this.answerQuestion}>CEVAPLA</span>}
                </div>

            </div>
        )
    }
}
;

export default connect()(QuizQuestionContainer);
