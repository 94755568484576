import React, {Component} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {EDITEDUCATIONDETAIL_WITHOUTPARAM,EDUCATIONDETAIL_WITHOUTPARAM} from "../../constants/routePaths";
import Helper from '../../helper';

class footerComponent extends Component {
    render() {
        let {educationCategories} = this.props;
        return (
            <div className="footer-root">
                <div className="footer-con safe-area">
                    <div className="footer-block">
                        <div className="logo-block">
                            <img src="/assets/img/unluTekstilLogo2.png" alt=""/>
                        </div>
                        {
                            educationCategories &&
                            <div className="menu-block">
                                {
                                    educationCategories.map((categoryItem,key)=>
                                        <ul key={key}>
                                            <li><a href="/">{categoryItem.name}</a></li>
                                            {
                                                categoryItem.educations.splice(0,6).map((educationItem,educationKey)=>
                                                    <li key={educationKey}><Link to={`${EDUCATIONDETAIL_WITHOUTPARAM}${Helper.turkishToLower(educationItem.name).split(" ").join("-").split("/").join("").split("?").join("")}-${educationItem.id}`}> {educationItem.name}</Link></li>
                                                )
                                            }
                                        </ul>
                                    )
                                }
                            </div>
                        }
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    educationCategories: state.homeReducer ? state.homeReducer.educationCategories : [],
});

export default connect(mapStateToProps)(footerComponent);
