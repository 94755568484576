import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {EDUCATIONDETAIL_WITHOUTPARAM} from '../../constants/routePaths';
import Helper from '../../helper';

class CategoryItemComponent extends Component{
    render(){
        let {title,id,educations,name} = this.props;
        return(
            <div className="edu-list-item">
                <h3>{name}</h3>
                <ul>
                    {
                        educations.map((education,key)=>
                            <li key={key}><Link to={`${EDUCATIONDETAIL_WITHOUTPARAM}${Helper.turkishToLower(education.name.toLowerCase()).split(" ").join("-").split("/").join("").split("?").join("")}-${education.id}`}>{Helper.titleCase(education.name)}</Link> </li>
                        )
                    }
                </ul>
            </div>
        )
    }
}

export default CategoryItemComponent;