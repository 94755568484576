import React from 'react';
import {ASSETS} from "../../constants/Path";

const NewsItemComponent = ({news_date,title,link,educations}) => (
    <div className="slick-item">
        <div className="text-block">
            <span className="date">{news_date}</span>
            <p>{title}</p>
            {
                link && <span className="link-btn bl">DETAYLI İNCELE</span>
            }
        </div>
        <div className="img-block">
            <img src={ASSETS+educations.image} alt={title}/>
        </div>

    </div>
);

export default NewsItemComponent;