import React, {Component} from 'react';
class TextAreaFieldComponent extends Component {
    render() {

        const {input, meta: {error, touched}, placeholder, label} = this.props;
        return (
            <div className="input-item">
                <label>{label}
            <textarea className={`${error === true && touched === true ? "error" : ""}`}
                      placeholder={placeholder} {...input}></textarea>
                </label>
            </div>
        );
    }
}

TextAreaFieldComponent.propTypes = {};
TextAreaFieldComponent.defaultProps = {};

export default TextAreaFieldComponent;
