import React, {Component, Fragment} from 'react';
import {getSmartDocumentsAutoCompleteAction} from "../../actions/panelAction";
import {connect} from "react-redux";

class AddItemBlockComponent extends Component {
    videoLinkIsShowing = false;
    smartDocumentIsShowing = false;
    addContentBlock = React.createRef();
    videoInput = React.createRef();
    autoCompleteArr;
    onChangeFunc = (e) => {
        if (e.target.value.length === 0) {
            this.autoCompleteArr = [];
            this.forceUpdate();
        } else {
            this.props.dispatch(getSmartDocumentsAutoCompleteAction({searchTerm: e.target.value}, (res) => {
                if (res !== false) {
                    this.autoCompleteArr = res;
                    this.forceUpdate();
                } else {
                    this.autoCompleteArr = [];
                    this.forceUpdate();
                }
            }));
        }
    };
    selectedFunc = (item) => {
        // this.props.selectedFunc(item);
        this.autoCompleteArr = [];
        // this.props.input.onChange(item.smart_document_name?item.smart_document_name:item.name);
    };
    openEdit = (value) => {
        if (this.addContentBlock.current.className.indexOf("opened") > 0 && this.videoLinkIsShowing === true) {
            this.videoLinkIsShowing = false;
            this.forceUpdate();
        }
        this.addContentBlock.current.classList.add("opened");
    };
    toggleVideo = (value) => {
        this.videoLinkIsShowing = value;
        this.forceUpdate();
        if (this.videoLinkIsShowing === true) {
            setTimeout(() => {
                this.videoInput.current.focus();
            }, 150);
        }
    };
    onKeyUp = (e) => {
        if (e.keyCode === 27) {
            this.toggleVideo(false);
        } else {
            this.props.textOnKeyUp(e, () => {
                this.addContentBlock.current.classList.remove("opened");
                this.toggleVideo(false);
            })
        }
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.isFocused !== this.props.isFocused && nextProps.isFocused === false || this.addContentBlock.current.className.indexOf("opened") === -1) {
            this.videoLinkIsShowing = false;
        }
        return true;
    }


    toggleSmartDocument = (value) => {
        this.smartDocumentIsShowing = value;
        this.forceUpdate();
    };
    smartDocumentSelected = (item) => {
        this.selectedSmartDocument = item;
    };
    addSmartDocument = (item) => {
        this.openEdit();
        this.toggleSmartDocument(false);
        this.props.addSmartDocument(item)
    };

    render() {
        let {imageOnLoad, addTitle, addDelimeter, addSmartDoc, isLastEdit, isSmartDocuments, index, uploadVideo} = this.props;
        return (
            <div className={"add-content-block " + (isLastEdit === true ? "last-add-area" : "")}
                 ref={this.addContentBlock}>
                {
                    isLastEdit === true ?
                        <Fragment>
                            {
                                this.videoLinkIsShowing === false &&
                                <Fragment>
                                    <div className="add-btn-item" onClick={() => {
                                        this.addContentBlock.current.classList.toggle("opened");
                                        {/*this.openEdit(true);*/
                                        }
                                        this.toggleSmartDocument(false);
                                    }}>
                                        <i className="icon-add-plus"></i>
                                    </div>
                                    <div className="add-content-list">
                                        <ul>
                                            <li>
                                                <input onChange={(e) => {
                                                    imageOnLoad(e, () => {
                                                        this.addContentBlock.current.classList.remove("opened");
                                                    })
                                                }} accept="image/*" type="file"/>
                                                <i className="icon-add-img"></i>
                                            </li>
                                            <li onClick={() => {
                                                this.toggleVideo(true)
                                            }}><i className="icon-add-video"></i></li>
                                            <li onClick={() => {
                                            }}>
                                                <input onChange={(e) => {
                                                    uploadVideo(e, () => {
                                                        this.addContentBlock.current.classList.remove("opened");
                                                    })
                                                }} accept="video/*" type="file"/>
                                                <i className="icon-uploadvideo"></i>
                                            </li>
                                            {
                                                isSmartDocuments !== true && <li onClick={() => {
                                                    addSmartDoc && this.toggleSmartDocument(true);
                                                }}><i className="icon-add-smartdoc"></i></li>
                                            }
                                            <li onClick={() => {
                                                this.addContentBlock.current.classList.remove("opened");
                                                addDelimeter && addDelimeter()
                                            }}><i className="icon-add-delimeter"></i></li>
                                            <li onClick={() => {
                                                this.addContentBlock.current.classList.remove("opened");
                                                addTitle(index)
                                            }}><i className="icon-h1"></i></li>
                                        </ul>
                                    </div>
                                </Fragment>
                            }
                            {
                                this.videoLinkIsShowing === true &&
                                <Fragment>
                                    <div className="add-btn-item">
                                        <i className="icon-add-plus" onClick={() => {
                                            this.openEdit()
                                            this.videoLinkIsShowing = false;
                                            this.forceUpdate();
                                        }}></i>
                                    </div>

                                    <div className="add-input-block">
                                        <input ref={this.videoInput}
                                               placeholder="Video Linkiniz Buraya Yazın veya Yapıştırın." type="text"
                                               onKeyUp={this.onKeyUp}/>
                                    </div>
                                </Fragment>
                            }
                            {
                                this.smartDocumentIsShowing === true &&
                                <Fragment>
                                    <div className="add-btn-item">
                                        <i className="icon-add-plus" onClick={() => {
                                            this.openEdit()
                                            this.toggleSmartDocument(false);
                                        }}></i>
                                    </div>

                                    <div className="add-input-block">
                                        <input ref={this.smartDocumentInput}
                                               placeholder="Eklemek istediğiniz akıllı dökümanı arayın ve listeden seçin."
                                               type="text" onChange={this.onChangeFunc}/>
                                        <div className="autocomplete-list">
                                            <ul>
                                                {
                                                    this.autoCompleteArr && this.autoCompleteArr.map((item, key) =>
                                                        <li key={key}><p><span>{item.smart_document_name}</span></p>
                                                            <span onClick={() => {
                                                                this.addSmartDocument(item)
                                                            }} className="btn-add">Ekle</span></li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        </Fragment>
                        :
                        <Fragment>

                            <div style={{display: this.videoLinkIsShowing === false ? "block" : "none"}}>
                                <i onClick={() => {
                                    this.openEdit(true)
                                }} className="icon-add-plus"></i>
                                <div className="add-content-list">
                                    <ul>
                                        <li>
                                            <input onChange={(e) => {
                                                imageOnLoad(e, () => {
                                                    this.addContentBlock.current.classList.remove("opened");
                                                })
                                            }} accept="image/*" type="file"/>
                                            <i className="icon-add-img"></i>
                                        </li>
                                        <li onClick={() => {
                                            this.toggleVideo(true)
                                        }}><i className="icon-add-video"></i></li>
                                        <li onClick={() => {
                                        }}>
                                            <input onChange={(e) => {
                                                {/*imageOnLoad(e, () => {
                                                    this.addContentBlock.current.classList.remove("opened");
                                                })*/
                                                }
                                            }} accept="video/*" type="file"/>
                                            <i className="icon-add-video"></i>
                                        </li>
                                        <li onClick={() => {
                                            addDelimeter && addDelimeter()
                                        }}><i className="icon-add-delimeter"></i></li>
                                        <li onClick={() => {
                                            addTitle(this.props.index)
                                        }}><i className="icon-h1"></i></li>
                                    </ul>
                                </div>
                            </div>

                            <div style={{display: this.videoLinkIsShowing === true ? "block" : "none"}}>
                                <div className="add-btn-item">
                                    <i className="icon-add-plus" onClick={() => {
                                        this.openEdit()
                                        this.videoLinkIsShowing = false;
                                        this.forceUpdate();
                                    }}></i>
                                </div>

                                <div className="add-input-block">
                                    <input ref={this.videoInput}
                                           placeholder="Video Linkiniz Buraya Yazın veya Yapıştırın." type="text"
                                           onKeyUp={this.onKeyUp}/>
                                </div>
                            </div>

                        </Fragment>
                }

            </div>
        )
    }
}
;

export default connect(null, null, null, {withRef: true})(AddItemBlockComponent);