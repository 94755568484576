import React, {Component} from 'react';
import PlannedEducationsContainers from "../../containers/home/plannedEducationsContainers";
import NewsContainer from "../../containers/home/newsContainer";

class HomeAndFeatureCoursesNews extends Component {

    render() {
        const {featureCourses} = this.props;

        return (
            <div className="news-root">
                <div className="news-wrapper safe-area">
                    <NewsContainer news={this.props.news}/>
                    <PlannedEducationsContainers featureCourses={featureCourses}/>
                </div>
            </div>
        );
    }
}

HomeAndFeatureCoursesNews.propTypes = {};
HomeAndFeatureCoursesNews.defaultProps = {};

export default HomeAndFeatureCoursesNews;
