import React from 'react';
import {Link} from "react-router-dom";
import * as ROUTES from "../../constants/routePaths";

const LoginLeftContainer = () => (
    <div className="left-block">
        <div className="logo-item">
            <img src="/assets/img/unluTekstilLogo2.png" alt="unluTekstilLogo"/>
        </div>
        <div>
            <p>Sustainability portalına kayıtlı değil misin?<br/><strong>Hemen kayıt ol!</strong> </p>
            <Link to={ROUTES.SIGNUP} className="btn-1">KAYIT OL</Link>
        </div>

    </div>
);

export default LoginLeftContainer;