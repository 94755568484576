import React, {Component, Fragment} from 'react';
import PageService from '../pageServices/pageService';
import * as ROUTES from "../constants/routePaths";
import {connect} from "react-redux";
import PanelMenuContainer from "../containers/Panel/PanelMenuContainer";
import {matchPath, Route, Switch, withRouter} from "react-router-dom";
import PanelListContainer from "../containers/Panel/PanelListContainer";
import PanelUserListContainer from "../containers/Panel/PanelUserListContainer";
import PanelEducationsListContainer from "../containers/Panel/PanelEducationsListContainer";
import PanelFeedbackListContainer from "../containers/Panel/PanelFeedbackListContainer";
import PanelQuizListContainer from "../containers/Panel/PanelQuizListContainer";
import PanelDraftListContainer from "../containers/Panel/PanelDraftListContainer";
import PanelLocationListContainer from "../containers/Panel/PanelLocationListContainer";
import PanelSmartEducationsListContainer from "../containers/Panel/PanelSmartEducationsListContainer";
import PanelSurveyListContainer from "../containers/Panel/PanelSurveyListContainer";
import PanelReportContainer from "../containers/Panel/PanelReportContainer";
import DraftDetailPage from '../pages/draftDetailPage';
import PanelParticipantListContainer from "../containers/Panel/PanelParticipantListContainer";
import QuizResultByEducationIdComponent from "../components/report/QuizResultByEducationIdComponent";
import PanelTagListContainer from "../containers/Panel/PanelTagListContainer";

class PanelPage extends Component {
    webService;
    routes = {
        /*"Dashboard": {
            link: "",
            icon:"icon-dashboard"
        },*/
        "Dökümanlar": {
            link: ROUTES.PANEL_DOCUMENTS,
            component: PanelListContainer,
            icon: "icon-documents"
        },
        "Akıllı Dökümanlar": {
            link: ROUTES.PANEL_SMARTDOCUMENTS,
            component: PanelSmartEducationsListContainer,
            icon: "icon-smartdocuments"
        },
        "Kullanıcılar": {
            link: ROUTES.PANEL_USERS,
            component: PanelUserListContainer,
            icon: "icon-users"
        },
        "Eğitimler": {
            link: ROUTES.PANEL_EDUCATIONS,
            component: PanelEducationsListContainer,
            icon: "icon-calendar"
        },
        "Geri Bildirimler": {
            link: ROUTES.PANEL_FEEDBACK,
            component: PanelFeedbackListContainer,
            icon: "icon-feedbacks"
        },

        "Quizler": {
            link: ROUTES.PANEL_QUIZ,
            component: PanelQuizListContainer,
            icon: "icon-quizs"
        },
        "Etiketler": {
            link: ROUTES.PANEL_TAG_WITHOUTPARAM,
            component: PanelTagListContainer,
            icon: "icon-tag"
        },
        "Taslaklar": {
            link: ROUTES.PANEL_DRAFTS,
            component: PanelDraftListContainer,
            icon: "icon-drafts"
        },
        "Lokasyonlar": {
            link: ROUTES.PANEL_LOCATIONS,
            component: PanelLocationListContainer,
            icon: "icon-locations"
        },
        "Anketler": {
            link: ROUTES.PANEL_SURVEY,
            component: PanelSurveyListContainer,
            icon: "icon-surveys"
        },
        "Katılımcılar": {
            link: ROUTES.PANEL_PARTICIANTS,
            component: PanelParticipantListContainer,
            icon: "icon-users"
        },
        "Raporlar": {
            link: ROUTES.PANEL_REPORTS,
            component: PanelReportContainer,
            icon: "icon-quizs"
        }
    };

    constructor(props) {
        super(props);
        this.getPageData(props);
    }

    getPageData = (props) => {
        this.webService = new PageService(props.location.pathname);
        let urlParams = matchPath(props.location.pathname, {
            path: ROUTES.PANEL,
            exact: false,
            strict: false
        });
        this.webService.getData(props.dispatch, urlParams)
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
            this.getPageData(nextProps);
        }
        return true;
    }

    render() {
        return (
            <div className="mainRoot">
                <div className="panel-root">
                    {
                        this.props.roleAccess &&
                        <div className="panel-wrapper safe-area">
                            <div className="panel-container">
                                <PanelMenuContainer routes={this.routes} pathName={this.props.location.pathname}
                                                    roleAccess={this.props.roleAccess}/>

                                                    <Fragment>
                                                        <Switch>
                                                            {
                                                                this.props.roleAccess.map((roleItem, key) => {

                                                                        switch (roleItem.page.menuItem) {
                                                                            case "Dashboard":
                                                                                return null;
                                                                            case "Dökümanlar":
                                                                                return <Route key={key} exact path={ROUTES.PANEL_DOCUMENTS}
                                                                                              location={this.props.location}
                                                                                              component={PanelListContainer}/>;
                                                                            case "Akıllı Dökümanlar":
                                                                                return <Route key={key} exact path={ROUTES.PANEL_SMARTDOCUMENTS}
                                                                                              component={PanelSmartEducationsListContainer}/>;
                                                                            case "Kullanıcılar":
                                                                                return <Route key={key} exact path={ROUTES.PANEL_USERS}
                                                                                              component={PanelUserListContainer}/>;
                                                                            case "Eğitimler":
                                                                                return <Route key={key} exact path={ROUTES.PANEL_EDUCATIONS}
                                                                                              component={PanelEducationsListContainer}/>;
                                                                            case "Geri Bildirimler":
                                                                                return <Route key={key} exact path={ROUTES.PANEL_FEEDBACK}
                                                                                              component={PanelFeedbackListContainer}/>;
                                                                            case "Taslaklar":
                                                                                return <Route exact key={key} path={ROUTES.PANEL_DRAFTS}
                                                                                              component={PanelDraftListContainer}/>;
                                                                            {/*<Fragment key={key}>*/
                                                                            }

                                                                            {/*<Route key={key} exact path={ROUTES.DRAFT_DETAIL} component={DraftDetailPage}/>*/
                                                                            }
                                                                            {/*</Fragment>;*/
                                                                            }
                                                                            case "Lokasyonlar":
                                                                                return <Route key={key} exact path={ROUTES.PANEL_LOCATIONS}
                                                                                              component={PanelLocationListContainer}/>;
                                                                            case "Anketler":
                                                                                return <Route key={key} exact path={ROUTES.PANEL_SURVEY}
                                                                                              component={PanelSurveyListContainer}/>;
                                                                            case "Katılımcılar":
                                                                                return <Route key={key} exact path={ROUTES.PANEL_PARTICIANTS}
                                                                                              component={PanelParticipantListContainer}/>;
                                                                            case "Quizler":
                                                                                return <Route key={key} exact path={ROUTES.PANEL_QUIZ}
                                                                                              component={PanelQuizListContainer}/>;
                                                                            case "Etiketler":
                                                                                return <Route key={key} exact path={ROUTES.PANEL_TAG_WITHOUTPARAM} history={this.props.history}
                                                                                              component={PanelTagListContainer}/>;
                                                                            /*case "Raporlar":
                                                                                return <Switch>
                                                                                    <Route key={key} exact
                                                                                           path={ROUTES.PANEL_REPORTS_EDUCATION_RANKING}
                                                                                           component={QuizResultByEducationIdComponent}/>
                                                                                    <Route key={key} exact path={ROUTES.PANEL_REPORTS}
                                                                                           component={PanelReportContainer}/>
                                                                                </Switch>*/
                                                                            default:

                                                                                return null;
                                                                        }
                                                                    }
                                                                )
                                                            }
                                                            {/* <Route exact path={ROUTES.PANEL_USERS} component={PanelUserListContainer}/>
                                     <Route exact path={ROUTES.PANEL_EDUCATIONS} component={PanelEducationsListContainer}/>
                                     <Route exact path={ROUTES.PANEL_FEEDBACK} component={PanelFeedbackListContainer}/>
                                     <Route exact path={ROUTES.PANEL_QUIZ} component={PanelQuizListContainer}/>
                                     <Route exact path={ROUTES.PANEL_DRAFTS} component={PanelDraftListContainer}/>
                                     <Route exact path={ROUTES.PANEL_LOCATIONS} component={PanelLocationListContainer}/>
                                     <Route exact path={ROUTES.PANEL_SMARTDOCUMENTS} component={PanelSmartEducationsListContainer}/>*/}
                                                            {/*<Route exact path={ROUTES.PANEL_SURVEY} component={PanelSurveyListContainer}/>*/}
                                                        </Switch>

                                                        <Switch>
                                                            {
                                                                this.props.roleAccess.map((roleItem, key) => {
                                                                        switch (roleItem.page.menuItem) {
                                                                            case "Raporlar":
                                                                                return <Switch>
                                                                                    <Route key={key} exact
                                                                                           path={ROUTES.PANEL_REPORTS_EDUCATION_RANKING}
                                                                                           component={QuizResultByEducationIdComponent}/>
                                                                                    <Route key={key} exact path={ROUTES.PANEL_REPORTS}
                                                                                           component={PanelReportContainer}/>
                                                                                </Switch>
                                                                            default:

                                                                                return null;
                                                                        }
                                                                    }
                                                                )
                                                            }
                                                        </Switch>
                                                    </Fragment>


                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    roleAccess: state.sessionReducer.roleAccess
});

export default withRouter(connect(mapStateToProps)(PanelPage));
