import React, {Component} from 'react';
import {Chart} from 'react-google-charts';

class QuizResultDepartmentComponent extends Component {
    yearArr = [];

    constructor(props) {
        super(props);
        let startYear = 2019;
        while (startYear < new Date().getFullYear() + 1) {
            this.yearArr.push(startYear);
            startYear++;
        }
    }

    locationOnChange = (e) => {
        this.props.getData(this.props, e.target.value);
    };

    render() {
        let {chartData} = this.props;
        let chartArr = [];
        chartData && chartData.map((item) => {
            // if(item.correct_count>0){
            let chartItem = [item.department_name, (item.correct_count * 100) / item.question_count, "#3366cc"];
            chartArr.push(chartItem);
            // }
        });
        return (
            <div className="chart-block">
                <div className="table-head">
                    <div></div>
                    <div className="select-container">
                        <div className="select-block">
                            {/*<label>Yıl Seçiniz</label>*/}
                            <select onChange={this.locationOnChange} defaultValue={new Date().getFullYear()}>
                                {
                                    this.yearArr.map((yearItem, key) =>
                                        <option key={key} value={yearItem}>{yearItem}</option>
                                    )
                                }
                            </select>
                            <i className="icon-arr-bottom"></i>
                        </div>
                    </div>
                </div>

                <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="500px"
                    data={[
                        ["Departmanlar", "Doğru Oranı %", {role: "style"}],
                        ...chartArr
                    ]}
                />
            </div>
        )
    }
}
;

export default QuizResultDepartmentComponent;