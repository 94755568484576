import React, {Component, Fragment} from 'react'
import {connect} from "react-redux";
import {reduxForm, Field, initialize} from "redux-form";
import inputFieldComponent from "../common/formFields/inputFieldComponent";
import {
    createNewLocationAction,
    getPanelLocationAction,
    receivePanelLocation,
    updateTag
} from "../../actions/panelAction";
import LoaderModalComponent from "../common/loaderModalComponent";
import SuccessModalComponent from "../common/successModalComponent";
import queryString from "query-string";
import {getTagDetail} from "../../services/webService";
import {createNewTagAction, getTagDetailAction} from "../../actions";
import {PANEL_TAG_WITHOUTPARAM} from "../../constants/routePaths";

const validate = (values) => {
    let hasErrors = false;
    let errors = {};
    if (!values.tag_name || values.tag_name.trim() === "") {
        hasErrors = true;
        errors.tag_name = "Lokasyon Adını Doldurmanız Gerekmektedir.";
    }
    return hasErrors && errors;
};

class NewTagModalComponent extends Component {
    errorMessage;
    contentBlock = React.createRef();
    loaderIsShowing = false;
    successMesage = false;
    isDeleteClicked = false;
    firstTagNameValue;
    buttonDisable = true;
    buttonText;
    tag;
    tagId;

    constructor(props) {
        super(props);
        const urlParams = queryString.parse(this.props.location.search);
        if (urlParams.id === "0") {
            this.buttonText = "ETİKETİ YARAT";
        } else {
            this.buttonText = "ETİKETİ KAYDET";
        }
    }

    componentDidMount() {
        const urlParams = queryString.parse(this.props.location.search)
        this.tagId = urlParams.id;
        this.forceUpdate();
        if (urlParams.id !== "0") {
            this.props.dispatch(getTagDetailAction({id: urlParams.id}, (res, data) => {
                    if (res === true) {
                        this.firstTagNameValue = data.tag.tag_name;
                        this.props.dispatch(initialize('createNewTag', {
                            tag_name: data.tag.tag_name,
                            id: data.tag.id
                        }));
                    }
                }
            ))
        }
    }

    createNewTagFunc = (values) => {
        this.loaderIsShowing = true;
        this.forceUpdate();
        if (this.isDeleteClicked) {
            values.is_deleted = "1";
            values.tag_name = this.firstTagNameValue;
        } else {
            values.is_deleted = "0";
        }
        this.props.dispatch(createNewTagAction({...values}, (res, data) => {
            this.loaderIsShowing = false;
            if (res === true) {
                data.tag.isNewTag = this.props.isNewTag;
                data.tag.isDeleted = values.is_deleted;
                this.props.dispatch(updateTag(data.tag));
                this.successMesage = true;
                setTimeout(() => {
                    this.props.toggleModal(false, "isNewTag");
                }, 3000);
                this.forceUpdate();
                this.props.history.push(this.props.location.pathname);
            }
        }))
        // this.props.dispatch(createNewTagAction({...values}, (res, message) => {
        //     this.loaderIsShowing = false;
        //     if (!this.props.selectedTag) {
        //         this.props.dispatch(receivePanelTag({tags: [message]}, false))
        //     }
        //     if (res === true) {
        //         this.successMesage = true;
        //         let params = {
        //             activePage: 0,
        //             order: "created_date",
        //             order_type: "desc"
        //         };
        //         this.props.dispatch(getPanelTagAction(params, (res, isMore) => {
        //         }));


        //     } else {
        //         this.errorMessage = message;
        //         this.forceUpdate();
        //     }
        // }))
    };

    textControl = (e) => {
        if (e.target.value.length > 0) {
            this.buttonDisable = false;
            if (e.target.value.length > 2) {
                this.errorMessage = false;
                if (e.target.value.indexOf("&") !== -1) {
                    this.errorMessage = true;
                }
            } else {
                this.errorMessage = true;
            }
            this.forceUpdate();

        } else {
            this.errorMessage = true;
            this.buttonDisable = true;
            this.forceUpdate();
        }
    };

    render() {
        let {toggleModal, handleSubmit, formErrors, submitFailed, selectedLocation, isNewTag, tagId} = this.props;
        let errArr = formErrors ? Object.keys(formErrors) : {};
        return (
            <Fragment>
                {
                    this.successMesage !== true && <div className="lb-root" onClick={(e) => {
                        if (this.contentBlock.current.contains(e.target) === false) {
                            toggleModal(false, "isNewTag")
                            this.props.history.push(this.props.location.pathname);
                        }
                    }}>
                        <div className="lb-block">
                            <div ref={this.contentBlock} className="content-block add-part-block add-new-block">

                                <form className="content-item"
                                      onSubmit={handleSubmit(this.createNewTagFunc)}
                                >

                                    <div className="head-block">
                                        <h2>Etiket Oluştur</h2>
                                        <p>Etiket oluşturun.</p>
                                    </div>

                                    <i className="icon-close" onClick={() => {
                                        toggleModal(false, "isNewTag");
                                        this.props.history.push(this.props.location.pathname);
                                    }}></i>


                                    <div className="form-block">
                                        <div className="input-container">
                                            <div className="input-block">
                                                <Field
                                                    name="tag_name"
                                                    label="ETİKET ADI "
                                                    type="text"
                                                    component={inputFieldComponent}
                                                    onChange={this.textControl}
                                                    // example="Örn: Kayıp İğne Prosedürü"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bottom-btn-item">
                                        {
                                            this.tagId !== "0" &&
                                            <button type="submit" className="btn-red" onClick={() => {
                                                this.isDeleteClicked = true;
                                                this.forceUpdate();
                                            }}>ETİKETİ SİL</button>

                                        }
                                        <p>{this.errorMessage && "En az 3 harfli olmalı ve sembol içermemeli."}</p>
                                        <button type="submit" disabled={this.errorMessage}
                                                className="btn-green">{this.buttonText}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {
                            this.loaderIsShowing === true && <LoaderModalComponent/>
                        }
                    </div>
                }
                {
                    this.successMesage === true && this.isDeleteClicked === false && <SuccessModalComponent
                        message={this.tagId === "0" ? "Etiket oluşturuldu." : "Etiket güncellendi."}/>
                }
                {
                    this.successMesage === true && this.isDeleteClicked === true && <SuccessModalComponent
                        message={"Etiket silindi."}/>
                }
            </Fragment>
        )
    }
}
;

const mapStateToProps = (state) => ({
    formErrors: state.form.createNewSmartDocument ? state.form.createNewSmartDocument.syncErrors : {}
});

NewTagModalComponent = reduxForm({
    form: "createNewTag",
    validate
})(NewTagModalComponent);

export default connect(mapStateToProps)(NewTagModalComponent);