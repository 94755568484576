import React,{Component} from 'react';
import EducationEditDetailContainer from "../containers/educationEditDetail/educationEditDetailContainer";
import {getSmartDetailAction} from "../actions/smartDetailAction";
import {connect} from "react-redux";
import EducationEditDetailHeaderContainer from '../containers/educationEditDetail/educationEditDetailHeaderContainer'

class EditSmartDocumentPage extends Component{
    detailContent = React.createRef();
    detailContentComponent = React.createRef();
    constructor(props) {
        super(props);
        props.dispatch(getSmartDetailAction({id:props.match.params.educationLink}))
    }
    render(){
        return(
            <div className="mainRoot">
                <EducationEditDetailHeaderContainer isEditing={this.isEditing} history={this.props.history}
                                                    urlLink={this.props.match.params.educationLink} isSmartDocument={true}
                                                    detailContent={this.detailContent} detailContentComponent={this.detailContentComponent}
                />
                <div className="detail-content-root" ref={this.detailContent}>
                    <EducationEditDetailContainer urlLink={this.props.match.params.educationLink}
                                                  toggleIsEditing={this.toggleIsEditing} isSmartDocumentEdit={true}
                                                  ref={this.detailContentComponent} isSmartDocuments={true}
                                                  detailContent={this.detailContent} isEditing={this.isEditing}/>
                </div>
            </div>
        )
    }
};

export default connect()(EditSmartDocumentPage);