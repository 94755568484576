import React,{Component} from 'react';
import EducationEditDetailContainer from "../containers/educationEditDetail/educationEditDetailContainer";
import {connect} from "react-redux";
import * as ROUTES from "../constants/routePaths";
import {matchPath} from "react-router-dom";
import PageService from "../pageServices/pageService";
import EducationEditDetailHeaderContainer from '../containers/educationEditDetail/educationEditDetailHeaderContainer';

class DraftDetailPage extends Component{
    detailContent = React.createRef();
    detailContentComponent = React.createRef();
    isEditing = true;
    constructor(props) {
        super(props);
        this.webService = new PageService(props.location.pathname);
        let urlParams = matchPath(props.location.pathname, {
            path: ROUTES.DRAFT_DETAIL,
            exact: false,
            strict: false
        });
        this.webService.getData(props.dispatch, urlParams);
    }
    render(){
        return(
            <div className="mainRoot">
                <EducationEditDetailHeaderContainer isEditing={this.isEditing} history={this.props.history} isEmpty={true} isDraft={true}
                                                    urlLink={this.props.match.params.educationLink} user_role={this.props.user_role}
                                                    detailContent={this.detailContent} detailContentComponent={this.detailContentComponent}
                />
                <div className="detail-content-root" ref={this.detailContent}>
                    <EducationEditDetailContainer urlLink={this.props.match.params.educationLink}
                                                  toggleIsEditing={this.toggleIsEditing}
                                                  ref={this.detailContentComponent}
                                                  detailContent={this.detailContent} isEditing={this.isEditing}/>
                </div>
            </div>
        )
    }
};

const mapStateToProps = (state,ownProps) => {
    let urlArr = ownProps.match.params.educationLink.split("-");
    return({
        user_role:state.sessionReducer.role_id,
        detailDocument: state.educationDetailReducer[urlArr[urlArr.length - 1]] ? state.educationDetailReducer[urlArr[urlArr.length - 1]].document : []
    });
};

export default connect(mapStateToProps)(DraftDetailPage);