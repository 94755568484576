import React, {Component} from 'react';
import {connect} from "react-redux";
import PanelBreadCrumbComponent from "../../components/Panel/panelBreadCrumbComponent";
import PanelDraftItemComponent from "../../components/Panel/panelDraftItemComponent";
import {getDraftsAction} from "../../actions/panelAction";
import InfiniteScroll from 'react-infinite-scroller';

class PanelDraftListContainer extends Component {
    activePage = 0;
    order = "name";
    order_type = "asc";
    isMore = true;
    loadFunc = () => {
        let urlArr = this.props.match.params.subPage ? this.props.match.params.subPage.split("-") : ["0"];
        let params = {
            category_id: urlArr[urlArr.length - 1],
            order: this.order,
            order_type: this.order_type,
            activePage: this.activePage
        };
        this.props.dispatch(getDraftsAction(params, (res, isMore) => {
            if (res === true) {
                this.isMore = isMore;
                this.activePage++;
                this.forceUpdate();
            }
        }));
    };
    sortFunc = (sort_name) => {
        this.order_type = this.order_type === "asc" ? "desc":"asc";
        // this.order_type = this.order === sort_name ? "asc" : "desc";
        this.order = sort_name;
        this.activePage = 0;
        this.loadFunc();
    };

    render() {
        let {items} = this.props;
        return (
            <div className="panel-content-block">
                <PanelBreadCrumbComponent page="Taslaklar"/>
                <div className="table-root">
                    <div className="table-container">
                        <div className="table-head">
                            <div className="t-1 td-item" onClick={()=>{this.sortFunc("name")}}>
                                <p>Konu</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-2 td-item resp-type">
                                <p>Döküman Tipi</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-3 td-item" onClick={()=>{this.sortFunc("created_by")}}>
                                <p>Oluşturan Kişi</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-3 td-item resp-date" onClick={()=>{this.sortFunc("created_date")}}>
                                <p>Oluşturulma Tarihi</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                        </div>

                        <div className="table-block">
                            {
                                items &&
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={this.loadFunc}
                                    hasMore={this.isMore && items.length > 19}
                                    threshold={250}
                                >
                                    {
                                        items.map((item, key) =>
                                            <PanelDraftItemComponent key={key} {...item}/>
                                        )
                                    }
                                </InfiniteScroll>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    items: state.PanelReducer.drafts
});

export default connect(mapStateToProps)(PanelDraftListContainer);