import React, {Component, Fragment} from 'react';
import QuizResultByEducationItemComponent from "./QuizResultByEducationItemComponent";
import {educationAutoCompleteAction} from "../../actions/panelAction";
import Helper from "../../helper/index";
import EducationForWeekItemComponent from "./educationForWeekItemComponent";
import datePickerInputComponent from "../common/formFields/datePickerInputComponent";
import {connect} from "react-redux";

class EducationForWeekComponent extends Component {
    yearArr = [];
    weekArr = [];
    monthArr = [];
    activeYear = new Date().getFullYear();
    activeMonth;
    activeWeek;
    autoCompleteArr;
    activeEducationLocation;
    autoIsShowing = false;
    selectedEducation;
    sortName = "education_name";
    sortType = "desc";
    //isSet=false;
    inputItem = React.createRef();

    constructor(props) {
        super(props);
        let startYear = 2019;
        while (startYear < new Date().getFullYear() + 1) {
            this.yearArr.push(startYear);
            startYear++;
        }
        this.setWeekArr();
    }

    setWeekArr = () => {
        let dt = new Date();
        let isSet = false;
        var currentWeekDay = dt.getDay();
        var lessDays = currentWeekDay == 0 ? 6 : currentWeekDay - 1;
        var wkStart = new Date(new Date(dt).setDate(dt.getDate() - lessDays));
        var wkEnd = new Date(new Date(wkStart).setDate(wkStart.getDate() + 6));
        this.activeWeek = [wkStart, wkEnd];
        let prevWeek = 0;
        while (!isSet) {
            let data = {};
            data.startDate = new Date(wkStart).setDate(wkStart.getDate() - (7 * prevWeek));
            data.endDate = new Date(wkEnd).setDate(wkEnd.getDate() - (7 * prevWeek));
            // if(data.start)


            let date = {
                startDate: Helper.convertTimestampToMonthYearFormat(data.startDate),
                endDate: Helper.convertTimestampToMonthYearFormat(data.endDate),
                startDateTimestamp: data.startDate,
                endDateTimestamp: data.endDate,
            };
            // console.log(date);
            var controlDate = new Date(date.startDateTimestamp);
            // console.log(controlDate);
            var dtm = controlDate.getMonth();
            // console.log(controlDate.getMonth());
            if (this.activeYear === 2019) {
                if (controlDate.getMonth() < 5) {
                    isSet = true;
                }
            } else {
                if (controlDate.getFullYear() !== this.activeYear) {
                    isSet = true;
                }
            }

            this.weekArr.push(date);
            prevWeek++;
        }
        this.forceUpdate();
        //console.log("this.weekArr",this.weekArr);
    }
    yearOnChange = (e) => {
        this.activeYear = parseInt(e.target.value);
        this.forceUpdate();
        // console.log("11");
        if (this.selectedEducation) {
            this.props.getData(this.props, e.target.value, 0, this.activeMonth, this.sortName, this.sortType,this.activeWeek, this.activeEducationLocation);
        }
    };

    educationLocationOnChange = (e) => {
        this.activeEducationLocation = e.target.value;
        this.forceUpdate();
        this.props.getData(this.props, this.activeYear, 0, this.activeMonth, this.sortName, this.sortType,this.activeWeek, this.activeEducationLocation);


    };

    sortFunc = (sortName) => {
        // console.log("222");
        if (this.sortName === sortName) {
            this.sortType = this.sortType === "desc" ? "asc" : "desc";
        }
        this.sortName = sortName;
        this.props.getData(this.props, this.activeYear, 0, this.activeMonth, this.sortName, this.sortType, this.activeWeek, this.activeEducationLocation);
    };
    weekOnChange = (e) => {
        // console.log("333");

        this.activeWeek = e.target.value;
        this.forceUpdate();
        this.props.getData(this.props, this.activeYear, 0, this.activeMonth, this.sortName, this.sortType, this.activeWeek, this.activeEducationLocation);

    };

    render() {
        let {data, selectWeek, educationLocation} = this.props;
        return (
            <Fragment>
                <div className="table-head table-filter">
                    <div className="select-container">
                        <div className="select-block">
                            {/*<label>Yıl Seçiniz</label>*/}
                            <select onChange={this.yearOnChange} defaultValue={new Date().getFullYear()}>
                                {
                                    this.yearArr.map((yearItem, key) =>
                                        <option key={key} value={yearItem}>{yearItem}</option>
                                    )
                                }
                            </select>
                            <i className="icon-arr-bottom"></i>
                        </div>
                        <div className="select-block t-2">
                            {/*<label>Ay Seçiniz</label>*/}
                            <select onChange={this.weekOnChange} defaultValue={selectWeek}>
                                {
                                    this.weekArr.map((weekItem, key) =>
                                        // (this.activeYear !== new Date().getFullYear() || weekItem.value<=new Date().getMonth()+1) &&
                                        // <option key={key} value={weekItem.startDateTimestamp} >{`${weekItem.startDate} - ${weekItem.endDate}`}</option>
                                        <option key={key}
                                                value={[weekItem.startDate, weekItem.endDate]}>{`${weekItem.startDate} - ${weekItem.endDate}`}</option>
                                    )
                                }
                            </select>
                            <i className="icon-arr-bottom"></i>
                        </div>
                        <div className="select-block t-2">
                            {/*<label>Ay Seçiniz</label>*/}
                            <select onChange={this.educationLocationOnChange} defaultValue={0}>
                                <option value="0">Seçiniz</option>
                                {
                                    educationLocation && educationLocation.map((locationItem, key) =>
                                        <option key={key} value={locationItem.id}>{locationItem.name}</option>
                                    )
                                }
                            </select>
                            <i className="icon-arr-bottom"></i>
                        </div>
                    </div>

                </div>

                <div className="table-head">
                    <div className="t-1 td-item" onClick={() => {
                        this.sortFunc("education_name")
                    }}>
                        <p>Eğitim Adı</p>
                        <i className="icon-arr-bottom"></i>
                    </div>
                    <div className="t-2 td-item" onClick={() => {
                        this.sortFunc("user_name")
                    }}>
                        <p>Eğitimci</p>
                        <i className="icon-arr-bottom"></i>
                    </div>
                    <div className="t-2 td-item" onClick={() => {
                        this.sortFunc("session_start_date")
                    }}>
                        <p>Eğitim Tarihi</p>
                        <i className="icon-arr-bottom"></i>
                    </div>
                    <div className="t-2 td-item" onClick={() => {
                        this.sortFunc("diff")
                    }}>
                        <p>Eğitim Süresi</p>
                        <i className="icon-arr-bottom"></i>
                    </div>
                    <div className="t-2 td-item" onClick={() => {
                        this.sortFunc("location_name")
                    }}>
                        <p>Lokasyon</p>
                        <i className="icon-arr-bottom"></i>
                    </div>
                </div>
                <div className="table-block">
                    {
                        data && data.map((item, key) =>
                            <EducationForWeekItemComponent key={key} {...item}/>
                        )
                    }
                </div>
            </Fragment>
        )
    }
}
;
const mapStateToProps = (state) => ({
    data: state.PanelReducer.educationWeekly,
    selectWeek: state.PanelReducer.selectWeek,
    educationLocation: state.PanelReducer.locations
});

export default connect(mapStateToProps)(EducationForWeekComponent);