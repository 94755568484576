import React from 'react';
import Helper from '../../helper';

const PlannedEducationItemComponent = ({planned_date,education}) => (
    <div className="cal-item">
        <div className="icon-block">
            <i className="icon-calendar"></i>
        </div>
        <div className="text-item">
            <span className="date">{planned_date}</span>
            <p>{Helper.titleCase(education.name)}</p>
        </div>
    </div>
);

export default PlannedEducationItemComponent;