import React,{Component} from 'react';
import moment from 'moment';

class QuizDurationComponent extends Component{
    timer;
    quizDuration=0;
    componentDidMount(){
        if(this.props.sessionQuiz){
            this.quizDuration = moment().diff(moment(this.props.sessionQuiz.started_date),"seconds");
            this.forceUpdate();
            this.timer = setInterval(()=>{
                this.quizDuration++;
                this.forceUpdate();
            },1000)
        }
    }
    getDurationString = (duration) => {
        let seconds = moment().diff(moment(this.props.sessionQuiz.started_date),"seconds")%60;
        let minutes = moment().diff(moment(this.props.sessionQuiz.started_date),"minutes")%24;
        let hours = moment().diff(moment(this.props.sessionQuiz.started_date),"hours");
        return `${hours.toString().length<2?"0"+hours:hours}.${minutes.toString().length<2?"0"+minutes:minutes}.${seconds.toString().length<2?"0"+seconds:seconds}`

        /*const hour = Math.floor(duration/3600).toString().length<2?`0${Math.floor(duration/3600)}`:Math.floor(duration/3600);
        const minute = Math.floor((duration/60)%60).toString().length<2?`0${Math.floor((duration/60)%60)}`:Math.floor((duration/60)%60);
        const second = (duration%60).toString().length<2?`0${(duration%60)}`:(duration%60);
        return `${hour}.${minute}.${second}`*/
    };

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
    }

    render(){
        let {quizInfo,quizQuestionCount,quizTotalQuestionCount} = this.props;
        return(
            <div className="status-item">
                <div className="status-text-item">
                    <p>Quiz Süresi</p>
                    <p>{this.getDurationString(this.quizDuration)}</p>
                    {
                        quizQuestionCount && <p>Soru - {quizQuestionCount}/{quizTotalQuestionCount}</p>
                    }
                </div>
                <div className="status-bar">
                    <div style={{width: (100*this.quizDuration)/((quizInfo.duration?quizInfo.duration:75)*60)+"%"}} className="progress-item"></div>
                </div>
            </div>
        )
    }
};

export default QuizDurationComponent;