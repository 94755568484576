import React, {Component} from 'react';
import SignUpLeftContainer from "../containers/signUp/signUpLeftContainer";
import SignUpRightContainer from "../containers/signUp/signUpRightContainer";
class SignupPage extends Component {

    render() {
        return (
            <div className="login-root signup-root">
                <div className="login-wrapper safe-area">

                    <div className="login-con">
                        {/*<div className="back-btn">*/}
                            {/*<p>GERİ DÖN</p>*/}
                        {/*</div>*/}
                        <div className="login-block">
                            <SignUpLeftContainer/>
                            <SignUpRightContainer/>
                        </div>
                    </div>


                </div>
            </div>
        )
    }

}


export default SignupPage;