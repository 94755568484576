import React, {Component} from 'react';
import {connect} from "react-redux";
import {getQuizResultsAction} from "../actions/quizAction";
import QuizResultListContainer from "../containers/quizResult/quizResultListContainer";
import moment from 'moment';
import QuizResultStatusContainer from "../containers/quizResult/quizResultStatusContainer";
import ModalEducationInfoComponent from "./../components/modals/modalEducationInfoComponent";
import EducationInfoComponent from "../containers/quizResult/educationInfoComponent";
import QuizStartContainer from '../containers/quizResult/quizStartContainer';
import UploadDocumentContainer from "../containers/quizResult/uploadDocumentContainer";
import QuizParticipantsContainer from "../containers/quizResult/quizParticipantsContainer";

class QuizResultPage extends Component {
    quizResults;
    quizInfo;
    quizData;
    educationDetail;

    constructor(props) {
        super(props);
        this.getData();
    }

    getData = () => {
        this.props.dispatch(getQuizResultsAction({
            session_quiz: this.props.match.params.quizSessionId,
            session_id: this.props.match.params.sessionId
        }, (res) => {
            if (res) {
                this.quizResults = res.quizResultArray;
                this.quizInfo = res.quizSessionInfo;
                this.quizData = res.quizInfo;
                this.educationDetail = res.educationDetail;
                this.sessionDetail = res.sessionDetail;
                this.isQuizExist = res.isQuizExist;
                this.participants = res.participants;
                this.sessionDocuments = res.sessionDocuments;
                this.sessionToken = res.sessionToken;
                this.forceUpdate();
            }
        }))
    };

    render() {
        return (
            <div className="mainRoot">

                <div className="quiz-result-root">

                    <div className="safe-area quiz-web-container">
                        <div className="content-block">
                            <div className="content-item">
                                <EducationInfoComponent educationDetail={this.educationDetail} history={this.props.history}
                                                        participants={(this.quizInfo && this.quizResults && this.quizResults.length > 0) ? this.quizResults : this.participants}
                                                        isQuizResult={this.quizInfo && this.quizResults && this.quizResults.length > 0}
                                                        sessionDetail={this.sessionDetail}/>
                                {
                                    (this.quizInfo && this.quizResults && this.quizResults.length > 0) &&
                                    <QuizResultStatusContainer quizData={this.quizData} quizResults={this.quizResults}
                                                               quizInfo={this.quizInfo}/>
                                }
                                {
                                    (this.quizInfo && this.quizResults && this.quizResults.length > 0) &&
                                    <QuizResultListContainer quizResults={this.quizResults}/>
                                }
                                {
                                    this.isQuizExist !== false && (!this.quizResults || this.quizResults.length === 0) &&
                                    <QuizStartContainer history={this.props.history}
                                                        sessionDetail={this.sessionDetail}/>
                                }

                                <UploadDocumentContainer sessionDetail={this.sessionDetail} sessionToken={this.sessionToken}
                                                         sessionDocuments={this.sessionDocuments} getData={this.getData}
                                                         educationDetail={this.educationDetail}/>


                                <QuizParticipantsContainer participants={this.participants}/>
                            </div>


                        </div>

                    </div>


                </div>
            </div>
        )
    }
}


export default connect()(QuizResultPage);
