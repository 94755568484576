import React, {Component} from 'react';
import {createNewSessionQuizAction, getParticipantsQrCodesAction, startQuizAction} from "../../actions/quizAction";
import {QUIZPROCESS_WITHOUT_PARAMS, QUIZWEBTEACHER_WITHOUT_PARAMS} from "../../constants/routePaths";
import {connect} from "react-redux";

class QuizStartContainer extends Component {
    goToQuizWeb = () => {
        this.props.dispatch(createNewSessionQuizAction({session_id: this.props.sessionDetail.id}, (result) => {
            if (result !== null) {
                this.props.history.push(`${QUIZWEBTEACHER_WITHOUT_PARAMS}${this.props.sessionDetail.id}/${result.quiz_id}/${result.question_id}`);
            }
        }))
    };
    printQrCode = () => {
        this.props.dispatch(getParticipantsQrCodesAction({session_id: this.props.sessionDetail.id}, (result) => {
            if (result !== null) {
                window.open(window.location.origin + "/back/" + result.filePath, "_blank");
                // this.props.history.push(`${QUIZPROCESS_WITHOUT_PARAMS}${this.props.sessionDetail.id}/${result.quiz_id}`);
            }
        }));
    };
    startQuiz = () => {
        this.props.dispatch(startQuizAction({session_id: this.props.sessionDetail.id}, (result) => {
            if (result !== null) {
                this.props.history.push(`${QUIZPROCESS_WITHOUT_PARAMS}${this.props.sessionDetail.id}/${result.quiz_id}`);
            }
        }))
    };

    render() {
        return (
            <div className="quiz-block">
                <div className="left-text">
                    <p className="head-text">Quiz Başlat</p>
                    <p>Kullancı cevaplarına yönelik performans değerlendirmeli quiz’i çözmek için
                        her kullanıcıya özel QR kodu yazdırın.</p>
                    <p>Performans değerlendirmesi olmadan sadece quiz sorularını görmek ve tüm
                        katılımcılar ile birlikte çözmek için yazdırmadan başlayın.</p>
                </div>
                <div className="right-btn">
                    <span className="btn-green" onClick={this.printQrCode}>QR KODU YAZDIR</span>
                    <span className="btn-green" onClick={this.startQuiz}>QUİZİ BAŞLAT</span>
                    <span className="btn-green" onClick={this.goToQuizWeb}>QUİZİ ÇÖZ</span>
                </div>
            </div>
        )
    }
}
;

export default connect()(QuizStartContainer)