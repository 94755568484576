import React from 'react';
import {EDITEDUCATIONVERSIONDETAIL_WITHOUT_PARAM,EDITEDUCATIONDETAIL_WITHOUTPARAM} from '../../constants/routePaths';
import moment from 'moment';
import trLocale from 'moment/locale/tr';
import {Link} from "react-router-dom";
import Helper from "../../helper";

moment.locale('tr', trLocale);

const PanelVersionDocItemComponent = ({title, state, publish_date, document_type, name, created_date, is_active, id, education, user_name_surname, role_id}) => (
    education ?
        <Link to={role_id == 1 ? `${EDITEDUCATIONVERSIONDETAIL_WITHOUT_PARAM}${Helper.turkishToLower(education.name).split(" ").join("-").split("/").join("").split("?").join("")}-${education.id}`:`${EDITEDUCATIONDETAIL_WITHOUTPARAM}${education.name.split(" ").join("-").split("/").join("").split("?").join("")}-${education.id}`}
              className="table-item">
            <div className="td-item t-1">
                <i className="icon-doc"></i>
                <p>{education.name}</p>
            </div>
            <div className="td-item t-2">
                <p>{user_name_surname}</p>
            </div>
            <div className="td-item t-3 resp-date">
                <p>{moment(created_date).format('LL')}</p>
            </div>
        </Link>
        :
        <div></div>
);

export default PanelVersionDocItemComponent;