import React, {Component} from 'react';
import {connect} from "react-redux";
import PanelBreadCrumbComponent from "../../components/Panel/panelBreadCrumbComponent";
import PanelUserItemComponent from "../../components/Panel/panelUserItemComponent";
import InfiniteScroll from 'react-infinite-scroller';
import {getPanelUserAction} from "../../actions/panelAction";
import NewUserModalComponent from "../../components/modals/newUserModalComponent";
import queryString from 'query-string'

class PanelUserListContainer extends Component {
    activePage = 0;
    order = "name_surname";
    order_type = "asc";
    isMore = true;
    isNewUser = false;
    selectedUserId;
    constructor(props){
        super(props);
        const values = queryString.parse(this.props.location.search);
        if(values.user_id){
            this.selectedUserId = values.user_id;
            this.isNewUser = true;
        }
    }
    loadFunc = () => {
        let params = {
            activePage:this.activePage,
            order:this.order,
            order_type:this.order_type
        };
        this.props.dispatch(getPanelUserAction(params,(res,isMore)=>{
            if(res===true){
                this.isMore = isMore;
                this.activePage++;
                this.forceUpdate();
            }
        }));
    };
    sortFunc = (sort_name) => {
        this.order_type = this.order_type === "asc" ? "desc":"asc";
        // this.order_type = this.order === sort_name ? "asc":"desc";
        this.order = sort_name;
        this.activePage = 0;
        this.loadFunc();
    };
    toggleNewUserModal = (value,userId) => {
        this.isNewUser = value;
        this.selectedUserId = userId;
        this.forceUpdate();
    };
    getUsers = () => {
      this.activePage = 0;
      this.loadFunc();
    };
    render() {
        let {items} = this.props;
        return (
            <div className="panel-content-block">
                <PanelBreadCrumbComponent page="Kullanıcılar"/>
                <div className="table-root">
                    <div className="table-container">
                        <div className="table-head">
                            <div className="t-1 td-item" onClick={()=>{this.sortFunc("name_surname")}}>
                                <p>Kullanıcı Adı</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-2 td-item" onClick={()=>{this.sortFunc("role_id")}}>
                                <p>Rolü</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-2 td-item" onClick={()=>{this.sortFunc("is_active")}}>
                                <p>Durum</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-3 td-item resp-date" onClick={()=>{this.sortFunc("last_edited_date")}}>
                                <p>Son Düzenleme Tarihi</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                        </div>
                        {
                            items &&
                            <InfiniteScroll
                                pageStart={0}
                                loadMore={this.loadFunc}
                                hasMore={this.isMore && items.length>19}
                                threshold={250}
                                // loader={<div className="loader" key={0}>Loading ...</div>}
                            >
                                <div className="table-block">

                                    {
                                        items && items.map((item, key) =>
                                            <PanelUserItemComponent toggleNewUserModal={this.toggleNewUserModal} key={key} {...item}/>
                                        )
                                    }
                                </div>
                            </InfiniteScroll>
                        }
                    </div>
                </div>
                {
                    this.isNewUser === true &&
                    <NewUserModalComponent getUsers={this.getUsers} toggleModal={this.toggleNewUserModal} selectedUserId={this.selectedUserId}/>
                }
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    items: state.PanelReducer.users
});

export default connect(mapStateToProps)(PanelUserListContainer);