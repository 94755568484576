import React, {Component} from 'react';
import ModalEducationInfoComponent from "./modalEducationInfoComponent";
import {getFeedbackDetailAction, responseFeedbackAction} from "../../actions/panelAction";
import {connect} from "react-redux";

class FeedbackDetailModalComponent extends Component {
    contentBlock = React.createRef();
    feedBackResponse;
    inputRef = React.createRef();
    constructor(props){
        super(props);
        props.dispatch(getFeedbackDetailAction({feedback_id:props.detailItem.id},(res)=>{
            if(res){
                this.feedBackResponse = res;
                this.forceUpdate();
            }
        }))
    };
    responseFeedBack = (e) => {
        let {detailItem} = this.props;
        if(this.inputRef.current.value.trim().length>0){
            this.props.dispatch(responseFeedbackAction({feedback_id:detailItem.id,response:this.inputRef.current.value},(res)=>{
                if(res){
                    this.feedBackResponse = res;
                    this.inputRef.current.value = "";
                    this.forceUpdate();
                }
            }))
        }
    };
    render() {
        let {toggleDetail,detailItem} = this.props;
        return (
            <div>
                <div className="lb-root" onClick={(e) => {
                    if (this.contentBlock.current.contains(e.target) === false) {
                        toggleDetail(null, false, )
                    }
                }}>
                    <div className="lb-block">
                        <div className="content-block" ref={this.contentBlock}>
                            <div className="head-block">
                                <h2>{detailItem.subject}</h2>
                                <div className="info-item">
                                    <span className="label-tag green">#{detailItem.educationCategory.tag}</span>
                                    {
                                        detailItem.duration &&
                                        <div className="time-block">
                                            <span><i className="icon-clock"></i>{detailItem.duration}</span>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="content-item">
                                <i className="icon-close" onClick={()=>{
                                    toggleDetail(null,false);
                                }}></i>
                                <div className="chat-root">
                                    <div className="chat-container">
                                        <div className="chat-block">
                                            <div className="chat-item">
                                                <span className="chatbg"></span>
                                                <div className="name">
                                                    <p>{detailItem.user_name}</p>
                                                </div>
                                                <div className="message-item">
                                                    <p>{detailItem.feedback}</p>
                                                </div>
                                                <div className="date-item">
                                                    <p>{detailItem.feedback_date}</p>
                                                </div>
                                            </div>

                                        </div>
                                        {
                                            this.feedBackResponse && this.feedBackResponse.map((responnseItem,key)=>
                                                <div key={key} className="chat-block moderator">
                                                    <div className="chat-item">
                                                        <span className="chatbg"></span>
                                                        <div className="message-item">
                                                            <p>{responnseItem.response}</p>
                                                        </div>
                                                        <div className="date-item">
                                                            <p>{responnseItem.response_date}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>


                                    <div className="chat-text-block">

                                        <textarea ref={this.inputRef} onKeyDown={(e)=>{
                                            if(e.keyCode === 13){
                                                this.responseFeedBack();
                                            }
                                        }} name="" id="" cols="30" rows="10"></textarea>

                                        <div className="btn-item" onClick={this.responseFeedBack}>
                                            <span className="btn-green">GÖNDER</span>
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

                {/*anket detay*/}
                {/*<div className="lb-root">
                    <div className="lb-block">
                        <div className="content-block add-part-block">
                            <div className="content-item">
                                <div className="head-block">
                                    <h2>Anket</h2>
                                    <p>Anket sonuçlarını görüntüleyin ve anketi kapatın.</p>
                                </div>
                                <i className="icon-close"></i>
                                <div className="survey-block">
                                    <div className="text-item">
                                        <h3>ANKET İÇERİĞİ</h3>
                                        <p>Nullam lacus sunt! Adipisicing fames porro, nunc mollis? Laborum! Mollit
                                            purus
                                            justo. Potro malesuafda illcuem paosucexre rirdiculus primis!</p>
                                    </div>


                                    <div className="status-block">
                                        <h3>ANKET SONUÇLARI</h3>
                                        <div className="status-item">
                                            <p>Evet</p>
                                            <p>%62</p>
                                            <div className="bar" style={{"width": "62%"}}></div>
                                        </div>
                                        <div className="status-item">
                                            <p>Hayır</p>
                                            <p>%34</p>
                                            <div className="bar" style={{"width": "34%"}}></div>
                                        </div>
                                        <div className="status-item">
                                            <p>Kararsızım</p>
                                            <p>%4</p>
                                            <div className="bar" style={{"width": "4%"}}></div>
                                        </div>
                                        <p className="count">Toplam 971 Oy</p>
                                    </div>

                                </div>
                                <div className="bottom-btn-item">
                                    <span className="btn-end">ANKETİ SONLANDIR</span>
                                    <span className="btn-back">GERİ DÖN</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/}

                {/*anket yarat - daha bitmedi*/}
                {/*<div className="lb-root">
                    <div className="lb-block">
                        <div className="content-block add-part-block">
                            <div className="content-item">
                                <div className="head-block">
                                    <h2>Anket Yarat</h2>
                                    <p>Yeni bir anket yaratın ve kullanıcıların hakkında ne düşündüğünü öğrenin.</p>
                                </div>
                                <i className="icon-close"></i>
                                <div className="survey-block">


                                </div>
                                <div className="bottom-btn-item">
                                    <p className="error">Lütfen en az 10 karakter Anket İçeriği giriniz.</p>
                                    <span className="btn-green">EĞİTİMİ YARAT</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/}

                {/*quiz oluştur*/}
                {/*<div className="lb-root">
                    <div className="lb-block">
                        <div className="content-block add-part-block">
                            <div className="content-item">
                                <div className="head-block">
                                    <h2>Quiz Oluştur</h2>
                                    <p>Bir eğitime bağlı quiz oluşturun</p>
                                    <div className="step-block">
                                        <span className="active">1</span>
                                        <span>2</span>
                                    </div>
                                </div>
                                <i className="icon-close"></i>
                               <div className="form-block">
                                   <p>Buraya form elementi gelecek</p>
                               </div>
                                <div className="bottom-btn-item">
                                    <p className="error">Seçtiğiniz döküman için daha önce bir quiz yaratılmış. Farklı bir döküman seçin.</p>
                                    <span className="btn-green">DEVAM ET</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/}


                {/*soru ekle*/}
                {/*<div className="lb-root">
                    <div className="lb-block">
                        <div className="content-block add-part-block add-quiz-root">
                            <div className="content-item">
                                <div className="head-block">
                                    <h2>Soru Ekle</h2>
                                    <p>Oluşturduğunuz quiz için soru ekleyin veya düzenleyin.</p>
                                    <div className="step-block">
                                        <span >1</span>
                                        <span className="active">2</span>
                                    </div>
                                </div>
                                <i className="icon-close"></i>
                                <div className="form-block">
                                    <div className="add-quiz-container">
                                        <div className="add-quiz-block">
                                            <div className="question-text">
                                                <span>SORU 1</span>
                                                <p>Hangisi Makineci - Dikim elemanının görev tanımına girer?</p>
                                                <div className="add-img">
                                                    <input type="file"/>
                                                    <span>GÖRSEL EKLE</span>
                                                </div>
                                            </div>
                                            <div className="options-block">
                                                <div className="option-item ">
                                                    <span>A</span>
                                                    <div className="question-content">
                                                        <p>sdgsdfg sdfg sdfg</p>
                                                        <div className="add-img">
                                                            <input type="file"/>
                                                            <span>GÖRSEL EKLE</span>
                                                        </div>
                                                    </div>
                                                    <input type="radio"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="new-question-root">
                                            <div className="top-new-btn">
                                                <div className="new-button">
                                                    <i className="icon-plus-circle"></i>
                                                    <span>YENİ SORU</span>
                                                </div>
                                            </div>

                                            <div className="question-count-list">
                                                <ul>
                                                    <li>1. Soru <i className="icon-close-2"></i></li>
                                                    <li>2. Soru <i className="icon-close-2"></i></li>
                                                    <li className="active">3. Soru <i className="icon-close-2"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="bottom-btn-item">
                                    <p className="error">Lütfen doğru şık seçiniz</p>
                                    <span className="btn-green">DEVAM ET</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/}


            </div>

        );
    }
}

export default connect()(FeedbackDetailModalComponent);