import {RECEIVE_SMART_DOCUMENT_DETAIL} from '../constants/actionTypes';

const initialState = {};

const SmartDocumentDetailReducer = (state=initialState,action) => {
    switch (action.type){
        case RECEIVE_SMART_DOCUMENT_DETAIL:
            return{
                ...state,
                [action.id]:action.payload
            };
        default:
            return state;
    }
};

export default SmartDocumentDetailReducer;