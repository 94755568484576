import React, {Component} from 'react';
import {connect} from "react-redux";
import EducationMenuItemComponent from "../../components/educationDetail/educationMenuItemComponent";

class EducationEditMenuComponent extends Component {
    render() {
        let {detailDocument, detailContent, detailDocumentVersion} = this.props;
        return (
            <div className="menu-block">
                <ul className="sticky">
                    {
                        detailDocumentVersion ?
                            detailDocumentVersion.map((item, key) =>
                                <EducationMenuItemComponent index={key} key={key} {...item}/>
                            )
                            :
                            (detailDocument && detailDocument.map((item, key) =>
                                <EducationMenuItemComponent index={key} key={key} {...item}/>
                            ))
                    }
                </ul>
            </div>
        )
    }
}
;

const mapStateToProps = (state, ownProps) => {
    let urlArr = ownProps.urlLink.split("-");
    let key = ownProps.isSmartDocuments ? "smartDocumentDetailReducer" : "educationDetailReducer";
    return ({
        detailDocument: state[key][urlArr[urlArr.length - 1]] ? state[key][urlArr[urlArr.length - 1]].document : null
    });
};

export default connect(mapStateToProps)(EducationEditMenuComponent);