import React, {Component, Fragment} from 'react'
import {connect} from "react-redux";
import {reduxForm, Field, initialize} from "redux-form";
import inputFieldComponent from "../common/formFields/inputFieldComponent";
import {createNewLocationAction, getPanelLocationAction, receivePanelLocation} from "../../actions/panelAction";
import LoaderModalComponent from "../common/loaderModalComponent";
import SuccessModalComponent from "../common/successModalComponent";

const validate = (values) => {
    let hasErrors = false;
    let errors = {};
    if (!values.name || values.name.trim() === "") {
        hasErrors = true;
        errors.name = "Lokasyon Adını Doldurmanız Gerekmektedir.";
    }
    return hasErrors && errors;
};

class NewLocationModalComponent extends Component {
    errorMessage;
    contentBlock = React.createRef();
    loaderIsShowing = false;
    successMesage = false;

    componentDidMount() {
        if (this.props.selectedLocation) {
            this.props.dispatch(initialize('createNewLocation', {
                name: this.props.selectedLocation.name,
                id: this.props.selectedLocation.id
            }));
        }
    }

    createNewLocation = (values) => {
        this.loaderIsShowing = true;
        this.forceUpdate();
        this.props.dispatch(createNewLocationAction({...values}, (res, message) => {
            this.loaderIsShowing = false;
            if (!this.props.selectedLocation) {
                this.props.dispatch(receivePanelLocation({locations: [message]}, false))
            }
            if (res === true) {
                this.successMesage = true;
                let params = {
                    activePage: 0,
                    order: "created_date",
                    order_type: "desc"
                };
                this.props.dispatch(getPanelLocationAction(params, (res, isMore) => {
                }));
                setTimeout(() => {
                    this.props.toggleModal(false, "isNewLocation");
                }, 3000);
                this.errorMessage = null;
                this.forceUpdate();
            } else {
                this.errorMessage = message;
                this.forceUpdate();
            }
        }))
    };

    render() {
        let {toggleModal, handleSubmit, formErrors, submitFailed, selectedLocation} = this.props;
        let errArr = formErrors ? Object.keys(formErrors) : {};
        return (
            <Fragment>
                {
                    this.successMesage !== true && <div className="lb-root" onClick={(e) => {
                        if (this.contentBlock.current.contains(e.target) === false) {
                            toggleModal(false, "isNewLocation")
                        }
                    }}>
                        <div className="lb-block">
                            <div ref={this.contentBlock} className="content-block add-part-block add-new-block">

                                <form className="content-item" onSubmit={handleSubmit(this.createNewLocation)}>

                                    <div className="head-block">
                                        <h2>Lokasyon Oluştur</h2>
                                        <p>Lokasyon oluşturun.</p>
                                    </div>

                                    <i className="icon-close" onClick={() => {
                                        toggleModal(false, "isNewLocation")
                                    }}></i>


                                    <div className="form-block">
                                        <div className="input-container">
                                            <div className="input-block">
                                                <Field
                                                    name="name"
                                                    label="LOKASYON ADI "
                                                    type="text"
                                                    component={inputFieldComponent}
                                                    // example="Örn: Kayıp İğne Prosedürü"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bottom-btn-item">
                                        <p>{(submitFailed === true && errArr.length > 0) ? formErrors[errArr[0]] : (this.errorMessage ? this.errorMessage : "") }</p>
                                        <button type="submit" className="btn-green">LOKASYON YARAT</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {
                            this.loaderIsShowing === true && <LoaderModalComponent/>
                        }
                    </div>
                }
                {
                    this.successMesage === true && <SuccessModalComponent
                        message={this.props.selectedLocation ? "Lokasyon güncellendi." : "Lokasyon oluşturuldu."}/>
                }
            </Fragment>
        )
    }
}
;

const mapStateToProps = (state) => ({
    formErrors: state.form.createNewSmartDocument ? state.form.createNewSmartDocument.syncErrors : {}
});

NewLocationModalComponent = reduxForm({
    form: "createNewLocation",
    validate
})(NewLocationModalComponent);

export default connect(mapStateToProps)(NewLocationModalComponent);