import React, {Component, Fragment} from 'react';
import ModalEducationInfoComponent from "./modalEducationInfoComponent";
import {
    getSessionDocumentsAction, saveSessionDocumentsAction,
    uploadSessionDocumentAction
} from "../../actions/educationDetailAction";
import {connect} from "react-redux";
import Helper from '../../helper';
import moment from 'moment';
import LoaderModalComponent from "../../components/common/loaderModalComponent";

class SessionAddDocumentModalComponent extends Component {
    contentBlock = React.createRef();
    documentArr = [];
    isLoader = false;
    extensionErr;
    constructor(props) {
        super(props);
        this.getDocuments()
    }

    uploadDocument = (e) => {
        this.isLoader = true;
        this.extensionErr = false;
        this.forceUpdate();
        if (e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "application/pdf") {
            this.props.dispatch(uploadSessionDocumentAction(e.target.files[0], (res) => {
                this.isLoader = false;
                if (res) {
                    let file = {
                        file_name: Helper.turkishToLower(this.props.detailInfo.name).split(" ").join("_") + "_" + moment().format('DD.MM.YYYY_HH.mm.ss') + ".png",
                        path: res,
                        session_id: this.props.sessionId
                    };
                    this.documentArr.push(file);
                    this.forceUpdate();
                } else {
                    // this.extensionErr = true;
                    this.forceUpdate();
                }
            }));
        }else{
            this.extensionErr = true;
            this.isLoader = false;
            this.forceUpdate();
        }

    };
    deleteFromIndex = (key) => {
        this.documentArr.splice(key, 1);
        this.forceUpdate();
    };
    saveDocuments = () => {
        this.props.dispatch(saveSessionDocumentsAction({
            session_id: this.props.sessionId,
            documents: this.documentArr
        }, (res) => {
            if (res === true) {
                this.props.openSessionDocs(false, null);
            }
        }))
    };
    getDocuments = () => {
        this.props.dispatch(getSessionDocumentsAction({session_id: this.props.sessionId}, (res, data) => {
            if (res === true) {
                this.documentArr = data;
                this.forceUpdate();
            }
        }))
    };

    render() {
        let {urlLink, docsType, openSessionDocs, isSessionExist, sessionToken} = this.props;
        let link = sessionToken ? `${window.location.origin}/import-document/${this.props.sessionId}/${sessionToken.token}` : `${window.location.origin}/import-document/${this.props.sessionId}`;
        return (
            <Fragment>
                <div className="lb-root" onClick={(e) => {
                    if (this.contentBlock.current.contains(e.target) === false) {
                        openSessionDocs(false, null);
                    }
                }}>
                    <div className="lb-block">
                        <div ref={this.contentBlock} className="content-block education-detail-lb">
                            {
                                urlLink && <ModalEducationInfoComponent urlLink={urlLink}/>
                            }
                            <div className="content-item">
                                <i className="icon-close" onClick={() => {
                                    openSessionDocs(false, null)
                                }}></i>
                                <div className="download-doc-root">
                                    {
                                        docsType === "computer" ?
                                            <div className="doc-head-item">
                                                <div>
                                                    <p className="head-text">Belge Yükle</p>
                                                    <p>Bu eğitim seansı ile ilgili dökümanları sisteme yükleyin.</p>
                                                </div>
                                            </div>
                                            :
                                            <div className="doc-head-item">
                                                <img
                                                    src={`https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=${link}&choe=UTF-8`}/>
                                                {/*<img src="/assets/img/common/qr-gorsel.png" alt="qr"/>*/}
                                                <div>
                                                    <p className="head-text">Telefondan Belge Yükle</p>
                                                    <p>Yandaki QR Kodu telefon kameranıza okutup, açılan arayüzden
                                                        ilgili
                                                        dökümanları ekleyebilirsiniz.</p>
                                                    <p>Güncelle butonuna basarak yüklemesi biten dosyaları
                                                        görebilirsiniz.</p>
                                                </div>
                                            </div>
                                    }


                                    <div className="down-doc-btn">
                                        <p>Belgeler</p>
                                        {
                                            docsType === "computer" ?
                                                <div className="new-button">
                                                    <i className="icon-plus-circle"></i>
                                                    <input type="file" onChange={this.uploadDocument}/>
                                                    <span>BELGE EKLE</span>
                                                </div>
                                                :
                                                <div className="new-button" onClick={this.getDocuments}>
                                                    <i className="icon-update"></i>
                                                    <span>GÜNCELLE</span>
                                                </div>
                                        }
                                    </div>
                                    <div className="doc-list">
                                        <ul>
                                            {
                                                this.documentArr.map((document, key) =>
                                                    <li key={key}>
                                                        <i className="icon-doc"></i>
                                                        {document.file_name}
                                                        <i className="icon-close-2" onClick={() => {
                                                            this.deleteFromIndex(key)
                                                        }}></i>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                    {
                                        this.extensionErr === true &&
                                        <div className="bottom-btn-item">
                                            <p className="error">Lütfen resim dosyası veya PDF dosyası ekleyiniz.</p>
                                        </div>
                                    }
                                    <div className="btn-item">
                                    <span className="btn-2" onClick={() => {
                                        openSessionDocs(false, null)
                                    }}>GERİ DÖN</span>
                                        <span className="btn-green" onClick={this.saveDocuments}>BELGELERİ YÜKLE</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {
                    this.isLoader === true && <LoaderModalComponent/>
                }
            </Fragment>
        )
    }
}

export default connect()(SessionAddDocumentModalComponent);