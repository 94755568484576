import React, {Component,Fragment} from 'react';
import QuizResultByEducationItemComponent from "./QuizResultByEducationItemComponent";
import {educationAutoCompleteAction} from "../../actions/panelAction";
import Helper from "../../helper/index";
import EducationForWeekItemComponent from "./educationForWeekItemComponent";
import datePickerInputComponent from "../common/formFields/datePickerInputComponent";
import PageService from "../../pageServices/pageService";
import {
    PANEL_REPORTS_EDUCATION_RANKING, PANEL_REPORTS_WITOUTPARAM,
    QUIZPROCESS_WITHOUT_PARAMS
} from "../../constants/routePaths";
import {connect} from "react-redux";
import {Link, matchPath} from "react-router-dom";
import PanelBreadCrumbComponent from "../Panel/panelBreadCrumbComponent";


class QuizResultByEducationIdComponent extends Component {
    yearArr=[];
    weekArr=[];
    monthArr=[];
    activeYear=new Date().getFullYear();
    activeMonth;
    activeWeek;
    autoCompleteArr;
    autoIsShowing = false;
    selectedEducation;
    sortName="correct_count";
    sortType="desc";
    //isSet=false;
    inputItem = React.createRef();
    constructor(props){
        super(props);
       this.getData(props,this.sortName);

    }
    getData=(props,sortName)=>{
        this.pageService = new PageService(props.location.pathname);
        let urlParams = matchPath(props.location.pathname, {
            path: PANEL_REPORTS_EDUCATION_RANKING,
            exact: false,
            strict: false
        });
        urlParams.sort={
            sortName:sortName,
            sortType:this.sortType
        }

        this.pageService.getData(props.dispatch,urlParams).then((res) => {

        });
    };
    sortFunc = (sortName) => {
        // console.log("222");
        if(this.sortName === sortName){
            this.sortType = this.sortType === "desc" ? "asc":"desc";
        }
        this.sortName = sortName;
        this.getData(this.props,this.sortName)
    };
    render() {
        let {data} = this.props;
        return (
            <Fragment>
                <div className="panel-content-block">
                    <PanelBreadCrumbComponent page="Eğitim Başarı Detayı"/>
                    <Link to={PANEL_REPORTS_WITOUTPARAM + "/haftalik-egitim-raporu"} className="btn-orange t-2">Geri Dön</Link>
                    <div className="table-root">
                        <div className="table-container">
                            <div className="table-head">
                                <div className="t-1 td-item" onClick={() => {
                                    this.sortFunc("name")
                                }}>
                                    <p>Kullanıcı Adı</p>
                                    <i className="icon-arr-bottom"></i>
                                </div>
                                <div className="t-2 td-item" onClick={() => {
                                    this.sortFunc("correct_count")
                                }}>
                                    <p>Doğru Cevap Sayısı</p>
                                    <i className="icon-arr-bottom"></i>
                                </div>
                            </div>
                            <div className="table-block">
                                {
                                    data && data.map((item, key) =>
                                        <QuizResultByEducationItemComponent key={key} {...item}/>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>


            </Fragment>
        )
    }
}
;
const mapStateToProps = (state) => ({
    data: state.PanelReducer.educationRanking
});
export default connect(mapStateToProps)(QuizResultByEducationIdComponent);