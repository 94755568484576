import React, {Fragment, Component} from 'react';
import {Field, reduxForm} from "redux-form";
import InputFieldComponent from '../../components/common/formFields/inputFieldComponent';
import AutoCompleteFieldComponent from '../../components/common/formFields/autoCompleteFieldComponent';
import TextAreaFieldComponent from '../../components/common/formFields/textAreaFieldComponent';
import {signUpAction} from "../../actions/index";
import {departmentAutoCompleteAction} from "../../actions/autoCompleteAction";

import * as ROUTES from "../../constants/routePaths";
import {Link} from "react-router-dom";

const validate = (values) => {
    let hasErrors = false;
    let errors = {};
    if (!values.name_surname) {
        hasErrors = true;
        errors.name_surname = 'Required'
    }
    if (!values.email) {
        hasErrors = true;
        errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        hasErrors = true;
        errors.email = 'Invalid email address'
    }
    return hasErrors && errors;
};

class SignUpRightContainer extends Component {
    errorIsExist = false;
    successIsExist = false;
    selectedDepartment;
    submitForm = (values) => {
        this.props.dispatch(signUpAction({...values,department_id:this.selectedDepartment.id}, (res) => {
            if (res === true) {
                this.successIsExist = true;
            } else {
                this.errorIsExist = true;
            }
            this.forceUpdate();
        }));
    };
    departmentSelected = (item) => {
        this.selectedDepartment = item;
    };
    render() {
        let {handleSubmit} = this.props;
        return (
            <Fragment>
                {
                    (this.errorIsExist === false && this.successIsExist === false) &&
                    <div className="right-block">
                        <div className="text-block">
                            <h2>Kayıt Ol</h2>
                            <p>Sisteme giriş izni için lütfen bilgilerinizi bırakın.</p>
                        </div>

                        <form onSubmit={handleSubmit(this.submitForm)}>
                            <div className="input-block">
                                <Field
                                    name="name_surname"
                                    type="text"
                                    label="AD SOYAD"
                                    placeholder="Ahmet"
                                    component={InputFieldComponent}
                                />
                                <Field
                                    name="departman"
                                    type="text"
                                    placeholder="Departman"
                                    label="Departmant"
                                    selectedFunc={this.departmentSelected}
                                    autoCompleteAction={departmentAutoCompleteAction}
                                    component={AutoCompleteFieldComponent}
                                />
                                <Field
                                    name="email"
                                    type="text"
                                    label="E-Posta Adresi giriniz.."
                                    placeholder="E-Posta Adresi giriniz.."
                                    component={InputFieldComponent}
                                />
                                <Field
                                    name="reason_to_member"
                                    placeholder=""
                                    label="KAYIT OLMA SEBEBİNİZ"
                                    component={TextAreaFieldComponent}
                                />
                            </div>
                            <div className="input-bottom">
                                <input type="submit" value="TALEBİ GÖNDER" className="btn-green"/>
                            </div>
                        </form>
                        <div className="resp-bottom-btn-block">
                            <div className="resp-bottom-btn">
                                <div>
                                    <p>Zaten bir hesabınız var mı?<br/><strong>Hemen giriş yap!</strong></p>
                                    <Link to={ROUTES.LOGIN} className="btn-1">GİRİŞ YAP</Link>
                                </div>
                            </div>
                        </div>

                    </div>
                }
                {
                    this.successIsExist === true &&
                    <div className="right-block">
                        <div className="img-block">
                            <img src="/assets/img/common/talep-img.png" alt=""/>
                        </div>
                        <div className="text-item">
                            <h3>Talebiniz Alındı.</h3>
                            <p>Yöneticilerimiz sisteme giriş isteğinizi değerlendirip en kısa sürede geri dönüş
                                yapacaktır.</p>
                            <p>Sisteme giriş talebiniz onaylandığında mail ile bildirim alacaksınız.</p>
                        </div>
                    </div>
                }
                {
                    this.errorIsExist === true &&
                    <div className="right-block">
                        <div className="img-block">
                            <img src="/assets/img/common/talep-img.png" alt=""/>
                        </div>
                        <div className="text-item">
                            <h3>Talebiniz Alınamadı..</h3>
                            <p>Talebiniz alınırken bir hata ile karşılaşıldı. Lütfen yetkili kişi ile iletişime geçiniz.</p>
                        </div>
                    </div>
                }
            </Fragment>
        )
    }
}

export default reduxForm({
    form: 'signUp',
    validate
})(SignUpRightContainer);