import React,{Component} from 'react';
import PageService from "../pageServices/pageService";
import {matchPath} from "react-router-dom";
import * as ROUTES from "../constants/routePaths";
import EducationEditDetailHeaderContainer from "../containers/educationEditDetail/educationEditDetailHeaderContainer";
import EducationEditDetailContainer from "../containers/educationEditDetail/educationEditDetailContainer";
import {connect} from "react-redux";
import {getVersionEducationDetailAction} from "../actions/educationDetailAction";

class EditEducationsDetailPage extends Component{
    detailContent = React.createRef();
    detailContentComponent = React.createRef();
    constructor(props) {
        super(props);
        this.webService = new PageService(props.location.pathname);
        let urlParams = matchPath(props.location.pathname, {
            path: ROUTES.EDITEDUCATIONDETAIL,
            exact: false,
            strict: false
        });
        this.webService.getData(props.dispatch, urlParams);
        let urlArr = props.match.params.educationLink.split("-");
        this.props.dispatch(getVersionEducationDetailAction({link:urlArr[urlArr.length-1]},(res)=>{
            this.versionData = res;
            this.forceUpdate();
        }))
    }
    render(){
        let detailDocument = this.props.isEditExist && this.props.user_role == "3" ? (this.versionData?this.versionData.document:[]) : this.props.detailDocument;
        return(
            <div className="mainRoot">
                <EducationEditDetailHeaderContainer isEditing={this.isEditing} history={this.props.history} isEmpty={detailDocument.length===0}
                                                    urlLink={this.props.match.params.educationLink} user_role={this.props.user_role}
                                                    detailContent={this.detailContent} detailContentComponent={this.detailContentComponent}
                                                    />
                <div className="detail-content-root" ref={this.detailContent}>
                    <EducationEditDetailContainer urlLink={this.props.match.params.educationLink}
                                                    toggleIsEditing={this.toggleIsEditing}
                                                    ref={this.detailContentComponent} detailDocument={detailDocument} detailDocumentVersion={detailDocument}
                                                    detailContent={this.detailContent} isEditing={this.isEditing}/>
                </div>
            </div>
        )
    }
};

const mapStateToProps = (state,ownProps) => {
    let urlArr = ownProps.match.params.educationLink.split("-");
    return({
        user_role:state.sessionReducer.role_id,
        detailDocument: state.educationDetailReducer[urlArr[urlArr.length - 1]] ? state.educationDetailReducer[urlArr[urlArr.length - 1]].document : [],
        isEditExist: state.educationDetailReducer[urlArr[urlArr.length - 1]] ? state.educationDetailReducer[urlArr[urlArr.length - 1]].isEditExist : []
    });
};

export default connect(mapStateToProps)(EditEducationsDetailPage);