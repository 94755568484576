import React,{Component} from 'react';
import {answerSurveyAction, getMainTilesAction} from "../../actions/index";
import {connect} from "react-redux";
import MainBannerComponent from "../../components/home/mainBannerComponent";
import BannerItemComponent from "../../components/home/bannerItemComponent";

class BannerContainer extends Component{
    surveyIndex = 0;
    answerToSurvey = (selectedOption, survey) => {
        let params = {survey_id: survey.id, answer: selectedOption.id};
        this.props.dispatch(answerSurveyAction(params, (res) => {
            if (res === true) {
                this.surveyIndex++;
                this.forceUpdate();
            } else {

            }
        }));
    };
    refreshData = () => {
        this.props.dispatch(getMainTilesAction())
    };
    render(){
        let {mainTiles,survey} = this.props;
        return(
            <div className="promo-root">
                <div className="promo-block">

                    {/*Main tile*/}
                    {
                        (mainTiles && mainTiles.main) &&
                        <MainBannerComponent main={mainTiles.main} refreshData={this.refreshData} index={0}/>
                    }

                    <div className="box-block">
                        {
                            (mainTiles && mainTiles.threeTiles && mainTiles.threeTiles.length > 0) &&
                            <BannerItemComponent refreshData={this.refreshData} index={1} obj={mainTiles.threeTiles[0]}/>
                        }

                        {/*SURVEY*/}
                        <div className="box-item survey">

                            <div className="img-item">
                                <img
                                    src={survey && survey[this.surveyIndex] ? survey[this.surveyIndex].image : "/assets/img/common/survey-img.png"}
                                    alt=""/>
                            </div>
                            <div className="info-block">

                                        <span
                                            className="label-tag empty">{survey && survey[this.surveyIndex] ? survey[this.surveyIndex].name : "Anket"}</span>
                                {
                                    (survey && survey[this.surveyIndex] && survey[this.surveyIndex].question) ?

                                        <p>{survey[this.surveyIndex].question}</p>

                                        :

                                        <p>Şu an anketimiz bulunmuyor, Teşekkür ederiz</p>
                                }


                                {
                                    (survey && survey[this.surveyIndex] && survey[this.surveyIndex].options) &&

                                    <div className="btn-item">
                                        {
                                            survey[this.surveyIndex].options.map((optionItem, key) =>
                                                <span className="btn-1" key={key} onClick={() => {
                                                    this.answerToSurvey(optionItem, survey[this.surveyIndex])
                                                }}>{optionItem.option_name}</span>
                                            )
                                        }
                                    </div>
                                }

                            </div>
                        </div>

                        {
                            (mainTiles && mainTiles.threeTiles && mainTiles.threeTiles.length > 0) &&
                                <BannerItemComponent refreshData={this.refreshData} index={2} obj={mainTiles.threeTiles[1]}/>
                        }

                        {
                            (mainTiles && mainTiles.threeTiles && mainTiles.threeTiles.length > 0) &&
                            <BannerItemComponent refreshData={this.refreshData} index={3} obj={mainTiles.threeTiles[2]}/>
                        }

                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    mainTiles: state.homeReducer ? state.homeReducer.mainTiles : {},
    survey: state.homeReducer ? state.homeReducer.survey : {},
});

export default connect(mapStateToProps)(BannerContainer);