import React,{Fragment} from 'react';
import moment from 'moment';

const QuizResultStatusContainer = ({quizResults,quizInfo,quizData}) => {
    let pasts = quizResults.filter(item=> item.correct_count/item.total_count >= 0.5 );
    return(
        <Fragment>
            <div className="info-text-block">
                <p> <i className="icon-warn"></i> Quiz'i Tamamladınız!</p>
            </div>
            <div className="status-root">
                <div className="text-block">
                    <div className="left-text-item">
                        <h2>{quizData.name}</h2>
                        <div className="tag-block">
                            <span className="label-tag grey">#MESLEKİ EĞİTİM</span>
                            <span className="label-tag grey">#QUIZ</span>
                        </div>
                    </div>
                    <div className="icon-item">
                        <i className="icon-book"></i>
                    </div>

                </div>
                <div className="status-block">
                    <div className="status-item">
                        <div className="status-text-item">
                            <p>Quiz Süresi</p>
                            <p>{moment.utc(moment(quizInfo.end_date).diff(moment(quizInfo.started_date))).format("HH:mm:ss")}</p>
                        </div>
                        <div className="status-bar">
                            <div className="progress-item" style={{width:"100%"}}></div>
                        </div>
                    </div>
                    <div className="status-item green-progress">
                        <div className="status-text-item">
                            <p>Doğru Cevaplar</p>
                            <p className="green">%{((pasts.length*100) / quizResults.length).toFixed(2)} Başarı</p>
                            <p>Yanlış Cevaplar</p>
                        </div>
                        <div className="status-bar">
                            <div className="progress-item" style={{width:((pasts.length*100) / quizResults.length)+"%"}}></div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default QuizResultStatusContainer;