import {RECEIVE_SMART_DOCUMENT_DETAIL} from '../constants/actionTypes';
import {getSmartDocumentDetail, updateSmartDocumentDetail} from "../services/webService";

const receiveSmartDetail = (payload,id) => ({
    type: RECEIVE_SMART_DOCUMENT_DETAIL,
    payload,
    id
});

export const getSmartDetailAction = (params) => dispatch => {
    getSmartDocumentDetail(params).then((res) => {
        if (res.data.success) {
            dispatch(receiveSmartDetail(res.data.success,params.id))
        }
    })
};

export const updateSmartDocumentDetailAction = (params,cb) => dispatch => {
    updateSmartDocumentDetail(params).then((res)=>{
        if(res.data.success){
            cb(true);
        }else{
            cb(false);
        }
    }).catch((err)=>{
        cb(false);
    })
};