import React, {Component} from 'react';
import {Chart} from 'react-google-charts';

class EducationForMonthComponent extends Component{
    yearArr=[];

    constructor(props){
        super(props);
        let startYear = 2019;
        while (startYear<new Date().getFullYear()+1){
            this.yearArr.push(startYear);
            startYear++;
        }
        this.props && this.props.getData(this.props,new Date().getFullYear(),0,0,0,0,0,0);
    }
    locationOnChange = (e) => {
        this.props.getData(this.props,e.target.value,0,0,0,0,0,0);
    };
    render(){
        let {chartData} = this.props;
        return(

            <div className="chart-block">
                <div className="table-head">
                    <div></div>
                    <div className="select-container">
                        <div className="select-block">
                            {/*<label>Yıl Seçiniz</label>*/}
                            <select onChange={this.locationOnChange} defaultValue={new Date().getFullYear()}>
                                {
                                    this.yearArr.map((yearItem, key) =>
                                        <option key={key} value={yearItem}>{yearItem}</option>
                                    )
                                }
                            </select>
                            <i className="icon-arr-bottom"></i>
                        </div>
                    </div>
                </div>

                <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="400px"
                    data={[
                        ["Aylar", "Eğitim Sayısı", { role: "style" }],
                        ["Ocak", chartData.find(item=>item.monthIndex==="1")?parseInt(chartData.find(item=>item.monthIndex==="1").total_count):0, "#3366cc"],
                        ["Şubat", chartData.find(item=>item.monthIndex==="2")?parseInt(chartData.find(item=>item.monthIndex==="2").total_count):0, "#3366cc"],
                        ["Mart", chartData.find(item=>item.monthIndex==="3")?parseInt(chartData.find(item=>item.monthIndex==="3").total_count):0, "#3366cc"],
                        ["Nisan", chartData.find(item=>item.monthIndex==="4")?parseInt(chartData.find(item=>item.monthIndex==="4").total_count):0, "#3366cc"],
                        ["Mayıs", chartData.find(item=>item.monthIndex==="5")?parseInt(chartData.find(item=>item.monthIndex==="5").total_count):0, "#3366cc"],
                        ["Haziran", chartData.find(item=>item.monthIndex==="6")?parseInt(chartData.find(item=>item.monthIndex==="6").total_count):0, "#3366cc"],
                        ["Temmuz", chartData.find(item=>item.monthIndex==="7")?parseInt(chartData.find(item=>item.monthIndex==="7").total_count):0, "#3366cc"],
                        ["Ağustos", chartData.find(item=>item.monthIndex==="8")?parseInt(chartData.find(item=>item.monthIndex==="8").total_count):0, "#3366cc"],
                        ["Eylül", chartData.find(item=>item.monthIndex==="9")?parseInt(chartData.find(item=>item.monthIndex==="9").total_count):0, "#3366cc"],
                        ["Ekim", chartData.find(item=>item.monthIndex==="10")?parseInt(chartData.find(item=>item.monthIndex==="10").total_count):0, "#3366cc"],
                        ["Kasım", chartData.find(item=>item.monthIndex==="11")?parseInt(chartData.find(item=>item.monthIndex==="11").total_count):0, "#3366cc"],
                        ["Aralık", chartData.find(item=>item.monthIndex==="12")?parseInt(chartData.find(item=>item.monthIndex==="12").total_count):0, "#3366cc"]
                    ]}
                />
            </div>
        )
    }
};

export default EducationForMonthComponent;