import React, {Component, Fragment} from 'react'
import {connect} from "react-redux";
import {reduxForm, Field, initialize, reset, change} from "redux-form";
import inputFieldComponent from "../common/formFields/inputFieldComponent";
import radioFieldComponent from "../common/formFields/radioFieldComponent";
import fileFieldComponent from "../common/formFields/fileFieldComponent";
import Helper from '../../helper';
import {createNewEducationAction, saveEducationPictureAction} from "../../actions/panelAction";
import {EDITEDUCATIONDETAIL_WITHOUTPARAM} from "./../../constants/routePaths";
import LoaderModalComponent from "../common/loaderModalComponent";
import {getTagsAction, getTagsBySearchAction, receiveEducationCategories, searchAction} from "../../actions";
import SearchResultItemComponent from "../header/searchResultItemComponent";
import {getEducationInfo, getTagsBySearch} from "../../services/webService";
import PageService from "../../pageServices/pageService";
import {matchPath} from "react-router-dom";
import * as ROUTES from "../../constants/routePaths";
import {PANEL_DOCUMENTS_WITOUTPARAM} from "../../constants/routePaths";
import queryString from "query-string";
import {getEducationInfoAction} from "../../actions/educationDetailAction";
import SuccessModalComponent from "../common/successModalComponent";

const validate = (values) => {
    let hasErrors = false;
    let errors = {};
    if (!values.name || values.name.trim() === "") {
        hasErrors = true;
        errors.name = "Eğitim Adını Doldurmanız Gerekmektedir.";
    }
    if (!values.category_id || values.category_id.trim() === "") {
        hasErrors = true;
        errors.category_id = "Lütfen Eğitim Kategorisi Seçiniz.";
    }
    if (!values.image || values.image.trim() === "") {
        hasErrors = true;
        errors.image = "Lütfen Kapak Görseli Ekleyiniz.";
    }
    return hasErrors && errors;
};

class NewEducationModalComponent extends Component {
    picture;
    loaderIsShowing = false;
    searchResult = [];
    selectedTags = [];
    contentBlock = React.createRef();
    openSearchMenu = React.createRef();
    tags = [];
    successMesage = false;
    buttonText;
    headerText;
    descText;
    pictureTerm;

    constructor(props) {
        super(props);
        const urlParams = queryString.parse(this.props.location.search)
        if (urlParams.education_id === "0") {
            this.buttonText = "EĞİTİMİ YARAT"
            this.headerText = "Eğitim Yarat";
            this.descText = "Yeni bir eğitim dökümanı oluşturun."
        } else {
            this.buttonText = "EĞİTİMİ KAYDET"
            this.headerText = "Eğitim Düzenle";
            this.descText = "Eğitim dökümanını düzenleyin."
        }
        this.props.dispatch(getTagsAction({}, (res, data) => {
            if (res === true) {
                this.tags = data;
            }
            if (props.location && props.location.search && props.location.search !== "0") {
                const urlParams = queryString.parse(props.location.search)
                this.props.dispatch(getEducationInfoAction({education_id: urlParams.education_id}, (res, data) => {
                    if (res === true) {
                        this.initializeForm(data);
                    }
                }));
            }
        }));

        this.state = {
            isBlocking: false
        };

    }

    initializeForm = (value) => {
        this.props.dispatch(initialize('createNewEducation', {
            name: value["info"].name,
            category_id: value["info"].category_id,
            image: value["info"].image
        }));
        value["tags"].map((item, inx) => {
            this.selectedTags.push(this.tags.find(tag => tag.id === item.tag_id))
        });
        this.pictureTerm = value["info"].image;
        this.forceUpdate();
    };

    createNewEducation = (values) => {
        const urlParams = queryString.parse(this.props.location.search)
        if (urlParams.education_id !== "0") {
            values.id = urlParams.education_id;
        }
        if (this.selectedTags && this.selectedTags.length > 0) {
            values.tags = (this.selectedTags.map(x => parseInt(x.id)));
            delete values.tag_id;
        }else{
            values.tags = null;
        }

        this.loaderIsShowing = true;
        this.forceUpdate();
        if (this.pictureTerm !== values.image) {
            this.saveImg((imgPath) => {
                this.props.dispatch(createNewEducationAction({...values, image: imgPath}, (res, result) => {
                    this.loaderIsShowing = false;
                    this.forceUpdate();
                    if (res === true) {
                        let detail = `${Helper.turkishToLower(result.name).split(" ").join("-").split("/").join("").split("?").join("").split("?").join("")}-${result.id}`;
                        if (!this.props.fromUpdate) {
                            this.props.history.push(EDITEDUCATIONDETAIL_WITHOUTPARAM + detail);
                        } else {
                            this.successMesage = true;
                            setTimeout(() => {
                                this.props.toggleModal(false, "isNewEducation");
                            }, 3000);
                            this.errorMessage = null;
                            this.forceUpdate();
                            this.props.history.goBack();
                        }

                    } else {
                        this.forceUpdate();
                    }
                }))
            });
        } else {
            this.props.dispatch(createNewEducationAction({...values}, (res, result) => {
                this.loaderIsShowing = false;
                this.forceUpdate();
                if (res === true) {
                    let detail = `${Helper.turkishToLower(result.name).split(" ").join("-").split("/").join("").split("?").join("").split("?").join("")}-${result.id}`;
                    if (!this.props.fromUpdate) {
                        this.props.history.push(EDITEDUCATIONDETAIL_WITHOUTPARAM + detail);
                    } else {
                        this.successMesage = true;
                        setTimeout(() => {
                            this.props.toggleModal(false, "isNewEducation");
                        }, 3000);
                        this.errorMessage = null;
                        this.forceUpdate();
                        this.props.history.goBack();
                    }

                } else {
                    this.forceUpdate();
                }
            }))
        }
    };
    saveImg = (cb) => {
        this.props.dispatch(saveEducationPictureAction(this.picture, "survey", (res) => {
            if (res !== false) {
                cb(res);
            }
        }));
    };
    fileOnChangeFunc = (files) => {
        // this.props.dispatch(change('createNewEducation','image',files[0].name));
        this.picture = files[0];
    };

    searchFunc = (e) => {
        if (e.target.value.length > 2) {
            this.props.dispatch(getTagsBySearchAction(e.target.value, (result) => {
                if (this.selectedTags.length > 0) {
                    this.searchResult = this.dontShowInArrayIfSelected(result);
                } else {

                    this.searchResult = result;
                }
                this.forceUpdate();
                if (this.openSearchMenu.current) {
                    this.openSearchMenu.current.style.display = "block";
                }
            }));
        } else {
            this.searchResult = [];
            this.forceUpdate();
            if (this.openSearchMenu.current) {
                this.openSearchMenu.current.style.display = "none";
            }

        }
    };

    dontShowInArrayIfSelected = (result) => {

        this.selectedTags.some(resultItem => {
            result = result.filter(item => {
                if (resultItem.id !== item.id) {
                    return item;
                }
            })
        });
        return result;
    };

    tagSearchClicked = (item, key) => {
        this.selectedTags.push(item);
        this.props.dispatch(change('createNewEducation', "tag_id", ''));
        this.forceUpdate();
        this.openSearchMenu.current.style.display = "none";
    };

    render() {
        let {toggleModal, urlLink, locations, handleSubmit, educationCategories, formErrors, submitFailed, detailInfo, fromUpdate} = this.props;
        let errArr = formErrors ? Object.keys(formErrors) : {};
        return (
            <Fragment>
                {
                    this.successMesage !== true &&
                    <div className="lb-root" onClick={(e) => {
                        if (this.contentBlock.current.contains(e.target) === false) {
                            if (fromUpdate) {
                                toggleModal(false);
                            } else {
                                toggleModal(false, "isNewEducation");
                            }
                            this.props.history.goBack();
                        }
                    }}>
                        <div className="lb-block">
                            <div ref={this.contentBlock} className="content-block add-part-block add-new-block">

                                <form className="content-item" onSubmit={handleSubmit(this.createNewEducation)}>

                                    <div className="head-block">
                                        <h2>{this.headerText}</h2>
                                        <p>{this.descText}</p>
                                    </div>

                                    <i className="icon-close" onClick={() => {
                                        if (fromUpdate) {
                                            toggleModal(false);
                                        } else {
                                            toggleModal(false, "isNewEducation");
                                        }
                                        this.props.history.goBack();
                                    }}></i>


                                    <div className="form-block">

                                        <div className="input-container">
                                            <div className="input-block">
                                                <Field
                                                    name="name"
                                                    label="EĞİTİM ADI "
                                                    type="text"
                                                    component={inputFieldComponent}
                                                    example="Örn: Makineci - Dikim Elemanı"
                                                />
                                                {/*<div className="input-item">,
                                             <label>EĞİTİM ADI <span>Örn: Makineci - Dikim Elemanı</span></label>
                                             <input type="text"/>
                                             </div>*/}
                                            </div>

                                        </div>

                                        {
                                            educationCategories &&
                                            <Field
                                                name="category_id"
                                                label="EĞİTİM TİPİ"
                                                component={radioFieldComponent}
                                                options={Helper.dataStandardized(educationCategories, "name", "id")}
                                                example="Döküman seçeceğiniz tipe göre menüde konumlandırılacaktır."
                                            />
                                        }

                                        <Field
                                            name="tag_id"
                                            type="text"
                                            label="ETİKET"
                                            placeholder="Etiket adı giriniz..."
                                            component={inputFieldComponent}
                                            onChange={this.searchFunc}
                                            // options={Helper.dataStandardized(educationCategories, "name", "id")}
                                            example=""
                                        />

                                        <div className="selected-tag-block">
                                            {
                                                this.selectedTags.length > 0 &&
                                                this.selectedTags.map((item, inx) =>
                                                        <span className="selected-tag" key={inx}>{item.tag_name}
                                                            <i className="icon-close" onClick={() => {
                                                                this.selectedTags.splice(this.selectedTags.indexOf(item), 1)
                                                                this.forceUpdate();
                                                            }}></i>
                                                </span>
                                                )
                                            }
                                            {
                                                this.searchResult.length > 0 &&
                                                <div className="open-tags-root" ref={this.openSearchMenu}>
                                                    <ul>
                                                        {
                                                            this.searchResult.map((item, key) =>
                                                                <li key={key} onClick={() => {
                                                                    this.tagSearchClicked(item, key);
                                                                }}>{item.tag_name}</li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                        <Field
                                            name="image"
                                            label="KAPAK GÖRSELİ "
                                            classTag="after-tag-input"
                                            component={fileFieldComponent}
                                            example="Yükleyeceğiniz görsel boyutunun en düşük 1000x1000px olmalıdır."
                                            fileOnChangeFunc={this.fileOnChangeFunc}
                                        />
                                    </div>
                                    <div className="bottom-btn-item">
                                        <p>{submitFailed === true && errArr.length > 0 ? formErrors[errArr[0]] : ""}</p>
                                        <button type="submit" className="btn-green">{this.buttonText}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                }

                {
                    this.loaderIsShowing === true && <LoaderModalComponent/>
                }
                {
                    this.successMesage === true && <SuccessModalComponent
                        message={this.props.fromUpdate ? "Eğitim güncellendi." : "Eğitim oluşturuldu."}/>
                }
            </Fragment>
        )
    }
};

const mapStateToProps = (state, ownProps) => {
    const values = queryString.parse(ownProps.location.search);
    return ({
        formErrors: state.form.createNewEducation ? state.form.createNewEducation.syncErrors : {},
        // detailInfo: state.educationDetailReducer[urlArr[urlArr.length - 1]] ? state.educationDetailReducer[urlArr[urlArr.length - 1]].info : null,
        educationCategories: state.homeReducer.educationCategories,
    })
};

NewEducationModalComponent = reduxForm({
    form: "createNewEducation",
    validate
})(NewEducationModalComponent);

export default connect(mapStateToProps)(NewEducationModalComponent);
