import {RECEIVE_SESSION_USER,LOGIN_DATA,UPDATE_NOTIFICATION_COUNT} from '../constants/actionTypes';

const initialState = {isLogin:false};

const SessionReducer = (state=initialState,action) => {
    switch (action.type){
        case RECEIVE_SESSION_USER:
            return{
                ...state,
                ...action.payload,
                isLogin:!!action.payload
            };
        case LOGIN_DATA:
            return{
                ...state,
                ...action.payload,
                isLogin:true
            };
        case UPDATE_NOTIFICATION_COUNT:
            return{
                ...state,
                notification_count:action.payload
            };
        default:
            return state;
    }
};

export default SessionReducer;