import React, {Component,Fragment} from 'react';
import {connect} from "react-redux";
import NewQuizFormComponent from "../Panel/newQuizFormComponent";
import CreateQuizQuestionsComponent from "../Panel/createQuizQuestionsComponent";
import {createQuizAction, getQuizAction} from "../../actions/quizAction";
import LoaderModalComponent from "../common/loaderModalComponent";
import SuccessModalComponent from "../common/successModalComponent";
import {receivePanelQuiz} from "../../actions/panelAction";

class CreateQuizModalComponent extends Component {
    contentBlock = React.createRef();
    educationId;
    activeStep = "1";
    questions;
    loaderIsShowing = false;
    successMesage = false;
    constructor(props){
        super(props);
        if(props.editedQuizId){
            this.activeStep = "2";
            this.educationId = props.editedQuizId;
            props.dispatch(getQuizAction({education_id:props.editedQuizId},(res)=>{
                if(res !==false && res.questions.length>0){
                    this.questions = res.questions;
                    this.forceUpdate();
                }
            }))
        }
    }
    // activeStep = "2";
    goToStep = (step,educationId) => {
        this.activeStep = step;
        this.educationId = educationId?educationId:this.educationId;
        this.forceUpdate();
    };
    saveQuiz = (params) => {
        this.loaderIsShowing = true;
        this.forceUpdate();
        this.props.dispatch(createQuizAction(params,(res,massage)=>{
            this.loaderIsShowing = false;
            if(res===true){
                if(!this.props.editedQuizId){
                    this.props.dispatch(receivePanelQuiz({quiz:massage},false));
                }
                setTimeout(()=>{
                    this.props.toggleModal(false,"isNewQuiz");
                },3000);
                this.successMesage = true;
                this.forceUpdate();
            }else{
                this.forceUpdate();
            }
        }));
    };
    render() {
        let {toggleModal} = this.props;
        return (
            <Fragment>
                {
                    this.successMesage === false &&
                    <div className="lb-root" onClick={(e) => {
                        if (this.contentBlock.current.contains(e.target) === false) {
                            toggleModal(false, "isNewQuiz")
                        }
                    }}>
                        <div className="lb-block">
                            {
                                this.activeStep === "1" &&
                                <div ref={this.contentBlock} className="content-block add-part-block">
                                    <NewQuizFormComponent goToStep={this.goToStep} toggleModal={toggleModal}/>
                                </div>
                            }
                            {
                                this.activeStep === "2" &&
                                <div ref={this.contentBlock} className="content-block add-part-block add-quiz-root">
                                    <CreateQuizQuestionsComponent saveQuiz={this.saveQuiz} questions={this.questions} educationId={this.educationId} goToStep={this.goToStep} toggleModal={toggleModal}/>
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    this.loaderIsShowing === true && <LoaderModalComponent/>
                }
                {
                    this.successMesage === true && <SuccessModalComponent message={this.props.editedQuizId ? "Quiz güncellendi.":"Quiz eklendi."}/>
                }
            </Fragment>
        )
    }
}
;



export default connect()(CreateQuizModalComponent);