import async from "async";
import * as WebService from '../services/webService';
import {getEducationCategories} from "../actions";

export const getAllEducationsDataInit = (dispatch) => {
    return new Promise((resolve, failure) => {
        async.series([
            (cb) => {
                WebService.getEducationCategories().then((res) => {
                    if (res.data.success) {
                        cb(null, res.data.success);
                    }
                })
                    .catch((err) => {

                    })
            }
        ], (err, result) => {
            dispatch(getEducationCategories(result[0]));
            resolve(result);
        });
    });


};
