import React from 'react';
import PlannedEducationItemComponent from "../../components/home/plannedEducationItemComponent";

const PlannedEducationsContainers = ({featureCourses}) => (

    <div className="calendar-container">
        <div className="head-block">
            <h2>GELECEK EĞİTİMLER</h2>
            <p>Planlanmış eğitimleri görüntüleyebilirsiniz.</p>
        </div>
        <div className="calendar-block">
            {
                featureCourses && featureCourses.map((course,i)=>
                    <PlannedEducationItemComponent key={i} {...course}/>
                )
            }
        </div>
    </div>
);

export default PlannedEducationsContainers;